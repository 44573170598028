import { Component, OnInit } from '@angular/core';
import { APIService } from 'app/shared/api.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
    selector: 'app-dev-api-public',
    templateUrl: './dev-api-public.component.html',
    styleUrls: ['./dev-api-public.component.css'],
    standalone: false
})
export class DevApiPublicComponent implements OnInit {

  apikey: any;
  api: any;
  assets: any[];
  totals = {
    pushedCountDay: 0
  };
  canRefresh: boolean;
  isLoading: boolean;
  nothingToDo: boolean;

  filters: string[] = [];

  constructor(private apiService: APIService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.apikey = params['key'];
      this.apiService
        .apiController('get', 'list')
        .then(apis => {
          try {
            this.api = apis.apikeys.filter(api => api.apikey === this.apikey)[0];
            console.log(this.api);
          } catch (error) {
            this.router.navigate(['developer']);
          }
        });
    });
  }

  addSettingToFilter(setting: string) {
    console.log('add', setting, ' fo filters');
    this.filters.push(setting);
    const filters = [...this.filters];
    this.filters = filters;
  }
}
