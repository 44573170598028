<article class="content-container">
  @defer (when buildings()) {
    <div class="no-print">
      <app-toolbar>
        <div class="left">
          <app-button [label]="building()?.title || 'Select Building'" (click)="dialogOpen.set('buildings')" />
          @if (building() && false) {
            <app-button (click)="dialogOpen.set('help')" label="Configure Building" icon="mdi mdi-cog-outline" />
          }
        </div>
        <div class="right">
          <app-button label="Help" (click)="dialogOpen.set('help')" icon="mdi mdi-book-open-blank-variant-outline" />
          @if (building() && buildingReviewConfig()) {
            @if (buildingReviewConfig()?.collection.id) {
              <app-button [label]="buildingReviewConfig()?.review?.next.isOverdue ? 'Create Review (is due)' : 'Create Review'" icon="mdi mdi-plus" (click)="createReviewClick()" [isCTA]="buildingReviewConfig()?.review?.next.isOverdue" />
            } @else {
              <app-button label="No Collection" icon="mdi mdi-alert-outline" pTooltip="Add collection in configuration" />
            }
          }
        </div>
      </app-toolbar>
    </div>

    <!-- TABS -->
    @if (isFirstTimeUsing()) {
      <app-insights-review-first-time />
    }

    @if (buildingReviewConfig()?.building) {
      <header class="no-print">
        <app-toolbar size="small">
          <div class="right">
            @if (buildingReviewConfig()?.review?.next.title) {
              <div class="review-title">
                Next review, {{ buildingReviewConfig()?.review.next.title }},
                @if (buildingReviewConfig()?.review?.next.isOverdue) {
                  is due now.
                } @else {
                  is due {{ buildingReviewConfig()?.review?.next.end | amTimeAgo }}.
                }
              </div>
            }
          </div>
        </app-toolbar>
      </header>
      <div class="tabview-list no-print">
        <div [class.active]="tabIndex() === 0" (click)="tabIndex.set(0)"><i class="mdi mdi-view-list"></i> Reviews</div>
        <div [class.active]="tabIndex() === 1" (click)="tabIndex.set(1)"><i class="mdi mdi-view-list"></i> Configuration</div>
        @for (reviewItem of reviewItems(); track $index; let i = $index) {
          <div [class.active]="tabIndex() === 2 + i" (click)="tabIndex.set(2 + i)">
            <i class="mdi mdi-file-document-outline"></i>
            {{ reviewItem.title }} <i class="mdi mdi-close can-hover" (click)="reviewOnClose(i)"></i>
          </div>
        }
        <div></div>
      </div>

      <section [hidden]="tabIndex() !== 1">
        <div class="flex">
          <div class="flex-1">
            <h4 class="mt-1"><span pTooltip="The address of the building." tooltipPosition="right">Address</span></h4>
            <div>{{ building()?.site.address.address1 }}</div>
            <div>{{ building()?.site.address.addressTown }}</div>
            <div>{{ building()?.site.address.addressPostcode }}</div>
            @if (building()) {
              <h4 class="mt-1">Operating Hours</h4>
              <app-site-hours [buildingId]="building().id" view="display" />
            }
          </div>

          <div class="flex-1 border border-dotted">
            <div class="flex">
              <div class="flex-1 mr-1">
                <h4 pTooltip="The number of months of data to include in the review, between 1 and 6." tooltipPosition="left">
                  Number of months
                  @if (!buildingReviewConfig()?.noOfMonths) {
                    <i class="mdi mdi-asterisk red" pTooltip="required"></i>
                  }
                </h4>
                <input class="fourd" [style.width.px]="90" type="number" [(ngModel)]="buildingReviewConfig().noOfMonths" max="6" min="1" />
                <h4 pTooltip="General notes about reviews for this building." tooltipPosition="left" class="mt-1">Notes</h4>
                <textarea [(ngModel)]="buildingReviewConfig().notes" class="fourd" rows="6"></textarea>
              </div>
              <div class="">
                <h4 pTooltip="The month to start the next review." tooltipPosition="left">
                  Next Review
                  @if (!buildingReviewConfig()?.review?.next.start) {
                    <i class="mdi mdi-asterisk red" pTooltip="required"></i>
                  }
                </h4>
                <app-month-select [value]="buildingReviewConfig().review.next.start" (onSelected)="nextReviewDateChange($event)" />
              </div>
            </div>

            <div class="text-right mt-1">
              <app-button label="Update" icon="mdi mdi-check" (click)="clickUpdateMonthsAndNotes()" />
            </div>
          </div>
        </div>
        @if (buildingReviewConfig()?.noOfMonths && buildingReviewConfig()?.review?.next.end) {
          <div>
            <h2>
              @if (buildingReviewConfig().collection?.title) {
                '{{ buildingReviewConfig().collection.title }}' collection Assets
              } @else {
                @if (!buildingReviewConfig().collections?.length) {
                  <div class="flex flex-centre">
                    <div>No Collection attached, to continue, go to</div>
                    <div>
                      &nbsp;
                      <app-button label="Site collections" size="medium" icon="mdi mdi-link" [routerLink]="['/org', 'sites', building()?.site.id]" [queryParams]="{ tab: 'collections' }" />
                      &nbsp;
                    </div>
                    <div>and select or create a collection.</div>
                  </div>
                }
              }
            </h2>
            @if (buildingReviewConfig().collections?.length) {
              <app-toolbar>
                <div class="right">
                  <app-button label="Go to site collections" icon="mdi mdi-link" [routerLink]="['/org', 'sites', building()?.site.id]" [queryParams]="{ tab: 'collections' }" />
                  <app-button label="Change Collection" icon="mdi mdi-pen" (click)="changeCollectionClick()" />
                </div>
              </app-toolbar>
              <table class="table">
                <thead>
                  <tr>
                    <th colspan="1">Asset</th>
                    <th colspan="1">Gateway</th>
                  </tr>
                </thead>
                <tbody>
                  @for (asset of buildingReviewConfig().collection.assets; track asset.id) {
                    <tr>
                      <td><app-asset-icon [asset]="asset" /> {{ asset.title }}</td>
                      <td>
                        <span class="dim" pTooltip="Gateway ID">{{ asset.gateway.id }}</span> {{ asset.gateway.title }}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            }
          </div>
        } @else {
          <app-info-panel> Please set the "Number of months" and "Next Review" date, then click "Update" before attaching a collection. </app-info-panel>
        }
      </section>

      @if (reviewsListItems()) {
        <section [hidden]="tabIndex() !== 0">
          <app-insights-reviews-list [reviews]="reviewsListItems()" (onReviewClick)="reviewClick($event)" />
          <div class="mt-1">
            @if (documents()) {
              <table class="table">
                <thead>
                  <tr>
                    <th>Document Title</th>
                    <th>Calendar</th>
                    <th class="text-right">Document</th>
                  </tr>
                </thead>
                <tbody>
                  @for (document of documents(); track document.key) {
                    <tr>
                      <td>{{ document.title }}</td>
                      <td>{{ document.calendarStartAt | date: 'dd/MM/yyyy' }}</td>
                      <td class="text-right"><a target="_blank" href="https://s3.eu-west-2.amazonaws.com/4d-documents/{{ document.key }}">Download</a></td>
                    </tr>
                  }
                </tbody>
              </table>
            } @else {
              @if (can.admin) {
                <app-button label="Load Documents" (click)="getDocumentsForBuilding()" />
              }
            }
          </div>
        </section>
      } @else {
        @if (!reviewsListItems()) {
          <i class="mdi mdi-spin-x2 mdi-loading"></i>
        }
      }
    }
    @if (reviewItems()?.length) {
      @for (review of reviewItems(); track review.review.dates.start; let i = $index) {
        @if (tabIndex() === i + 2) {
          <section>
            <app-insights-review-item [review]="review" [config]="buildingReviewConfig()" (onClose)="reviewOnClose(i)" />
          </section>
        }
      }
    }

    <!-- DIALOGS -->

    @switch (dialogOpen()) {
      @case ('buildings') {
        <app-dialog header="Select a building" [canScroll]="true" (onClose)="buildingChange(null)">
          <section>
            <app-search-input [searchText]="searchFor" (onSearchChanged)="searchChanged($event)" tooltipText="Search"></app-search-input>
          </section>
          <table class="table table-hover" aria-label="Buildings">
            <thead>
              <tr>
                <th colspan="1">Building</th>
                <th colspan="3">Address</th>
              </tr>
            </thead>
            <tbody>
              @for (building of filteredBuildings(); track building.id) {
                <tr (click)="buildingChange(building)">
                  <td>{{ building.title }}</td>
                  <td>{{ building.site.address.addressTown }}</td>
                  <td>{{ building.site.address.addressCounty }}</td>
                  <td>{{ building.site.address.addressPostcode }}</td>
                </tr>
              }
            </tbody>
          </table>
        </app-dialog>
      }
      @case ('create') {
        <app-dialog [dimensions]="{ width: 340, height: 490 }" header="Configure Report" [canScroll]="false" (onClose)="dialogOpen.set(null)">
          <main class="content-container">
            <section>
              <h3>Start of report</h3>
              <app-month-select [value]="buildingReviewConfig()?.review?.next.start" (onSelected)="monthChange($event)"></app-month-select>
            </section>
            <section>
              <h3>Number of months</h3>
              @for (month of [1, 2, 3, 4, 5, 6]; track $index) {
                <app-chip label="{{ month }} months" [isRadio]="true" [checked]="month === noOfMonths" (onChanged)="setMonths(month)"></app-chip>
              }
            </section>
            <section class="mt-1">
              <app-toolbar [hasBackground]="false">
                <div class="left message">
                  {{ message() }}
                </div>
                <div class="right">
                  <app-button label="Create" [isCTA]="true" icon="mdi mdi-check" (click)="reviewMonthClick(null)"></app-button>
                </div>
              </app-toolbar>
            </section>
          </main>
        </app-dialog>
      }
      @case ('help') {
        <app-dialog [dimensions]="{ width: 700, height: 500 }" header="Building Review Help" [canScroll]="true" (onClose)="dialogOpen.set(null)">
          <app-insights-review-help />
        </app-dialog>
      }
      @case ('collections') {
        <app-dialog [dimensions]="{ width: 700, height: 500 }" header="Available collections" [canScroll]="true" (onClose)="dialogOpen.set(null)">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Title</th>
                <th>Assets</th>
              </tr>
            </thead>
            <tbody>
              @for (collection of collections(); track collection.id) {
                <tr (click)="collectionClick(collection.id)">
                  <td>{{ collection.title }}</td>
                  <td>{{ collection.assets.length }}</td>
                </tr>
              }
            </tbody>
          </table>
        </app-dialog>
      }
    }
  } @placeholder {
    <i class="mdi-loading mdi mdi-spin-x2"></i>
  }
  @if (isLoadingBuilding()) {
    <i class="mdi-loading mdi mdi-spin-x2"></i>
  }
</article>
