import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SitesDashboardComponent } from './dashboards/sites-dashboard/sites-dashboard.component';
import { SitesDashboardV2Component } from './dashboards/sites-dashboard-v2/sites-dashboard-v2.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProfileComponent } from './profile/profile.component';
import { OrgDashboardComponent } from './org-dashboard/org-dashboard.component';
import { DashboardTagComponent } from './dashboards/dashboard-tag/dashboard-tag.component';
import { DashboardSiteListComponent } from './dashboards/dashboard-site-list/dashboard-site-list.component';
import { DashboardAssetListComponent } from './dashboards/dashboard-asset-list/dashboard-asset-list.component';
import { AlarmActionFromTokenComponent } from './alarm-action-from-token/alarm-action-from-token.component';
import { FootfallDashboardComponent } from './footfall/footfall-dashboard/footfall-dashboard.component';
import { ReportListComponent } from './reports/report-list/report-list.component';
import { ReportViewComponent } from './reports/report-view/report-view.component';
import { ReportConfigureComponent } from './reports/report-configure/report-configure.component';
import { SetpointListComponent } from './setpoints/setpoint-list/setpoint-list.component';
import { LiveGatewayListComponent } from './live/live-gateway-list/live-gateway-list.component';
import { InviteLandingComponent } from './invite/invite-landing/invite-landing.component';
import { DeviceLandingComponent } from './device/device-landing/device-landing.component';
import { SiteSubmitComponent } from './site-submit/site-submit.component';
import { TrainingLandingComponent } from './training/training-landing/training-landing.component';
import { TrainingOverviewComponent } from './training/training-overview/training-overview.component';
import { OrderingLandingComponent } from './ordering/ordering-landing/ordering-landing.component';
import { OrderCreateComponent } from './ordering/order-create/order-create.component';
import { OrderViewComponent } from './ordering/order-view/order-view.component';
import { RulesEngineV2Component } from './rules-engine/v2/rules-engine-v2.component';
import { RuleEngineV2ItemComponent } from './rules-engine/v2/rule-engine-v2-item/rule-engine-v2-item.component';
import { RuleEngineV2NewComponent } from './rules-engine/v2/rule-engine-v2-new/rule-engine-v2-new.component';
import { AuthGuardService } from './shared/route-guard.service';
import { IssuesComponent } from './issues/issues.component';
import { IssueUpdateComponent } from './issues/issue-update/issue-update.component';
import { IssueDashboardComponent } from './issues/issue-dashboard/issue-dashboard.component';
import { LiveEmbedComponent } from './live/live-embed/live-embed.component';
import { CmsVersionsComponent } from './cms/cms-versions/cms-versions.component';
import { PartnerLandingComponent } from './partner/partner-landing/partner-landing.component';
import { PartnerContainerComponent } from './partner/partner-container/partner-container.component';
import { CameraContainerComponent } from './cameras/camera-container/camera-container.component';
import { CameraLandingComponent } from './cameras/camera-landing/camera-landing.component';
import { CameraLightmonitorComponent } from './cameras/camera-lightmonitor/camera-lightmonitor.component';
import { TrainingContainerComponent } from './training/training-container/training-container.component';
import { TrainingCourseSelectorComponent } from './training/training-course-selector/training-course-selector.component';
import { ExportContainerComponent } from './export/export-container/export-container.component';
import { ExportLandingComponent } from './export/export-landing/export-landing.component';
import { JourneyContainerComponent } from './journey/journey-container/journey-container.component';
import { JourneyLandingComponent } from './journey/journey-landing/journey-landing.component';
import { AcceptTermsComponent } from './terms/accept-terms/accept-terms.component';
import { ProfilingContainerComponent } from './profiling/profiling-container/profiling-container.component';
import { ProfilingLandingComponent } from './profiling/profiling-landing/profiling-landing.component';
import { TermsComponentComponent } from './policies/terms-component/terms-component.component';
import { PrivacyComponent } from './policies/privacy/privacy.component';
import { CookiesComponent } from './policies/cookies/cookies.component';
import { AcceptableTermsComponent } from './policies/acceptable-terms/acceptable-terms.component';
import { DataTermsComponent } from './policies/data-terms/data-terms.component';
import { PoliciesContainerComponent } from './policies/policies-container/policies-container.component';
import { CameraControlComponent } from './cameras/camera-control/camera-control.component';
import { PartnerUserListComponent } from './partner/partner-user-list/partner-user-list.component';
import { TicketViewComponent } from './tickets/ticket-view/ticket-view.component';
import { SitePlanComponent } from './profiling/site-plan/site-plan.component';
import { SetpointDetailComponent } from './setpoints/setpoint-detail/setpoint-detail.component';
import { MonitorContainerComponent } from './monitor/monitor-container/monitor-container.component';
import { MonitorLandingComponent } from './monitor/monitor-landing/monitor-landing.component';
import { ReviewsContainerComponent } from './reviews/reviews-container/reviews-container.component';
import { ReviewsListComponent } from './reviews/reviews-list/reviews-list.component';
import { ReviewNewComponent } from './reviews/review-new/review-new.component';
import { ReviewUpdateComponent } from './reviews/review-update/review-update.component';
import { RuleEngineV2ActionComponent } from './rules-engine/v2/rule-engine-v2-action/rule-engine-v2-action.component';
import { SiteDashboardComponent } from './sites/site-dashboard/site-dashboard.component';
import { SetpointContainerComponent } from './setpoints/setpoint-container/setpoint-container.component';
import { SetpointOverviewComponent } from './setpoints/setpoint-overview/setpoint-overview.component';
import { DashboardLandingComponent } from './dashboard/dashboard-landing/dashboard-landing.component';
import { ChartsLandingComponent } from './charts/charts-landing/charts-landing.component';
import { ManageLandingComponent } from './manage/manage-landing/manage-landing.component';
import { DevLandingComponent } from './dev/dev-landing/dev-landing.component';
import { HomepageComponent } from './homepage/homepage.component';
import { DevCreateKeyComponent } from './dev/dev-create-key/dev-create-key.component';
import { DevApiPublicComponent } from './dev/dev-api-public/dev-api-public.component';
import { OccupancyLandingComponent } from './occupancy/occupancy-landing/occupancy-landing.component';
import { BuildingLandingComponent } from './buildings/building-landing/building-landing.component';
import { DevApiUpdateComponent } from './dev/dev-api-update/dev-api-update.component';
import { EngageContainerComponent } from './engage/engage-container/engage-container.component';
import { EngageLandingComponent } from './engage/engage-landing/engage-landing.component';
import { EngageUsersComponent } from './engage/engage-users/engage-users.component';
import { ReportAirqualityComponent } from './reports/report-airquality/report-airquality.component';
import { Rulesv3ItemComponent } from './rulesv3/rulesv3-item/rulesv3-item.component';
import { OrgComplianceAddComponent } from './org-dashboard/org-compliance-add/org-compliance-add.component';
import { OrgComplianceItemComponent } from './org-dashboard/org-compliance-item/org-compliance-item.component';
import { BillingBuildingsHomeComponent } from './billing/billing-buildings-home/billing-buildings-home.component';
import { BillingBuildingsListComponent } from './billing/billing-buildings-list/billing-buildings-list.component';
import { InsightsAirqualityComponent } from './insights/insights-airquality/insights-airquality.component';
import { ProfilingAddComponent } from './profiling/profiling-add/profiling-add.component';
import { AssetsOfflineComponent } from './issues/assets-offline/assets-offline.component';
import { InsightsLandingComponent } from './insights/insights-landing/insights-landing.component';
import { InsightsFfLandingComponent } from './insights/ff/insights-ff-landing/insights-ff-landing.component';
import { InsightsAsLandingComponent } from './insights/as/insights-as-landing/insights-as-landing.component';
import { SchematicsLandingComponent } from './schematics/schematics-landing/schematics-landing.component';
import { SetpointSummaryComponent } from './setpoints/setpoint-summary/setpoint-summary.component';
import { TelemetryListComponent } from './telemetry/telemetry-list/telemetry-list.component';
import { SetpointTemplatesComponent } from "./setpoints/setpoint-templates/setpoint-templates.component";
import { SetpointTemplateItemComponent } from "./setpoints/setpoint-templates/setpoint-template-item/setpoint-template-item.component";
import { SetpointTemplateItemSchedulerComponent } from "./setpoints/setpoint-template-item-scheduler/setpoint-template-item-scheduler.component";
import { SetpointSchedulesComponent } from './setpoints/setpoint-schedules/setpoint-schedules.component';
import { SetpointSchedulerItemComponent } from './setpoints/setpoint-schedules/setpoint-scheduler-item/setpoint-scheduler-item.component';
import { SitePlanDashboardContainerComponent } from './profiling/site-plan/site-plan-dashboard/site-plan-dashboard-container/site-plan-dashboard-container.component';
import { InsightsAsListComponent } from './insights/as/insights-as-list/insights-as-list.component';
import { OrgUserInviteComponent } from './org-dashboard/org-user-invite/org-user-invite.component';
import { SitePlanV2Component } from './site-plan/site-plan-v2/site-plan-v2.component';
import { ReportTriggerComponent } from './reports/report-trigger/report-trigger.component';
import { ReportCustomComponent } from './reports/report-custom/report-custom.component';
import { EnergyHomeComponent } from './energy/energy-home/energy-home.component';
import { EnergySiteComponent } from './energy/energy-site/energy-site.component';
import { InsightsReviewsLandingComponent } from "./insights/reviews/insights-reviews-landing/insights-reviews-landing.component";
import { AutomationReviewComponent } from './automation/insights/automation-review/automation-review.component';
import { EngageConfigComponent } from './engage/engage-config/engage-config.component';
import { IssueTrackingHomeComponent } from './issue-tracking/issue-tracking-home/issue-tracking-home.component';
import { IssueTrackingAdminComponent } from './issue-tracking/issue-tracking-admin/issue-tracking-admin.component';
import { IssueTrackingListComponent } from './issue-tracking/issue-tracking-list/issue-tracking-list.component';

const routes: Routes = [
	{ path: '', redirectTo: '/login', pathMatch: 'full' },
	{ path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
	{ path: 'alarm/token/:tokenid', component: AlarmActionFromTokenComponent },
	{ path: 'alarm/token/:tokenid/pause', component: AlarmActionFromTokenComponent },
	{ path: 'alarm/token/:tokenid/paused', component: AlarmActionFromTokenComponent },
	{ path: 'alarm/token/:tokenid/acknowledge', component: AlarmActionFromTokenComponent },
	{ path: 'alarm/token/:tokenid/acknowledged', component: AlarmActionFromTokenComponent },
	{ path: 'alarm/token/:tokenid/resolve', component: AlarmActionFromTokenComponent },
	{ path: 'alarm/token/:tokenid/managesub', component: AlarmActionFromTokenComponent },
	{ path: 'alarm/token/:tokenid/resolved', component: AlarmActionFromTokenComponent },
	{ path: 'automation/token/:tokenid/review', component: AutomationReviewComponent },
	{ path: 'buildings/:buildingid', component: BuildingLandingComponent },
	{
		path: 'camera', component: CameraContainerComponent, canActivate: [AuthGuardService], children: [
			{ path: '', component: CameraLandingComponent, canActivate: [AuthGuardService] },
			{ path: 'lightmonitor/:assetid', component: CameraLightmonitorComponent, canActivate: [AuthGuardService] },
			{ path: ':assetid/control', component: CameraControlComponent, canActivate: [AuthGuardService] },
		]
	},
	{ path: 'charts', component: ChartsLandingComponent, canActivate: [AuthGuardService] },
	{ path: 'dashboard', component: SitesDashboardV2Component, canActivate: [AuthGuardService] },
	{ path: 'dashboardv1', component: SitesDashboardComponent, canActivate: [AuthGuardService] },
	{ path: 'dashboard/:tag', component: DashboardTagComponent, canActivate: [AuthGuardService] },
	{ path: 'dashboard/:tag/site/:siteid', component: DashboardTagComponent, canActivate: [AuthGuardService] },
	{ path: 'developer', component: DevLandingComponent },
	{ path: 'developer/api/new', component: DevApiUpdateComponent },
	{ path: 'developer/keys/:key', component: DevApiPublicComponent },
	{ path: 'developer/keys/create', component: DevCreateKeyComponent },
	{ path: 'device/token/:tokenid', component: DeviceLandingComponent },
	{ path: 'energy', component: EnergyHomeComponent, canActivate: [AuthGuardService] },
	{ path: 'energy/site/:siteid', component: EnergySiteComponent, canActivate: [AuthGuardService] },
	{
		path: 'engagement', component: PartnerContainerComponent, canActivate: [AuthGuardService], children: [
			{ path: '', redirectTo: 'org', pathMatch: 'full' },
			{ path: 'org', component: PartnerLandingComponent, canActivate: [AuthGuardService] },
			{ path: ':orgid/users', component: PartnerUserListComponent, canActivate: [AuthGuardService] }
		]
	},
	{
		path: 'engage', component: EngageContainerComponent, canActivate: [AuthGuardService], children: [
			{ path: '', component: EngageLandingComponent, canActivate: [AuthGuardService] },
			{ path: 'users', component: EngageUsersComponent, canActivate: [AuthGuardService] },
			{ path: 'config', component: EngageConfigComponent, canActivate: [AuthGuardService] }
		]
	},
	{
		path: 'export', component: ExportContainerComponent, children: [
			{ path: '', component: ExportLandingComponent, canActivate: [AuthGuardService] },
			{ path: ':table', component: ExportLandingComponent, canActivate: [AuthGuardService] }
		]
	},
	{ path: 'floorplan/:floorplanid', component: SitePlanV2Component, canActivate: [AuthGuardService] },
	{ path: 'footfall', component: FootfallDashboardComponent, canActivate: [AuthGuardService] },
	{ path: 'home', component: HomepageComponent },
	{ path: 'insights', component: InsightsLandingComponent, canActivate: [AuthGuardService] },
	{ path: 'insights/assets/list', component: InsightsAsListComponent, canActivate: [AuthGuardService] },
	{ path: 'insights/assets', component: InsightsAsLandingComponent, canActivate: [AuthGuardService] },
	{ path: 'insights/airquality', component: InsightsAirqualityComponent, canActivate: [AuthGuardService] },
	{ path: 'insights/footfall', component: InsightsFfLandingComponent, canActivate: [AuthGuardService] },
	{ path: 'insights/reviews', component: InsightsReviewsLandingComponent, canActivate: [AuthGuardService] },
	{ path: 'invite/token/:tokenid', component: InviteLandingComponent },
	{
		path: 'issues', component: IssuesComponent, canActivate: [AuthGuardService], children: [
			{ path: '', component: IssueDashboardComponent, canActivate: [AuthGuardService] },
			{ path: 'create', component: IssueUpdateComponent, canActivate: [AuthGuardService] }]
	},
	{
		path: 'issuetracking', component: IssueTrackingHomeComponent, canActivate: [AuthGuardService], children: [
			{ path: '', component: IssueTrackingListComponent, canActivate: [AuthGuardService] },
			{ path: 'admin', component: IssueTrackingAdminComponent, canActivate: [AuthGuardService] }]
	},
	{
		path: 'journey', component: JourneyContainerComponent, canActivate: [AuthGuardService], children: [
			{ path: '', component: JourneyLandingComponent, canActivate: [AuthGuardService] }]
	},
	{ path: 'login', component: LoginComponent },
	{ path: 'login/token/:tokenid/lost', component: LoginComponent },
	{ path: 'live', component: LiveGatewayListComponent, canActivate: [AuthGuardService] },
	{ path: 'live/:gatewayid', component: LiveEmbedComponent, canActivate: [AuthGuardService] },
	{ path: 'manage', component: ManageLandingComponent, canActivate: [AuthGuardService] },
	{
		path: 'monitor', component:
			MonitorContainerComponent, canActivate: [AuthGuardService], children: [
				{ path: '', component: MonitorLandingComponent, canActivate: [AuthGuardService] }
			]
	},
	{ path: 'occupancy', component: OccupancyLandingComponent, canActivate: [AuthGuardService] },
	{ path: 'offline', component: AssetsOfflineComponent, canActivate: [AuthGuardService] },
	{ path: 'orders', component: OrderingLandingComponent, canActivate: [AuthGuardService] },
	{ path: 'orders/new', component: OrderCreateComponent, canActivate: [AuthGuardService] },
	{ path: 'orders/:orderid/edit', component: OrderCreateComponent, canActivate: [AuthGuardService] },
	{ path: 'orders/:orderid', component: OrderViewComponent, canActivate: [AuthGuardService] },
	{ path: 'org/invite', component: OrgUserInviteComponent },
	{
		path: 'org', component: OrgDashboardComponent, canActivate: [AuthGuardService], children: [
			{ path: ':site', component: DashboardSiteListComponent, canActivate: [AuthGuardService], outlet: 'dashboard-site-list' },
			{ path: ':gateway', component: DashboardAssetListComponent, canActivate: [AuthGuardService], outlet: 'dashboard-asset-list' }
		]
	},
	{ path: 'org/billing/building/list/:buildingid', component: BillingBuildingsHomeComponent, canActivate: [AuthGuardService] },
	{ path: 'org/billing/building/list', component: BillingBuildingsListComponent, canActivate: [AuthGuardService] },
	{
		path: 'org/collections-new', component: OrgComplianceAddComponent, canActivate: [AuthGuardService]
	},
	{
		path: 'org/compliance/:complianceid', component: OrgComplianceItemComponent, canActivate: [AuthGuardService]
	},
	{ path: 'org/sites/:siteid', component: SiteDashboardComponent },

	{
		path: 'policies', component: PoliciesContainerComponent, children: [
			{ path: '', redirectTo: 'terms', pathMatch: 'full' },
			{ path: 'terms', component: TermsComponentComponent },
			{ path: 'privacy', component: PrivacyComponent },
			{ path: 'cookies', component: CookiesComponent },
			{ path: 'acceptance', component: AcceptableTermsComponent },
			{ path: 'data', component: DataTermsComponent }
		]
	},
	{ path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService] },
	{
		path: 'profiling', component: ProfilingContainerComponent, canActivate: [AuthGuardService], children: [
			{ path: '', component: ProfilingLandingComponent, canActivate: [AuthGuardService] },
			{ path: 'add', component: ProfilingAddComponent, canActivate: [AuthGuardService] },
			{ path: 'sites/:siteid/floorplans', component: SitePlanV2Component, canActivate: [AuthGuardService] },
			{ path: 'sites/:siteid/floorplans/dashboard', component: SitePlanDashboardContainerComponent, canActivate: [AuthGuardService] },
			{ path: 'sites/:siteid/floorplansv1', component: SitePlanComponent, canActivate: [AuthGuardService] },
			{ path: 'sites/:siteid/floorplansv1/dashboard', component: SitePlanDashboardContainerComponent, canActivate: [AuthGuardService] }
		]
	},
	{ path: 'realtime', component: DashboardLandingComponent, canActivate: [AuthGuardService] },
	{
		path: 'reviews', component: ReviewsContainerComponent, canActivate: [AuthGuardService], children: [
			{ path: '', component: ReviewsListComponent, canActivate: [AuthGuardService] },
			{ path: 'new', component: ReviewNewComponent, canActivate: [AuthGuardService] },
			{ path: ':reviewid', component: ReviewUpdateComponent, canActivate: [AuthGuardService] }
		]
	},
	{ path: 'reports', component: ReportListComponent, canActivate: [AuthGuardService] },
	{ path: 'reports/airquality', component: ReportAirqualityComponent, canActivate: [AuthGuardService] },
	{ path: 'reports/trigger', component: ReportTriggerComponent, canActivate: [AuthGuardService] },
	{ path: 'reports/custom', component: ReportCustomComponent, canActivate: [AuthGuardService] },
	{ path: 'reports/:reportid', component: ReportConfigureComponent, canActivate: [AuthGuardService] },
	{ path: 'reports/:reportid/view', component: ReportViewComponent, canActivate: [AuthGuardService] },
	{ path: 'rules/new', component: RuleEngineV2NewComponent, canActivate: [AuthGuardService] },
	{ path: 'rules', component: RulesEngineV2Component, canActivate: [AuthGuardService] },
	{ path: 'rules2/:ruleid', component: RuleEngineV2ItemComponent, canActivate: [AuthGuardService] },
	{ path: 'rules2/:ruleid/change', component: RuleEngineV2NewComponent, canActivate: [AuthGuardService] },
	{ path: 'rules2/:ruleid/alarm/:alarmid', component: RuleEngineV2ActionComponent, canActivate: [AuthGuardService] },
	{ path: 'rules/new', component: RuleEngineV2NewComponent, canActivate: [AuthGuardService] },
	{ path: 'rules/:ruleid', component: Rulesv3ItemComponent, canActivate: [AuthGuardService] },
	{ path: 'rules/:ruleid/change', component: RuleEngineV2NewComponent, canActivate: [AuthGuardService] },
	{ path: 'schematics', component: SchematicsLandingComponent, canActivate: [AuthGuardService] },
	{ path: 'schematics/:schematicid', component: SchematicsLandingComponent, canActivate: [AuthGuardService] },
	{ path: 'site/new', component: SiteSubmitComponent, canActivate: [AuthGuardService] },
	{ path: 'setpoints/summary', component: SetpointSummaryComponent, canActivate: [AuthGuardService], runGuardsAndResolvers: 'always' },
	{
		path: 'setpoints', component: SetpointContainerComponent, canActivate: [AuthGuardService], children: [
			{ path: '', component: SetpointListComponent, canActivate: [AuthGuardService] },
			{ path: 'overview', component: SetpointOverviewComponent, canActivate: [AuthGuardService] },
			{ path: 'templates', component: SetpointTemplatesComponent, canActivate: [AuthGuardService] },
			{ path: 'schedules', component: SetpointSchedulesComponent, canActivate: [AuthGuardService] },
			{ path: 'schedules/:schedulerid', component: SetpointSchedulerItemComponent, canActivate: [AuthGuardService] },
			{ path: 'templates/:templateid/scheduler', component: SetpointTemplateItemSchedulerComponent, canActivate: [AuthGuardService] },
			{ path: 'templates/:templateid/scheduler/:schedulerid', component: SetpointTemplateItemSchedulerComponent, canActivate: [AuthGuardService] },
			{ path: 'templates/:templateid', component: SetpointTemplateItemComponent, canActivate: [AuthGuardService] },
			{ path: ':assetid', component: SetpointDetailComponent, canActivate: [AuthGuardService] },
			{ path: ':assetid/rule', component: RuleEngineV2ItemComponent, canActivate: [AuthGuardService] },
			{ path: ':assetid/:rangeid', component: SetpointDetailComponent, canActivate: [AuthGuardService] }
		]
	},
	{ path: 'telemetry', component: TelemetryListComponent, canActivate: [AuthGuardService] },
	{ path: 'terms', component: AcceptTermsComponent },
	{ path: 'tickets/:ticketid', component: TicketViewComponent },
	{
		path: 'training', component: TrainingContainerComponent, canActivate: [AuthGuardService], children: [
			{ path: '', component: TrainingCourseSelectorComponent, canActivate: [AuthGuardService] },
			{ path: ':courseid', component: TrainingLandingComponent, canActivate: [AuthGuardService] },
			{ path: ':courseid/modules/:moduleid', component: TrainingOverviewComponent, canActivate: [AuthGuardService] }
		]
	},
	{ path: 'versions', component: CmsVersionsComponent, canActivate: [AuthGuardService] },
	{ path: 'ml', loadChildren: () => import('./ml/ml.module').then(m => m.MlModule) },

	{ path: '**', component: PageNotFoundComponent }
];
@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
