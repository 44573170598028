import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-cms-page',
    templateUrl: './cms-page.component.html',
    styleUrls: ['./cms-page.component.css'],
    standalone: false
})
export class CmsPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
