import { ApplicationRef, Input, ComponentRef, Directive, ElementRef, Injector, OnDestroy, HostListener } from '@angular/core';

@Directive({
    selector: '[tooltip]',
    standalone: false
})
export class TooltipDirective implements OnDestroy {
  @Input() tooltip = '';
  @Input() delay? = 50;
  @Input() tooltipPosition: 'right' | 'left' | 'top' | 'bottom' | string | undefined;
  @Input() tooltipZIndex: string | undefined;

  private myPopup;
  private timer;

  @HostListener("mouseenter") onMouseEnter() {
    this.timer = setTimeout(() => {
      if (!this.tooltip) {
        return;
      }
      let x =
        this.el.nativeElement.getBoundingClientRect().left +
        this.el.nativeElement.offsetWidth / 2; // Get the middle of the element
      let y =
        this.el.nativeElement.getBoundingClientRect().top +
        this.el.nativeElement.offsetHeight +
        6; // Get the bottom of the element, plus a little extra

      console.log(x, y);
      this.createTooltipPopup(x, y);
    }, this.delay);
  }

  @HostListener("mouseleave") onMouseLeave() {
    if (this.timer) clearTimeout(this.timer);
    if (this.myPopup) {
      this.el.nativeElement.classList.remove('tooltip');
      this.myPopup.remove();
    }
  }

  constructor(
    private el: ElementRef) {

  }

  private createTooltipPopup(x: number, y: number) {
    this.el.nativeElement.classList.add('tooltip');
    let popup = document.createElement("span");
    popup.setAttribute('style', 'font-size:12px; font-style:normal');
    popup.innerHTML = this.tooltip;
    popup.setAttribute("class", "tooltiptext");
    // popup.style.top = y.toString() + "px";
    // popup.style.left = x.toString() + "px";
    this.el.nativeElement.appendChild(popup);
    this.myPopup = popup;
    setTimeout(() => {
      if (this.myPopup) this.myPopup.remove();
    }, 5000); // Remove tooltip after 5 seconds
  }

  ngOnDestroy(): void {
    if (this.myPopup) {
      this.el.nativeElement.classList.remove('tooltip');
      this.myPopup.remove();
    }
  }

}
