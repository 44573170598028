import { Component, signal, Input, Output, EventEmitter } from '@angular/core';
import { User, UserExportItem, UserExportItemFrequency } from 'app/classes/user';

@Component({
    selector: 'app-user-manage-export',
    templateUrl: './user-manage-export.component.html',
    styleUrl: './user-manage-export.component.css',
    standalone: false
})
export class UserManageExportComponent {

  @Input()
  public set exportItem(v: UserExportItem) {
    this.sql.set(v ? v.sqlCommand : '');
    this.id.set(v ? v.id : null);
    this.isActive.set(v ? v.isActive : false);
  }


  @Input()
  public set user(v: User) {
    this.sql.set(v.exports ? v.exports[0].sqlCommand : '');
    this.id.set(v.exports ? v.exports[0].id : null);
    this.isActive.set(v.exports ? v.exports[0].isActive : false);
  }

  @Output()
  onChanged = new EventEmitter<UserExportItem>();

  sql = signal('');
  id = signal<number>(null);
  isActive = signal(false);
  frequency = signal<UserExportItemFrequency>('daily');

  constructor() {

  }

  onSQLchange(sqlCommand: string) {
    this.sql.set(sqlCommand);
    this.emitChange();
  }

  emitChange() {
    this.onChanged.emit({ id: this.id(), sqlCommand: this.sql(), frequency: this.frequency(), isActive: this.isActive() });
  }

  activeChange(ev: any) {
    console.log(ev, ev.returnValue);
    this.emitChange();
  }
}
