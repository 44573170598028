import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-power-hour',
    templateUrl: './power-hour.component.html',
    styleUrls: ['./power-hour.component.css'],
    standalone: false
})
export class PowerHourComponent implements OnInit {

  @Input()
  hour: number;

  @Input()
  timestamp: number;

  @Input()
  value: number;

  constructor() { }

  ngOnInit(): void {
  }

}
