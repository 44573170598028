import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { AssetService } from './asset.service';
import { Camera } from 'app/classes/asset';

@Injectable({ providedIn: 'root' })
export class CameraService {

  constructor(private apiService: APIService) { }

  getCameras() {
    return this.apiService.getAssetsWithCategory<Camera>('image', AssetService.factory);
  }
  getCamerasWithPresets() {
    return this.apiService.getAssetsWithPresets();
  }
  // REquest a session with the camera,must have "control_camera" role
  startSession(assetId: number) {
    return this.apiService.startAssetSession(assetId);
  }

  getCamera(assetId: number) {
    return this.apiService.getCamera(assetId);
  }
}
