import adder from "./adder.js";
import { abs, atan2, cos, radians, sin, sqrt } from "./math.js";
import noop from "./noop.js";
import stream from "./stream.js";
var lengthSum = adder(),
  lambda0,
  sinPhi0,
  cosPhi0;
var lengthStream = {
  sphere: noop,
  point: noop,
  lineStart: lengthLineStart,
  lineEnd: noop,
  polygonStart: noop,
  polygonEnd: noop
};
function lengthLineStart() {
  lengthStream.point = lengthPointFirst;
  lengthStream.lineEnd = lengthLineEnd;
}
function lengthLineEnd() {
  lengthStream.point = lengthStream.lineEnd = noop;
}
function lengthPointFirst(lambda, phi) {
  lambda *= radians, phi *= radians;
  lambda0 = lambda, sinPhi0 = sin(phi), cosPhi0 = cos(phi);
  lengthStream.point = lengthPoint;
}
function lengthPoint(lambda, phi) {
  lambda *= radians, phi *= radians;
  var sinPhi = sin(phi),
    cosPhi = cos(phi),
    delta = abs(lambda - lambda0),
    cosDelta = cos(delta),
    sinDelta = sin(delta),
    x = cosPhi * sinDelta,
    y = cosPhi0 * sinPhi - sinPhi0 * cosPhi * cosDelta,
    z = sinPhi0 * sinPhi + cosPhi0 * cosPhi * cosDelta;
  lengthSum.add(atan2(sqrt(x * x + y * y), z));
  lambda0 = lambda, sinPhi0 = sinPhi, cosPhi0 = cosPhi;
}
export default function (object) {
  lengthSum.reset();
  stream(object, lengthStream);
  return +lengthSum;
}