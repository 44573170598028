import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-training-container',
    templateUrl: './training-container.component.html',
    styleUrls: ['./training-container.component.css'],
    standalone: false
})
export class TrainingContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
