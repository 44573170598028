import { Component, EventEmitter, Input, Output, input, signal } from '@angular/core';
import { CalendarService } from 'app/shared/calendar.service';
import moment from 'moment';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrl: './calendar.component.css',
    standalone: false
})
export class CalendarComponent {
  rows: any[];
  _value = signal<Date>(null);

  // Where to start the calendar from
  @Input()
  date: Date;

  // The selected date
  @Input()
  public set value(v: Date) {
    if (!this.date) {
      this.date = v || new Date();
    } else {
      this.date = v;
    }
    if (this.date.toString() === 'Invalid Date') {
      this.date = new Date;
    }
    this._value.set(v);
    this.build();
  }

  public get value(): Date {
    return this._value();
  }

  @Output()
  onSelect = new EventEmitter<Date>();

  _title = signal<string>('');

  // Only the current selected month shown, otherwise selected date is the center row.
  showOnlySelectedMonth = input<boolean>(true);

  constructor(private calendarService: CalendarService) {

  }

  build() {
    this.buildValueMonth();
  }

  buildValueMonth() {
    console.log(`before`, this.date);
    const rows: any[] = [];

    const date = moment(this.date).clone().startOf('month').startOf('isoWeek').startOf('day');
    const todayCompare = moment().format('L');

    const months = new Array(12).fill(0);
    const years = new Array(12).fill('');
    const selectedValueAsMoment = moment(this.value).startOf('day');

    const dateMonth = moment(this.date).get('month');
    for (let weekIndex = 0; weekIndex < 5; weekIndex++) {
      let week: ICalendarDay[] = [];
      for (let dayIndex = 0; dayIndex < 7; dayIndex++) {

        const dateCompare = date.format('L');
        months[date.get('month')]++;
        years[date.get('month')] = date.format('MMMM YYYY');

        const weekday = date.isoWeekday();
        const weekdayShortCode = date.format('ddd');
        const isOtherMonth = moment(date).get('month') !== dateMonth;
        const tooltip = '';
        const isToday = todayCompare === dateCompare;
        const day: ICalendarDay = { isOtherMonth, weekday, weekdayShortCode, date: date.toDate(), tooltip, isFuture: date.isAfter(), isToday, isSelected: false };
        day.isSelected = date.isSame(selectedValueAsMoment);

        week.push(day);
        date.add(1, 'day');
      }
      rows.push(week);
    }
    console.log(this.date);
    this.rows = rows;
    const idx = months.indexOf(Math.max(...months))
    this._title.set(years[idx]);
  }

  buildDynamic() {
    console.log(`before`, this.date);
    const rows: any[] = [];
    const date = moment(this.date).clone().isoWeekday(1).subtract(2, 'week').startOf('isoWeek').startOf('day');
    const todayCompare = moment().format('L');


    const dateMonth = moment(this.date).get('month');

    const months = new Array(12).fill(0);
    const years = new Array(12).fill('');
    const selectedValueAsMoment = moment(this.value).startOf('day');

    for (let weekIndex = 0; weekIndex < 5; weekIndex++) {
      let week: ICalendarDay[] = [];
      for (let dayIndex = 0; dayIndex < 7; dayIndex++) {

        const dateCompare = date.format('L');
        const isOtherMonth = moment(date).get('month') !== dateMonth;
        months[date.get('month')]++;
        years[date.get('month')] = date.format('MMMM YYYY');

        const weekday = date.isoWeekday();
        const weekdayShortCode = date.format('ddd');
        //const alarms = this.items.filter(item => moment(item.createdAt).format('L') === dateCompare);
        //const tooltip = (alarms.length) + ' alarms on ' + date.format('DD/MM/YYYY');
        const tooltip = '';
        const isToday = todayCompare === dateCompare;
        const day: ICalendarDay = { isOtherMonth, weekday, weekdayShortCode, date: date.toDate(), tooltip, isFuture: date.isAfter(), isToday, isSelected: false };
        day.isSelected = date.isSame(selectedValueAsMoment);

        week.push(day);
        date.add(1, 'day');
      }
      rows.push(week);
    }
    console.log(this.date);
    this.rows = rows;
    const idx = months.indexOf(Math.max(...months))
    this._title.set(years[idx]);
  }

  goBack() {
    this.date = moment(this.date).subtract(1, 'month').toDate();
    this.build();
  }

  goForward() {
    this.date = moment(this.date).add(1, 'month').toDate();
    this.build();
  }

  clickDay(day: any) {
    this._value.set(day.date);
    this.rows.forEach(row => row.forEach(d => d.isSelected = false));
    day.isSelected = true;
    this.onSelect.emit(day);
  }

  clickTitle() {
    this.clickDay({ date: new Date() });
  }

}


export interface ICalendarDay {
  weekday?: number;
  weekdayShortCode?: string;
  date: Date;
  tooltip?: string;
  isFuture?: boolean;
  isToday?: boolean;
  isSelected?: boolean;
  isOtherMonth?: boolean;
}
