import { Component, OnInit } from '@angular/core';
import { APIService } from 'app/shared/api.service';
import { UploadService } from "../../shared/upload.service";

@Component({
    selector: 'app-system-upload-list',
    templateUrl: './system-upload-list.component.html',
    styleUrls: ['./system-upload-list.component.css'],
    standalone: false
})
export class SystemUploadListComponent implements OnInit {

  uploads: any[];

  constructor(private uploadService: UploadService, private apiService: APIService) { }

  ngOnInit(): void {
    this.get();
  }

  get() {
    this.uploadService
      .getListForOrg()
      .then(uploads => {
        this.uploads = uploads.map(u => {
          return { ...u, metaTable: [], viewMeta: -1, meta: (u.meta ? JSON.parse(u.meta) : null) }
        });
        this.uploads.forEach(u => {
          if (!u.meta) {
            return;
          }
          this.processMeta(u);
          let helper = [];
          const isTektelic = u.metaTable.find(i => i.text === 'TEKTELIC COMMUNICATIONS');
          if (isTektelic) {
            let tektelic = {
              appeui: '',
              appkey: ''
            };
            for (let index = 0; index < u.metaTable.length; index++) {
              const element = u.metaTable[index];
              if (!element || !element.text) { continue };
              console.log(element);
              if (element.text.substr(0, 6) === 'APPEUI' && !tektelic.appeui) {
                tektelic.appeui =  element.text.split(' ')[1];
              }
              if (element.text === 'APPKEY:' && !tektelic.appkey) {
                tektelic.appkey = u.metaTable[index + 1].text;
              }
            }
            u.helper = Object.keys(tektelic).map( h => {
              return [h,tektelic[h]];
            });
          }
        });
      });
  }
  ocrClick(upload) {
    this.apiService.toastWarn('Processing..', 'PLEASE WAIT 6 seconds+');
    this.uploadService.requestOCR(`key=${upload.key}`)
      .then(r => {
        this.apiService.toastSuccess('done', '');
        console.log(r);
        this.get();
      });
  }

  toggleMeta(upload) {
    upload.metaDisplay = JSON.stringify(upload.meta, null, 2);

    // this.processMeta(upload);

    upload.viewMeta = upload.viewMeta * - 1;
  }

  processMeta(upload: any) {
    upload.metaTable = upload.meta.textByLine.map(l => {
      return { confidence: l.Confidence, text: l.Text, type: 'line' };
    });
    upload.metaTable = [...upload.metaTable, ...upload.meta.texTractblocks.filter(l => l.BlockType !== 'PAGE' && l.BlockType !== 'KEY_VALUE_SET').map(l => {
      return { confidence: l.Confidence, text: l.Text, type: l.TextType | l.BlockType };
    })];
  }
}
