<div [class.svg-image-rendered]="isSVGImageRendered" class="plan-root">
  <div class="hidden-xs can-click" (click)="back()">
    <app-page-title [canGoBack]="true" (click)="back()" [label]="siteFloorplan?.title ? siteFloorplan.site.title + ' - ' + siteFloorplan.title : site?.title"> </app-page-title>
  </div>
  @if (hasLoaded) {
    <div class="floorplan-container">
      <div class="left no-print">
        @if (hasDashboard) {
          <div class="can-click" routerLink="dashboard"><i class="mdi mdi-view-dashboard-outline"></i> Dashboard</div>
        }
        <div class="no-print">
          <h4>Floors</h4>
          <div>
            <div *ngFor="let plan of siteFloorplans" class="can-click" (click)="onClickSitePlanLabel(plan)" [class.selected]="plan.id === planId">
              {{ plan.title }}
            </div>
          </div>
        </div>
        <div class="no-print" *ngIf="selected.presentation === 'floorplan' && areas.length">
          <h4>Areas</h4>
          <div class="area-list">
            <div class="can-click" [class.selected]="selectedArea?.id === area.id" *ngFor="let area of areas" class="can-click" (click)="clickFloorplanLabel(area)">
              <i class="mdi mdi-eye" (mouseenter)="previewArea(area)" (mouseleave)="previewArea(null)"></i>
              &nbsp;<span style="color: #e0e0e0">|</span> &nbsp; <span (mouseenter)="onMouseEnterAreaLabel(area)" (mouseleave)="onMouseLeaveAreaLabel(area)">{{ area.title }}</span>
            </div>
          </div>
        </div>
        <div class="no-print">
          <h4>Presentations</h4>
          <div class="can-click" (click)="switchTo('floorplan')" [class.selected]="selected.presentation === 'floorplan'">Plan</div>
          <div class="can-click" (click)="switchTo('table')" [class.selected]="selected.presentation === 'table'">Table</div>
          <div class="no-print" *ngIf="selected.presentation === 'floorplan' && sitePlanComplete?.areas.length">
            <h4>Areas</h4>
            <div class="area-list">
              <div class="can-click" [class.selected]="selectedArea?.id === area.id" *ngFor="let area of sitePlanComplete.areas" class="can-click" (click)="clickFloorplanLabel(area)">
                <i class="mdi mdi-eye" (mouseenter)="previewArea(area)" (mouseleave)="previewArea(null)"></i>
                &nbsp;<span style="color: #e0e0e0">|</span> &nbsp; <span (mouseenter)="onMouseEnterAreaLabel(area)" (mouseleave)="onMouseLeaveAreaLabel(area)">{{ area.title }}</span>
              </div>
            </div>
          </div>

          <ng-template [ngIf]="selected.presentation === 'table'">
            <h1>Options</h1>
            <ul class="option-list">
              <li>
                <span>From:</span>
                <input class="form-control" [(ngModel)]="selected.range.startsAt" />
              </li>
              <li>
                <span>To:</span>
                <input class="form-control" [(ngModel)]="selected.range.endsAt" />
              </li>
              <li>
                <span>Type:</span>

                <p-dropdown [options]="rangeOptions" [(ngModel)]="selected.range.type" optionLabel="title" optionValue="id" styleClass="w-full"> </p-dropdown>
              </li>
              <li>&nbsp;</li>
              <li>
                <button (click)="updateOptions()" class="btn btn-sm btn-block btn-secondary"><i class="fa fa-refresh"></i> Update</button>
              </li>
            </ul>
          </ng-template>
        </div>
        <div class="weather">
          <h4 pTooltip="Weather at site" tooltipPosition="bottom">Site Weather</h4>
          <div class="site-weather">
            <app-weather-temp [site]="site"></app-weather-temp>
          </div>
        </div>
      </div>

      <div class="plan">
        <div class="chart-table" *ngIf="planId && sitePlanComplete?.assets && selected.presentation === 'table'">
          <app-site-table [range]="selected.range" [sitePlanComplete]="sitePlanComplete"> </app-site-table>
        </div>
        @if (planId && selected.presentation === 'floorplan') {
          <app-site-plan-floorplan-container [config]="{ planId, siteId }" (onFloorplanLoaded)="floorplanLoaded($event)" />
        }
      </div>
    </div>
  } @else {
    <app-please-wait></app-please-wait>
  }
</div>
