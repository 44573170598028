<main class="theme4d" *ngIf="custom && pins">
  @if (custom) {
    <section *ngIf="assets">
      <app-setpoint-query [saveState]="false" [showValue]="true" view="compact" [asset]="assets[0]" [fullscreen]="false" dateType="custom" [custom]="custom" [pins]="pins"> </app-setpoint-query>
    </section>
  }
  <section *ngIf="alarm" class="timeline">
    <app-rulesv3-item-alarm-timeline [alarm]="alarm"></app-rulesv3-item-alarm-timeline>
  </section>
</main>
