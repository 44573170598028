import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { APIService } from '../../shared/api.service';
import { SiteReview } from '../../classes/reviews/review';
import { OnFinishedLoadingInterface } from '../layout/review-graph/review-graph.component';

@Component({
    selector: 'app-review-update',
    templateUrl: './review-update.component.html',
    styleUrls: ['./review-update.component.css'],
    standalone: false
})
export class ReviewUpdateComponent implements OnInit {

  siteReview: SiteReview;
  isCached: boolean;

  constructor(private router: Router, private route: ActivatedRoute, private apiService: APIService) {

  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      const cached = localStorage.getItem('reviews:' + params.reviewid);
      if (!cached) {
        // No chached record - grab the review item
        this.apiService.getSiteReview(params.reviewid)
          .then(review => {
            this.siteReview = review;

          })
          .catch(e => console.log(e));
      } else {
        this.isCached = true;
        this.siteReview = new SiteReview(JSON.parse(cached));
      }
    });
  }

  onFinishedLoading(loaded: OnFinishedLoadingInterface) {
    this.siteReview.assets.forEach(reviewAsset => {
      if (reviewAsset.id === loaded.asset.id) {
        setTimeout(() => {
          reviewAsset.telemetry.min = loaded.minimum;
          reviewAsset.telemetry.max = loaded.maximum;
        }, 100);
      }
    });
  }
}
