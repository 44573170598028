import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Alarm } from 'app/classes/alarm';
import { APIService } from 'app/shared/api.service';
import { StoreService } from 'app/shared/store.service';

@Component({
    selector: 'app-alarm-view',
    templateUrl: './alarm-view.component.html',
    styleUrls: ['./alarm-view.component.css'],
    standalone: false
})
export class AlarmViewComponent implements OnInit {

  _alarm: Alarm;
  @Input()
  public set alarm(v: Alarm) {
    if (typeof v.rulePackage === 'undefined') {
      this.apiService.getAlarm(v.id).then(alarm => {
        this._alarm = alarm;
      });
    } else {
      this._alarm = v;
    }
  }

  public get alarm(): Alarm {
    return this._alarm;
  }

  @ViewChild('mytoolbar') toolbar;

  showDialog: string;

  floorplan: any = null;

  constructor(private apiService: APIService, private storeService: StoreService) { }

  ngOnInit() {
  }

  addNote() {
    if (this.showDialog === 'add-note') {
      this.showDialog = null;
    } else {
      this.showDialog = 'add-note';
    }
  }

  toState(state: string) {
    this.apiService.alarmAction(this.alarm, state, "", 0).then(() => {
      this.apiService.getAlarm(this.alarm.id).then(alarm => {
        this.alarm = alarm;
        if (alarm.state !== 'resolved') {
          // Only notify if not resolved. Resolved emails will notify via the appComponent.
          this.apiService.toastSuccess(`Alarm ${alarm.state}`, alarm.rulePackage.title);
        }
        this.storeService.setDataChanged('alarm', 'updated', alarm);
      });
    });
  }

  planLoaded(plan: any) {
    this.floorplan = plan;
  }
}
