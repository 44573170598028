
import { Component, Input, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-dialog-insights-wait',
    templateUrl: './dialog-insights-wait.component.html',
    styleUrls: ['./dialog-insights-wait.component.css'],
    providers: [DialogService],
    standalone: false
})
export class DialogInsightsWaitComponent implements OnInit {

  constructor(public dialogService: DialogService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
  
  }

  ngOnInit(): void {

  }

  cancel() {
    this.ref.close();
  }

  update() {
    this.ref.close();
  }

}
