import { Component, effect, input, OnInit, output, signal } from '@angular/core';
import { DataTable, IDataTableColumnItem } from 'app/classes/data-table/data-table';
import { APIService } from 'app/shared/api.service';
import { ISensorTypeItem } from '../data-table-filter-types/data-table-filter-types.component';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrl: './data-table.component.css',
  standalone: false
})
export class DataTableComponent implements OnInit {

  dataTable = input.required<DataTable>();

  rows = signal<any[]>(null);
  onRowClick = output<any>();
  canClick = input<boolean>(true);
  filter = input<IDataTableFilter>(null);
  isAdmin = signal<boolean>(false);
  canFilterByAssetType = input<boolean>(false);
  sensorTypes = signal<ISensorTypeItem[]>([]);
  sensorTypeSelected = signal<ISensorTypeItem>(null);

  constructor(private apiService: APIService) {
    this.isAdmin.set(apiService.isAdmin());
    effect(() => {
      if (this.dataTable()) {
        console.log(`DATA_TABLE_CHANGE`);
        this.changed();
      }
    });
  }

  ngOnInit(): void {
    if (!this.dataTable()) {
      this.rows.set(null);
      return;
    }

    if (this.canFilterByAssetType()) {
      this.updateSensorTypesUsed();
    }
    this.sort();
  }

  sensorTypeChange(sensorType: ISensorTypeItem) {
    this.sensorTypeSelected.set(sensorType);
    this.sort();
  }

  changed() {
    if (this.canFilterByAssetType()) {
      this.updateSensorTypesUsed();
    }
    this.sort();
  }

  updateSensorTypesUsed() {
    const rows = [...this.dataTable().data];

    const sensorsUsed: ISensorTypeItem[] = [...rows.reduce((u, c) => {
      // Skip null for new asset types
      if (c.assetType) {
        if (!u.has(c.assetType)) {
          u.set(c.assetType, { title: c.assetTypeTitle, id: c.assetType, count: 1, isSelected: false });
        } else {
          u.get(c.assetType).count++;
        }
      }
      return u;
    }, new Map()).values()];

    this.sensorTypes.set(sensorsUsed.sort((a, b) => a.title > b.title ? 1 : -1));
  }

  sortColumnClick(column: IDataTableColumnItem) {
    const currentSortColumn = this.dataTable().columns.find(c => !!c.sortDirection);

    if (currentSortColumn?.attribute === column.attribute) {
      currentSortColumn.sortDirection = currentSortColumn.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      if (currentSortColumn) {
        currentSortColumn.sortDirection = null;
      }
      column.sortDirection = 'asc';
    }
    this.sort();
  }

  rowClick(row: any) {
    this.onRowClick.emit(row);
  }

  sort() {
    if (!this.dataTable()) {
      return;
    }

    let rows = this.dataTable().data;
    if (!rows) {
      return;
    }

    if (this.sensorTypeSelected()) {
      rows = rows.filter(r => r.assetType === this.sensorTypeSelected().id);
    }

    let sortedColumn = this.dataTable().columns.find(c => !!c.sortDirection);
    if (!sortedColumn) {
      this.rows.set(rows);
      return;
    }

    let config = this.dataTable().dataClassConfig[sortedColumn.attribute];
    if (!config) {
      console.error(`No config for ${sortedColumn.attribute}`);

      return;
    }

    const sortDirection = sortedColumn.sortDirection;

    if (config.sortUsingKey) {
      // Sort using alternative column
      const useKey = config.sortUsingKey;
      config = this.dataTable().dataClassConfig[useKey];
      sortedColumn = this.dataTable().columns.find(c => c.attribute === useKey);
    }

    const sorted = rows.sort((a, b) => {
      let aValue, bValue;

      switch (config.valueType) {
        case 'number':
        case 'date':
          if (typeof aValue === 'string') {
            aValue = +new Date(a[sortedColumn.attribute]);
            bValue = +new Date(b[sortedColumn.attribute]);
          } else {
            aValue = +a[sortedColumn.attribute];
            bValue = +b[sortedColumn.attribute];
          }
          break;
        default:
          aValue = a[sortedColumn.attribute];
          bValue = b[sortedColumn.attribute];
          break;
      }

      if (aValue === null) aValue = '';
      if (bValue === null) bValue = '';

      switch (sortDirection) {
        case 'asc':
          return aValue > bValue ? 1 : -1;
        case 'desc':
          return aValue < bValue ? 1 : -1;
        default:
          return 0;
      }
    });

    this.rows.set(sorted);
  }
}


export interface IDataTableFilter {
  attributes: IDataTableFilterItem[];
}

export interface IDataTableFilterItem {
  attribute: string;
  value: string;
  operand: 'gt' | 'lt' | 'eq';
}
