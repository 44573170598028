@defer (when data) {
  <header class="mb-1">
    <app-toolbar>
      <div class="right">
        @if (isAdmin()) {
          <app-button [label]="view() === 'org' ? 'Org Users' : 'All Orgs'" icon="mdi mdi-rotate-3d-variant" [tooltipOptions]="{ tooltipPosition: 'top', positionTop: -20 }" [pTooltip]="view() === 'org' ? 'Switch to Show all orgs' : 'Switch to  this org'" (click)="toggleView()" class="mr-1" />
        }
        @if (timer) {
          <app-button label="Tracking" pTooltip="Tracking changes every minute" icon="mdi mdi-eye-refresh-outline"></app-button>
        } @else {
          <app-button label="Refresh" pTooltip="Refresh data and start tracking changes every minute" icon="mdi mdi-refresh" (click)="refresh()"></app-button>
        }
      </div>
    </app-toolbar>
  </header>
  <main>
    @if (data?.length) {
      <app-data-table [dataTable]="dataTable" (onRowClick)="onRowClick($event)"> </app-data-table>
    } @else {}
  </main>
} @placeholder {
  <i class="mdi mdi-spin mdi-loading"></i>
}
