import { Component, OnInit, signal, OnDestroy, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { APIService } from '../../shared/api.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Gateway } from '../../classes/gateway';
import { Asset } from '../../classes/asset';
import { Setpoint } from '../../classes/setpoint';
import { GatewayIS } from 'app/classes/gateway-is';

@Component({
  selector: 'app-live-embed',
  templateUrl: './live-embed.component.html',
  styleUrls: ['./live-embed.component.css'],
  standalone: false
})
export class LiveEmbedComponent implements OnInit, OnDestroy {

  gateway = signal<Gateway | GatewayIS>(null);
  assets = signal<Asset[]>(null);
  iframeSrc = signal<string>(null);
  showHelp = signal<boolean>(false);
  setpoints = signal<Setpoint[]>([]);
  selectedAsset = signal<Asset>(null);
  width = signal<number>(null);
  height = signal<number>(null);

  @HostListener('window:resize', ['$event'])
  onresize(event) {
    this.width.set(event.target.innerWidth);
    this.height.set(event.target.innerHeight);
    console.log('RESIZE', this.width(), this.height());
  }

  constructor(private router: Router, private route: ActivatedRoute, private apiService: APIService, public sanitizer: DomSanitizer) {
    route.params.subscribe((params: Params) => {
      apiService.getGateway(params['gatewayid']).then(gateway => {

        this.gateway.set(gateway);
        if (gateway instanceof GatewayIS) {
          const is = <GatewayIS>gateway;
          switch (gateway.initialstate.version) {
            case 1:
              this.iframeSrc.set(`https://app.initialstate.com/embed/#/tiles/${is.initialstate.embedLink}`);
              break;
            case 2:
              this.iframeSrc.set(`https://iot.app.initialstate.com/embed/#/tiles/${is.initialstate.embedLink}`);
              break;
          }
        }
      });
      this.apiService.getAssetsForGatewayId(params['gatewayid']).then(assets => this.assets.set(assets));
    });

    const setpoints = [];

    this.showHelp.set(false);
    for (let i = 0; i < 7; i++) {
      setpoints.push(new Setpoint(null, i, '', '', false, 0, 0, false));
    }
    this.setpoints.set(setpoints);
    this.apiService.setFullscreen(true);
  }

  ngOnInit() {

  }

  iframeOnLoad(event: any) {
    console.log('iframe loaded', event);
  }

  toggleHelp() {
    this.showHelp.update(state => !state);
    this.selectedAsset.set(null);
  }

  toggleAsset(asset: Asset) {
    if (this.selectedAsset()?.id === asset.id) {
      this.selectedAsset.set(null);
    } else {
      this.selectedAsset.set(asset);
    }
  }

  ngOnDestroy() {
    this.apiService.setFullscreen(false);
  }
}
