/**
 * QUILL REMOVED REWRITE
 */
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { Org } from 'app/classes/org';
import { ManageService } from 'app/shared/manage.service';

@Component({
    selector: 'app-manage-create-org',
    templateUrl: './manage-create-org.component.html',
    styleUrls: ['./manage-create-org.component.css'],
    standalone: false
})
export class ManageCreateOrgComponent implements OnInit, AfterViewInit {

  quill: any;

  @Output()
  gotFocus: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  org: Org;

  @Output()
  didSave: EventEmitter<Org> = new EventEmitter<Org>();

  constructor(private manageService: ManageService) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    const self = this;

  }

  quillEvent(range, oldRange, source) {
    console.log('GOT SELECTION CHANGED', range, oldRange, source);

    if (range) {
      console.log('fire event', this);
      this.focus('editor');
    }
  }

  focus(element: string) {
    console.log('got ', element);
    this.manageService.setFieldFocus(element);
  }

  submit() {
    this.manageService.save(this.org).subscribe(response => {
      console.log(response);
      this.didSave.emit(this.org);
    });
  }
}
