import { RuleCondition } from './rule-condition';
import { RuleNotification } from './rule-notification';
import { RuleTimeRange } from './rule-hours';
import { RuleAction } from './rule-action';
import { Asset } from '../asset';
import { Alarm } from '../alarm';
import { Grouping } from '../grouping';

const RULE_STATUSES: any = {
	TESTING: 1,
	LIVE: 2,
	DISABLED: 3,
	DELETED: 4
};

export class RulePackage {
	id: number;
	title: string;
	orgId: number;
	userId: number;
	userName: string;

	conditions: RuleCondition[];
	notifications: RuleNotification[];
	groupings: Grouping[];
	ruleTimeRanges: RuleTimeRange[];

	actions: RuleAction[];
	alarm: Alarm;
	message: string;
	isEnabled: string;
	instructions: string;
	severity: RuleSeverity = 'low';
	triggerAfter: number;
	status: number;
	createdAt: string;
	inAlert: string;
	lastAlertedAt: Date;
	autoResolve: string;
	resetValueOnAlert: string;
	counts: { totalCount: 0, todaysCount: 0, thisWeeksCount: 0 };
	includeSiteNotifications: boolean;
	asset: Asset; // Linked to a setpoint if exists
	tag: string;
	siteId: number;
	siteTitle: string;
	gatewayId: string;
	gatewayTitle: string;
	bankHoliday: boolean;
	overview: string;
	integration: any;
	hasIntegration = false;

	setpointRangeId: number;

	constructor(conditions?: RuleCondition[], notifications?: RuleNotification[], message?: string, actions?: RuleAction[]) {
		this.conditions = conditions || [];
		this.notifications = notifications || [];
		this.ruleTimeRanges = [];
		this.actions = actions || [];
		this.message = message || '';
		this.alarm = null;
		this.status = RULE_STATUSES.TESTING;
		this.autoResolve = 'Y';
		// User defined groups
		this.groupings = [];
	}

	serialise(): any {
		// Serialise the conditions, no need to send all the asset data
		let conditions = [];
		for (let idx = 0; idx < this.conditions.length; idx++) {
			conditions.push(this.conditions[idx].serialise());
		}

		const serialised = {
			id: this.id,
			title: this.title,
			orgId: this.orgId,
			instructions: this.instructions,
			conditions: conditions,
			notifications: this.notifications,
			ruleTimeRanges: this.ruleTimeRanges,
			actions: this.actions,
			message: this.message,
			severity: this.severity || 'low',
			triggerAfter: this.triggerAfter || 0,
			status: this.status || RULE_STATUSES.TESTING,
			isEnabled: this.isEnabled,
			bankHoliday: this.bankHoliday ? 'Y' : 'N',
			includeSiteNotifications: this.includeSiteNotifications,
			autoResolve: this.autoResolve,
			gatewayId: this.gatewayId,
			integration: this.integration,
			groupings: this.groupings
		};

		return serialised;
	}

	serialiseForCopy(): any {
		let conditions = [];
		for (let idx = 0; idx < this.conditions.length; idx++) {
			const condition = this.conditions[idx].serialise();
			condition.id = null;
			conditions.push(condition);
		}

		const serialised = {
			id: null,
			title: `${this.title} (copy)`,
			orgId: this.orgId,
			instructions: this.instructions,
			conditions: conditions,
			notifications: this.notifications,
			ruleTimeRanges: this.ruleTimeRanges,
			actions: this.actions,
			message: this.message,
			severity: this.severity || 'low',
			triggerAfter: this.triggerAfter || 0,
			status: this.status || RULE_STATUSES.TESTING,
			isEnabled: this.isEnabled,
			bankHoliday: this.bankHoliday ? 'Y' : 'N',
			includeSiteNotifications: this.includeSiteNotifications,
			autoResolve: this.autoResolve,
			gatewayId: this.gatewayId,
			integration: this.integration
		};

		return serialised;
	}
}

export type RuleSeverity = 'low' | 'medium' | 'high';
