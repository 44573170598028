<article [class.pagebreakbefore]="wantsPageBreak">
  @if (gateways) {
    <section>
      <main class="header">
        <div>
          <div>
            <h3>{{ insight.types[type].title }}</h3>
          </div>

          <div class="alarms">
            <div *ngFor="let alarm of alarmBlocks" class="alarm">
              <div><span class="mdi mdi-circle {{ alarm.colour }}"></span> {{ alarm.header }}</div>
              <div>{{ alarm.title }}</div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <img src="{{ svgLocation }}icon-{{ type }}.svg" />
          </div>
          <div class="average">
            {{ data?.monthTotals.grandtotal.avg }}<small>{{ insight.types[type].measurement }}</small>
          </div>
        </div>
      </main>
      <div class="mt-1">
        {{ insight.types[type].description }}
      </div>

      <div class="gateways mt-1">
        <div class="chart" *ngIf="data?.telemetry">
          <app-d3-chart [hasToClickToInteract]="true" [canAnnotate]="false" [canZoom]="false" [height]="200" [telemetry]="data.telemetry" [limitLines]="limitLines" [showLegend]="true" [minMaxFromData]="true"> </app-d3-chart>
        </div>
        <table class="table">
          <thead>
            <tr>
              <td></td>
              <td>Gateway</td>
              <td>Average</td>
              <td>Lowest</td>
              <td>Highest</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let gatewayData of gateways">
              <td><img src="{{ svgLocation }}icon-{{ type }}.svg" /></td>
              <td>{{ gatewayData.gateway.title }}</td>
              <td *ngIf="gatewayData.totals?.colours">
                <span class="mdi mdi-checkbox-blank-circle {{ gatewayData.totals?.colours.avg }}"></span> {{ gatewayData.totals?.avg }}<small>{{ insight.types[type].measurement }}</small>
              </td>
              <td *ngIf="gatewayData.totals?.colours">
                <span class="mdi mdi-checkbox-blank-circle {{ gatewayData.totals?.colours.min }}"></span> {{ gatewayData.totals?.min }}<small>{{ insight.types[type].measurement }}</small>
              </td>
              <td *ngIf="gatewayData.totals?.colours">
                <span class="mdi mdi-checkbox-blank-circle {{ gatewayData.totals?.colours.max }}"></span> {{ gatewayData.totals?.max }}<small>{{ insight.types[type].measurement }}</small>
              </td>
              <td *ngIf="!gatewayData.totals?.colours" colspan="3"></td>
            </tr>
            <tr *ngFor="let gatewayData of collections">
              <td><img src="{{ svgLocation }}icon-{{ type }}.svg" /></td>
              <td>{{ gatewayData.gateway.title }}</td>
              <td *ngIf="gatewayData.totals?.colours">
                <span class="mdi mdi-checkbox-blank-circle {{ gatewayData.totals?.colours.avg }}"></span> {{ gatewayData.totals?.avg }}<small>{{ insight.types[type].measurement }}</small>
              </td>
              <td *ngIf="gatewayData.totals?.colours">
                <span class="mdi mdi-checkbox-blank-circle {{ gatewayData.totals?.colours.min }}"></span> {{ gatewayData.totals?.min }}<small>{{ insight.types[type].measurement }}</small>
              </td>
              <td *ngIf="gatewayData.totals?.colours">
                <span class="mdi mdi-checkbox-blank-circle {{ gatewayData.totals?.colours.max }}"></span> {{ gatewayData.totals?.max }}<small>{{ insight.types[type].measurement }}</small>
              </td>
              <td *ngIf="!gatewayData.totals?.colours" colspan="3"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  }
</article>
