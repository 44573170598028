import { Component } from '@angular/core';
import { OccupancyService } from 'app/shared/occupancy.service';

@Component({
    selector: 'app-occupancy-admin',
    templateUrl: './occupancy-admin.component.html',
    styleUrls: ['./occupancy-admin.component.css'],
    standalone: false
})
export class OccupancyAdminComponent {
  constructor(occupancyService: OccupancyService) {

  }
}
