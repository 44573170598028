import { Component, OnInit } from '@angular/core';
import { APIService } from 'app/shared/api.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-dev-create-key',
    templateUrl: './dev-create-key.component.html',
    styleUrls: ['./dev-create-key.component.css'],
    standalone: false
})
export class DevCreateKeyComponent implements OnInit {

  apikey: { title: string } = { title: '' };
  constructor(private apiService: APIService, private router: Router) { }

  ngOnInit() {
  }

  createKey() {
    if (!this.apikey.title) {
      // Must have a name.
      return;
    }
    this.apiService.apiController('post', 'apikey', this.apikey)
      .then(r => {
        this.router.navigate(['/developer']);
      });
  }

}
