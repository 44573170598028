<main>
  <section>
    <div>
      <div>
        <p>{{ issue().annotation }}</p>
      </div>
      <div>
        <p>{{ issue().status }}</p>
      </div>
    </div>
  </section>
</main>
