/**
 * Custom report for any asset
 */
import { Component, signal } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { IExplorerSelectedItems } from 'app/developer/explorer/explorer.component';
import { IDatesFromTo } from 'app/interfaces/dates-from-to';
import { APIService } from 'app/shared/api.service';
import { AssetService } from 'app/shared/asset.service';
import { CalendarService, IDateConfig, IDateRange } from 'app/shared/calendar.service';
import { Chart } from 'chart.js';
import 'chartjs-adapter-date-fns';
import moment from 'moment-timezone';

@Component({
    selector: 'app-report-custom',
    templateUrl: './report-custom.component.html',
    styleUrl: './report-custom.component.css',
    standalone: false
})
export class ReportCustomComponent {
  readonly LOCALSTORAGE_STATE = 'reports:custom:state';

  assetIds = signal<number[]>([]);
  assets = signal<Asset[]>([]);
  assetsDateConfigs = signal<any>({});
  assetsChartConfigs = signal<any>({});
  assetsTelemetry = signal<any>({});

  explorerSelectedItems = signal<IExplorerSelectedItems>(null);

  colours = ['#690af5', '#d50cf3', 'brown', 'purple'];

  readonly LINE_CURVED = .8;
  readonly POINT_RADIUS = 2;

  dateConfigs = signal<IDateConfig[]>([
    { dateRange: 'thisweek', dates: { from: null, to: null } },
    { dateRange: 'lastweek', dates: { from: null, to: null } },
    { dateRange: 'thismonth', dates: { from: null, to: null } },
    { dateRange: 'lastmonth', dates: { from: null, to: null } },
    { dateRange: 'custom', dates: { from: null, to: null } }
  ]);

  assetSelected = signal<Asset>(null);
  configureSelected = signal<boolean>(false);

  dialogConfig: { lineWidth: number, pointStyle: 'circle' | 'square' | false, fill: boolean, lineColour: string, lineCurve: boolean } = { pointStyle: false, fill: false, lineColour: 'black', lineWidth: 2, lineCurve: false };

  public chart: any;

  public analysis: any = { message: 'Analysis will appear here.' };

  constructor(private assetService: AssetService, private apiService: APIService, private calendarService: CalendarService) {
    this.getLocalState();
  }

  /**
   * Analyse the assets and datasets and make intellegient decisions
   */
  analyseAssets() {

  }

  clickConfigure() {
    this.configureSelected.set(true);
  }

  toggleProperty(property: string) {
    switch (property) {
      case 'pointStyle':
        this.dialogConfig.pointStyle = this.dialogConfig.pointStyle ? false : 'circle';
        break;
      case 'lineWidth':
        this.dialogConfig.lineWidth = this.dialogConfig.lineWidth === 1 ? 3 : 1;
        break;
      default:
        this.dialogConfig[property] = !this.dialogConfig[property];
        break;
    }
    this.storeLocalState();

    if (this.chart) {
      this.chart.data.datasets.forEach(dataset => {
        dataset.pointStyle = this.dialogConfig.pointStyle;
        dataset.fill = this.dialogConfig.fill;
        dataset.borderWidth = this.dialogConfig.lineWidth;
        dataset.tension = this.dialogConfig.lineCurve ? this.LINE_CURVED : 0;
      });
      this.chart.update();
    }
  }

  getData(asset: Asset, from: Date, to: Date) {
    if (!asset) {
      return;
    }
    this.assetService.getTelemetryForRangeAsObjects(asset, from, to).then(telemetry => {
      this.assetsTelemetry.update(assetsTelemetry => {
        assetsTelemetry[asset.id] = telemetry.map(t => { return { x: new Date(t.c), y: +t.v } });
        this.showChart(asset);

        return assetsTelemetry;
      });
    });
  }

  assetsSelected(selected: IExplorerSelectedItems) {
    const assets = selected.assets;
    this.assetIds.set(Object.keys(assets).filter(key => assets[key]).map(id => +id));
    this.getAssetData();
    console.log(assets);
    this.storeLocalState();
    this.getLocalState();
  }

  removeAsset(asset: Asset) {
    this.assetIds.update(ids => {
      return ids.filter(id => id !== asset.id);
    });
    this.assets.update(assets => {
      return assets.filter(assetsAsset => assetsAsset.id !== asset.id);
    });
    this.storeLocalState();
    this.getLocalState();
    this.buildCharts();
  }

  getAssetData() {
    if (this.assetIds()?.length) {
      this.assetService.getAssets(this.assetIds()).then(assets => {
        this.assets.set(assets);
      });
    }
  }

  getLocalState() {
    const orgId = this.apiService.getUserOrg().id;
    const localstorageString = localStorage.getItem(`org:${orgId}:` + this.LOCALSTORAGE_STATE);
    if (localstorageString) {

      const selected = JSON.parse(localstorageString);

      if (selected.config) {
        this.dialogConfig = selected.config;
      }

      this.assetIds.set(selected.assets);
      const assets = {};
      selected.assets.forEach(assetId => assets[assetId] = true);

      this.explorerSelectedItems.set({ sites: {}, gateways: {}, assets });

      this.assetsDateConfigs.update(configs => {
        selected.assets.forEach(assetId => configs[assetId] = { dateRange: null, dates: { from: null, to: null } });

        return configs;
      });

      this.getAssetData();
    }
  }

  storeLocalState() {
    const orgId = this.apiService.getUserOrg().id;
    const payload = JSON.stringify({ assets: this.assetIds(), config: this.dialogConfig });
    console.log(payload);
    localStorage.setItem(`org:${orgId}:` + this.LOCALSTORAGE_STATE, payload);
  }

  addDateRange() {
    this.dateConfigs.update(dateConfigs => {

      let dateRange: IDateRange = 'today';
      let dates: IDatesFromTo = { from: null, to: null };

      if (dateConfigs?.length) {
        switch (dateConfigs[dateConfigs.length - 1].dateRange) {
          case 'today':
            dateRange = 'thisweek';
            break;
          case 'thisweek':
            dateRange = 'lastweek';
            break;
          case 'lastweek':
            dateRange = 'thismonth';
            break;
          case 'thismonth':
            dateRange = 'lastmonth';
            break;
          default:
            dateRange = 'custom';
            break;
        }
      }
      const payload: IDateConfig = { dateRange, dates };

      dateConfigs.push(payload);

      return dateConfigs;
    });
  }

  assetChipClick(asset: Asset) {
    this.assetSelected.set(asset);
  }

  /* chart.js cant overlay diferent series of data, so for now force one dataset */
  assetUseDateConfig(dateConfig: IDateConfig) {
    const asset = this.assetSelected();
    this.assetsDateConfigs.update(configs => {
      Object.keys(configs).forEach(key => configs[key] = dateConfig);
      // configs[asset.id] = dateConfig;
      return configs;
    });
    this.assetSelected.set(null);
  }

  async buildCharts() {
    try {
      const assetIds = Object.keys(this.assetsDateConfigs());

      if (!assetIds?.length) {
        this.apiService.toastWarn('No assets (max 2) or dates selected', '');
        return;
      }

      for (let index = 0; index < assetIds.length; index++) {
        const assetId = +assetIds[index];

        const dateConfig: IDateConfig = this.assetsDateConfigs()[assetId];

        let { from, to } = this.calendarService.calculateDatesFromDateConfig(dateConfig);

        const asset = this.assets().find(asset => asset.id === assetId);

        if (!from || !to) {
          console.log('no from or to date');
          return;
        }
        this.getData(asset, from, to);
      }

    } catch (e) {
      console.log('not yet');
      return;
    }
  }


  showChart(asset?: Asset) {
    if (this.chart) {
      this.chart.clear();
      this.chart.destroy();
    }

    const assetIdsWithTelemetry = Object.keys(this.assetsTelemetry());

    let datasets = [];

    let scales = {};


    for (let index = 0; index < assetIdsWithTelemetry.length; index++) {

      let position = 'left';
      let scaleId = 'y1';
      if (index > 0) {
        position = 'right';
        scaleId = 'y2';
      }
      const assetId = +assetIdsWithTelemetry[index];

      const asset = this.assets().find(asset => asset.id === assetId);

      datasets.push({
        label: asset.title,
        yAxisID: scaleId,
        data: this.assetsTelemetry()[asset.id],
        fill: this.dialogConfig.fill,
        pointStyle: this.dialogConfig.pointStyle,
        borderColor: this.colours[index],
        borderWidth: this.dialogConfig.lineWidth,
        tension: this.dialogConfig.lineCurve ? this.LINE_CURVED : 0,
        pointRadius: this.POINT_RADIUS
      });
    }

    this.chart = new Chart("chart-1",
      {
        type: 'line',
        data: {
          datasets
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            x: { type: 'time', time: { unit: 'day' } },
            y1: { position: 'left', beginAtZero: true },
            y2: { position: 'right', beginAtZero: true },
          }
        }
      });
  }
}
