import { Component, OnInit, Input } from '@angular/core';
import { AQItem, AQService } from 'app/shared/aq.service';
import { APIService } from 'app/shared/api.service';
import { Asset } from 'app/classes/asset';

@Component({
    selector: 'app-gateway-aq',
    templateUrl: './gateway-aq.component.html',
    styleUrls: ['./gateway-aq.component.css'],
    standalone: false
})
export class GatewayAqComponent implements OnInit {

  @Input()
  aq: AQItem;

  assets: Asset[];

  isLoading: boolean;

  constructor(private aqService: AQService, private apiService: APIService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.apiService
      .getAssetsForGatewayId(this.aq.id)
      .then(assets => {
        this.assets = [];
        const assetList = assets.filter(asset => {
          switch (asset.assetType_id) {
            case 27: // voc
            case 29: // pm2.5
            case 51: // Radon
            case 25: // co2
            case 2: // room temp
            case 3: // pressure
            case 8: // humidity
              return true;
          }
        });

        this.pushAsset(assetList);

        console.log(this.assets, assets);
      });
  }


  pushAsset(assetList: Asset[]) {
    this.assets.push(assetList.pop());
    if (assetList.length) {
      setTimeout(() => {
        this.pushAsset(assetList);
      }, 500);
    } else {
      this.isLoading = false;
    }
  }
}
