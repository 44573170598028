import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-info-box',
    templateUrl: './info-box.component.html',
    styleUrls: ['./info-box.component.css'],
    standalone: false
})
export class InfoBoxComponent implements OnInit {

  @Input()
  title: string;

  @Output()
  trayLinkClicked:EventEmitter<string> = new EventEmitter<string>();

  @Input()
  link: string;

  @Input()
  theme: 'compact' | 'extended' = 'extended';

  constructor() { }

  ngOnInit() {
  }

  clickTray() {
    this.trayLinkClicked.emit(this.link);
  }

}
