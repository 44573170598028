import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { User } from 'app/classes/user';
import { APIService } from 'app/shared/api.service';
import { EngagementSharedSite, EngagementUserLogItem } from 'app/shared/engagement.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-partner-shared-sites',
    templateUrl: './partner-shared-sites.component.html',
    styleUrls: ['./partner-shared-sites.component.css'],
    standalone: false
})
export class PartnerSharedSitesComponent implements OnInit, OnDestroy {

  @Input()
  engagementSharedSites: EngagementSharedSite[];

  _userLog: EngagementUserLogItem[];
  @Input()
  public set userLog(v: EngagementUserLogItem[]) {

    console.log('GOT USER_LOG', v);
    this._userLog = v;

    this.calc();
  }

  userSubscription: Subscription;
  user: User;

  constructor(private apiService: APIService) {

    this.userSubscription = this.apiService.user.subscribe(user => {
      console.log(user);
      this.user = user;
    });
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }


  calc() {
    if (this._userLog) {
      this._userLog.forEach(log => {
        const site = this.engagementSharedSites.find(s => s.site.id === log.siteId);
        if (site) {
          const org = site.orgs.find(o => o.org.id === log.orgId);
          if (org) {
            org.count++;
          } else {
            console.log(`no org`, log);
          }
        } else {
          console.log(`no site`, log);
        }
      });

    }
  }



}
