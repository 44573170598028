import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-org-integration-home',
    templateUrl: './org-integration-home.component.html',
    styleUrls: ['./org-integration-home.component.css'],
    standalone: false
})
export class OrgIntegrationHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
