import { Component, OnInit } from '@angular/core';
import { EngageService } from '../engage.service';

@Component({
    selector: 'app-engage-users',
    templateUrl: './engage-users.component.html',
    styleUrls: ['./engage-users.component.css'],
    standalone: false
})
export class EngageUsersComponent implements OnInit {
  users: any[];

  constructor(private engageService: EngageService) {

    engageService.getUsers()
      .then(users => {
        this.users = users;
      });
  }

  ngOnInit() {
  }

}
