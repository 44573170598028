<div class="col-sm-12">
  <table class="table table-hover table-sm table-striped">
    <thead>
      <tr>
        <th>
          Sensors
          <span *ngIf="range.type === 'avg'">Average</span>
          <span *ngIf="range.type === 'min'">Minimum</span>
          <span *ngIf="range.type === 'max'">Maximum</span>
        </th>
        <th>{{ legends[6].title }}</th>
        <th>{{ legends[5].title }}</th>
        <th>{{ legends[4].title }}</th>
        <th>{{ legends[3].title }}</th>
        <th>{{ legends[2].title }}</th>
        <th>{{ legends[1].title }}</th>
        <th>{{ legends[0].title }}</th>

        <th class="text-right">Latest</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let profileAsset of assets" (click)="selectSensor(profileAsset)">
        <td *ngIf="assetService.isNumeric(profileAsset)">
          <i [class]="assetService.getIcon(profileAsset)" title="#{{ profileAsset.id }}"></i>
          <span title="{{ profileAsset.location }}">{{ profileAsset.title }}</span>
        </td>
        <td *ngIf="assetService.isNumeric(profileAsset)" [ngClass]="{ amber: profileAsset.history[6] && profileAsset.history[6].rag.amber, red: profileAsset.history[6] && profileAsset.history[6].rag.red }">
          <span *ngIf="profileAsset.history[6]">{{ profileAsset.history[6][range.type] | number: '0.2' }}</span>
        </td>
        <td *ngIf="assetService.isNumeric(profileAsset)" [ngClass]="{ amber: profileAsset.history[5] && profileAsset.history[5].rag.amber, red: profileAsset.history[5] && profileAsset.history[5].rag.red }">
          <span *ngIf="profileAsset.history[5]">{{ profileAsset.history[5][range.type] | number: '0.2' }}</span>
        </td>
        <td *ngIf="assetService.isNumeric(profileAsset)" [ngClass]="{ amber: profileAsset.history[4] && profileAsset.history[4].rag.amber, red: profileAsset.history[4] && profileAsset.history[4].rag.red }">
          <span *ngIf="profileAsset.history[4]">{{ profileAsset.history[4][range.type] | number: '0.2' }}</span>
        </td>
        <td *ngIf="assetService.isNumeric(profileAsset)" [ngClass]="{ amber: profileAsset.history[3] && profileAsset.history[3].rag.amber, red: profileAsset.history[3] && profileAsset.history[3].rag.red }">
          <span *ngIf="profileAsset.history[3]">{{ profileAsset.history[3][range.type] | number: '0.2' }}</span>
        </td>
        <td *ngIf="assetService.isNumeric(profileAsset)" [ngClass]="{ amber: profileAsset.history[2] && profileAsset.history[2].rag.amber, red: profileAsset.history[2] && profileAsset.history[2].rag.red }">
          <span *ngIf="profileAsset.history[2]">{{ profileAsset.history[2][range.type] | number: '0.2' }}</span>
        </td>
        <td *ngIf="assetService.isNumeric(profileAsset)" [ngClass]="{ amber: profileAsset.history[1] && profileAsset.history[1].rag.amber, red: profileAsset.history[1] && profileAsset.history[1].rag.red }">
          <span *ngIf="profileAsset.history[1]">{{ profileAsset.history[1][range.type] | number: '0.2' }}</span>
        </td>
        <td *ngIf="assetService.isNumeric(profileAsset)" [ngClass]="{ amber: profileAsset.history[0] && profileAsset.history[0].rag.amber, red: profileAsset.history[0] && profileAsset.history[0].rag.red }">
          <span *ngIf="profileAsset.history[0]">{{ profileAsset.history[0][range.type] | number: '0.2' }}</span>
        </td>
        <td *ngIf="assetService.isNumeric(profileAsset)" class="text-right" title="{{ profileAsset.updatedAt | amDateFormat: apiService.getDateTimeFormat() }}">
          {{ profileAsset.value | number: '0.2' }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

<app-sensor-popup [show]="!!sensorSelected" (showChange)="sensorSelected = null" [asset]="sensorSelected" [startsAt]="startsAt" [endsAt]="endsAt" [showPoints]="true"></app-sensor-popup>

<app-please-wait *ngIf="isLoading"></app-please-wait>
