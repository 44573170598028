import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-profiling-add',
    templateUrl: './profiling-add.component.html',
    styleUrls: ['./profiling-add.component.css'],
    standalone: false
})
export class ProfilingAddComponent implements OnInit {

  constructor(public messageService: MessageService) { }

  ngOnInit(): void {
  }
  toolbarClick(item) {
    this.messageService.add({ summary: 'Exporting...', severity: 'info' });
    const filename = 'license_export';
  }

}
