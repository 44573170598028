import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";

@Component({
    selector: "app-tick",
    templateUrl: "./tick.component.html",
    styleUrls: ["./tick.component.css"],
    standalone: false
})
export class TickComponent implements OnInit {
  
  _selected: boolean;
  
  @Input()
  public set selected(edit: boolean) {
      this._selected = edit;
   //   this.selectedChange.emit(this._selected);
  }
  public get selected(): boolean {
    return this._selected;
  }

 // @Output()
 // selectedChange = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
