import { Component, HostListener, Output, EventEmitter, Input, signal, OnInit, input } from '@angular/core';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrl: './dialog.component.css',
    standalone: false
})
export class DialogComponent implements OnInit {
  _value = signal<any>(null);
  _header = signal<string>(null);
  _footerItems = signal<IFooterItem[]>(null);
  _style = signal('width: calc(100vw - 100px);left: 50px;');
  _mainStyle = signal<string>(null);
  _widthPx = signal<number>(null);
  _canScroll = signal<boolean>(false);
  _canSave = signal<boolean>(false);
  _height = signal<number>(null);
  _zIndex = signal<number>(11);
  _hasToolbar = signal<boolean>(false);
  _dimensions = signal<{ width: number, height: number }>(null);
  _originalStyle = signal<string>(null);

  _canFullscreen = input(false);
  canInteract = input(true);

  padding = 10;

  @Input()
  public set value(v: any) {
    this._value.set(v);
  }

  @Input()
  public set header(v: string) {
    this._header.set(v);
  }

  @Input()
  public set footer(v: IFooterItem[]) {
    this._footerItems.set(v);
  }

  @Input()
  public set width(v: number) {
    this._widthPx.set(v);
    this._style.set(`width: ${v}px;left: calc(50vw - ${v / 2}px;`);
  }

  @Input()
  public set canScroll(v: boolean) {
    this._canScroll.set(v);
  }

  @Input()
  public set canSave(v: boolean) {
    this._canSave.set(v);
    if (v) {
      this._footerItems.set([{ type: 'action', label: 'save', id: 'save' }])
    } else {
      this._footerItems.set(null);
    }
  }

  @Input()
  public set dimensions(v: { width: number, height: number }) {
    this._dimensions.set(v);
  }

  @Input()
  public set zIndex(v: number) {
    if (v) {
      this._zIndex.set(v);
    }
  }
  public get zIndex(): number {
    return this._zIndex();
  }

  @Input()
  public set hasToolbar(v: boolean) {
    this._hasToolbar.set(v);
  }

  @Output()
  onClose = new EventEmitter<any>();

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key.toLowerCase() == "escape") {
      console.log(event.key);
      this.close();
    }
  }

  ngOnInit(): void {
    const footerHeight = this._footerItems() ? 57 : 0;
    const toolbarHeight = this._hasToolbar() ? 30 : 0
    const otherElementsHeights = toolbarHeight + footerHeight;
    const v = this._dimensions();
    if (v) {
      if (v.height && !v.width) {
        const style = `height:${v.height}px; top:calc(50vh - ${Math.round(v.height / 2)}px);left: 40px; width:calc(100vw - 80px);`;
        console.log(style);
        this._style.set(style);
      } else {
        const style = `width:${v.width}px; height:${v.height}px; top:calc(50vh - ${Math.round(v.height / 2)}px);left: calc(50vw -  ${Math.round(v.width / 2)}px);`;
        console.log(style);
        this._style.set(style);
      }
      console.log(`(${v.height}px - ${otherElementsHeights}px)`)
      this._mainStyle.set(`height:calc(${v.height}px - 34px - ${this.padding * 2}px - ${otherElementsHeights}px)`);
    }
  }

  backgroundWheel(e: MouseEvent) {
    e.preventDefault();
  }

  fullscreen() {
    if (this._originalStyle()) {
      this._style.set(this._originalStyle());
      this._originalStyle.set(null);
    } else {
      this._originalStyle.set(this._style());
      this._style.set('width:calc(100vw - 26px);height:calc(100vh - 10px);top:5px;left:5px;');
    }
  }

  close(id?: number | string) {
    if (id) {
      this.onClose.emit({ id, value: this._value() });
    } else {
      this.onClose.emit(this._value());
    }
  }
}

export interface IFooterItem {
  type: 'button' | 'text' | 'action';
  label: string;
  icon?: string;
  id?: number | string;
}
