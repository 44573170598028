import { Component, Input, signal, Output, EventEmitter } from '@angular/core';
import { IExplorerSelectedItems } from '../../developer/explorer/explorer.component';
import { GatewayService } from '../../shared/gateway.service';

@Component({
    selector: 'app-dialog-select',
    templateUrl: './dialog-select.component.html',
    styleUrl: './dialog-select.component.css',
    standalone: false
})
export class DialogSelectComponent {

  _config = signal<IExplorerSelectedItems>(null);

  @Input()
  public set selectedItems(v: IExplorerSelectedItems) {
    this._config.set(v);
  }

  public get selectedItems(): IExplorerSelectedItems {
    return this._config();
  }

  @Output()
  onSelected = new EventEmitter<IExplorerSelectedItems>();

  constructor(private gatewayService: GatewayService) {

  }

  selected(ev: any) {
    console.log(ev);
    this.onSelected.emit(ev);
  }

  selectionCompleted(ev: IExplorerSelectedItems) {
    console.log(ev);
    this.onSelected.emit(ev);
  }
}

export type DialogSelectWhat = 'site' | 'gateway';
