import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FilterMenus } from '../classes/filter-menus';
import { FilterMenuItem } from '../classes/filter-menu-item';
import { APIService } from '../shared/api.service';
import { StoreService } from '../shared/store.service';
import { Alert } from '../classes/alert';

@Component({
    selector: 'filter-menus',
    styleUrls: ['./filter-menus.component.css'],
    templateUrl: './filter-menus.component.html',
    animations: [
        trigger('filterState', [
            state('inactive', style({
                opacity: 0
            })),
            state('active', style({
                opacity: 1,
                marginLeft: 0
            })),
            transition('void => active', animate('400ms ease-in')),
            transition('inactive => active', animate('1000ms ease-in')),
            transition('active => inactive', animate('1000ms ease-out'))
        ])
    ],
    standalone: false
})
export class FilterMenusComponent implements OnInit {
    alerts: Alert[];
    filterMenus: FilterMenus;
    filterState: string;

    constructor(private storeService: StoreService, private apiService: APIService, private router: Router) {
        this.filterState = 'inactive';
        this.storeService.filtering.subscribe(menus => {
            this.filterMenus = menus;
        });
    }

    ngOnInit(): void {
        //  console.log('FilterMenusComponent.oninit()');
    }

    menuClick(item: FilterMenuItem) {
        this.storeService.setFilterMenuItem(item);
    }

    menusLoaded() {
        this.filterState = 'active';
    }

    animationStarted(event) {
        console.log('a started', event);
    }
    animationDone(event) {
        console.log('a done', event);
    }
}
