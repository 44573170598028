import { Injectable } from '@angular/core';
import { SiteService } from './site.service';
import { HttpClient } from '@angular/common/http';
import { APIService } from './api.service';
import { OccCollection } from 'app/classes/occ-collection';
import { OccupancySiteWeeklyStats } from 'app/site-plan/site-plan-dashboard/spd-weekly/spd-weekly.component';
import { OccupancySiteMonthlyStats } from '../classes/occupancy-site-monthly-stats';
import { BehaviorSubject, Observable } from 'rxjs';
import { IDatesFromTo } from 'app/interfaces/dates-from-to';
import { Tenant } from 'app/classes/tenant';
import { ServiceForTenants } from 'app/classes/service-for-tenant';

@Injectable({
  providedIn: 'root'
})
export class OccupancyService {

  static readonly API_URL = 'https://43u2n4sh75.execute-api.eu-west-2.amazonaws.com/prod';

  private _toolbar: BehaviorSubject<IToolbarChanged> = new BehaviorSubject(null);
  public toolbar: Observable<IToolbarChanged> = this._toolbar.asObservable();

  constructor(private siteService: SiteService, private http: HttpClient, private apiService: APIService) { }

  toolbarDatesChanged(dates: IDatesFromTo) {
    this._toolbar.next({ action: 'dates', value: dates });
  }

  toolbarTenantChanged(tenant: Tenant) {
    this._toolbar.next({ action: 'tenant', value: tenant });
  }

  toolbarChanged(settings: IToolbarSettings) {
    this._toolbar.next({ action: 'settings', value: settings });
  }

  async getCollections(): Promise<any> {
    return new Promise(async (resolve) => {
      this.http
        .get<any>(OccupancyService.API_URL + '/collections', this.apiService.getUAOHeaders())
        .subscribe(async response => {
          resolve(response.map(collection => new OccCollection(collection)));
        });
    });
  }

  async getTenantsPercentages(dates: IDatesFromTo, tenantId = 0): Promise<ITenantPercentage[]> {
    return new Promise(async (resolve) => {
      this.http
        .get<any>(OccupancyService.API_URL + `/tenancy?from=${dates.from}&to=${dates.to}&tid=${tenantId}`, this.apiService.getUAOHeaders())
        .subscribe(async response => {
          resolve(response);
        });
    });
  }

  async getServicePercentages(dates: IDatesFromTo, serviceId = 0): Promise<IServicePercentage[]> {
    return new Promise(async (resolve) => {
      this.http
        .get<any>(OccupancyService.API_URL + `/services?from=${dates.from}&to=${dates.to}&sid=${serviceId}`, this.apiService.getUAOHeaders())
        .subscribe(async response => {
          resolve(response);
        });
    });
  }

  async updateCollection(occCollection: OccCollection, rebuild: boolean = false): Promise<any> {
    return new Promise(async (resolve) => {

      const payload: any = occCollection.serialise();
      payload.rebuild = rebuild;

      this.http
        .post<any>(OccupancyService.API_URL + '/collections', payload, this.apiService.getUAOHeaders())
        .subscribe(async response => {
          resolve(response);
        });
    });
  }

  async rebuildCollection(collectionId: number, jobId: number = null, qs = ''): Promise<any> {
    return new Promise(async (resolve) => {
      this.http
        .post<any>(OccupancyService.API_URL + `/collections/${collectionId}/rebuild${qs && '?' + qs}`, { jobId }, this.apiService.getUAOHeaders())
        .subscribe(async response => {
          resolve(response);
        });
    });
  }

  /**
   * Fill in missing days since list date stored
   * @returns 
   */
  async autoRebuild(qs = ''): Promise<any> {
    return new Promise(async (resolve) => {
      this.http
        .post<any>(OccupancyService.API_URL + `/collections/rebuild/auto${qs && '?' + qs}`, {}, this.apiService.getUAOHeaders())
        .subscribe(async response => {
          resolve(response);
        });
    });
  }

  /**
   * Re-link assets
   * @returns 
   */
  async relinkAssets(collectionId, qs = ''): Promise<any> {
    return new Promise(async (resolve) => {
      this.http
        .patch<any>(OccupancyService.API_URL + `/collections/${collectionId}/rebuild/links${qs ? '?' + qs : ''}`, {}, this.apiService.getUAOHeaders())
        .subscribe(async response => {
          resolve(response);
        });
    });
  }

  /**
   * Re-link assets
   * @returns 
   */
  async getAnalysisForCollection(collectionId, qs = ''): Promise<any> {
    return new Promise(async (resolve) => {
      this.http
        .get<any>(OccupancyService.API_URL + `/collections/${collectionId}/analyse${qs ? '?' + qs : ''}`, this.apiService.getUAOHeaders())
        .subscribe(async response => {
          resolve(response);
        });
    });
  }
  getBuiildings() {
    this.siteService.getSites();
  }

  async getHourlyForAsset(assetIds, forDate): Promise<IgetHourlyForAssetResponse> {
    return new Promise(async (resolve) => {
      let q = `a=${assetIds}&df=${this.apiService.dbDate(forDate)}`;
      this.http
        .get<any>(OccupancyService.API_URL + `/hourly?${q}`, this.apiService.getUAOHeaders())
        .subscribe(async response => resolve(response));
    });
  }

  async getSettingsForSite(siteId): Promise<IGetSettingsForSite> {
    return new Promise(async (resolve) => {
      let q = `s=${siteId}`;
      this.http
        .get<any>(OccupancyService.API_URL + `/settings?${q}`, this.apiService.getUAOHeaders())
        .subscribe(async response => resolve({ tenants: response.tenants.map(t => new Tenant(t)), services: response.services.map(s => new ServiceForTenants(s)) }));
    });
  }

  async rebuildHourlyForAsset(assetId: number | null, forDate: Date, toDate: Date, resetData: boolean, indexOn = null, totalAssets = null, trackingUUID = null): Promise<any> {
    return new Promise(async (resolve) => {
      let q = `a=${assetId || ''}&fromDate=${this.apiService.dbDate(forDate)}&toDate=${this.apiService.dbDate(toDate)}&resetData=${resetData ? 1 : 0}`;
      if (totalAssets) {
        // This is part of a collection of assets
        q += `&ion=${indexOn}&ta=${totalAssets}&tu=${trackingUUID}`;
      }
      this.http
        .get<any>(OccupancyService.API_URL + `/hourly/build?${q}`, this.apiService.getUAOHeaders())
        .subscribe(async response => resolve(response));
    });
  }

  async getHourlyForAssetFromRange(assetIds, forDate: Date, toDate: Date, wantsSummary = false, view: 'daily' | 'weekly' | 'monthly' = 'daily'): Promise<IgetHourlyForAssetFromRangeResponse> {
    return new Promise(async (resolve) => {
      let q = `a=${assetIds}&df=${this.apiService.dbDate(forDate)}`;
      q += `&dt=${this.apiService.dbDate(toDate)}`;
      if (wantsSummary) {
        q += `&summary=1`;
      }
      if (view) {
        q += `&view=${view}`;
      }

      this.http
        .get<any>(OccupancyService.API_URL + `/hourly?${q}`, this.apiService.getUAOHeaders())
        .subscribe(async (response: IgetHourlyForAssetFromRangeResponse) => {
          if (response?.assets) {
            Object.keys(response.assets).forEach(assetId => {
              const asset: IGetHourlyForAssetFromRangeAsset = response.assets[assetId];
              if (response.collections.length === 1) {
                asset.collection = response.collections[0];
              }

              asset.daysOfHours.forEach(dh => dh.datetime = new Date(dh.date + ' 00:00:00'));
            });
          }
          resolve(response);
        });
    });
  }

  async getWeeklyForSite(siteId: number, forDate: Date): Promise<OccupancySiteWeeklyStats> {
    return new Promise(async (resolve) => {
      const q = `s=${siteId}&df=${this.apiService.dbDate(forDate)}`;
      this.http
        .get<IGetWeeklyForSiteResponse>(OccupancyService.API_URL + `/weekly?${q}`, this.apiService.getUAOHeaders())
        .subscribe(async response => resolve(new OccupancySiteWeeklyStats(response)));
    });
  }

  async getMonthlyForSite(siteId: number, fromDate: Date, toDate?: Date, tenantId?: number): Promise<IGetMonthlyForSiteResponse> {
    return new Promise(async (resolve) => {
      const q = `s=${siteId}&df=${this.apiService.dbDate(fromDate)}&dt=${this.apiService.dbDate(toDate)}&t=${tenantId ? tenantId : ''}`;
      this.http
        .get<any>(OccupancyService.API_URL + `/monthly?v=2&${q}`, this.apiService.getUAOHeaders())
        .subscribe(async response => {
          //const months = [];
          response.assets = {};
          for (let daysInMonth = 0; daysInMonth < response.months.length; daysInMonth++) {
            response.months[daysInMonth].tenants = response.tenants;
            response.months[daysInMonth].services = response.services;
            response.months[daysInMonth].plans = response.plans;
            response.months[daysInMonth].shapes = response.shapes;
            response.months[daysInMonth] = new OccupancySiteMonthlyStats(response.months[daysInMonth]);

            // Remove unwanted days.
            // response.months[daysInMonth].dows = response.months[daysInMonth].dows.filter(dow => +new Date(dow.date) >= +fromDate && +new Date(dow.date) <= +toDate);

            Object.keys(response.months[daysInMonth].assets).forEach(assetId => response.assets[assetId] = response.months[daysInMonth].assets[assetId]);
          }
          resolve(response);
        });
    });
  }

  async getRangeForSite(siteId: number, forDate: Date, toDate: Date, tenantId?: number): Promise<any> {
    return new Promise(async (resolve) => {
      const q = `s=${siteId}&df=${this.apiService.dbDate(forDate)}&dt=${this.apiService.dbDate(toDate)}&t=${tenantId ? tenantId : ''}`;
      this.http
        .get<any>(OccupancyService.API_URL + `/monthly?${q}`, this.apiService.getUAOHeaders())
        .subscribe(async response => {
          resolve(response);
        });
    });
  }
}

export interface IGetMonthlyForSiteResponse {
  assets: { [id: number]: any };
  assetIdArray: number[];
  assetIds: { [id: number]: any };
  plans: any[];
  services: ServiceForTenants[];
  tenants: Tenant[];
  shapes: any[];
  months: OccupancySiteMonthlyStats[]
}

export interface IgetHourlyForAssetFromRangeResponse {
  assets: { [assetId: number]: IGetHourlyForAssetFromRangeAsset };
  collections: ICollection[];
  floorplans: { rotateDeg: number, isColour: number, sfId: number, asset_id: number, planKey: string, floor: number, x: number, y: number, vw: number, vh: number, mfs: number }[],
  extra: { dates: { from: Date, to: Date, days: number } },
  values: {
    a_id: number,
    coll_id: number,
    datetime: null,
    duration: number,
    forDate: Date, assets: { [assetId: number]: IGetHourlyForAssetFromRangeAsset };
    collections: { i: number, t: string, org_id: number }[];
    values: {
      a_id: number,
      d: number,
      forDate: Date
    }[];
    forHour: number
  }[];
  occupationCollectionAssets?: { asset_id: number, collection_id: number }[];
}

export interface ICollection {
  i: number;
  t: string;
  org_id: number;
  days: { d: number, startTime: string, endTime: string, startHour: number, endHour: number, days: number }[];
}

export interface IGetHourlyForAssetFromRangeAsset {
  dow?: number[];
  gateway_id: string;
  assetType_id: number;
  createdAt: Date;
  identifier: string;
  location: string;
  title: string;
  daysOfHours: IDaysOfHours[];
  collection: any;
  tenant_id: number;
  service_id: number;
  tenantTitle: string;
  serviceTitle: string;
}

export interface IDaysOfHours {
  hours: IgetHourlyForAssetAssetsResponse[];
  sumOfDuration: number;
  dow: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  date: string;
  datetime: Date;
  rating: 'a' | 'b' | 'c' | 'd' | 'e';
  collectionForDow: { d: number, startTime: string, endTime: string, startHour: number, endHour: number, days: number };
}

export interface IgetHourlyForAssetResponse {
  assets: { [assetId: number]: { collection?: any, gateway_id: string, assetType_id: number, createdAt: Date, identifier: string, location: string, title: string, hours: IgetHourlyForAssetAssetsResponse[] } };
  collections: { i: number, t: string, org_id: number }[];
  values: {
    a_id: number,
    coll_id: number,
    datetime: null,
    duration: number,
    forDate: Date, assets: { [assetId: number]: { dow?: number[], gateway_id: string, assetType_id: number, createdAt: Date, identifier: string, location: string, title: string, hours: IgetHourlyForAssetAssetsResponse[] } };
    collections: { i: number, t: string, org_id: number }[];
    values: {
      a_id: number,
      d: number,
      forDate: Date
    }[];
    forHour: number
  }[];
}

export interface IGetWeeklyForSiteResponse {
  assets: { [assetId: number]: { dow: number[], gateway_id: string, title: string, a_id: number, coll_id: number } };
  collections: { [collectionId: number]: { i: number, org_id: number, t: string, days: { coll_id: number, d: number, days: number[], endHour: number, startHour: number, startTime: string, endTime: string }[] } };
  values: {
    a_id: number,
    d: number,
    forDate: Date
  }[];
}

export interface IgetHourlyForAssetAssetsResponse {
  c: number;
  d: number;
  h: number;
}

export interface IToolbarChanged {
  action: 'dates' | 'export' | 'tenant' | 'settings';
  value: IDatesFromTo | Tenant | null | IToolbarSettings;
}

export interface IGetSettingsForSite {
  tenants: Tenant[];
  services: ServiceForTenants[];
}

export interface IToolbarSettings {
  tenant: Tenant;
  datesFromTo: IDatesFromTo;
}

export interface ITenantPercentage {
  tenant_id: number;
  forDate: Date;
  percentage: number;
}

export interface IServicePercentage {
  service_id: number;
  forDate: Date;
  percentage: number;
}
