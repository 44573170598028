import { Component, OnInit } from '@angular/core';
import { DialogGroupComponent } from 'app/dialogs/dialog-group/dialog-group.component';
import { OrgService } from 'app/shared/org.service';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Grouping } from "../../classes/grouping";
import { WindowService } from 'app/shared/window.service';

@Component({
    selector: 'app-org-group-home',
    templateUrl: './org-group-home.component.html',
    styleUrls: ['./org-group-home.component.css'],
    providers: [DialogService],
    standalone: false
})
export class OrgGroupHomeComponent implements OnInit {

  groupings: Grouping[];
  dialogRef: DynamicDialogRef;

  constructor(public dialogService: DialogService, public messageService: MessageService, private orgService: OrgService, private windowService: WindowService) { }

  ngOnInit(): void {
    this.get();
  }

  get() {
    this.orgService.getGroupings().then(list => this.groupings = list);
  }

  showGroup(grouping: Grouping) {
    this.dialogRef = this.dialogService.open(DialogGroupComponent, {
      header: `Group ${grouping.title}`,
      width: '80%',
      data: grouping
    });

    this.dialogRef.onClose.subscribe((data: any) => {
      if (data) {
        this.messageService.add({ severity: 'info', summary: ' updated' });
      }
    });
  }

  createGroup() {
    const width = this.windowService.isMobile ? '100%' : '80%';
    this.dialogRef = this.dialogService.open(DialogGroupComponent, {
      header: `Create Group`,
      width,
      data: null
    });

    this.dialogRef.onClose.subscribe((data: any) => {
      if (data) {
        this.messageService.add({ severity: 'info', summary: ' updated' });
        this.get();
      }
    });
  }
}


