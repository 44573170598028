import { Component, effect, input, signal } from '@angular/core';
import { Site } from 'app/classes/site';
import { User, UserSelection } from 'app/classes/user';
import { UserGroup } from 'app/classes/user-group';
import { APIService } from 'app/shared/api.service';
import { UserGroupService } from 'app/shared/user-group.service';
import { UserService } from 'app/shared/user.service';
import { group } from 'console';

@Component({
    selector: 'app-site-notifications',
    templateUrl: './site-notifications.component.html',
    styleUrl: './site-notifications.component.css',
    standalone: false
})
export class SiteNotificationsComponent {

  site = input<Site>();
  users = signal<UserSelection[]>(null);

  constructor(private apiService: APIService, private userGroupService: UserGroupService) {
    effect(() => {
      if (this.site()?.id) {
        this.getOrgUsers();
      }
    });
  }

  getOrgUsers() {
    const query = `site=${this.site().id}&contacts=1`;
    this.apiService
      .getUsersForOrg(query)
      .then(users => {
        this.users.set(<UserSelection[]>users);
        this.site().userGroups.filter(ug => ug.grouping === 'notifications').forEach(group => {
          const user = this.users().find(u => u.id === group.user.id);
          if (user) {
            user.isSelected = true;
          }
        })
      });
  }

  toggleSelected(user: UserSelection) {
    user.isSelected = !user.isSelected;
    if (user.isSelected) {
      this.addUserFromGroup(user);
    } else {
      this.removeUserFromGroup(user);
    }
  }

  removeUserFromGroup(user: UserSelection) {
    let userGroup = new UserGroup({ user, grouping: 'notifications' });
    this.userGroupService.patch('remove', 'sites', this.site().id, userGroup).then(() => {
      this.apiService.toastSuccess('User removed from notifications', userGroup.user.name);
      //this.getOrgUsers();
    });
  }

  addUserFromGroup(user: UserSelection) {
    let userGroup = new UserGroup({ user, grouping: 'notifications' });
    this.userGroupService.patch('add', 'sites', this.site().id, userGroup).then(() => {
      this.apiService.toastSuccess('User added to notifications', user.name);
      // this.getOrgUsers();
    });
  }
}
