@if (isLoading) {
  <i class="mdi mdi-loading mdi-spin-x2"></i>
} @else {
  <div class="no-print drop-downs">
    <div class="flex">
      <div class="mr-1 flex-1">
        @if (buildings) {
          <app-dropdown [isBlock]="true" [value]="buildingSelectId" placeholder="Select a building" (onSelected)="selectBuildingChanged($event)" [list]="buildings" />
        }
      </div>
      <div>
        @if (hasCollection !== 'footfall') {
          <app-button (click)="clickShowReviews()">Review history</app-button>
        }
      </div>
    </div>
  </div>

  @if (buildingForReport) {
    <div class="mt-1 no-print">
      <table class="table">
        <thead>
          <tr>
            <th>Building</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ buildingForReport.title }} #{{ buildingForReport.id }}</td>
            <td>{{ buildingForReport.site.address.address1 }}, {{ buildingForReport.site.address.addressPostcode }}</td>
          </tr>
        </tbody>
      </table>

      <div>
        <header>
          <h1>Building Hours</h1>
          <app-button size="small" label="Update hours" icon="mdi mdi-pencil" (click)="toolbarClick('EDIT_HOURS')" />
        </header>

        @if (buildingForReport.openingHours.isSet) {
          <table class="table hours">
            <thead>
              <tr>
                <th>Monday</th>
                <th>Tuesday</th>
                <th>Wednesday</th>
                <th>Thursday</th>
                <th>Friday</th>
                <th>Saturday</th>
                <th>Sunday</th>
              </tr>
            </thead>
            <tr>
              @for (day of buildingForReport.openingHours.hours; track day) {
                <td>
                  @if (day.isClosed) {
                    Closed
                  } @else {
                    @if (day.from === '00:00' && day.to === '23:59') {
                      All day
                    } @else {
                      {{ day.from }} <span class="mobile-break">-</span> {{ day.to }}
                    }
                  }
                </td>
              }
            </tr>
          </table>
        } @else {
          <div>
            <p>No hours set for the building, please update the building hours to continue.</p>
            <p class="mt-1">
              <app-button label="Update hours" icon="mdi mdi-calendar-edit" (click)="toolbarClick('EDIT_HOURS')" />
            </p>
          </div>
        }
      </div>
    </div>
  }
}

@if (isShowingReviews()) {
  <app-dialog header="Reviews" [canScroll]="true" (onClose)="isShowingReviews.set(false)">
    <div class="search">
      <app-search-input [searchText]="searchText" (onSearchChanged)="searchChanged($event)" tooltipText="Search org & title"> </app-search-input>
    </div>

    <table class="table table-sm table-hover table-striped" aria-label="Reviews">
      <thead>
        <tr>
          <th>Org</th>
          <th>Title</th>
          <th>Collection</th>
          <th style="width: 130px">Created</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let review of reviews" (click)="clickReview(review)">
          <td>{{ review.orgShortTitle }}</td>
          <td>{{ review.title }}</td>
          <td>{{ review.collectionTitle }}</td>
          <td>{{ review.createdAt | amDateFormat: 'DD MMM YYYY' }}</td>
        </tr>
      </tbody>
    </table>
  </app-dialog>
}

@if (isShowingBuildingHours()) {
  <app-dialog (onClose)="isShowingBuildingHours.set(false)" [dimensions]="{ width: 420, height: 560 }">
    <article class="building-hours">
      <section class="days">
        <div>
          @for (hour of hoursEditing.hours; track hour) {
            <div [class.is-open]="!hour.isClosed" (click)="hour.isClosed = !hour.isClosed"><i class="mdi" [class.mdi-check]="!hour.isClosed" [class.mdi-close]="hour.isClosed"></i> {{ hour.shortDay }}</div>
          }
        </div>
      </section>

      <section class="day-hours">
        @for (hour of hoursEditing.hours; track hour; let i = $index) {
          <div [class.disabled]="hour.isClosed">
            {{ hour.day }}
            <div class="times">
              <div>
                <input [disabled]="hour.isClosed" class="form-control" type="time" [(ngModel)]="hour.from" />
              </div>
              <div>&nbsp;to&nbsp;</div>
              <div>
                <input [disabled]="hour.isClosed" class="form-control" type="time" [(ngModel)]="hour.to" />
              </div>
              <div class="ml-1">
                <i class="mdi mdi-content-copy" pTooltip="Copy to all" (click)="copyToAll(hour)"></i>
              </div>
            </div>
          </div>
        }
      </section>

      <section class="actions mt-1">
        <div class="text-right">
          <app-button label="Cancel" icon="pi pi-times" (click)="isShowingBuildingHours.set(false)" />
          <app-button label="Update" icon="mdi mdi-check" (click)="updateBuildingHours()" />
        </div>
      </section>
    </article>
  </app-dialog>
}
