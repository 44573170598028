import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-reviews-audit-trail',
    templateUrl: './reviews-audit-trail.component.html',
    styleUrls: ['./reviews-audit-trail.component.css'],
    standalone: false
})
export class ReviewsAuditTrailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
