@if (init()) {
  <app-please-wait></app-please-wait>
} @else {
  @if (!sites.length) {
    <div>
      <app-info-panel (click)="clickShowMore()">Your organisation has no floorplans. We can overlay sensors onto your buildings floorplans. </app-info-panel>
    </div>
  } @else {
    @if (!siteSelected) {
      <div>
        <div class="component-list no-print content-container">
          <table class="table table-hover sticky under-tabs">
            <thead>
              <tr>
                @if (showOrgs()) {
                  <th>Org</th>
                }
                <th>Site</th>
                <th class="isDesktop">Floorplans</th>
                <th>RAG</th>
              </tr>
            </thead>
            <tbody>
              @for (site of sites; track site) {
                <tr (click)="siteClick(site)">
                  @if (showOrgs()) {
                    <td>{{ site.org.shortTitle }}</td>
                  }
                  <td>{{ site.title }}</td>
                  <td class="plans isDesktop">
                    @if (site.hasDashboard) {
                      <span [routerLink]="['/profiling', 'sites', site.id, 'floorplans', 'dashboard']">Dashboard</span>
                    }
                    @for (plan of site.floorplans; track plan) {
                      <span (click)="gotoplan($event, plan)" pTooltip="Floorplan for {{ plan.title }}" tooltipPosition="top">
                        <i class="mdi" [ngClass]="{ 'mdi-chair-rolling': plan.hasOccupancy }"></i>
                        {{ plan.title }}
                      </span>
                    }
                  </td>
                  <td class="text-center">
                    @if (site.rag) {
                      <i class="fa fa-square rag {{ site.rag }}"></i>
                    }
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    } @else {
      <div class="">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Floor</th>
            </tr>
          </thead>
          <tbody>
            @for (floorplan of siteSelected.floorplans; track floorplan) {
              <tr (click)="floorClick(floorplan)">
                <td>{{ floorplan.title }}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    }
  }
}
