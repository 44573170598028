import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { Org } from 'app/classes/org';
import { User } from 'app/classes/user';
import { DialogInviteUserComponent } from 'app/dialogs/dialog-invite-user/dialog-invite-user.component';
import { DialogUserManageComponent } from 'app/dialogs/dialog-user-manage/dialog-user-manage.component';
import { APIService } from 'app/shared/api.service';
import { UserService } from 'app/shared/user.service';
import { WindowService } from 'app/shared/window.service';
import { Session } from 'inspector';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { IDirtyData } from './org-user-details/org-user-details.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-org-users-home',
    templateUrl: './org-users-home.component.html',
    styleUrls: ['./org-users-home.component.css'],
    providers: [DialogService],
    standalone: false
})
export class OrgUsersHomeComponent implements OnInit, OnDestroy {

  user: User;
  session: Session;
  users: User[];
  userSubscription: Subscription;
  today: Date = new Date();
  tomorrow: Date = new Date();
  org: Org;
  dialogRef: any;
  isLoading: boolean;
  is = { admin: false };
  can = { invite: false, admin: false };
  toolar = [];
  userSelected = signal<User>(null);
  isEditingUser = signal<boolean>(false);
  dirtyData: IDirtyData;

  wantsToDeleteUser = false;

  constructor(private windowService: WindowService, private apiService: APIService, public dialogService: DialogService, public messageService: MessageService, private userService: UserService, private route: Router) {

  }

  ngOnInit(): void {
    this.is.admin = this.can.invite = this.apiService.isAdmin();
    if (!this.can.invite) {
      this.can.invite = this.apiService.hasOrgRole('invite_user');
    }
    this.can.admin = this.is.admin || this.apiService.moduleAccess.user_admin;

    this.getOrgUsers();
  }

  toolbarClick(event: any) {
    console.log(event);
    this.inviteUser();
  }

  inviteUser() {
    this.dialogRef = this.dialogService.open(DialogInviteUserComponent, {
      header: `Invite User`,
      width: '90%'
    });
    this.dialogRef.onClose.subscribe((invite: any) => {
      if (invite) {
        this.getOrgUsers();
      }
    });
  }

  getOrgUsers() {
    this.isLoading = true;
    this.userSelected.set(null);
    this.userService.getUsersForOrg().then(
      users => {
        this.users = users.filter(u => u.role !== 'admin' && u.role !== 'contact').sort((a, b) => a.name > b.name ? -1 : 1).sort((a, b) => a.isActive ? -1 : 1);
        this.isLoading = false;
      }
    );
  }

  clickUserRow(user: User) {
    console.log(user);
    this.userSelected.set(user);
  }

  editUser() {
    this.userService.setIsEditing(true);
  }

  clickUserSites(event: any, user: User) {

    if (!this.can.admin) {
      return;
    }

    const width = this.windowService.isMobile ? '100%' : '90%';

    const dialogRef = this.dialogService.open(DialogUserManageComponent, {
      header: `Manage ${user.name}`,
      width,
      data: { user }
    });
    dialogRef.onClose.subscribe((user: any) => {
      if (user) {
        this.getOrgUsers();
      }
    });
  }

  disableUserToggle() {

  }

  deleteUser(hasConfirmed = false) {
    if (hasConfirmed) {
      this.wantsToDeleteUser = false;
      this.apiService.toastSuccess('deleting...', '');
      this.userService.adminCommandPost('delete', { id: this.userSelected().id }).then(() => {
        this.apiService.toastSuccess('deleted.', '');
        this.getOrgUsers();
      });
    } else {
      this.wantsToDeleteUser = true;
    }
  }

  updateUser() {
    this.apiService.toastSuccess('updating...', '');
    this.userService.adminCommandPost('update', { id: this.userSelected().id, changes: this.dirtyData }).then(() => {
      this.apiService.toastSuccess('updated.', '');
      this.getOrgUsers();
    });
  }

  onDataChange(dirtyData: IDirtyData) {
    console.log('dirtyData', dirtyData);
    this.dirtyData = dirtyData;
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      console.log('USER UNSUBSCRIBE');
      this.userSubscription.unsubscribe();
    }
  }
}
