import { Component, signal, Input } from '@angular/core';
import { AdminBuilderService } from 'app/admin/services/admin-builder.service';
import { BuilderItem } from 'app/classes/builderItem';
import { AssetService } from 'app/shared/asset.service';

@Component({
    selector: 'app-user-screen',
    templateUrl: './user-screen.component.html',
    styleUrl: './user-screen.component.css',
    standalone: false
})
export class UserScreenComponent {

  _screenId = signal<number>(null);
  _builderItem = signal<BuilderItem>(null);
  _fetchedAssetIds = signal<number[]>([]);

  @Input()

  public set screenId(v: number) {
    this._screenId.set(v);
    if (v) {
      this.builderService.get(`id=${v}`).then(data => {
        this.getAssets(new BuilderItem(data.blocks[0]));
      });
    }
  }

  constructor(private builderService: AdminBuilderService, private assetService: AssetService) {

  }

  getAssets(builderItem: BuilderItem) {
    this.fetchAssets(builderItem).then(_ => this._builderItem.set(builderItem));
  }

  fetchAssets(builderItem: BuilderItem) {
    return new Promise((resolve) => {

      const assetIds = builderItem.getAssetIds();
      if (assetIds.some(id => this._fetchedAssetIds().some(fid => fid === id) === false)) {
        this._fetchedAssetIds.set(assetIds);
        this.assetService.getAssets(assetIds).then(assets => {
          if (assets.length) {
            builderItem.updateAssets(assets);
            this._builderItem.set(builderItem);
          }
        });
      }
    });
  }
}
