import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BuildCmsItemBlock } from '../../../classes/cms-item-block';
import { CMSItem } from '../../../classes/cms-item';
import { ThemeType } from 'app/shared/store.service';

@Component({
    selector: 'app-cms-item',
    templateUrl: './cms-item.component.html',
    styleUrls: ['./cms-item.component.css'],
    standalone: false
})
export class CmsItemComponent {

  @Input()
  showDate: boolean = true;

  @Input()
  theme: ThemeType = 'dark';

  @Input()
  isEditing: boolean = false;

  @Input()
  public set item(v: CMSItem) {
    this._item = v;
  }

  public get item(): CMSItem {
    return this._item;
  }

  _item: CMSItem;

  @Output()
  onBlockClicked: EventEmitter<BuildCmsItemBlock> = new EventEmitter<BuildCmsItemBlock>();

  @Output()
  onBlockTrashed: EventEmitter<BuildCmsItemBlock> = new EventEmitter<BuildCmsItemBlock>();

  @Output()
  OnBlockMoved: EventEmitter<OnBlockMovedInterface> = new EventEmitter<OnBlockMovedInterface>();

  constructor() { }

  blockClicked(block: BuildCmsItemBlock) {
    console.log(`CmsItemComponent.onBlockClicked.emit(block)`);
    this.onBlockClicked.emit(block);
  }

  blockTrashed(block: BuildCmsItemBlock) {
    console.log(`CmsItemComponent.onBlockTrashed.emit(block)`);
    this.onBlockTrashed.emit(block);
  }

  blockMoved(direction: string, block: BuildCmsItemBlock) {
    console.log(`CmsItemComponent.onBlockMoved.emit(block)`);
    this.OnBlockMoved.emit({ block, direction });
  }
}

export interface OnBlockMovedInterface {
  direction: string;
  block: BuildCmsItemBlock;
}
