import { Component, input, OnInit, output, signal } from '@angular/core';
import { HelpService } from "app/shared/help.service";
@Component({
    selector: 'app-dialog-help',
    templateUrl: './dialog-help.component.html',
    styleUrl: './dialog-help.component.css',
    standalone: false
})
export class DialogHelpComponent implements OnInit {

  id = input<string>('');
  html = signal<string>('');
  onClose = output<boolean>();

  constructor(private helpService: HelpService) { }

  ngOnInit(): void {

    this.helpService.getContent(this.id()).then(b => {
      console.log(b);
      this.html.set(b.data);
    });
  }

}
