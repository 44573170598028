import { Component, input, OnInit, signal } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { APIService } from 'app/shared/api.service';
import { AssetService, IGetTelemetryForRangeAsObjectsResponse } from 'app/shared/asset.service';
import { CalendarService, IDateConfig } from "app/shared/calendar.service";
import { RedisService } from 'app/shared/redis.service';
import { Chart } from 'chart.js';
import moment, { Moment } from 'moment';

@Component({
    selector: 'app-energy-asset-graph',
    templateUrl: './energy-asset-graph.component.html',
    styleUrl: './energy-asset-graph.component.css',
    standalone: false
})
export class EnergyAssetGraphComponent implements OnInit {

  static readonly LOCALSTORAGE_KEY = 'energy:asset:graph:dates';

  id = input.required<number>();
  telemetry = signal<IGetTelemetryForRangeAsObjectsResponse[]>(null);
  telemetry2 = signal<IGetTelemetryForRangeAsObjectsResponse[]>(null);
  asset = input.required<Asset>();
  asset2 = signal<Asset>(null);

  stats = signal<EnergyStats>(new EnergyStats());

  apiGetsCompleted = 0;
  dates = signal<IDateConfig>(null);
  wantsPreviousYear = signal<boolean>(true);
  redis = signal<any>(null);

  dataset = signal<'this_year' | 'last_year' | 'lastmonth'>('this_year');
  granularity = signal<'day' | 'week' | 'month' | 'year'>('month');

  public chart: any;

  constructor(private redisService: RedisService, private assetService: AssetService, private calendarService: CalendarService, private apiService: APIService) {

  }

  ngOnInit(): void {
    this.getLocalStorage();
    this.redisService.getAsset(this.asset().id).then(redis => this.redis.set(redis));
    this.getDataSet();
  }

  toggleComparePrevioousYear() {
    this.wantsPreviousYear.update(item => !item);
    this.getDataSet();
  }

  getLocalStorage() {
    const dates = localStorage.getItem(EnergyAssetGraphComponent.LOCALSTORAGE_KEY);
    if (!dates) {
      this.dates.set({ dateRange: 'lastmonth' });
    } else {
      try {
        this.dates.set(JSON.parse(dates));
      } catch (e) {
        this.dates.set({ dateRange: 'lastmonth' });
      }
    }

    if (this.dates().dateRange !== 'custom') {
      this.dates.update(item => {
        item.dates = this.calendarService.calculateDatesFromRangeText(this.dates().dateRange);
        return item;
      });
    } else {
      this.dates.update(item => {
        item.dates = { from: new Date(item.dates.from), to: new Date(item.dates.to) };
        return item;
      });
    }
  }

  get() {
    const { from, to } = this.dates().dates;




    this.getData(from, to);
  }

  getDataSet() {

    let from, to;

    to = moment().subtract(1, 'month').endOf('month').toDate();
    switch (this.dataset()) {
      case 'this_year':
        from = moment().startOf('year').startOf('month').toDate();
        break;
      case 'last_year':
        from = moment().subtract(1, 'year').startOf('year').startOf('month').toDate();
        to = moment().subtract(1, 'year').endOf('year').endOf('month').toDate();
        break;
      case 'lastmonth':
        this.dataset.set('lastmonth');
        break;
    }

    this.getData(from, to);
  }




  getData(from: Date, to: Date) {
    this.apiGetsCompleted = (this.wantsPreviousYear() ? 2 : 1);
    this.stats.set(new EnergyStats());
    this.telemetry2.set(null);

    this.assetService.getTelemetryForRangeAsObjects(this.asset(), this.apiService.dbDate(from), this.apiService.dbDate(to)).then(telemetry => {
      this.apiGetsCompleted--;
      this.stats.update(item => {
        item.calcFromTelemetry(telemetry, 'total', to);

        return item;
      });

      if (this.granularity().endsWith('month')) {
        const monthly: IGetTelemetryForRangeAsObjectsResponse[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((v, index) => { return { v: 0, m: moment(from).startOf('year').add(index, 'month'), c: '' } });

        telemetry.forEach(t => {
          const monthIndex = t.m.get('month');
          if (t.v === undefined || monthIndex < 0) debugger;
          monthly[monthIndex].v += t.v;
        });

        telemetry = monthly.reverse();

      }
      this.telemetry.set(telemetry);
      if (this.apiGetsCompleted <= 0) {
        this.buildChart();
      }
    });
    if (this.wantsPreviousYear()) {

      const fromPrevious = moment(from).subtract(1, 'year').startOf('year').startOf('month').startOf('day').toDate();
      const toPrevious = moment(to).subtract(1, 'year').endOf('year').toDate();

      this.assetService.getTelemetryForRangeAsObjects(this.asset(), this.apiService.dbDate(fromPrevious), this.apiService.dbDate(toPrevious)).then(telemetry => {

        this.apiGetsCompleted--;
        this.stats.update(item => {
          item.calcFromTelemetry(telemetry, 'previous', to);

          return item;
        });

        if (telemetry) {

          // align with main year
          telemetry.map(t => {
            t.m = moment(t.m).add(1, 'year');
            return t;
          })


          if (this.granularity().endsWith('month')) {
            const monthly: IGetTelemetryForRangeAsObjectsResponse[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((v, index) => { return { v: 0, m: moment(from).startOf('year').add(index, 'month'), c: '' } });

            telemetry.forEach(t => {
              const monthIndex = t.m.get('month');
              if (t.v === undefined || monthIndex < 0) debugger;
              monthly[monthIndex].v += t.v;
            });

            telemetry = monthly.reverse();
          }

          this.telemetry2.set(telemetry);
        }

        if (this.apiGetsCompleted <= 0) {
          this.buildChart();
        }
      });
    }
  }

  buildChart() {

    // this.buildChartDaySlots();
    this.buildChartMonthSlots();
  }

  dateChange(dateConfig: IDateConfig) {
    if (dateConfig.dateRange === this.dates().dateRange && dateConfig.dates.from === this.dates().dates.from && this.dates().dates.to === dateConfig.dates.to) {
      console.log('no change');
      return;
    }

    this.dates.set(dateConfig);

    this.get();
  }

  datesSelected(dates: IDateConfig) {
    console.log(dates);
  }

  /**
   * Return an array of days holding the value e.g. [2605.1, 706.9, ...]
   * 
   * @param datasetIndex 
   * @param threeHourSlots 
   * @param labelKeys 
   * @param dowKeys 
   * @param labels 
   * @returns 
   */
  buildDataSetsDaySlots(datasetIndex: number, threeHourSlots: any, labelKeys: any, dowKeys: any, labels?: any): number[] {
    const dataset = [];

    for (let index = 0; index < dowKeys.length; index++) {
      const dowKey = dowKeys[index];
      const day = threeHourSlots[dowKey][datasetIndex];
      dataset.push(day);
      if (datasetIndex === 0) {
        labels.push(dowKey);
      }
    }

    return dataset;
  }


  buildChartMonthSlots() {
    const daySlots = {};
    let dataset1, dataset2;
    const labels = [];
    const labelKeys = ['00:00 - 03:00', '03:00 - 06:00', '06:00 - 09:00', '09:00 - 12:00', '12:00 - 15:00', '15:00 - 18:00', '18:00 - 21:00', '21:00 - 00:00'];

    if (this.granularity() === 'month') {
      this.analyseTelemetryMonthSlots(this.telemetry(), 0, daySlots);
    } else {
      this.analyseTelemetryDaySlots(this.telemetry(), 0, daySlots);
    }
    if (this.telemetry2()?.length) {

      if (this.granularity() === 'month') {
        this.analyseTelemetryMonthSlots(this.telemetry2(), 1, daySlots);
      } else {
        this.analyseTelemetryDaySlots(this.telemetry2(), 1, daySlots);
      }
    }
    const dowKeys = Object.keys(daySlots).reverse();
    dataset1 = this.buildDataSetsDaySlots(0, daySlots, labelKeys, dowKeys, labels);
    if (this.telemetry2()?.length) {
      dataset2 = this.buildDataSetsDaySlots(1, daySlots, labelKeys, dowKeys);
    }
    this.showChart(labels, dataset1, dataset2);
  }

  analyseTelemetryMonthSlots(telemetry: IGetTelemetryForRangeAsObjectsResponse[], datasetIndex: number, threeHourSlots: any) {
    if (telemetry) {
      const DOW_FORMAT = 'MMMM';
      telemetry.forEach(t => {
        const dow = t.m.format(DOW_FORMAT);
        threeHourSlots[dow] = threeHourSlots[dow] || [0, 0];
        threeHourSlots[dow][datasetIndex] += +t.v;
      });
    }
  }

  buildChartDaySlots() {
    const daySlots = {};
    let dataset1, dataset2;
    const labels = [];
    const labelKeys = ['00:00 - 03:00', '03:00 - 06:00', '06:00 - 09:00', '09:00 - 12:00', '12:00 - 15:00', '15:00 - 18:00', '18:00 - 21:00', '21:00 - 00:00'];

    this.analyseTelemetryDaySlots(this.telemetry(), 0, daySlots);
    if (this.telemetry2()?.length) {
      this.analyseTelemetryDaySlots(this.telemetry2(), 1, daySlots);
    }
    const dowKeys = Object.keys(daySlots).reverse();
    dataset1 = this.buildDataSetsDaySlots(0, daySlots, labelKeys, dowKeys, labels);
    if (this.telemetry2()?.length) {
      dataset2 = this.buildDataSetsDaySlots(1, daySlots, labelKeys, dowKeys);
    }
    this.showChart(labels, dataset1, dataset2);
  }

  analyseTelemetryDaySlots(telemetry: IGetTelemetryForRangeAsObjectsResponse[], datasetIndex: number, threeHourSlots: any) {
    if (telemetry) {
      const DOW_FORMAT = 'ddd DD/MM/YY';
      telemetry.forEach(t => {
        const dow = t.m.format(DOW_FORMAT);
        threeHourSlots[dow] = threeHourSlots[dow] || [0, 0];
        threeHourSlots[dow][datasetIndex] += +t.v;
      });
    }
  }

  showChart(labels2: any, dataset1: any, dataset2?: any) {
    if (this.chart) {
      this.chart.clear();
      this.chart.destroy();
    }

    const datasets = [
      {
        label: this.asset().title,
        data: dataset1,
        backgroundColor: '#2b8cbe'
      }
    ];

    if (this.telemetry2()?.length) {

      datasets.push({
        label: this.asset().title + ' (previous year)',
        data: dataset2.map(d => d),
        backgroundColor: '#a6bddb'
      });
    }

    this.chart = new Chart("chart-1",
      {
        type: 'bar',
        data: {
          labels: labels2,
          datasets
        },
        options: {
          maintainAspectRatio: false
        }
      });
  }
}


class EnergyStats {
  total: number;
  previous: number;
  diff: number;
  perc: string;

  calcFromTelemetry(telemetry: IGetTelemetryForRangeAsObjectsResponse[], which: 'total' | 'previous', to: Date) {
    if (telemetry?.length) {
      this[which] = Math.floor(telemetry.reduce((a, b) => {
        if (b.m.get('month') > moment(to).get('month')) {
          return a;
        } else {
          return a + b.v;
        }
      }, 0));
      if (this.total && this.previous) {
        this.diff = Math.floor(this.total - this.previous);
        this.perc = Math.abs(((this.previous - this.total) / this.total) * 100).toFixed(1);
      }
    }
  }
}
