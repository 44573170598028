import { Component, OnInit } from '@angular/core';
import { APIService } from 'app/shared/api.service';
import { OccupancyService } from 'app/shared/occupancy.service';

@Component({
    selector: 'app-occupancy-landing',
    templateUrl: './occupancy-landing.component.html',
    styleUrls: ['./occupancy-landing.component.css'],
    standalone: false
})
export class OccupancyLandingComponent implements OnInit {

  can = { admin: false, list: false };

  constructor(private occupancyService: OccupancyService, private apiService: APIService) { }

  ngOnInit() {
    this.can.list = this.apiService.hasOrgRole('occupancy_admin') || this.apiService.isAdmin();
    this.can.admin = this.apiService.hasOrgRole('occupancy_admin') || this.apiService.isAdmin();
  }

  handleTabChange(event: any) {

  }
}
