import { Component, OnInit, NgZone, Input, Output, EventEmitter, OnDestroy, signal, } from "@angular/core";
import { AssetService } from "../../shared/asset.service";
import { StoreService } from "../../shared/store.service";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { ClipboardService } from "../../shared/clipboard.service";
import { APIService } from "../../shared/api.service";
import { Asset } from "../../classes/asset";
import { Setpoint } from "../../classes/setpoint";
import { BlockClickedInterface } from "../../assets/asset-chart/asset-chart.component";
import { DialogService } from "primeng/dynamicdialog";
import { DialogGroupSelectComponent } from "app/dialogs/dialog-group-select/dialog-group-select.component";
import { Grouping } from "app/classes/grouping";
import { SetpointsService } from "app/shared/setpoints.service";

export class AlarmRAGState {
  amber_min = false;
  amber_max = false;
  red_min = false;
  red_max = false;
  constructor(data?: { amber_min: boolean, amber_max: boolean, red_min: boolean, red_max: boolean }) {
    if (!data) {
      return;
    }
    this.amber_min = data.amber_min;
    this.amber_max = data.amber_max;
    this.red_max = data.red_max;
    this.red_min = data.red_min;
  }
}

@Component({
    selector: "app-setpoint-detail",
    templateUrl: "./setpoint-detail.component.html",
    styleUrls: ["./setpoint-detail.component.css"],
    providers: [DialogService],
    standalone: false
})
export class SetpointDetailComponent implements OnInit, OnDestroy {

  readonly LOCALSTORAGE_DEFAULT_TAB = 'setpoint:detail:tab';

  @Input()
  assetId: number;

  @Input()
  rangeId: number;

  @Output()
  onBack: EventEmitter<any> = new EventEmitter();

  cameFromRoute = false;
  asset: Asset;
  copySetpoint: any;
  setpoints: any[];
  loaderState: "loaded";
  weekdays: any[] = [
    { title: "Monday", idx: 1 },
    { title: "Tuesday", idx: 2 },
    { title: "Wednesday", idx: 3 },
    { title: "Thursday", idx: 4 },
    { title: "Friday", idx: 5 },
    { title: "Saturday", idx: 6 },
    { title: "Sunday", idx: 7 },
  ];
  // Weekday clicked on chart
  selectedWeekday: number;
  subtitle = "loading...";
  actions: any[] = null;
  contacts: any[] = [];
  groupings = signal<Grouping[]>([]);
  alarms: AlarmRAGState = new AlarmRAGState();
  loading = true;
  isShowingMonitor = false;
  master = { notes: "", delay: 0, hasSiteNotifications: true };
  defaultTab = 0;
  rangeText: string;
  dialogRef: any;
  greenTooltip: any;

  isUpdating: boolean;

  constructor(
    private setpointService: SetpointsService,
    private assetService: AssetService,
    private storeService: StoreService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: APIService,
    private clipboardService: ClipboardService,
    private zone: NgZone,
    private dialogService: DialogService
  ) {
    const storedDefaultTab = localStorage.getItem(this.LOCALSTORAGE_DEFAULT_TAB) || 0;
    this.defaultTab = +storedDefaultTab;

    this.route.params.subscribe((params: Params) => {
      if (params.assetid) {
        this.cameFromRoute = true;
        this.assetId = params.assetid;
        this.rangeId =
          +params.rangeid || this.apiService.RANGES_BY_TITLE.OPERATIONAL_HOURS;
      } else {
        this.cameFromRoute = false;
      }
    });
  }

  back() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.subtitle = "please wait...";

    setTimeout(() => {
      if (this.cameFromRoute) {
        this.router.navigate(["/setpoints"]);
      } else {
        this.onBack.emit(true);
      }
    }, 200);
  }

  get() {
    this.rangeText = SetpointsService.RANGE_TEXT[this.rangeId];
    console.log("RANGE_ID", this.rangeId);
    this.assetService.getAsset(this.assetId).then((asset) => {
      this.asset = asset;
      this.setpointService
        .getSetpointsForAssetAndRange(asset, this.rangeId)
        .then((setpointData) => {
          console.log(setpointData);
          this.setpoints = setpointData.setpoints;
          this.subtitle =
            asset.gateway.title + ", " + asset.title;
          this.master = setpointData.master;
          this.contacts = setpointData.contacts;
          this.groupings.set(setpointData.groupings);
          setpointData.rules.forEach((rule) => {
            this.alarms[rule.tag] = true;
          });
          this.generateGreenRAGTooltip();
          window.scroll(0, 0);
          this.loading = false;
        });
    });
  }

  generateGreenRAGTooltip() {
    try {
      this.setpoints.forEach(setpoint => {
        let tooltipLeft = '';
        let tooltipRight = '';
        if (setpoint.red_min != null && setpoint.red_min !== '') {
          tooltipLeft = 'Is greater than ' + setpoint.red_min;
        }
        if (setpoint.amber_min != null && setpoint.amber_min !== '') {
          tooltipLeft = 'Is greater than ' + setpoint.amber_min;
        }
        if (setpoint.red_max != null && setpoint.red_max !== '') {
          tooltipRight = 'is less than ' + setpoint.red_max;
        }
        if (setpoint.amber_max != null && setpoint.amber_max !== '') {
          tooltipRight = 'is less than ' + setpoint.amber_max;
        }

        let tooltip = tooltipLeft;

        if (tooltip && tooltipRight) {
          tooltip += ' and ';
        }

        if (tooltipRight) {
          tooltip += tooltipRight;
        }

        const startsAt = setpoint.startsAt && setpoint.startsAt.substr(0, 5);
        const endsAt = setpoint.endsAt && setpoint.endsAt.substr(0, 5);


        if (tooltip && (startsAt && endsAt)) {
          tooltip += ', between ' + startsAt + ' and ' + endsAt;
        }
        if (tooltip && (startsAt && !endsAt)) {
          tooltip += ', after ' + startsAt;
        }
        if (tooltip && (endsAt && !startsAt)) {
          tooltip += ', before ' + endsAt;
        }
        if (tooltip && (!endsAt && !startsAt)) {
          tooltip += ', all day';
        }

        setpoint.tooltips = { green: tooltip };
      });
    } catch (e) { }
  }


  ngOnInit() {
    this.get();
    this.clipboardService.clipboard.subscribe((event) => {
      if (!event) {
        return;
      }
      console.log("clipboard is ", event);

      this.copySetpoint = event.obj;
    });
  }

  /**
   * An alarm request for a column has changed.
   * -- Ensure alarms have data in the column they represent.
   * @deprecated
   */
  linkRule() {
    setTimeout(() => {
      const originalAlarms = { ...this.alarms };
      // Record which alarms have values
      const hasValues = {
        red_min: false,
        red_max: false,
        amber_max: false,
        amber_min: false,
      };
      for (const setpoint of this.setpoints) {
        console.log(setpoint);
        if (setpoint.amber_max !== null) {
          hasValues.amber_max = true;
        }
        if (setpoint.amber_min !== null) {
          hasValues.amber_min = true;
        }
        if (setpoint.red_max !== null) {
          hasValues.red_max = true;
        }
        if (setpoint.red_min !== null) {
          hasValues.red_min = true;
        }
      }

      this.zone.run(() => {
        // Only allow alarms to be set if values exist
        if (this.alarms.amber_max) {
          this.alarms.amber_max = hasValues.amber_max;
        }
        if (this.alarms.amber_min) {
          this.alarms.amber_min = hasValues.amber_min;
        }
        if (this.alarms.red_min) {
          this.alarms.red_min = hasValues.red_min;
        }
        if (this.alarms.red_max) {
          this.alarms.red_max = hasValues.red_max;
        }
        // Did we reset an alarm?
        if (
          originalAlarms.amber_max !== this.alarms.amber_max ||
          originalAlarms.amber_min !== this.alarms.amber_min ||
          originalAlarms.red_max !== this.alarms.red_max ||
          originalAlarms.red_min !== this.alarms.red_min
        ) {
          console.log("not allowed");
          this.apiService.toastWarn(
            "Need value",
            "Need a value in the column to enable alarms"
          );
        }
      });
    }, 200);
  }

  enableWeekday(index: number) {
    this.storeService.change("setpoints");
    this.onBlur();
    console.log(` enableWeekday(${index})`, this.setpoints[index].isActive);
  }

  allday(index: number) {
    if (this.setpoints[index].allday) {
      this.setpoints[index].allday = false;
    } else {
      this.setpoints[index].allday = true;
      this.setpoints[index].startsAt = null;
      this.setpoints[index].endsAt = null;
    }
    this.onBlur();
  }

  onBlur() {
    this.generateGreenRAGTooltip();
    this.assetService.setSetpoints(this.setpoints);

  }

  doCopy(index) {
    const sp = this.setpoints[index];
    // Copy all of setpoint for paste into other assets.
    const copySetpoint = {
      asset: this.asset,
      index: index,
      startsAt: sp.startsAt,
      endsAt: sp.endsAt,
      min: sp.min,
      max: sp.max,
      red_min: sp.red_min,
      red_max: sp.red_max,
      amber_max: sp.amber_max,
      amber_min: sp.amber_min,
      allday: sp.allday,
      isActive: true,
    };
    this.clipboardService.copy("setpoint", copySetpoint);
    this.apiService.toastSuccess('You can now paste this row into any setpoint', '');
  }

  copy(index: number) {
    console.log("copy", this.copySetpoint, index);

    return this.doCopy(index);
  }

  paste(index: number) {
    if (this.copySetpoint === null) {
      return;
    }

    const sp = this.copySetpoint;
    this.setpoints[index] = {
      startsAt: sp.startsAt,
      endsAt: sp.endsAt,
      min: sp.min,
      max: sp.max,
      allday: sp.allday,
      weekday: index,
      amber_max: sp.amber_max,
      amber_min: sp.amber_min,
      red_max: sp.red_max,
      red_min: sp.red_min,
      isActive: true,
    };
    this.onBlur();
  }

  cancel() {
    this.router.navigate(["/setpoints"]);
  }

  updateSetPoints() {
    let errors = false;

    for (const setpoint of this.setpoints) {
      if (setpoint.isActive) {
        if (
          (setpoint.amber_max === null || setpoint.amber_max === "") &&
          (setpoint.amber_min === null || setpoint.amber_min === "") &&
          (setpoint.red_min === null || setpoint.red_min === "") &&
          (setpoint.red_max === null || setpoint.red_max === "")
        ) {
          errors = true;
        }
      }
    }

    if (errors) {
      this.apiService.toastWarn(
        "Enabled without values",
        "You must have values if you enable a day"
      );

      return;
    }

    this.isUpdating = true;

    const setpoints = [];
    this.setpoints.forEach((inputRow, index) => {
      // Allday is turned on if no dates supplied to active row
      if (
        inputRow.startsAt === null &&
        inputRow.endsAt === null &&
        inputRow.allday === false &&
        inputRow.isActive
      ) {
        inputRow.allday = true;
      }
      if (
        inputRow.allday ||
        (!inputRow.allday && inputRow.startsAt && inputRow.endsAt)
      ) {
        setpoints.push(
          new Setpoint(
            inputRow.id,
            index,
            inputRow.startsAt,
            inputRow.endsAt,
            inputRow.allday,
            inputRow.min,
            inputRow.max,
            inputRow.isActive,
            inputRow.amber_min,
            inputRow.amber_max,
            inputRow.red_min,
            inputRow.red_max
          )
        );
      }
    });
    /*
        this.setpointsService.updateAsset(this.asset.id, this.rangeId, setpoints, this.alarms, this.master, this.groupings).then( r => {
          this.apiService.toastSuccess(null, 'Setpoints updated.');
        });
    */

    this.setpointService
      .setSetPointsForAsset(
        this.asset,
        this.rangeId,
        setpoints,
        this.alarms,
        this.master,
        this.groupings()
      )
      .then((r) => {
        console.log(r);
        this.postRoute(
          `${this.asset.id}`,
          "update",
          JSON.stringify({ asset: { id: this.asset.id }, setpoints: setpoints })
        );
        this.isUpdating = false;
        this.apiService.toastSuccess(null, "Setpoints updated.");
        this.router.navigate(["/setpoints"]);
      });
  }

  updateNotifications(groupings?: Grouping[]) {
    if (!groupings) {
      return;
    } else {
      this.groupings.set(groupings);
    }
  }

  change(v: string) {
    this.storeService.change("setpoints");
    this.onBlur();
  }

  assetChartBlockClicked(e: BlockClickedInterface) {
    console.log(e);
    this.selectedWeekday = e.legend.dow - 1;
    if (this.selectedWeekday < 0) {
      this.selectedWeekday = 6;
    }
  }

  postRoute(suffix: string, action?: string, changes?: string) {
    this.apiService.postRoute(`/setpoints/${suffix}`, action, changes);
  }

  handleTabChange(e: any) {
    this.defaultTab = e.index || e;
    localStorage.setItem(this.LOCALSTORAGE_DEFAULT_TAB, String(this.defaultTab));
  }

  modifyUserGroups() {
    this.dialogRef = this.dialogService.open(DialogGroupSelectComponent, {
      header: `Groups to notify`,
      width: "800px",
      data: {
        groupings: this.groupings,
        siteNotifications: this.master.hasSiteNotifications,
      },
    });
    this.dialogRef.onClose.subscribe((groupings) => {
      if (groupings) {
        console.log("got", groupings);
        this.groupings.set(groupings);
      }
    });
  }

  toggleSiteNotifications() {
    this.zone.run(() => {
      this.master.hasSiteNotifications = this.master.hasSiteNotifications
        ? false
        : true;
    });
  }

  ngOnDestroy(): void { }
}
