import { Component, computed, effect, input, output, signal } from '@angular/core';

@Component({
  selector: 'app-data-table-filter-types',
  standalone: false,

  templateUrl: './data-table-filter-types.component.html',
  styleUrl: './data-table-filter-types.component.css'
})
export class DataTableFilterTypesComponent {
  sensorTypes = input.required<ISensorTypeItem[]>();
  sensorTypeSelected = signal<ISensorTypeItem>(null);

  onSensorTypeSelected = output<ISensorTypeItem>();
  sensorExists = computed(() => this.sensorTypes()?.some(st => st.id === this.sensorTypeSelected()?.id));

  constructor() {
    effect(() => {

    });
  }

  sensorClick(sensorType: ISensorTypeItem) {
    if (this.sensorTypeSelected()?.id === sensorType.id) {
      this.sensorTypeSelected.set(null);
    } else {
      this.sensorTypeSelected.set(sensorType);
    }
    this.onSensorTypeSelected.emit(this.sensorTypeSelected());
  }
}

export interface ISensorTypeItem {
  id: number;
  title: string;
  count: number;
  isSelected: boolean;
}
