import none from "./none.js";
export default function (series) {
  var peaks = series.map(peak);
  return none(series).sort(function (a, b) {
    return peaks[a] - peaks[b];
  });
}
function peak(series) {
  var i = -1,
    j = 0,
    n = series.length,
    vi,
    vj = -Infinity;
  while (++i < n) if ((vi = +series[i][1]) > vj) vj = vi, j = i;
  return j;
}