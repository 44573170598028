import { Component, Input, OnInit } from '@angular/core';
import { ComplianceCollection } from 'app/classes/compliance/compliance-collection';
import { APIService } from 'app/shared/api.service';
import { AssetService } from 'app/shared/asset.service';

import moment from 'moment';

@Component({
    selector: 'app-compliance-assets',
    templateUrl: './compliance-assets.component.html',
    styleUrls: ['./compliance-assets.component.css'],
    standalone: false
})
export class ComplianceAssetsComponent implements OnInit {

  @Input()
  compliance: ComplianceCollection;

  asset: any;

  df: Date;
  dt: Date;

  constructor(private assetService: AssetService, private apiService: APIService) {

  }

  ngOnInit(): void {
    if (!this.compliance) {
      this.get();
    }
  }

  get() {
    this.df = moment().startOf('month').toDate();
    this.dt = moment().toDate();

    this.apiService.getCompliance(this.df, this.dt)
      .then(r => {
        this.compliance = r;
        this.build();
      });
  }

  build() {
    this.compliance.groups
      .forEach(group => {

      });
  }

  select(asset) {
    this.assetService.getAsset(asset.id).then(a => this.asset = a);
  }
}
