import { Component, OnInit } from '@angular/core';
import { StoreService } from 'app/shared/store.service';
import { APIService } from 'app/shared/api.service';

@Component({
    selector: 'app-engage-org',
    templateUrl: './engage-org.component.html',
    styleUrls: ['./engage-org.component.css'],
    standalone: false
})
export class EngageOrgComponent implements OnInit {


  results: any;
  selectedOrg: any;
  orgs: any[];

  dropdownOpen: boolean;

  constructor(private apiService: APIService, private storeService: StoreService) {
  }

  ngOnInit() {
  }

  wantsOrg(org: any) {
    this.selectedOrg = org;
    this.dropdownOpen = false;
  }

}
