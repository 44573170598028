<main [class.maximised]="isMaximised">
  @if (occupancyStats) {
    <section class="full-width-content">
      <table class="table table-sm" aria-label="Stats" [class.suppress-tenant-service]="supressTenantAndService()">
        <thead>
          <tr>
            <th class="sortable" (click)="sortClick('floor')">Floor <i class="mdi" [class.mdi-sort-ascending]="sortColumn.column === 'floor' && sortColumn.ascending" [class.mdi-sort-descending]="sortColumn.column === 'floor' && !sortColumn.ascending"></i></th>
            <th class="sortable" (click)="sortClick('asset')">Asset <i class="mdi" [class.mdi-sort-ascending]="sortColumn.column === 'asset' && sortColumn.ascending" [class.mdi-sort-descending]="sortColumn.column === 'asset' && !sortColumn.ascending"></i></th>

            @if (!supressTenantAndService()) {
              <th class="sortable" class="tenant-title" (click)="sortClick('tenant')">Tenant <i class="mdi" [class.mdi-sort-ascending]="sortColumn.column === 'tenant' && sortColumn.ascending" [class.mdi-sort-descending]="sortColumn.column === 'tenant' && !sortColumn.ascending"></i></th>

              <th class="sortable" class="service-title" (click)="sortClick('service')">Service <i class="mdi" [class.mdi-sort-descending]="sortColumn.column === 'service' && sortColumn.ascending" [class.mdi-sort-ascending]="sortColumn.column === 'service' && sortColumn.ascending"></i></th>
            }
            @for (monthStats of occupancyStats.months; track monthStats; let monthindex = $index) {
              <th *ngFor="let dayItem of monthStats.dows; let i = index; let isFirst = first" class="month-{{ monthindex }}" [ngClass]="{ 'is-first': isFirst }">
                {{ dayItem.day }}
                <p class="dow">{{ calendarService.DOW_ISO_NAMES_MIN[dayItem.dow] }}</p>
              </th>
            }
          </tr>
        </thead>

        <tbody>
          @for (assetId of lists?.assetIds; track assetId) {
            <tr>
              @if (occupancyStats.assets[assetId]) {
                <td>{{ occupancyStats.assets[assetId].floor }}</td>
                <td class="can-click link" (click)="assetClick(assetId)">
                  {{ occupancyStats.assets[assetId].title }}
                </td>
                @if (!supressTenantAndService()) {
                  <td class="tenant-title">{{ occupancyStats.assets[assetId].tenantTitle }}</td>
                  <td class="service-title">{{ occupancyStats.assets[assetId].serviceTitle }}</td>
                }
                @for (monthStats of occupancyStats.months; track monthStats) {
                  <td *ngFor="let dow of monthStats.dows; let i = index" [showDelay]="1000" [pTooltip]="occupancyStats.assets[assetId].title + ' ' + calendarService.DOW_ISO_NAMES_MIN[dow.dow] + ' ' + dow.day" class="can-click rating rating-{{ monthStats.assets[assetId]?.rating[i] }}" (click)="assetClick(assetId, i, dow.date)">
                    <span>{{ monthStats.assets[assetId]?.perc[i] }}</span>
                    <span *ngIf="monthStats.assets[assetId]?.rating[i] === ''">-</span>
                  </td>
                }
              }
            </tr>
          }
          <!--
				<tr class="totals" *ngIf="!isFiltering && lists.assetIds?.length">
					<td colspan="4">Totals</td>
					<td *ngFor="let total of occupancyStats.grandTotals;let i = index" class="rating"
						[class.rating-e]="total!== null && total < 20" [class.rating-d]="total >= 20 &&total < 40"
						[class.rating-c]="total >= 40 && total< 60" [class.rating-b]="total >= 60  && total < 80"
						[class.rating-a]="total >= 80">
						<span
							[pTooltip]="calendarService.DOW_ISO_NAMES_MIN[occupancyStats.dows[i].dow] + ' ' + occupancyStats.dows[i]?.day">{{total}}</span>
					</td>
				</tr>
			-->
        </tbody>
      </table>
    </section>

    @if (supressTenantAndService()) {
      <div class="tenant-service-summary">Tenant:{{ lists.tenant[0]?.title }} | Service:{{ lists.service[0]?.title }}</div>
    }
  }
  <!--
	<section class="mt-2" *ngIf="lists?.assetIds.length">
		<app-info-panel type=" note">
			You can press the [F] key to toggle full screen.
		</app-info-panel>
	</section>
--></main>

@if (isLoading) {
  <app-please-wait></app-please-wait>
}

@switch (isShowingDialog()) {
  @case ('asset') {
    <app-dialog-asset-view (onClose)="isShowingDialog.set(null)" [assetId]="selectedAssetId()" [dates]="selectedDates()"></app-dialog-asset-view>
  }
}
