import { Component, OnInit, AfterViewInit, signal } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { APIService } from '../../shared/api.service';
import { Router } from '@angular/router';
import { Gateway } from '../../classes/gateway';
import { Asset } from '../../classes/asset';
import { Setpoint } from '../../classes/setpoint';
import { StoreService } from '../../shared/store.service';
import { filter } from "rxjs/operators";
import { GatewayIS } from 'app/classes/gateway-is';
import { WeatherService } from 'app/shared/weather.service';
import { WindowService } from 'app/shared/window.service';
import { Site } from 'app/classes/site';
@Component({
    selector: 'app-live-gateway-list',
    templateUrl: './live-gateway-list.component.html',
    styleUrls: ['./live-gateway-list.component.css'],
    standalone: false
})
export class LiveGatewayListComponent implements OnInit, AfterViewInit {

  static readonly CONTACT_US_URL = 'https://4dmonitoring.co.uk/contact-us/';

  loading: boolean;
  results: any;
  gateways: GatewayIS[];
  viewGateways: boolean = true;

  myurl: string;
  gateway: Gateway;
  noLiveFeeds = signal<boolean>(null);
  showHelp: boolean = false;
  assets: Asset[];
  setpoints: Setpoint[];
  selectedAsset: Asset;
  sites = signal<ISiteWithGateways[]>(null);

  filtered: { list: GatewayIS[], text: string, count: number } = { list: [], text: '', count: null };
  isMobile = signal<boolean>(false);

  constructor(private storeService: StoreService, private router: Router, private apiService: APIService, public sanitizer: DomSanitizer, protected weatherService: WeatherService, windowService: WindowService) {
    storeService.selectedPageOption.pipe(filter(option => option !== null)).subscribe(option => {
      console.log('got option', option);
      switch (option.id) {
        case 'export':
          this.router.navigate(['/export', 'gateways']);
          break;
        case 'help':
          this.router.navigate(['/training', 2, 'modules', 15]);
          break;
      }
    });

    this.getLocalStorage();

    this.isMobile.set(windowService.isMobile());
  }

  searchChanged(search: string) {
    console.log(search);
    this.filtered.text = search.toLowerCase().trim();
    this.saveLocalStorage();
    this.applyFilter();
  }

  getLocalStorage() {
    try {
      this.filtered.text = localStorage.getItem(`org:${this.apiService.getUserOrg().id}:livegateway:search`) || '';
    } catch (error) { }
  }

  saveLocalStorage() {
    localStorage.setItem(`org:${this.apiService.getUserOrg().id}:livegateway:search`, this.filtered.text);
  }

  clickShowMore() {
    window.location.href = LiveGatewayListComponent.CONTACT_US_URL;
  }

  ngOnInit() {
    this.loading = true;
    this.apiService.getGateways(null, null, 'is').then(gateways => {
      this.gateways = <GatewayIS[]>gateways;
      this.loading = false;
      this.noLiveFeeds.set(this.gateways.length === 0);
      this.applyFilter();
    });
  }

  applyFilter() {
    if (this.filtered.text === '') {
      this.filtered.list = this.gateways;
      this.filtered.count = null;

      this.setSites();

      return;
    }

    this.filtered.list = this.gateways.filter(g => {
      if (g.title.toLowerCase().includes(this.filtered.text)) return true;
      if (g.location && g.location.toLowerCase().includes(this.filtered.text)) return true;
      if (g.org.shortTitle.toLowerCase().includes(this.filtered.text)) return true;
      if (g.site.title.toLowerCase().includes(this.filtered.text)) return true;
      if (g.id.toLowerCase().includes(this.filtered.text)) return true;
      if (g.site.address?.address1.toLowerCase().includes(this.filtered.text)) return true;
      if (g.site.address?.addressCounty.toLowerCase().includes(this.filtered.text)) return true;
      if (g.site.address?.addressPostcode.toLowerCase().includes(this.filtered.text)) return true;
      if (g.site.address?.addressTown.toLowerCase().includes(this.filtered.text)) return true;
      if (g.initialstate?.bucketKey && g.initialstate.bucketKey.toLowerCase().includes(this.filtered.text)) return true;
      if (g.initialstate?.embedLink && g.initialstate.embedLink.toLowerCase().includes(this.filtered.text)) return true;
      if (this.filtered.text === 'offline' && !g.seenOnline && g.statusTitle === 'Deployed') return true;
      if (this.filtered.text === 'online' && g.seenOnline) return true;
      if (this.filtered.text === 'standby' && !g.seenOnline && g.statusTitle !== 'Deployed') return true;
    });

    this.filtered.count = this.filtered.list.length;

    this.setSites();
  }


  setSites() {
    const sites = [];

    this.filtered.list.forEach(g => {
      let siteWithGateways = sites.find(sg => sg.site.id === g.site.id);
      if (!siteWithGateways) {
        siteWithGateways = { site: g.site, gateways: [] };
        sites.push(siteWithGateways);
      }
      siteWithGateways.gateways.push(g);
    });

    this.sites.set(sites);
  }

  ngAfterViewInit() {
    window.scroll(0, 0);
  }

  ngOnDestroy() {
    this.apiService.setFullscreen(false);
    this.storeService.setPageOptions(null);
  }
}


export interface ISiteWithGateways {
  site: Site;
  gateways: GatewayIS[];
}
