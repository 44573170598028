import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { StoreService } from '../../shared/store.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';


@Component({
    selector: 'app-section-banner',
    templateUrl: './section-banner.component.html',
    styleUrls: ['./section-banner.component.css'],
    standalone: false
})
export class SectionBannerComponent implements OnInit, OnDestroy {

  static readonly SECTIONS = {
    wellbeing: 'building-o',
    floorplan: 'building-o',
    floorplans: 'building-o',
    export: 'cloud-download',
    issues: 'ticket',
    orders: 'shopping-cart',
    setpoints: 'bullseye',
    rules: '_rules',
    training: 'university',
    reports: 'line-chart',
    organisation: 'sitemap',
    live: 'area-chart'
  };

  _icon: string;
  _icon2: string;

  @Input()
  section: string;

  @Input()
  title: string;

  @Input()
  subtitle: string;

  @Input()
  public set icon(v: string) {
    this._icon = v;
  }

  @Input()
  detail: string;

  @Input()
  actions: string[];

  @Input()
  styling = '';

  @Output()
  onBack:EventEmitter<any> = new EventEmitter<any>();

  // Disabled animations until a click
  hasClicked: boolean;

  changedSubscription: Subscription;

  constructor(private storeService: StoreService) {

    this.changedSubscription = storeService.sectionBannerChanged
      .pipe(filter(value => (value !== null && typeof value !== 'undefined')))
      .subscribe(value => {
        if (value.detail || value.detail === null) {
          this.detail = value.detail;
        }
      });

  }

  ngOnInit() {
    if (!this._icon) {
      // User didn't pass an icon
      const font: string = SectionBannerComponent.SECTIONS[this.section.toLowerCase()];
      if (font && font.indexOf('_') !== -1) {
        this._icon2 = 'icon-font' + font;
      } else {
        this._icon = 'fa-' + font;
      }
    }
  }

  back() {
    this.storeService.setSectionBannerBack({ section: this.section, state: true });
    this.hasClicked = true;
    this.onBack.emit(true);
  }

  icons(title: string) {
    switch (title) {
      case 'profiling':
        return 'fa-snowflake-o';
    }

    return '';
  }

  ngOnDestroy(): void {
    this.storeService.setSectionBannerChangedDetail(null);
    this.changedSubscription.unsubscribe();
  }

}
