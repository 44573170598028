import { Component, signal, computed, Signal, Output, EventEmitter } from '@angular/core';
import { OrgExport } from "./org-export";
import { Condition } from './condition';
import { DataForm } from 'app/layout/data-form/data-form.component';
import { ReportingService } from "../../shared/reporting.service";
import { APIService } from 'app/shared/api.service';
import { IExplorerSelectedItems } from 'app/developer/explorer/explorer.component';
import { ICalendarDay } from 'app/layout/calendar/calendar.component';
import { IDateConfig, IDateRange } from 'app/shared/calendar.service';
import { ExportService } from 'app/shared/export.service';
import { AssetService } from 'app/shared/asset.service';
import { Asset } from 'app/classes/asset';
import { SetpointQueryDateRange } from 'app/setpoints/setpoint-query/setpoint-query.component';

@Component({
    selector: 'app-org-export',
    templateUrl: './org-export.component.html',
    styleUrl: './org-export.component.css',
    standalone: false
})
export class OrgExportComponent {
  _ingestedState = signal<number>(null);
  _gatewayState = signal<number>(null);
  _valueState = signal<number>(null);
  _siteState = signal<number>(1);
  _onDialog = signal<'explorer'>(null);
  exportItem = signal<OrgExport>(null);
  _triggerDataForm = signal<DataForm>(null);
  hasDialogActive = signal<'condition' | 'sites' | 'gateways' | 'reports'>(null);
  _results = signal<{ assets: any[], values: any[] }>(null);
  tabIndexResults = signal<number>(0);
  isAdmin = signal<boolean>(false);
  isWorking = signal<boolean>(false);
  setpointQueryDateRange = signal<SetpointQueryDateRange>(null);
  wantsToSaveReport = signal<boolean>(false);
  saveReportDataForm = signal<DataForm>(null);
  asset = signal<Asset>(null);
  reports = signal<any[]>(null);

  exportAssets: Signal<{ sites: any, gateways: any, assets: any }> = computed(() => {
    const payload = { sites: {}, assets: {}, gateways: {} };
    this.exportItem().siteIds.forEach(s => payload.sites[s] = true);
    this.exportItem().gatewayIds.forEach(s => payload.gateways[s] = true);
    this.exportItem().assetIds.forEach(s => payload.assets[s] = true);

    return payload;
  });

  @Output()
  onReportLoaded = new EventEmitter<OrgExport>();

  constructor(private reportService: ReportingService, private apiService: APIService, private exportService: ExportService, private assetService: AssetService) {
    const data = this.getLocal();

    if (data) {
      const from = new Date(data.dates?.from);
      const to = new Date(data.dates?.to);
      if (from.toString() === 'Invalid Date') {
        data.dates.from = null;
      }
      if (to.toString() === 'Invalid Date') {
        data.dates.to = null;
      }

      this.exportItem.set(new OrgExport(data));
    } else {
      this.exportItem.set(new OrgExport({
        id: null,
        dates: { from: null, to: null },
        dateRange: 'lastweek',
        title: 'Cold rooms in the morning',
        conditions: [
          new Condition({ id: null, operand: 'lt', target: 'asset', timespan: { from: '08:00', to: '08:16' }, value: 20 })
        ]
      }));
    }

    this.isAdmin.set(apiService.isAdmin());
  }

  changed() {
    this.saveLocal();
  }

  datesSelected(dates: IDateConfig) {
    if (!dates) {
      return;
    }
    this.exportItem.update(item => {
      item.dateRange = dates.dateRange;
      item.dates = dates.dates;

      return item;
    });
    this.changed();
  }

  dateButtonClick(dateRange: IDateRange) {
    this.exportItem.update(item => {
      item.dateRange = dateRange;
      return item;
    });
    this.changed();
  }

  dateSelected(part: 'from' | 'to', day: ICalendarDay) {
    console.log(part, day);
    this.exportItem.update(obj => {
      obj.dates[part] = day.date;
      return obj;
    });
    this.changed();
  }

  assetsSelected(data: IExplorerSelectedItems) {
    console.log(data);
    this.exportItem().setAssetsFromExplorer(data);
    this.changed();
  }

  sensorsSelected(data: number[]) {
    this.exportItem.update(item => {
      item.setAssetTypesFromArray(data);
      return item;
    });
    this.changed();
  }

  triggerSelected(data: OrgExport) {
    this.exportItem.update(obj => {
      obj.conditions = data.conditions;
      return obj;
    });
    this.changed();
  }

  getLocal() {
    const key = `org:${this.apiService.getUserOrg().id}:report:trigger:defaults`;
    const obj = localStorage.getItem(key);

    return obj && JSON.parse(obj);
  }

  saveLocal() {
    const payload = this.exportItem().serialise();
    const key = `org:${this.apiService.getUserOrg().id}:report:trigger:defaults`;
    console.log(key, payload);
    localStorage.setItem(key, JSON.stringify(payload));
  }

  headerClick(column, state: number) {
    switch (column) {
      case 'ingested':
        this._siteState.set(null);
        this._gatewayState.set(null);
        this._valueState.set(null);
        this._ingestedState.set(state);
        break;
      case 'site':
        this._ingestedState.set(null);
        this._gatewayState.set(null);
        this._valueState.set(null);
        this._siteState.set(state);
        break;
      case 'gateway':
        this._ingestedState.set(null);
        this._siteState.set(null);
        this._valueState.set(null);
        this._gatewayState.set(state);
        break;
      case 'value':
        this._ingestedState.set(null);
        this._siteState.set(null);
        this._gatewayState.set(null);
        this._valueState.set(state);
        break;
    }

    this.filter();
  }

  filter() {
    if (this._ingestedState() === 1) {
      this._results().values.sort((a, b) => a.d >= b.d ? 1 : -1);
      return;
    }
    if (this._ingestedState() === 2) {
      this._results().values.sort((a, b) => a.d >= b.d ? -1 : 1);
      return;
    }

    if (this._siteState() === 1) {
      this._results().values.sort((a, b) => a.a.siteTitle >= b.a.siteTitle ? 1 : -1);
      return;
    }
    if (this._siteState() === 2) {
      this._results().values.sort((a, b) => a.a.siteTitle >= b.a.siteTitle ? -1 : 1);
      return;
    }

    if (this._gatewayState() === 1) {
      this._results().values.sort((a, b) => a.a.gTitle >= b.a.gTitle ? 1 : -1);
      return;
    }
    if (this._gatewayState() === 2) {
      this._results().values.sort((a, b) => a.a.gTitle >= b.a.gTitle ? -1 : 1);
      return;
    }

    if (this._valueState() === 1) {
      this._results().values.sort((a, b) => + a.v >= +b.v ? 1 : -1);
      return;
    }
    if (this._valueState() === 2) {
      this._results().values.sort((a, b) => +a.v >= +b.v ? -1 : 1);
      return;
    }

    this._results().values.sort((a, b) => a.a.siteTitle >= b.a.siteTitle ? -1 : 1);
  }

  run() {
    this.isWorking.set(true);
    this.reportService.run(this.exportItem()).then(response => {
      console.log(response);
      this._results.set(response);
      this.isWorking.set(false);
      this.filter();
    });
  }
  startNewReport() {
    this.exportItem.set(new OrgExport());
  }

  async assetClick(a: any, ingested: Date) {
    const asset = await this.assetService.getAsset(a.id);

    if (ingested) {
      this.setpointQueryDateRange.set(new SetpointQueryDateRange({ from: ingested, to: ingested, type: 'custom' }));
    } else {

      this.setpointQueryDateRange.set(new SetpointQueryDateRange({ from: this.exportItem().dates.from, to: this.exportItem().dates.to, type: 'custom' }));
    }
    this.asset.set(asset);
  }

  exportToExcel() {
    if (!this._results().values.length) {
      return;
    }

    this.exportService.exportTriggerReport(this._results().values);
  }

  // Saved Reports

  async loadSavedReport() {
    this.isWorking.set(true);
    const reports = await this.reportService.getCustomReports();
    this.reports.set(reports);
    this.hasDialogActive.set('reports');
    this.isWorking.set(false);
  }

  async clickReport(report: any) {
    console.log(report);
    this.hasDialogActive.set(null);
    const orgExport = await this.reportService.getCustomReport(report.id);
    orgExport.id = report.id;
    this.exportItem.set(orgExport);
    this.onReportLoaded.emit(orgExport);
  }

  async clickSaveReport() {
    this.saveReportDataForm.set(new DataForm({ items: [{ id: 'title', label: 'Report Title', value: this.exportItem().title, type: 'string' }, { id: 'date', label: 'Date', value: 'lastweek', type: 'group', options: [{ id: 'lastweek', title: 'Last week' }, { id: 'yesterday', title: 'yesterday' }, { id: 'today', title: 'today' }] }] }));
    this.wantsToSaveReport.set(true);
  }

  async saveReport(ev: any) {
    console.log(ev);
    this.wantsToSaveReport.set(false);
    if (ev?.id === 'save') {
      this.exportItem.update(item => {
        item.title = <string>this.saveReportDataForm().getValue('title');
        item.dateRange = <IDateRange>this.saveReportDataForm().getValue('date');

        return item;
      });
      await this.reportService.saveCustomReport(this.exportItem());
      this.apiService.toastSuccess('Report Saved', '');
    } else {
      this.apiService.toastSuccess('Save cancelled', '');
    }
  }

}
