/**
 * @deprecated use asset-viewer
 */
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Asset } from '../../classes/asset';
import { AssetService } from '../../shared/asset.service';
import { APIService } from '../../shared/api.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-asset-view',
    templateUrl: './asset-view.component.html',
    styleUrls: ['./asset-view.component.css'],
    standalone: false
})
export class AssetViewComponent implements OnInit, OnDestroy {

  @Input()
  orientation = 'portrait';

  @Input()
  asset: Asset;

  @Input()
  assetId: number;

  @Input()
  styling = 'default';

  @Input()
  fullscreen: boolean;

  // If an image type, the image (or most recent) is linked here
  image: string;

  assetSubscription: Subscription;

  constructor(private assetService: AssetService, public apiService: APIService) { }

  ngOnInit() {
    if (!this.asset) {
      this.assetSubscription = this.assetService.asset.subscribe(asset => {
        if (asset && +asset.id === +this.assetId) {
          this.asset = asset;
          if (asset.assetType_id === this.assetService.ASSET_TYPE_CAPTURE) {
            console.log(`${asset.id} is am image type, get last image...`);
            this.assetService.getImage(this.asset).then(image => {
              const gateway = this.asset.gateway_id.replace(/^0+/, '');
              this.image = `https://s3.eu-west-2.amazonaws.com/4d-image-capture/${gateway}_${image}.jpg`;
            });
          }
        }
      });
      this.assetService.getAssetFromId(this.assetId);
    }
    window.scrollTo(0, 0);
  }

  requestImage() {
    if (this.asset.assetType_id !== this.assetService.ASSET_TYPE_CAPTURE) {
      // Must be an image capturing asset
      console.log(this.asset.assetType);
      return;
    }

    this.apiService.requestCaptureAssetImage(this.asset.gateway_id, this.asset.id).then(results => {
      console.log(results);
    });
  }

  ngOnDestroy() {
    if (this.assetSubscription) {
      console.log('Unsubscribed');
      this.assetSubscription.unsubscribe();
    }
  }
}
