import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SetpointSchedule } from 'app/classes/setpoint-schedule';
import { SetpointsService } from 'app/shared/setpoints.service';

@Component({
    selector: 'app-setpoint-scheduler-item',
    templateUrl: './setpoint-scheduler-item.component.html',
    styleUrls: ['./setpoint-scheduler-item.component.css'],
    standalone: false
})
export class SetpointSchedulerItemComponent {

  schedulerId: number;
  schedule: SetpointSchedule;
  assetCount: number;
  dayCount: number;
  alarmCount: number;
  ruleCount: number;

  constructor(private setpointService: SetpointsService, private route: ActivatedRoute) {
    console.log(`sid`, route.snapshot.params['schedulerid']);
    this.schedulerId = +(route.snapshot.params['schedulerid']);
    if (!this.schedulerId) {
      throw new Error("NO_SCHEDULE_ID");
    }
    setpointService.getSchedule(this.schedulerId, { wantsSitesAndAssets: true }).then(schedule => {

      this.schedule = schedule;
      this.assetCount = schedule.wouldProcess?.assets.length || 0;
      this.alarmCount = Number(schedule.template.alarms.amber_max) + Number(schedule.template.alarms.amber_min) + Number(schedule.template.alarms.red_max) + Number(schedule.template.alarms.red_min);
      this.dayCount = schedule.template.setpoints.filter(s => s.isActive).length;
      this.ruleCount = (this.alarmCount * this.dayCount) * this.assetCount;
    });
  }

}
