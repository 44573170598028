import constant from "./constant";
import { x as pointX, y as pointY } from "./point";
import Diagram, { epsilon } from "./Diagram";
export default function () {
  var x = pointX,
    y = pointY,
    extent = null;
  function voronoi(data) {
    return new Diagram(data.map(function (d, i) {
      var s = [Math.round(x(d, i, data) / epsilon) * epsilon, Math.round(y(d, i, data) / epsilon) * epsilon];
      s.index = i;
      s.data = d;
      return s;
    }), extent);
  }
  voronoi.polygons = function (data) {
    return voronoi(data).polygons();
  };
  voronoi.links = function (data) {
    return voronoi(data).links();
  };
  voronoi.triangles = function (data) {
    return voronoi(data).triangles();
  };
  voronoi.x = function (_) {
    return arguments.length ? (x = typeof _ === "function" ? _ : constant(+_), voronoi) : x;
  };
  voronoi.y = function (_) {
    return arguments.length ? (y = typeof _ === "function" ? _ : constant(+_), voronoi) : y;
  };
  voronoi.extent = function (_) {
    return arguments.length ? (extent = _ == null ? null : [[+_[0][0], +_[0][1]], [+_[1][0], +_[1][1]]], voronoi) : extent && [[extent[0][0], extent[0][1]], [extent[1][0], extent[1][1]]];
  };
  voronoi.size = function (_) {
    return arguments.length ? (extent = _ == null ? null : [[0, 0], [+_[0], +_[1]]], voronoi) : extent && [extent[1][0] - extent[0][0], extent[1][1] - extent[0][1]];
  };
  return voronoi;
}