import { Component, OnInit, AfterViewInit } from '@angular/core';
import { APIService } from '../../shared/api.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PlatformModuleService } from 'app/shared/platform-module.service';
import { StoreService } from 'app/shared/store.service';

@Component({
    selector: 'app-invite-landing',
    templateUrl: './invite-landing.component.html',
    styleUrls: ['./invite-landing.component.css'],
    standalone: false
})
export class InviteLandingComponent implements OnInit, AfterViewInit {

  password1: string;
  password2: string;
  activating: boolean;
  token: string;
  browser: string;
  connectedStatus: string = '';
  connectedMessage: string = '';

  constructor(private router: Router, private route: ActivatedRoute, private apiService: APIService, private storeService: StoreService) {
    this.route.params.subscribe((params: Params) => {
      this.token = params['tokenid'];
      console.log(this.token);
      this.checkToken();
    });
  }

  ngOnInit() { }

  checkToken() {
    this.apiService.getTokenStatus(this.token).then(result => {
      if (result && result.active === 1) {
        // Activated token, move to login
        this.router.navigate(['/login']);
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.browser = this.apiService.browserCheck();
      // Send config request with browser support
      this.apiService.postActivateRoute(this.token)
        .then(status => {
          this.connectedStatus = status?.text || '';
          this.connectedMessage = status?.message || '';
        });
    }, 1000);
  }

  activate() {
    this.activating = true;
    this.apiService.activateUser(this.token, this.password1).then(r => {
      console.log('activated', r);
      if (r.email) {
        // Let's log them in and show their dashboard
        this.apiService
          .login(r.email, this.password1)
          .then(user => {
            if (user) {
              console.log(user);
              if (user.isRestricted) {
                this.storeService.setIsLoading(false);
                const [module] = user.modules.values();
                const url = [PlatformModuleService.modules[module].route];
                this.router.navigate(url);
              } else {
                this.router.navigate(['/dashboard']);
              }
            }
          });
      } else {
        this.activating = false;
      }
    });
  }
}
