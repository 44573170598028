import { Component, OnInit, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { APIService } from 'app/shared/api.service';

@Component({
  selector: 'app-issue-tracking-home',
  standalone: false,

  templateUrl: './issue-tracking-home.component.html',
  styleUrl: './issue-tracking-home.component.css'
})
export class IssueTrackingHomeComponent implements OnInit {
  isAdmin = signal<boolean>(false);
  tabIndex = signal<number>(0);

  constructor(private apiService: APIService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.isAdmin.set(apiService.isAdmin());
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Handle route change here
        console.log('Route changed:', event.url);
        if (event.url.includes('admin')) {
          this.tabIndex.set(1);
        } else {
          this.tabIndex.set(0);
        }
      }
    });
  }

  ngOnInit(): void {

  }

  navigate() {
    switch (this.tabIndex()) {
      case 1:
        if (this.isAdmin()) {
          this.router.navigate(['admin'], { relativeTo: this.activatedRoute });
        }
        break;
      default:
        this.router.navigate(['/issuetracking']);
    }
  }

  tabClick(tabIndex: number) {
    this.tabIndex.set(tabIndex);
    this.navigate();
  }
}
