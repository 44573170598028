import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SetpointTemplate } from 'app/classes/setpoint-template';
import { SetpointTemplateCategory } from 'app/classes/setpoint-template-category';
import { SetpointsService } from 'app/shared/setpoints.service';

@Component({
    selector: 'app-setpoint-templates',
    templateUrl: './setpoint-templates.component.html',
    styleUrls: ['./setpoint-templates.component.css'],
    standalone: false
})
export class SetpointTemplatesComponent {

  categories: SetpointTemplateCategory[];
  templates: SetpointTemplate[];

  constructor(public setpointsService: SetpointsService, private router: Router, private route: ActivatedRoute,) {
    this.get();
  }

  get() {
    this.setpointsService.getTemplateCategories().then(categories => this.categories = categories);
    this.setpointsService.getTemplates().then(templates => this.templates = templates);
  }

  addTemplate() {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  toolbarClick(event: any) {
    this.addTemplate();
  }
}
