import { Component, Input, OnInit } from '@angular/core';
import { Org } from 'app/classes/org';
import { User } from 'app/classes/user';
import { Site } from "app/classes/site";
import { APIService } from 'app/shared/api.service';
import { SiteService } from 'app/shared/site.service';
import { MessageService, SelectItem } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-dialog-invite-user',
    templateUrl: './dialog-invite-user.component.html',
    styleUrls: ['./dialog-invite-user.component.css'],
    providers: [DialogService],
    standalone: false
})
export class DialogInviteUserComponent implements OnInit {

  INVITE_SUCCESS = 'User invite sent.';

  user: User;
  users: User[];
  userSubscription: Subscription;
  invite: {
    modules: string[], sites: number[], email: string, name: string, notes: string, expiresAt: string | null, isTrackable: number
  } =
    { sites: null, modules: null, email: '', name: '', notes: '', expiresAt: null, isTrackable: 1 };

  siteList: any[];
  selectedSites: number[];

  moduleList: any[];
  selectedModules: string[];
  expiresAt: Date;
  today: Date = new Date();
  tomorrow: Date = new Date();
  org: Org;
  isAdmin: Boolean;
  isTrackable = true;
  siteContextMenuItems = [
    { label: 'Only select this' }
  ];

  totals: { modules: number, sites: number } = { modules: 0, sites: 0 };

  constructor(private siteService: SiteService, private apiService: APIService, public dialogService: DialogService, public ref: DynamicDialogRef, public config: DynamicDialogConfig, public messageService: MessageService) {

    this.userSubscription = this.apiService.user.subscribe(user => {
      this.user = user;
      this.org = user.org;
      if (this.user.role === 'admin') {
        this.isAdmin = true;
        // Admins allow full access to modules
        this.moduleList = Object
          .keys(apiService.MODULES)
          .map(v => {
            return {
              tooltip: apiService.MODULE_TOOLTIPS[v] || '',
              label: v,
              value: apiService.MODULES[v],
              selected: ('partnership_engagement,engagement,footfall,integration,api,export,status,orders,planner,timelapse,reviews,camera,integration,water,billing,compliance,occupancy,insight_footfall,schematics,realtime_map'.indexOf(v) === -1)
            }
          });
      } else {
        this.isAdmin = false;
        // User can only invite based on their modules
        this.moduleList = [];
        user.modules.forEach(value => {
          this.moduleList.push({
            tooltip: apiService.MODULE_TOOLTIPS[value] || '',
            label: value,
            value: apiService.MODULES[value],
            selected: ('engagement,integration,api'.indexOf(value) === -1)
          });
        });
      }
      this.calcTotals();
    });

    this.siteService.getSites().then(sites => {
      // Convert sites into a multi select list
      this.siteList = sites.map(v => { return { label: v.title, value: v.id, selected: true, tooltip: v.address.formattedForHTML } });
      this.calcTotals();
    });
  }

  ngOnInit(): void {

  }

  toggleSite(site) {
    site.selected = !site.selected;
    this.calcTotals();
  }

  calcTotals() {
    this.totals.modules = this.moduleList ? this.moduleList.filter(m => m.selected).length : 0;
    this.totals.sites = this.siteList ? this.siteList.filter(m => m.selected).length : 0;
  }

  toggleModule(module) {
    module.selected = !module.selected;
    this.calcTotals();
  }

  cancel() {
    this.messageService.add({ severity: 'info', summary: 'Cancelled', detail: '' });
    this.ref.close();
  }

  updated() {
  }

  inviteUser() {
    if (this.invite.name.length < 2) {
      this.messageService.add({ severity: 'warn', summary: 'Enter a valid name', detail: '' });
      return;
    }
    if (this.invite.email.length < 10) {
      this.messageService.add({ severity: 'warn', summary: 'Enter a valid email', detail: '' });
      return;
    }
    if (this.totals.sites === 0) {
      this.messageService.add({ severity: 'warn', summary: 'No sites selected', detail: '' });
      return;
    }
    if (this.totals.modules === 0) {
      this.messageService.add({ severity: 'warn', summary: 'No modules selected', detail: '' });
      return;
    }

    let invite = this.invite;
    invite.sites = this.siteList.filter(s => s.selected).map(s => s.value);
    invite.modules = this.moduleList.filter(m => m.selected).map(m => m.value);
    invite.expiresAt = this.expiresAt ? this.apiService.dbDate(this.expiresAt) : null;
    invite.isTrackable = this.isTrackable ? 1 : 0;

    this.apiService.inviteUserToOrg(invite).then(
      status => {
        if (status && status.error) {
          this.messageService.add({ severity: 'error', summary: 'There was a problem', detail: 'Error inviting, please try later' });
          return;
        }
        this.messageService.add({ severity: 'success', summary: 'Invite emailed', detail: '' });
        console.log(status);
        this.ref.close(invite);
      }
    );
  }

  clearSites() {
    if (this.siteList.filter(s => s.selected).length) {
      this.siteList.forEach(s => s.selected = false);
    } else {
      this.siteList.forEach(s => s.selected = true);
    }
  }

}
