import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-menu-mobile',
    templateUrl: './menu-mobile.component.html',
    styleUrl: './menu-mobile.component.css',
    standalone: false
})
export class MenuMobileComponent {

  @Input()
  public set isRestricted(v: boolean) {
    this._isRestricted.set(v);
  }

  _isRestricted = signal<boolean>(false);

  @Input()
  moduleAccess: any;

  @Input()
  user: any;

  @Output()
  onMenuClick = new EventEmitter<string>();

  constructor(private router: Router) {

  }

  click(menuItem: string) {
    this.onMenuClick.emit(menuItem);

    switch (menuItem) {
      case 'insights':
        this.router.navigate(['/insights']);
        break;
      case 'energy':
        this.router.navigate(['/energy']);
        break;
      case 'dashboard':
        this.router.navigate(['/dashboard']);
        break;
      case 'reports':
        this.router.navigate(['/reports']);
        break;
      case 'footfall':
        this.router.navigate(['/footfall']);
        break;
      case 'training':
        this.router.navigate(['/training']);
        break;
      case 'engagement':
        this.router.navigate(['/engagement']);
        break;
      case 'engage':
        this.router.navigate(['/engage']);
        break;
      case 'camera':
        this.router.navigate(['/camera']);
        break;
      case 'profiling':
        this.router.navigate(['/profiling']);
        break;
      case 'org':
        this.router.navigate(['/org']);
        break;
      case 'live':
        this.router.navigate(['/live']);
        break;
      case 'telemetry':
        this.router.navigate(['/telemetry']);
        break;
      case 'profile':
        this.router.navigate(['/profile']);
        break;
      case 'login':
        this.router.navigate(['/login']);
        break;
    }
  }

}
