import { Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ReportService } from '../../shared/report.service';
import { Report } from '../../classes/reports/report';
import { ReportBlock } from '../../classes/reports/report-block';
import { ReportConfig } from '../../classes/reports/report-config';

@Component({
    selector: 'app-report-block-render',
    templateUrl: './report-block-render.component.html',
    styleUrls: ['./report-block-render.component.css'],
    standalone: false
})
export class ReportBlockRenderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('blockRef', { static: true }) blockElement;
  private _reportConfig: ReportConfig;
  private blockSubscription: any;
  refresh: any[];
  @Input()
  report: Report;
  @Input()
  block: ReportBlock;
  @Input()
  set reportConfig(value: ReportConfig) {
    this._reportConfig = value;
    this.reportService.getBlockData(this.report.id, this.block, this._reportConfig);
  }

  @Output()
  onLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  blockReady: boolean;
  data: any;
  master: any;

  constructor(
    private reportService: ReportService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.blockSubscription = this.reportService.block
      .subscribe(blockResults => {
        if (blockResults && blockResults.block === this.block && blockResults.data && blockResults.master.gateway.id === this._reportConfig.forGateways[0].id) {
          this.master = blockResults.master;
          this.prepareData(blockResults);
          this.blockReady = true;
          this.onLoaded.emit(true);
        }
      }
      );
  }

  getBlock() {
    this.blockReady = false;
    this.refresh = [];
    this.reportService.getBlockData(this.report.id, this.block, this._reportConfig);
  }

  prepareData(blockResults) {
    this.data = null;
    if (this.block.fragment_id === 1) {
      this.prepareData1(blockResults.data);
    } else if (this.block.fragment_id === 2) {
      this.prepareData2(blockResults.data);
    } else if (this.block.fragment_id >= 3 && this.block.fragment_id <= 99) {
      // Component prepares the data
      this.data = blockResults.data;
      this.refresh = [Math.random()];
    }
  }

  /**
   * see https://www.alessioatzeni.com/meteocons/
   */
  prepareData2(data) {
    let tableData;
    const co = 0;
    this.data = [];
    let dayFrom;
    for (let week = 0; week <= 1; week++) {
      tableData = [];

      if (week === 0) {
        dayFrom = new Date(this.master.startAt);
        // dayTo = 20;
      } else {
        dayFrom = new Date(this.master.previousStartAt);
        // dayTo = 13
      }
      for (let idx0 = 0; idx0 < 7; idx0++) {
        const dayx = dayFrom.getDate();
        let value = { type: '', temp: 0, pressure: 0, icon: '' };
        for (let idx = 0; idx < data[week].length; idx++) {
          if (data[week][idx].dayNumber == dayx) {
            value = data[week][idx];
            switch (value.type) {
              case 'Sunny day ☀️':
                value.icon = 'B';
                break;
              case 'Cloudy ☁️':
                value.icon = 'N';
                break;
              case 'Mist ????':
                value.icon = 'E';
                break;
              case 'Overcast ☁️':
                value.icon = 'Y';
                break;
              case 'Partly cloudy (day) ⛅️':
                value.icon = 'H';
                break;
              case 'Light snow ????':
                value.icon = 'U';
                break;
              case 'Drizzle ????':
              case 'Light rain shower (day) ????':
              case 'Light rain ????':
                value.icon = 'Q';
                break;
              case 'Heavy rain ????':
                value.icon = '8';
                break;
              case 'Fog ????':
                value.icon = 'M';
                break;
              default:
                value.icon = ')';// Weather type not available.
                break;
            }
          }
        }
        tableData.push(value);
        dayFrom.setDate(dayFrom.getDate() + 1);
      }

      this.data.push(tableData);
    }
    this.refresh = [Math.random()];
    this.blockReady = true;
  }

  prepareData1(data) {
    const hours = [];
    for (let idx = 0; idx < 24; idx++) {
      hours.push(idx + ':00');
    }

    const chartData = {
      labels: hours,
      datasets: []
    };
    const datasets = [];
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    let co = 0;
    const dt = new Date(this.master.startAt);
    // Loop through the startAt days for the week
    for (let idx0 = 0; idx0 < 7; idx0++) {
      const dayx = dt.getDate();
      const daySet = { label: days[co], borderColor: this.reportService.colourForDay(days[co]), data: [], fill: false };
      // Loop through the days hours
      for (let hourx = 0; hourx < 24; hourx++) {

        let value = 0;
        for (let idx = 0; idx < data.length; idx++) {

          if (data[idx].dayNumber === dayx && data[idx].hourOfDay === hourx) {

            value = data[idx].value;
          }
        }
        daySet.data.push(value);
      }
      chartData.datasets.push(daySet);
      co++;
      dt.setDate(dt.getDate() + 1);
    }
    this.data = chartData;
    this.blockReady = true;
    this.refresh = [Math.random()];
  }

  ngAfterViewInit() {
    const block = this.blockElement.nativeElement;
  }

  ngOnDestroy() {
    this.blockSubscription.unsubscribe();
  }
}
