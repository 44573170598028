import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Ticket } from "../../classes/ticket";
import { AssetTicket } from "../../classes/asset-ticket";
import { APIService } from "../../shared/api.service";
import { Asset } from 'app/classes/asset';

@Component({
    selector: 'app-issue-ticket',
    templateUrl: './issue-ticket.component.html',
    styleUrls: ['./issue-ticket.component.css'],
    standalone: false
})
export class IssueTicketComponent implements OnInit {

  // Unable to get typescript to use union, fudged.
  assetTicket: AssetTicket;
  _ticket: AssetTicket | Ticket;

  @Input()
  public set ticket(v: AssetTicket | Ticket) {
    this._ticket = v;
    if (v instanceof AssetTicket) {
      this.assetTicket = v;
    }
  }
  public get ticket(): AssetTicket | Ticket {
    return this._ticket;
  }

  constructor(private apiService: APIService) { }

  ngOnInit() {

  }

}
