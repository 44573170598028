import { Component, signal, input } from '@angular/core';
import { DataForm } from '../data-form/data-form.component';
import { AssetService } from 'app/shared/asset.service';
import { Asset } from 'app/classes/asset';

@Component({
    selector: 'app-button-asset-admin',
    templateUrl: './button-asset-admin.component.html',
    styleUrl: './button-asset-admin.component.css',
    standalone: false
})
export class ButtonAssetAdminComponent {

  caption = input<string>(null);
  label = signal<string>('Modify');
  size = input<string>('small');
  showDialog = signal(false);
  pleaseWait = signal(false);

  assetId = input.required<number>();

  asset = signal<Asset>(null);

  dataForm: DataForm;

  constructor(private assetService: AssetService) {
    console.log('ButtonAssetAdminComponent');
  }

  buttonClick() {
    if (this.assetId()) {
      this.assetService.getAsset(this.assetId()).then(a => {
        this.asset.set(a);
        this.dataForm = new DataForm({
          items: [
            {
              id: 'location',
              label: 'Location',
              type: 'string',
              width: '100%',
              value: this.asset().location
            },
            {
              id: 'ref',
              label: 'Your Ref',
              type: 'string',
              width: '100%',
              value: this.asset().ref
            },
            {
              id: 'display',
              label: 'Name',
              type: 'string',
              width: '100%',
              value: this.asset().title
            },
          ]
        });
        this.showDialog.set(true);
      });
    }

  }

  dialogClose(event: any) {
    console.log(event);
    if (event?.id === 'update') {
      this.assetService.updateAsset(this.asset().id, this.dataForm.serialise());
    }
    this.showDialog.set(false);
  }
}
