import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Grouping } from 'app/classes/grouping';
import { Asset } from 'app/classes/asset';
import { Org } from 'app/classes/org';

@Injectable({
  providedIn: 'root'
})
export class OrgService {

  API_URL = 'https://q3167hk0p0.execute-api.eu-west-2.amazonaws.com/4d/';

  constructor(private apiService: APIService, private http: HttpClient) { }

  getOrgsAvailable(): Promise<Org[]> {
    return new Promise(resolve => {
      const url = `${this.API_URL}access`;
      return this.http
        .get<any[]>(url, this.apiService.getUAOHeaders())
        .subscribe(b => resolve(b.map(o => new Org(o))));
    });
  }

  getDefaultSensors(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.get('a=get-default-sensors')
        .then(response => {
          resolve(response);
        });
    });
  }

  updateDefaultSensors(settings: any) {
    return new Promise((resolve, reject) => {
      this.post(settings, 'a=update-default-sensors')
        .then(response => {
          resolve(response);
        });
    });
  }

  async getGroupings(): Promise<Grouping[]> {
    return new Promise(async (resolve) => {
      this.get('a=get-groupings')
        .then(response => {
          resolve(response.map(r => new Grouping(r)));
        });
    });
  }

  async getGrouping(groupingId: number): Promise<Grouping> {
    return new Promise(async (resolve) => {
      this.get(`a=get-grouping&i=${groupingId}`)
        .then(r => {
          resolve(new Grouping(r));
        });
    });
  }

  async getGroupingsAndUsers(): Promise<Grouping[]> {
    return new Promise(async (resolve) => {
      this.get(`a=get-groupings-and-users`)
        .then(r => {
          resolve(r.map(r => new Grouping(r)));
        });
    });
  }

  async updateGrouping(grouping: Grouping): Promise<any> {
    return new Promise(async (resolve) => {
      this.post({ grouping: grouping.serialise() }, 'a=update-grouping')
        .then(response => {
          resolve(response);
        });
    });
  }

  async getTelemetry(): Promise<Asset[]> {
    return new Promise(resolve => {
      const url = `${this.API_URL}telemetry`;
      return this.http
        .get<Asset[]>(url, this.apiService.getUAOHeaders())
        .subscribe(b => resolve(b));
    });
  }

  async getOrgUserActivity(view: 'org' | 'all'): Promise<{ users: any[], urls: any[], orgs: any[] }> {
    return new Promise(resolve => {
      const url = `${this.API_URL}activity?v=${view}`;
      return this.http
        .get<any>(url, this.apiService.getUAOHeaders())
        .subscribe(b => resolve(b));
    });
  }


  post(body, qs: string = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${this.API_URL}?${qs}`;
      return this.http
        .post<any>(url, body, this.apiService.getUAOHeaders())
        .subscribe(b => resolve(b));
    });
  }

  get(qs: string = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${this.API_URL}?${qs}`;
      return this.http
        .get<any>(url, this.apiService.getUAOHeaders())
        .subscribe(b => resolve(b));
    });
  }
}
