/**
 * Supports elogbooks integration
 */
import { Component, Input, OnInit } from '@angular/core';
import { RulePackage } from 'app/classes/rule-service/rule-package';
import { Integration } from 'app/org-dashboard/org-integration-elogbooks/org-integration-elogbooks.component';
import { APIService } from 'app/shared/api.service';

@Component({
    selector: 'app-rule-engine-v2-integration',
    templateUrl: './rule-engine-v2-integration.component.html',
    styleUrls: ['./rule-engine-v2-integration.component.css'],
    standalone: false
})
export class RuleEngineV2IntegrationComponent implements OnInit {

  @Input()
  rule: RulePackage;

  @Input()
  integration: Integration;

  @Input()
  state: 'delayed' | 'new' | 'paused' | 'resolved' = 'new';

  isSelecting: { action: 'site' | 'priority', id: number };

  constructor(private apiService: APIService) { }

  ngOnInit(): void {
    console.log('GOT', this.rule);

    if (!this.rule.integration) {
      this.rule.integration = {
        integrator: 'elogbooks',
        states: {
          'new': {
            siteId: null,
            siteTitle: null,
            summary: null,
            description: null,
            priorityId: null,
            priorityTitle: null
          }
        }
      };
    }
  };

  formChanged() {

  }

  getSites() {

    this.isSelecting = { action: 'site', id: null };
  }

  getPriorities() {
    const siteId: number = this.rule.integration.states[this.state].siteId;
    if (!siteId) {
      // Must of selected a site to get it's priorities
      return;
    }
    this.isSelecting = { action: 'priority', id: siteId };
  }

  selected(obj: { id: number, name: string }) {

    if (!obj) {
      this.isSelecting = { action: null, id: null };
      return;
    }
    switch (this.isSelecting.action) {
      case 'site':
        this.rule.integration.states[this.state].siteId = obj.id;
        this.rule.integration.states[this.state].siteTitle = obj.name;
        break;
      case 'priority':
        this.rule.integration.states[this.state].priorityId = obj.id;
        this.rule.integration.states[this.state].priorityTitle = obj.name;
        break;
    }
    this.isSelecting = { action: null, id: null };
  }

}
