import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { SiteFloorplanAsset } from 'app/classes/site-floorplan-asset';
import { SiteFloorplanShape } from 'app/classes/site-floorplan-shape';
import { APIService } from 'app/shared/api.service';

@Component({
    selector: 'app-site-plan-shape',
    templateUrl: './site-plan-shape.component.html',
    styleUrls: ['./site-plan-shape.component.css'],
    standalone: false
})
export class SitePlanShapeComponent implements OnInit {

  @Input()
  shape: SiteFloorplanShape;

  @Input()
  fullscreen: boolean;

  assetTelemetry: AssetTelemetry[] = [];

  @Output()
  onBack: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log(event.key);

    if (event.key === "Escape") {
      // User pressed escape on the modal
      this.onBack.emit();
    }
  }
  @HostListener('window:click', ['$event'])
  handleClick(event: MouseEvent) {
    console.log('CLICK', event);
  }
  constructor(private apiService: APIService) { }

  ngOnInit(): void {
    /*
    this.shape.assets.forEach(asset => {
      const df = this.apiService.dbDate(new Date());
      const dt = this.apiService.dbDate(new Date());
      const q = `c=id as i,value as v,createdAt as d&df=${df} 00:00:00&dt=${dt} 23:59:59&w=1&`;
      this.apiService.getTelemetry(asset.id, 15000, q)
        .then(telemetry => {
          if (telemetry[0].length) {
            this.assetTelemetry.push({ asset, telemetry: telemetry[0] });
          }
        });
    });
    */
  }

  navigateBack() {
    this.onBack.emit();
  }

  handleChange(event) {

  }

}


export class AssetTelemetry {
  asset: SiteFloorplanAsset;
  telemetry: any[];
}
