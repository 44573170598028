<article *ngIf="template">
  <section>
    <app-toolbar>
      <div class="left">
        <input class="fourd" [(ngModel)]="template.title" placeholder="Title" />
      </div>
      <div class="right">
        <app-button-sensors [maxSensorsToShow]="4" [selectedItems]="template.assetTypeIds" (onSelect)="sensorsSelected($event)"></app-button-sensors>
        @if (cats.length) {
          <app-select [list]="cats" [value]="template.category.id"></app-select>
        }
      </div>
    </app-toolbar>

    <app-theme-page-section theme="white" [border]="false" label="Setpoints" [toolbar]="[{ icon: 'mdi-eraser', label: 'clear form', tag: 'CLEAR', text: 'clear', tooltipposition: 'left' }]" (onToolbarClick)="toolbarClick($event)">
      <table class="hidden-xs table table-sm setpoint-table">
        <thead>
          <th>Day</th>
          <th tooltipPosition="left" [pTooltip]="rangeId === 3 ? 'Trigger before this time' : 'Trigger from this time'">
            <span *ngIf="rangeId === 3"
              >Trigger
              <p>Before</p></span
            >
            <span *ngIf="rangeId === 2">From</span>
          </th>
          <th tooltipPosition="left" [pTooltip]="rangeId === 3 ? 'Trigger after this time' : 'Trigger before this time'">
            <span *ngIf="rangeId === 3"
              >Trigger
              <p>After</p></span
            >
            <span *ngIf="rangeId === 2">To</span>
          </th>
          <th class="rag-red" [ngClass]="{ enabled: template.alarms.red_min }">
            Red Min
            <p pTooltip="Enable alarm - Red minimum breached" tooltipPosition="top">
              <label class="switch" title=""><input name="typetoggle" type="checkbox" [(ngModel)]="template.alarms.red_min" (click)="linkRule()" /><span class="checkboxslider alarm round"></span> </label>
            </p>
          </th>
          <th class="rag-amber" [ngClass]="{ enabled: template.alarms.amber_min }">
            Amber Min
            <p pTooltip="Enable alarm - Amber minimum breached" tooltipPosition="top">
              <label class="switch" title=""><input name="typetoggle" type="checkbox" [(ngModel)]="template.alarms.amber_min" (click)="linkRule()" /><span class="checkboxslider alarm round"></span> </label>
            </p>
          </th>
          <th class="green-gap">
            Green
            <p></p>
          </th>
          <th class="rag-amber" [ngClass]="{ enabled: template.alarms.amber_max }">
            Amber Max
            <p pTooltip="Enable alarm - Amber maximum breached" tooltipPosition="top">
              <label class="switch" title=""><input name="typetoggle" type="checkbox" [(ngModel)]="template.alarms.amber_max" (click)="linkRule()" /><span class="checkboxslider alarm round"></span> </label>
            </p>
          </th>
          <th class="rag-red" [ngClass]="{ enabled: template.alarms.red_max }">
            Red Max
            <p pTooltip="Enable alarm - Red maximum breached" tooltipPosition="top">
              <label class="switch" title=""><input name="typetoggle" type="checkbox" [(ngModel)]="template.alarms.red_max" (click)="linkRule()" /><span class="checkboxslider alarm round"></span> </label>
            </p>
          </th>
          <th class="all-day" *ngIf="rangeId === 2">All Day</th>
          <th>Enabled</th>
          <th></th>
        </thead>
        <tbody>
          <tr [class.chart-clicked-weekday]="selectedWeekday === i" *ngFor="let setpoint of template.setpoints; let i = index">
            <td>
              {{ weekdays[i].title }}
            </td>
            <td *ngIf="!setpoint.allday">
              <input type="time" [(ngModel)]="setpoint.startsAt" (blur)="onBlur()" [disabled]="!setpoint.isActive" (keyup)="generateGreenRAGTooltip()" />
            </td>
            <td *ngIf="setpoint.allday">00:00:00</td>
            <td *ngIf="!setpoint.allday">
              <input type="time" [(ngModel)]="setpoint.endsAt" (blur)="onBlur()" [disabled]="!setpoint.isActive" (keyup)="generateGreenRAGTooltip()" />
            </td>
            <td *ngIf="setpoint.allday">23:59:59</td>
            <td class="rag-red">
              <input [(ngModel)]="setpoint.red_min" style="width: 60px" [disabled]="!setpoint.isActive" (blur)="change('red_min')" (keyup)="generateGreenRAGTooltip()" />
            </td>
            <td class="rag-amber">
              <input [(ngModel)]="setpoint.amber_min" style="width: 60px" [disabled]="!setpoint.isActive" (blur)="change('amber_min')" (keyup)="generateGreenRAGTooltip()" />
            </td>
            <td class="green-gap"></td>
            <td class="rag-amber">
              <input [(ngModel)]="setpoint.amber_max" style="width: 60px" [disabled]="!setpoint.isActive" (blur)="change('amber_max')" (keyup)="generateGreenRAGTooltip()" />
            </td>
            <td class="rag-red">
              <input [(ngModel)]="setpoint.red_max" style="width: 60px" [disabled]="!setpoint.isActive" (blur)="change('red_max')" (keyup)="generateGreenRAGTooltip()" />
            </td>
            <td class="all-day" *ngIf="rangeId === 2">
              <label class="switch" title="Enable to run all day"><input name="typetoggle" type="checkbox" [(ngModel)]="setpoint.allday" (click)="allday(i)" /><span class="checkboxslider round"></span></label>
            </td>
            <td>
              <label class="switch" title="Enable this line"><input name="typetoggle" type="checkbox" [(ngModel)]="setpoint.isActive" (click)="enableWeekday(i)" /><span class="checkboxslider round"></span></label>
            </td>
            <td class="actions">
              <i class="fa fa-copy" [class.disabled]="!setpoint.isActive" title="Copy" (click)="copy(i)"></i>
              <i class="fa fa-paste" [class.disabled]="!copySetpoint" title="Paste" (click)="paste(i)"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </app-theme-page-section>

    <section>
      <span class="p-float-label">
        <textarea pInputTextarea [(ngModel)]="template.notes"></textarea>
        <label>Notes</label>
      </span>
    </section>
  </section>

  <footer>
    <section>
      <div>
        <button [disabled]="!template.id" pButton type="button" routerLink="scheduler" pTooltip="Create a new schedule from this template" icon="mdi mdi-calendar-arrow-left" class="p-button-outlined p-button-secondary" label="Scheduler"></button>
      </div>
      <button pButton type="button" (click)="delete()" icon="pi pi-trash" class="p-button-outlined p-button-danger" label="Delete" [disabled]="!template.id"></button>
      <button pButton type="button" (click)="cancel()" icon="pi pi-times" class="p-button-outlined" label="Cancel"></button>

      <!--	<button [disabled]="!template.id" pTooltip="Create a new template based on these values" tooltipPosition="top"
				pButton type="button" (click)="apiService.toastWarn('not implemented','')" class="p-button-outlined"
				icon="mdi mdi-content-copy" label="Duplicate"></button> -->

      <button pButton type="button" (click)="submit()" icon="pi pi-check" label="Save Changes"></button>
    </section>

    <section>
      <app-info-panel type="note">
        <ul *ngIf="template.id">
          <li>Saving the template will effect all existing and new schedules based on this template.</li>
          <li>When making changes, you can duplicate this template if you'd like to keep existing schedules on previous values.</li>
        </ul>
        <ul *ngIf="!template.id">
          <li style="vertical-align: middle">
            After saving this new template you can come back here and create schedules through the
            <span class="button">
              <span class="p-button-icon p-button-icon-left mdi mdi-calendar-arrow-left" aria-hidden="true"></span>
              Scheduler
            </span>
            button.
          </li>
        </ul>
      </app-info-panel>
    </section>
  </footer>
</article>
