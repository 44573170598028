/**
 * [left, center:flex 1, right]
 * 
 * if only left
 * [left:flex 1]
 */
import { AfterViewInit, Component, ElementRef, OnInit, signal, input } from '@angular/core';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrl: './toolbar.component.css',
    standalone: false
})
export class ToolbarComponent implements OnInit, AfterViewInit {
  onlyLeft = signal<boolean>(false);
  onlyRight = signal<boolean>(false);
  noCenter = signal<boolean>(true);
  hasCenter = signal<boolean>(null);
  hasBackground = input<boolean>(true);

  size = input<'small' | 'medium'>('medium');

  constructor(private el: ElementRef) {

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    const sections = { left: false, center: false, right: false };

    for (const element of this.el.nativeElement.children[0].children[0].children[0].children) {
      const className = element.className;
      if (className.indexOf('toolbar-') !== -1) {
        if (element.children.length) {
          sections[className.split('-')[1]] = true;
        }
      }
    }

    this.onlyLeft.set((sections.left && !sections.center && !sections.right))
    this.onlyRight.set((sections.right && !sections.center && !sections.left))
    this.noCenter.set(!sections.center);
    this.hasCenter.set(sections.center);

    // console.log(this.onlyLeft(), this.hasCenter(), this.onlyRight(),);
  }
}
