<article>
  <section class="map">
    <!--
        <p-gmap *ngIf="overlays" #gmap [style]="{'width':'100%','height':'420px'}" (onMapReady)="setMap($event)"
            [options]="options" [overlays]="overlays" (onOverlayClick)="handleOverlayClick($event)"></p-gmap>-->
  </section>

  <section class="table">
    <table class="table">
      <thead>
        <tr>
          <th></th>
          <th>Gateway</th>
          <th>Updated</th>
          <th>co2</th>
          <th>voc</th>
          <th>tem</th>
          <th>hum</th>
          <th>pm25</th>
          <th>pres</th>
          <th>rad</th>
        </tr>
      </thead>
      <tbody>
        <ng-template ngFor [ngForOf]="aqlist" let-aq>
          <tr *ngIf="aq.isOffline" class="offline">
            <td><img src="https://s3.eu-west-2.amazonaws.com/4d-documents/{{ aq.orgLogo }}" /></td>
            <td>{{ aq.title }}</td>
            <td title="{{ aq.updatedAt | amDateFormat: 'DD MMM YYYY HH:MM' }}">{{ aq.updatedAt | amTimeAgo }}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr (click)="aq.isSelected = !aq.isSelected" *ngIf="!aq.isOffline" [class.is-selected]="aq.isSelected">
            <td><img src="https://s3.eu-west-2.amazonaws.com/4d-documents/{{ aq.orgLogo }}" /></td>
            <td>{{ aq.title }}</td>
            <td title="{{ aq.updatedAt | amDateFormat: 'DD MMM YYYY HH:MM' }}">{{ aq.updatedAt | amTimeAgo }}</td>
            <td class="{{ aq.states.co2 }}">{{ aq.co2 }}</td>
            <td class="{{ aq.states.voc }}">{{ aq.voc }}</td>
            <td class="{{ aq.states.temp }}">{{ aq.temp }}</td>
            <td class="{{ aq.states.humidity }}">{{ aq.humidity }}</td>
            <td>{{ aq.pm25 }}</td>
            <td>{{ aq.pressure }}</td>
            <td>{{ aq.radon }}</td>
          </tr>
          <tr class="aq" *ngIf="aq.isSelected">
            <td colspan="10">
              <div>
                <app-gateway-aq [aq]="aq"></app-gateway-aq>
              </div>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </section>

  <section class="info">
    <div>
      <span>Carbon Dioxide</span> – CO2 is an invisible gas which rises to unhealthy levels indoors. It can cause headaches, restlessness and drowsiness as well as affect decision-making skills. High levels are directly correlated to low productivity, absenteeism and infectious disease transmission.
      <ul>
        <li>250 - 400 ppm: background (normal) outdoor air level.</li>
        <li>400 - 1,000 ppm: typical level found in occupied spaces with good air exchange.</li>
        <li>1,000 - 2,000 ppm: level associated with complaints of drowsiness and poor air.</li>
        <li>2,000 - 5,000 ppm: level associated with headaches, sleepiness, and stagnant, stale, stuffy air.</li>
      </ul>
    </div>

    <div><span>Radon</span> – Decrease exposure to this radioactive gas that accumulates in buildings and homes. It is the number one cause of lung cancer among nonsmokers but can be managed with continuous monitoring.</div>

    <div><span>Total Volatile Organic Compounds</span> – TVOCs are vapors emitted from all sorts of daily products including paints and furniture, wax and cosmetics, cleaning and hobby products, cooking and human breath. At high concentrations they can be harmful and cause negative side-effects from minor eye, nose and throat irritations all the way to liver and kidney damage and even cancer, depending on the level and length of exposure.</div>

    <div>
      <span>Humidity</span> – Too much or too little humidity can affect allergies and cold or flu symptoms. When humidity levels are too high, mold and rot will occur. Low humidity levels cause static electricity, dry skin and hair, and increased susceptibility to colds and respiratory illness.
      <ul>
        <li>Acceptable humidity levels in office buildings are generally within the “safe zone” of 40 to 60 percent RH</li>
      </ul>
    </div>

    <div><span>Temperature</span> – Indoor temperatures can affect performance, mood and comfort level. Individual sleep patterns are also affected by indoor air temperature.</div>

    <div><span>Pressure</span> – Barometric pressure is the pressure given by the atmosphere at any given point. It is known as the “weight of the air” and changes depending on your elevation, as well as weather patterns. It can cause headaches.</div>
  </section>
</article>
