import { Component, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { TrainingService } from '../../shared/training.service';
import { TrainingModule } from '../../classes/training-module';
import { APIService } from '../../shared/api.service';

@Component({
    selector: 'app-training-landing',
    templateUrl: './training-landing.component.html',
    styleUrls: ['./training-landing.component.css'],
    standalone: false
})
export class TrainingLandingComponent implements OnInit {

  modules: TrainingModule[];
  nextModule: TrainingModule;
  courseId: number;
  tabIndex = signal<number>(0);

  constructor(private route: ActivatedRoute, private apiService: APIService, private router: Router, private trainingService: TrainingService) {

  }

  ngOnInit() {
    console.log('nginit');
    this.modules = [];
    this.route.params.subscribe((params: Params) => {
      this.courseId = params['courseid'];
      this.apiService.getTrainingModules(params['courseid']).then(modules => {
        this.modules = modules;
        this.trainingService.modules = modules;
        this.processModules();
      });
    });
  }

  processModules() {
    let completed: TrainingModule = null;
    this.modules.forEach(module => {
      if (!module.isCompleted && !completed) {
        module.isViewable = true;
        completed = module;
      }
    });
    // Store the next module to watch
    this.nextModule = completed;
  }

  selectModule(module: TrainingModule) {
    if (module.isViewable) {
      this.router.navigate(['training', this.courseId, 'modules', module.id]);
    }
  }
}

