import { Component, computed, input, OnInit, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Asset } from 'app/classes/asset';
import { Site } from 'app/classes/site';
import { APIService } from 'app/shared/api.service';
import { AssetService } from 'app/shared/asset.service';
import { SiteService } from 'app/shared/site.service';

@Component({
    selector: 'app-energy-site',
    templateUrl: './energy-site.component.html',
    styleUrl: './energy-site.component.css',
    standalone: false
})
export class EnergySiteComponent implements OnInit {

  site = signal<Site>(null);
  assets = signal<Asset[]>([]);
  asset = signal<Asset>(null);

  assetTypesAllowed = [72, 73];

  constructor(private siteService: SiteService, private apiService: APIService, private assetService: AssetService, private activatedRoute: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.getSite(this.activatedRoute.snapshot.params.siteid);
  }

  getSite(siteId: number) {
    this.apiService.getSite(siteId).then(site => {
      this.site.set(site);
    });
    this.assetService.getAssetsForSite(siteId).then(assets => {
      assets = assets.filter(asset => this.assetTypesAllowed.includes(asset.assetType_id));
      assets.forEach(asset => asset.assetTypeTitle = this.assetService.getTypeTitle(asset.assetType_id));
      this.assets.set(assets);

    });
  }

  assetClick(asset: Asset) {
    this.asset.set(asset);
  }

}
