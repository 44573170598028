import { Component, OnInit, OnDestroy } from '@angular/core';
import { Asset } from '../../classes/asset';
import { ActivatedRoute, Router } from '@angular/router'

import { CameraService } from "../../shared/camera.service";
import { Collection } from "../../classes/collection";

@Component({
    selector: 'app-camera-lightmonitor',
    templateUrl: './camera-lightmonitor.component.html',
    styleUrls: ['./camera-lightmonitor.component.css'],
    standalone: false
})
export class CameraLightmonitorComponent implements OnInit {

  cameras: Collection<Asset>;

  constructor(private router: Router, private cameraService: CameraService, private route: ActivatedRoute) {
    cameraService.getCamerasWithPresets().then(cameras => {
      this.cameras = cameras;
    });
  }

  ngOnInit() {
  }

  selectItem(camera: Asset) {
    switch (camera.purpose.id) {
      case 1:
        // this.router.navigate(['timelapse']);
        break;
      case 2:
        //    this.router.navigate(['lightmonitor', camera.id], { relativeTo: this.route });
        break;
    }
  }

  mousemove(e: any) {
    console.log(e);
  }
}
