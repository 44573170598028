import { Component, OnInit, OnDestroy, signal } from '@angular/core';
import { APIService } from '../../shared/api.service';
import { StoreService } from "../../shared/store.service";
import { Site } from '../../classes/site';
import { Router, ActivatedRoute } from '@angular/router';
import { SitePlan } from '../../classes/site-plan';
import { from } from 'rxjs';
import { distinct } from 'rxjs/operators';
import { WindowService } from 'app/shared/window.service';
import { Org } from 'app/classes/org';

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.css'],
  standalone: false
})
export class ProfileListComponent implements OnInit, OnDestroy {
  static readonly CONTACT_US_URL = 'https://4dmonitoring.co.uk/contact-us/';

  init = signal<boolean>(true);
  scenarios = {}
  siteFloorPlans: SitePlan[];
  siteCategories: any[];
  sites: Site[] = [];
  profiling: any[];
  pageTitle: string = 'Floor Profiling';
  canAdmin: boolean;
  actions: any;
  siteSelected: Site;
  orgs = signal<Org[]>(null);
  showOrgs = signal<boolean>(false);

  constructor(private apiService: APIService, private windowService: WindowService, private storeService: StoreService, private router: Router, private route: ActivatedRoute) {
    this.apiService.getAllSiteFloorplans()
      .then(plansAndCats => {
        this.siteFloorPlans = plansAndCats.plans.sort((a, b) => {
          if (a.title < b.title) { return -1; }
          if (a.title > b.title) { return 1; }
          return 0;
        });
        from(this.siteFloorPlans)
          .pipe(distinct(siteplan => siteplan.site.id))
          .forEach(sitePlan => { this.sites.push(sitePlan.site) });

        this.siteFloorPlans.forEach(floorplan => {
          const site = this.sites.find(site => site.id === floorplan.site.id);
          if (site) {
            site.floorplans.push(floorplan);
          }
        });


        const orgs = [...this.siteFloorPlans.reduce((u, c) => {
          if (!u.has(c.site.org.id)) {
            u.set(c.site.org.id, c.site.org);
          }
          return u;
        }, new Map()).values()];

        this.orgs.set(orgs);

        this.showOrgs.set(orgs.length > 1);

        this.sites.sort((a, b) => {
          if (a.title < b.title) { return -1; }
          if (a.title > b.title) { return 1; }
          return 0;
        });

        // Sort by floor
        this.sites.forEach(site => site.floorplans.sort((a, b) => a.floor > b.floor ? 1 : -1));

        this.init.set(false);
        if (this.sites.length === 1) {
          // If only one site always go into that site, no need to show a list
          router.navigate(['/profiling', 'sites', this.sites[0].id, 'floorplans']);
        }
      });

    this.canAdmin = this.apiService.hasOrgRole('floorplan_admin') || this.apiService.isAdmin();

    if (this.canAdmin) {
      // this.actions = [{ title: 'Add', link: ['/profiling', 'add'], icon: 'plus' }];
    }
  }

  siteClick(site: Site) {
    if (this.windowService.isMobile()) {
      this.siteSelected = site;
    } else {
      // console.log(`['/profiling', 'sites', ${site.id}, 'floorplans']`);

      this.router.navigate(['/profiling', 'sites', site.id, 'floorplans']);
    }
    //[routerLink] = "['sites', site.id, 'floorplans']"
  }

  floorClick(plan: SitePlan) {
    this.gotoplan(null, plan);
  }

  gotoplan(event: any, plan: SitePlan) {
    if (event) {
      event.stopPropagation();
    }
    // console.log(plan);

    this.router.navigate(['/profiling', 'sites', plan.site.id, 'floorplans'], { queryParams: { planid: plan.id } });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  clickShowMore() {
    window.location.href = ProfileListComponent.CONTACT_US_URL;
  }

  ngOnDestroy() {
    // console.log('profile-list destroy');
  }
}
