import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ClipboardService } from 'app/shared/clipboard.service';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-dev-curl',
    templateUrl: './dev-curl.component.html',
    styleUrls: ['./dev-curl.component.css'],
    standalone: false
})
export class DevCurlComponent implements OnInit {

  @Input()
  api: any;

  _path: string;
  @Input()
  public set path(v: string) {
    this._path = v;
    this.build();
  }

  @ViewChild('section', { static: true })
  sectionRef: ElementRef;

  stage = 'v2';
  curl = '';
  copyCurl = '';

  constructor(private messageService: MessageService) { }

  ngOnInit(): void {
    this.build();
  }

  build() {
    if (!this.api) {
      return;
    }
    this.curl = `curl --request GET \\
--url 'https://2rx4xrq7i1.execute-api.eu-west-2.amazonaws.com/${this.stage}/${this._path}' \\
--header 'x-api-key: ${this.api.apikey}'  `;

    this.copyCurl = `curl --request GET --url 'https://2rx4xrq7i1.execute-api.eu-west-2.amazonaws.com/${this.stage}/${this._path}' --header 'x-api-key: ${this.api.apikey}'`;
  }

  copyToClipboard() {
    //const copyText = this.sectionRef.nativeElement;
    //const text = copyText.children[0].innerText;
    ClipboardService.copyText(this.copyCurl);
    this.messageService.add({ severity: 'success', summary: 'Added to clipboard', detail: '' });
  }
}
