
import { Component, OnInit, Input } from '@angular/core';
import { APIService } from '../../shared/api.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-device-landing',
    templateUrl: './device-landing.component.html',
    styleUrls: ['./device-landing.component.css'],
    standalone: false
})
export class DeviceLandingComponent implements OnInit {

  activating: boolean;
  token: string;
  init: boolean = true;
  db: any = {};
  assets: any[];
  iot: any;
  myUrl: string = '';

  constructor(private router: Router, private route: ActivatedRoute, private apiService: APIService, public sanitizer: DomSanitizer) {
    var self = this;
    this.route.params.subscribe((params: Params) => {
      this.token = params['tokenid'];
      console.log(this.token);
      this.apiService.getDeviceStatus(this.token).then(d => {
        console.log('device status returned', d);
        self.init = false;
        if (!d) {
          return;
        }
        if (d.db.gatewayId) {
          self.myUrl = 'https://streams.4dml.com/streams/' + d.db.gatewayId;
        }
        self.db = d.db;
        self.iot = d.iot;
        if (d.assets && d.assets.length === 1 && d.assets[0].id === null) {
          d.assets = null;
        }
        self.assets = d.assets;
      });
    });
  }

  ngOnInit() {
  }

  createThing() {
    var self = this;
    this.init = true;
    this.apiService.manageDevice(this.token, 'create_thing').then(
      function (results) {
        self.init = false;
      }
    );
  }

}
