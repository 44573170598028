import { Component, Input, OnInit } from '@angular/core';
import { Calendar4D } from "../../../classes/calendar";
import moment from 'moment';

@Component({
    selector: 'app-rulesv3-item-alarm-calendar',
    templateUrl: './rulesv3-item-alarm-calendar.component.html',
    styleUrls: ['./rulesv3-item-alarm-calendar.component.css'],
    standalone: false
})
export class Rulesv3ItemAlarmCalendarComponent implements OnInit {
  @Input()
  items: any[];
  calendar: Calendar4D;
  rows: any;

  constructor() { }

  ngOnInit(): void {
    this.build();
  }

  build() {
    const rows = [];
    const date = moment().isoWeekday(1).startOf('isoWeek').startOf('day');
    const todayCompare = moment().format('L');
    let firstWeekOfPreviousMonth = 0;
    let previousMonth = false;
    let topBorder = false;
    for (let weekIndex = 0; weekIndex < 5; weekIndex++) {
      let week = [];
      for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
        const dateCompare = date.format('L');
        const wd = date.isoWeekday();
        const wt = date.format('ddd');
        const alarms = this.items.filter(item => moment(item.createdAt).format('L') === dateCompare);
        const tooltip = (alarms.length) + ' alarms on ' + date.format('DD/MM/YYYY');
        const isToday = todayCompare === dateCompare;
        if (date.date() === 1) {
          previousMonth = true;
          firstWeekOfPreviousMonth = 8 - wd;
          week.forEach(w => w.topBorder = true);
        }
        const day = { topBorder, previousMonth, dayOfMonth: date.date(), wd, wt, date: date.toDate(), tooltip, isFuture: date.isAfter(), hasItems: !!alarms.length, items: alarms, isToday };
        week.push(day);
        date.add(1, 'day');
        firstWeekOfPreviousMonth--;
        previousMonth = firstWeekOfPreviousMonth > 0;
      }
      // Previous month only needs formatting for the first line.

      rows.push(week);
      date.subtract(14, 'days');
    }

    this.rows = rows;
  }
}

