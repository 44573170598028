import { Component, OnInit, computed, input, signal } from '@angular/core';
import { AssetService, IGetTelemetryForRangeAsObjectsResponse } from 'app/shared/asset.service';
import { FootfallAsset, IFootfallArea } from 'app/shared/footfall.service';
import { CalendarService, IDateConfig } from "app/shared/calendar.service";
import { Chart } from 'chart.js';
import { Moment } from 'moment';
import { compareAsc } from 'date-fns';

@Component({
    selector: 'app-foorfall-area',
    templateUrl: './foorfall-area.component.html',
    styleUrl: './foorfall-area.component.css',
    standalone: false
})
export class FoorfallAreaComponent implements OnInit {
  area = input.required<IFootfallArea>()
  isFullscreen = input.required<boolean>();

  assetList = computed(() => this.area().assets.map(a => {
    return { id: a.id, title: a.title }
  }))

  asset = signal<FootfallAsset>(null);
  asset2 = signal<FootfallAsset>(null);

  // Future use
  assetArray: { asset: FootfallAsset, telemetry: IGetTelemetryForRangeAsObjectsResponse[] }[] = [];

  telemetry = signal<IGetTelemetryForRangeAsObjectsResponse[]>(null);
  telemetry2 = signal<IGetTelemetryForRangeAsObjectsResponse[]>(null);

  value = computed(() => this.area().assets[0].id);
  value2 = computed(() => {
    if (this.area().assets.length > 1) {
      return this.area().assets[1].id;
    }
    return null;
  });

  dates = signal<IDateConfig>(null);

  apiGetsCompleted = 0;
  public chart: any;

  constructor(private assetService: AssetService, private calendarService: CalendarService) {
    this.getLocalStorage();
  }

  getLocalStorage() {
    const dates = localStorage.getItem('footfallarea:dates');
    if (!dates) {
      this.dates.set({ dateRange: 'today' });
    } else {
      try {
        this.dates.set(JSON.parse(dates));
      } catch (e) {
        this.dates.set({ dateRange: 'today' });
      }
    }

    if (this.dates().dateRange !== 'custom') {
      this.dates.update(item => {
        item.dates = this.calendarService.calculateDatesFromRangeText(this.dates().dateRange);
        return item;
      });
    } else {
      this.dates.update(item => {
        item.dates = { from: new Date(item.dates.from), to: new Date(item.dates.to) };
        return item;
      });
    }
  }

  setLocalStorage() {
    localStorage.setItem('footfallarea:dates', JSON.stringify(this.dates()));
  }

  ngOnInit(): void {
    console.log(this.area());
    this.assetSelected(1, this.area().assets[0].id);
    if (this.area().assets.length > 1) {
      this.assetSelected(2, this.area().assets[1].id);
    }
    this.get();
  }

  dateChange(dateConfig: IDateConfig) {
    if (dateConfig.dateRange === this.dates().dateRange && dateConfig.dates.from === this.dates().dates.from && this.dates().dates.to === dateConfig.dates.to) {
      console.log('no change');
      return;
    }

    this.dates.set(dateConfig);
    this.setLocalStorage();
    this.get();

  }

  datesSelected(dates: IDateConfig) {
    console.log(dates);
  }

  assetSelectedByUser(index: number, assetId: number) {
    this.assetSelected(index, assetId);
    this.get();
  }

  assetSelected(index: number, assetId: number) {
    console.log(assetId);
    switch (index) {
      case 1:
        this.asset.set(this.area().assets.find(a => +a.id === +assetId));
        break;
      case 2:
        this.asset2.set(this.area().assets.find(a => +a.id === +assetId));
        break;
    }
    if (!this.asset()) {
      return;
    }
  }

  get() {
    const { from, to } = this.dates().dates;
    this.getData(from, to);
  }

  getData(from: Date, to: Date) {
    this.apiGetsCompleted = (this.asset2()?.id ? 2 : 1);
    this.assetService.getTelemetryForRangeAsObjects(this.asset(), from, to).then(telemetry => {
      this.apiGetsCompleted--;
      telemetry = telemetry.filter(t => (t.m.isAfter(from) || t.m.isSame(from)) && (t.m.isBefore(to) || t.m.isSame(to)));
      this.telemetry.set(telemetry);
      if (this.apiGetsCompleted <= 0) {
        this.buildChart();
      }
    });
    if (this.asset2()) {
      this.assetService.getTelemetryForRangeAsObjects(this.asset2(), from, to).then(telemetry => {
        this.apiGetsCompleted--;
        telemetry = telemetry.filter(t => (t.m.isAfter(from) || t.m.isSame(from)) && (t.m.isBefore(to) || t.m.isSame(to)));
        this.telemetry2.set(telemetry);
        if (this.apiGetsCompleted <= 0) {
          this.buildChart();
        }
      });
    }
  }

  analyseTelemetryThreeHourSlots(telemetry: IGetTelemetryForRangeAsObjectsResponse[], datasetIndex: number, threeHourSlots: any) {
    const DOW_FORMAT = 'ddd DD/MM/YY';
    telemetry.forEach(t => {
      const hour = t.m.get('hour');
      const dow = t.m.format(DOW_FORMAT);
      let index = 0;
      switch (hour) {
        case 3:
        case 4:
        case 5:
          index = 1;
          break;
        case 6:
        case 7:
        case 8:
          index = 2;
          break;
        case 9:
        case 10:
        case 11:
          index = 3;
          break;
        case 12:
        case 13:
        case 14:
          index = 4;
          break;
        case 15:
        case 16:
        case 17:
          index = 5;
          break;
        case 18:
        case 19:
        case 20:
          index = 6;
          break;
        case 21:
        case 22:
        case 23:
          index = 7;
          break;
      }
      threeHourSlots[dow] = threeHourSlots[dow] || [[0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0]];
      threeHourSlots[dow][datasetIndex][index] += t.v;
    });
  }

  analyseTelemetryDaySlots(telemetry: IGetTelemetryForRangeAsObjectsResponse[], datasetIndex: number, threeHourSlots: any) {
    const DOW_FORMAT = 'ddd DD/MM/YY';
    telemetry.forEach(t => {
      const dow = t.m.format(DOW_FORMAT);
      threeHourSlots[dow] = threeHourSlots[dow] || [0, 0];
      threeHourSlots[dow][datasetIndex] += +t.v;
    });
  }

  buildDataSetsDaySlots(datasetIndex: number, threeHourSlots: any, labelKeys: any, dowKeys: any, labels?: any) {
    const dataset = [];

    for (let index = 0; index < dowKeys.length; index++) {
      const dowKey = dowKeys[index];
      const day = threeHourSlots[dowKey][datasetIndex];
      dataset.push(day);
      if (datasetIndex === 0) {
        labels.push(dowKey);
      }
    }

    return dataset;
  }

  buildDataSetsThreeHourSlots(datasetIndex: number, threeHourSlots: any, labelKeys: any, dowKeys: any, labels?: any) {
    const dataset = [];

    for (let index = 0; index < dowKeys.length; index++) {
      const dowKey = dowKeys[index];
      for (let index2 = 0; index2 < threeHourSlots[dowKey][datasetIndex].length; index2++) {
        const hour = threeHourSlots[dowKey][datasetIndex][index2];
        dataset.push(hour);
        if (datasetIndex === 0) {
          labels.push(dowKey + ' ' + labelKeys[index2]);
        }
      }
    }

    return dataset;
  }

  buildChart() {
    const days = (<Moment>this.telemetry()[0].m).diff((<Moment>this.telemetry()[this.telemetry().length - 1].m), 'days');
    if (days <= 7) {
      this.buildChartThreeHourSlots();
    } else {
      this.buildChartDaySlots();
    }
  }

  buildChartDaySlots() {
    const daySlots = {};
    let dataset1, dataset2;
    const labels = [];
    const labelKeys = ['00:00 - 03:00', '03:00 - 06:00', '06:00 - 09:00', '09:00 - 12:00', '12:00 - 15:00', '15:00 - 18:00', '18:00 - 21:00', '21:00 - 00:00'];

    this.analyseTelemetryDaySlots(this.telemetry(), 0, daySlots);
    if (this.telemetry2()?.length) {
      this.analyseTelemetryDaySlots(this.telemetry2(), 1, daySlots);
    }
    const dowKeys = Object.keys(daySlots).reverse();
    dataset1 = this.buildDataSetsDaySlots(0, daySlots, labelKeys, dowKeys, labels);
    if (this.telemetry2()?.length) {
      dataset2 = this.buildDataSetsDaySlots(1, daySlots, labelKeys, dowKeys);
    }
    this.showChart(labels, dataset1, dataset2);
  }

  buildChartThreeHourSlots() {
    const threeHourSlots = {};
    let dataset1, dataset2;
    const labels = [];
    const labelKeys = ['00:00 - 03:00', '03:00 - 06:00', '06:00 - 09:00', '09:00 - 12:00', '12:00 - 15:00', '15:00 - 18:00', '18:00 - 21:00', '21:00 - 00:00'];

    this.analyseTelemetryThreeHourSlots(this.telemetry(), 0, threeHourSlots);
    if (this.telemetry2()?.length) {
      this.analyseTelemetryThreeHourSlots(this.telemetry2(), 1, threeHourSlots);
    }
    const dowKeys = Object.keys(threeHourSlots).reverse();
    dataset1 = this.buildDataSetsThreeHourSlots(0, threeHourSlots, labelKeys, dowKeys, labels);
    if (this.telemetry2()?.length) {
      dataset2 = this.buildDataSetsThreeHourSlots(1, threeHourSlots, labelKeys, dowKeys);
    }
    this.showChart(labels, dataset1, dataset2);
  }

  showChart(labels2: any, dataset1: any, dataset2?: any) {
    if (this.chart) {
      this.chart.clear();
      this.chart.destroy();
    }

    const datasets = [
      {
        label: this.asset().title,
        data: dataset1,
        backgroundColor: '#3c9ac3'
      }
    ];

    if (this.telemetry2()?.length) {
      datasets.push({
        label: this.asset2().title,
        data: dataset2,
        backgroundColor: '#4171c3'
      });
    }

    this.chart = new Chart("chart-1",
      {
        type: 'bar',
        data: {
          labels: labels2,
          datasets
        },
        options: {
          maintainAspectRatio: false
        }
      });
  }
}
