import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-reviews-container',
    templateUrl: './reviews-container.component.html',
    styleUrls: ['./reviews-container.component.css'],
    standalone: false
})
export class ReviewsContainerComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
  }

  clickNewReview() {
    this.router.navigate(['new'], { relativeTo: this.route });
  }
}
