import { Component, computed, Signal, signal } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Org } from 'app/classes/org';
import { User } from 'app/classes/user';
import { APIService } from 'app/shared/api.service';
import { CameraService } from 'app/shared/camera.service';
import { SiteService } from 'app/shared/site.service';
import { UserService } from 'app/shared/user.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-org-user-invite',
    templateUrl: './org-user-invite.component.html',
    styleUrls: ['./org-user-invite.component.css'],
    standalone: false
})
export class OrgUserInviteComponent {
  INVITE_SUCCESS = 'User invite sent.';

  user: User;
  users: User[];
  userSubscription: Subscription;
  invite: {
    modules: string[], sites: number[], email: string, name: string, notes: string, expiresAt: string | null, isTrackable: number
  } =
    { sites: null, modules: null, email: '', name: '', notes: '', expiresAt: null, isTrackable: 1 };

  siteList = signal<ISiteItem[]>([]);
  siteSelectedCount: Signal<number> = computed(() => {
    console.log('COMPUTED');
    return this.siteList().filter(s => s.selected).length;
  });

  moduleList: any[];
  selectedModules: string[];
  expiresAt: Date;
  today: Date = new Date();
  tomorrow: Date = new Date();
  org: Org;

  isTrackable = true;
  isInviting: boolean;
  siteContextMenuItems = [
    { label: 'Only select this' }
  ];

  totals: { modules: number, sites: number } = { modules: 0, sites: 0 };

  constructor(private userService: UserService, private siteService: SiteService, private apiService: APIService, public messageService: MessageService, private router: Router, private cameraService: CameraService, private route: ActivatedRoute) {

    this.userSubscription = this.apiService.user.subscribe(user => {
      this.user = user;
      this.org = user.org;
      if (this.user.role === 'admin') {
        // Admins allow full access to modules
        this.moduleList = Object
          .keys(apiService.MODULES)
          .map(v => {
            return {
              tooltip: apiService.MODULE_TOOLTIPS[v] || '',
              label: v,
              value: apiService.MODULES[v],
              selected: ('partnership_engagement,engagement,footfall,integration,api,export,orders,planner,timelapse,reviews,camera,integration,water,billing,compliance,occupancy,insight_footfall,schematics,realtime_map,energy'.indexOf(v) === -1)
            }
          })
          .sort((a, b) => a.label > b.label ? 1 : -1);
      } else {
        // User can only invite based on their modules
        this.moduleList = [];
        user.modules.forEach(value => {
          this.moduleList.push({
            tooltip: apiService.MODULE_TOOLTIPS[value] || '',
            label: value,
            value: apiService.MODULES[value],
            selected: ('engagement,integration,api'.indexOf(value) === -1)
          });
        });
      }
      this.calcTotals();
    });

    this.siteService.getSites().then(sites => {
      // Convert sites into a multi select list
      this.siteList.set(sites.map(v => { return { label: v.title, value: v.id, selected: true, tooltip: v.address.formattedForHTML } }));
      this.calcTotals();
    });
  }

  toggleSite(site) {
    this.siteList.update(sites => {
      sites.find(s => s.value === site.value).selected = !site.selected;

      return [...sites];
    });

    this.calcTotals();
  }

  toggleSites() {
    this.siteList.update(sites => {
      sites.forEach(s => s.selected = !s.selected);

      return [...sites];
    });

    this.calcTotals()
  }

  calcTotals() {
    this.totals.modules = this.moduleList ? this.moduleList.filter(m => m.selected).length : 0;
    this.totals.sites = this.siteList().filter(m => m.selected).length;
  }

  toggleModule(module) {
    module.selected = !module.selected;
    this.calcTotals();
  }

  cancel() {
    this.messageService.add({ severity: 'info', summary: 'Cancelled', detail: '' });
    this.goBack();
  }

  goBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  updated() {
  }

  inviteUser() {
    if (this.isInviting) {
      this.messageService.add({ severity: 'warn', summary: 'Already sending invite', detail: '' });
      return;
    }

    if (this.invite.email.length < 10 || !this.invite.email.includes('@') || !this.invite.email.includes('.')) {
      this.messageService.add({ severity: 'warn', summary: 'Enter a valid email', detail: '' });
      return;
    }

    if (this.invite.name.length < 2) {
      this.messageService.add({ severity: 'warn', summary: 'Enter a valid name', detail: '' });
      return;
    }

    if (this.totals.sites === 0) {
      this.messageService.add({ severity: 'warn', summary: 'No sites selected', detail: '' });
      return;
    }
    if (this.totals.modules === 0) {
      this.messageService.add({ severity: 'warn', summary: 'No modules selected', detail: '' });
      return;
    }

    this.isInviting = true;

    const invite = this.invite;
    invite.sites = this.siteList().filter(s => s.selected).map(s => s.value);
    invite.modules = this.moduleList.filter(m => m.selected).map(m => m.value);
    invite.expiresAt = this.expiresAt ? this.apiService.dbDate(this.expiresAt) : null;
    invite.isTrackable = this.isTrackable ? 1 : 0;
    try {
      this.userService.inviteUserToOrg(invite).then(status => {
        if (status && status.error) {
          this.messageService.add({ severity: 'error', summary: 'There was a problem', detail: 'Error inviting, please try later' });
          return;
        }
        this.messageService.add({ severity: 'success', summary: 'Invite emailed', detail: '' });
        console.log(status);
        this.isInviting = false;
        this.goBack();
      });
    } catch (e) {
      console.log(e);
      this.apiService.toastWarn('Invite failed', '');
      this.isInviting = false;
      this.goBack();
    }
  }
}

export interface ISiteItem {
  label: string;
  value: number;
  selected: boolean;
  tooltip: string;
}
