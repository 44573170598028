<div [class.caption]="_caption()">
  @if (_caption()) {
    <label>{{ _caption() }}</label>
  }
  <app-button [size]="size()" [label]="label()" (click)="_onDialog.set(true)"></app-button>
</div>

@if (_onDialog()) {
  <app-dialog [canScroll]="false" (onClose)="closed($event)" [dimensions]="{ width: 660, height: 350 }">
    <app-toolbar>
      <div class="right">
        <app-button size="medium" (click)="clear()"> <i class="mdi mdi-eraser"></i>&nbsp; Clear </app-button>
        <app-button size="medium" (click)="save()"> <i class="mdi mdi-check"></i>&nbsp; Use Selected </app-button>
      </div>
    </app-toolbar>

    @if (_sites().length) {
      <div class="mb-1">
        <app-select [value]="_config().siteId" [list]="_sites()" caption="Site" (onSelected)="siteSelected($event)" />
      </div>
    }

    <section class="dates">
      @if (_config().dates) {
        <app-calendar [value]="_config().dates.from" (onSelect)="changeDate('from', $event)"></app-calendar>
        <app-calendar [value]="_config().dates.to" (onSelect)="changeDate('to', $event)"></app-calendar>
      }
    </section>
  </app-dialog>
}
