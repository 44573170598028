import moment from 'moment';
import { Asset } from "./asset";
import { Gateway } from "./gateway";
import { Telemetry } from "./telemetry";
import { LimitLine } from 'app/setpoints/setpoint-query/setpoint-query.component';
import { CombinedCollectionItem } from 'app/shared/site.service';
import { CollectionAsset } from './collection-for-assets';
import { IGrandTotals } from 'app/insights/aq-collections/aq-collections-report/aq-report-type/aq-report-type.component';
import { Building } from './building';

export class Insight {
    // Configuration
    configuration: IConfiguration;

    types: IInsightType = {
        "pm1": {
            measurement: 'µg/m3',
            alarms: {
                low: null,
                normal: { to: null, from: 10 },
                high: { warn: { from: 35, to: null }, action: { from: 10, to: 35 } }
            },
            total: 0, count: 0, avg: 0, typeid: [62], min: null, max: null, colour: null,
            title: 'PM1',
            description: "Particles <1 μm in size. Examples: dust, combustion particles*, bacteria and viruses. Exposure can trigger asthma and allergies, as well as cause irritation of eyes, ears, nose and throat. High levels can indicate problems with your filters or ventilation system."
        },
        "pm2.5": {
            measurement: 'μg/m3',
            alarms: {
                low: null,
                normal: { to: null, from: 10 },
                high: { warn: { from: 35, to: null }, action: { from: 10, to: 35 } }
            },
            total: 0, count: 0, avg: 0, typeid: [29], min: null, max: null, colour: null,
            title: 'PM2.5',
            description: "Particles <2.5 μm in size. Examples: pollen, spoors and other organic particles.  Exposure can trigger asthma and allergies, as well as cause irritation of eyes, ears, nose and throat. High levels can indicate problems with your filters or ventilation system."
        },
        "pm10": {
            measurement: 'μg/m3',
            alarms: {
                low: null,
                normal: { to: null, from: 10 },
                high: { warn: { from: 35, to: null }, action: { from: 10, to: 35 } }
            },
            total: 0, count: 0, avg: 0, typeid: [30], min: null, max: null, colour: null,
            title: 'PM10',
            description: "particles <10 μm in size. Examples: coarser fine dust and organic particles. Exposure can trigger asthma and allergies, as well as cause irritation of eyes, ears, nose and throat. High levels can indicate problems with your filters or ventilation system."
        },
        co2: {
            measurement: 'ppm',
            alarms: {
                low: null,
                normal: { from: 800, to: null },
                high: { action: { from: 800, to: 1000 }, warn: { from: 1000, to: null } }
            },
            total: 0, count: 0, avg: 0, typeid: [25], min: null, max: null, colour: null,
            title: 'CO₂',
            description: "Carbon dioxide (CO₂) is an important consideration when it comes to comfort and productivity. Air with high levels of CO₂ can lead to difficulty concentrating, decreased cognitive ability, and fatigue. Typically, CO₂ levels outdoors are around 400 parts per million (ppm). Concentrations below 800 ppm are considered ideal for a healthy and productive workspace. To reduce your CO₂ levels increase space ventilation."
        },
        humidity: {
            measurement: '%',
            alarms: {
                low: { action: { from: 25, to: 35 }, warn: { from: 25, to: null } },
                normal: { from: 30, to: 60 },
                high: { action: { from: 60, to: 70 }, warn: { from: 70, to: null } }
            },

            total: 0, count: 0, avg: 0, typeid: [8], min: null, max: null, colour: null,
            title: 'Humidity',
            description: "Humidity has a significant impact on comfort, respiratory health, and infectious disease transmission. Humidity levels between 30-60% are considered optimal. This range is recommended especially for those with allergies, asthma, or other respiratory illnesses. Maintaining humidity within these levels can also minimize the growth and spread of mold, viruses, and bacteria."
        },
        temperature: {
            measurement: '°',
            alarms: {
                low: { action: null, warn: { from: 18, to: null } },
                normal: { from: 18, to: 25 },
                high: { action: null, warn: { from: 25, to: null } }
            },
            total: 0, count: 0, avg: 0, typeid: [2], min: null, max: null, colour: null,
            title: 'Temperature',
            description: "The temperature is an important component of occupant comfort and productivity. The optimal temperature is in the range of 18-25°C. An indoor temperature either above or below this range will reduce the overall indoor air quality rating."
        },
        voc: {
            measurement: 'ppb',
            alarms: {
                low: null,
                normal: { from: 250, to: null },
                high: { action: { from: 250, to: 2000 }, warn: { from: 2000, to: null } }
            },
            total: 0, count: 0, avg: 0, typeid: [27], min: null, max: null, colour: null,
            title: 'VOC',
            description: "Total Volatile Organic Compounds (VOCs) are a diverse group of chemicals that are commonly found in the air in homes and offices. Common sources of VOCs include paints (such as formaldehyde), lacquers, cleaning supplies, furnishings, office equipment, glues, alcohol, and human breath. Moderate levels of exposure can cause headaches, fatigue, eye and throat irritation, and other symptoms that can affect comfort and concentration. In order to reduce the VOC levels, improve ventilation and identify and remove the potential sources."
        }
    };
    typeList = ['co2', 'humidity', 'temperature', 'voc', 'pm1', 'pm2.5', 'pm10'];
    typeIndex = { 'co2': 0, 'humidity': 1, 'temperature': 2, 'voc': 3, 'pm1': 4, 'pm2.5': 5, 'pm10': 6 };
    telemetry: Telemetry[];
    telemetryCollection: any[];
    gateways: Gateway[];
    generatedAt: Date;
    collections: CombinedCollectionItem[];
    collectionAssets: CollectionAsset[];

    constructor() { }

    setAlarmsFromBuilding(building: Building) {
        const aq = building.aqConfig;
        if (!aq) {
            return;
        }
        let config = aq.config;
        if (typeof config === 'string') {
            config = JSON.parse(config);
        }
        Object.keys(this.types).forEach(key => {
            this.types[key].alarms = config.find(item => item.id === key).alarms;
        });
    }

    setGrandGrandTotals(grandTotals: IGrandTotals[]) {
        grandTotals.forEach((element, index) => {
            if (element?.count) {
                const key = this.typeList[index];
                //        total: 0, count: 0, avg: 0, typeid: [62], min: null, max: null, colour: null,
                this.types[key].count = element.count;
                this.types[key].min = element.min;
                this.types[key].max = element.max;
                this.types[key].avg = element.avg;
            }
        });
    }

    calculateTotals() {
        this.types.co2.avg = Math.floor(this.types.co2.total / this.types.co2.count);
        this.types.temperature.avg = Math.floor(this.types.temperature.total / this.types.temperature.count);
        this.types.humidity.avg = Math.floor(this.types.humidity.total / this.types.humidity.count);
        this.types.voc.avg = Math.floor(this.types.voc.total / this.types.voc.count);
    }

    getGatewaysForType(type: 'voc' | 'co2' | 'temperature' | 'humidity' | 'pm1' | 'pm2.5' | 'pm10'): IGetGateways[] {
        const typeId = this.types[type].typeid[0];
        const typeIndex = this.typeList.findIndex(t => t === type);

        const gateways = [];
        this.gateways.forEach(gateway => {
            const asset = gateway.assets.list.filter(a => a.assetType_id === typeId)[0];
            /*if (asset) {
                // only use one asset currently.
                const totals = { count: 0, sum: 0, avg: 0, min: 999999, max: -99999 };
                this.telemetry.filter(t => t.i === asset.id).forEach(t => {
                    totals.count++;
                    totals.sum += +t.v;
                    if (+t.v < totals.min) totals.min = +t.v;
                    if (+t.v > totals.max) totals.max = +t.v;
                });
                totals.avg = Math.floor(totals.sum / totals.count);
                const payload = { gateway, asset, totals };
                gateways.push(payload);
            }*/
            if (asset) {
                const totals = this.telemetryCollection[typeIndex].monthTotals.assets[asset.id];

                gateways.push({ gateway, asset, totals });
            } else {
                console.log('NO_ASSET', typeId);
            }
        });

        return gateways;
    }


    /**
     * @deprecated use getCollectionAssetsForType
     */
    getCollectionsForType(type: 'voc' | 'co2' | 'temperature' | 'humidity' | 'pm1' | 'pm2.5' | 'pm10'): IGetGateways[] {
        const typeId = this.types[type].typeid[0];
        const typeIndex = this.typeList.findIndex(t => t === type);

        const gateways = [];
        this.collections.forEach(collection => {
            const asset = collection.assets.filter(a => a.typeId === typeId)[0];
            if (asset) {
                const totals = this.telemetryCollection[typeIndex].monthTotals.assets[asset.id];

                gateways.push({ gateway: collection, asset, totals });
            } else {
                console.log('NO_ASSET', typeId);
            }
        });

        return gateways;
    }

    getCollectionAssetsForType(type: 'voc' | 'co2' | 'temperature' | 'humidity' | 'pm1' | 'pm2.5' | 'pm10'): IGetCollectionAsset[] {
        const typeId = this.types[type].typeid[0];
        const typeIndex = this.typeList.findIndex(t => t === type);

        const response = [];
        this.collectionAssets.forEach(collection => {
            const assets = collection.assets.filter(a => a.assetType_id === typeId);
            for (let index = 0; index < assets.length; index++) {
                const asset = assets[index];
                const telemetryItem = this.telemetryCollection[typeIndex].find(item => item.asset.id === asset.id);
                if (telemetryItem) {
                    // The asset has data
                    const totals = telemetryItem.monthTotals.grandtotal;

                    response.push({ collection: { id: collection.id, title: collection.title }, asset, totals, telemetry: telemetryItem.telemetry.map(t => { return { i: t.i, v: +t.v, d: new Date(t.d) }; }) });
                }
            }
        });

        return response;
    }

    setTelemetry(telemetryCollection: IInsightAPITypeItem[]) {
        // Clean up telemetry, make value a number and timestamp a date
        for (let index = 0; index < telemetryCollection.length; index++) {
            //  Convert date to object
            if (telemetryCollection[index]?.telemetry) {
                telemetryCollection[index].telemetry = telemetryCollection[index].telemetry.map(t => {
                    return { i: t.i, v: +t.v, d: new Date(t.d) };
                });
            }
        }

        for (let index = 0; index < telemetryCollection.length; index++) {
            if (telemetryCollection[index]?.telemetry) {
                const c = telemetryCollection[index];
                const typeKey: 'co2' | 'humidity' | 'temperature' | 'voc' | 'pm1' | 'pm2.5' | 'pm10' = <any>this.typeList[index];
                // Move grand totals from the API to the types collections
                const type: IInsightTypeItem = this.types[typeKey];
                type.avg = c.monthTotals.grandtotal.avg;
                type.count = c.monthTotals.grandtotal.count;
                type.min = c.monthTotals.grandtotal.min;
                type.max = c.monthTotals.grandtotal.max;

                // Assign colours to totals
                const alarms = type.alarms;
                type.colour = this.getAlarmColourForValue(typeKey, type.avg);
                Object.keys(c.monthTotals.assets).forEach(assetKey => {
                    const asset = c.monthTotals.assets[assetKey];
                    asset.colours = {
                        avg: this.getAlarmColourForValue(typeKey, asset.avg),
                        min: this.getAlarmColourForValue(typeKey, asset.min),
                        max: this.getAlarmColourForValue(typeKey, asset.max)
                    };
                });
            }
        }

        this.telemetryCollection = telemetryCollection;

        // Assign the warnings
    }

    setConfiguration(configuration: IConfiguration) {
        const parts = configuration.title.split(' from ');
        const startString = parts[1].split(' ')[0];
        const endString = parts[1].split(' ')[2];
        configuration.start = moment(startString, "DD/MM/YYYY");
        configuration.end = moment(endString, "DD/MM/YYYY");
        this.configuration = configuration;

    }

    /**
     * Return the collection for the asset type
     * @param type 
     * @returns 
     */
    getTelemetryForType(type: 'voc' | 'co2' | 'temperature' | 'humidity') {
        const types = { "co2": 0, "humidity": 1, "temperature": 2, "voc": 3 };

        return this.telemetryCollection[types[type]];
    }

    getTelemetryAverageForAssets(assetIds: number[]): Telemetry[] {

        console.log('fixing telemetry');
        const telemetry = this.telemetry.filter(t => assetIds.includes(t.i));

        const telemetryAverages: Telemetry[] = [];

        let currentDate = telemetry[0].d.toISOString().substr(0, 13);
        let currentValue = 0;
        let currentCount = 0;
        // The asset that starts the hour range
        let assetIndex = 0
        for (let index = 0; index < telemetry.length; index++) {
            const element = telemetry[index];
            const compareDate = element.d.toISOString().substr(0, 13);
            if (compareDate !== currentDate) {
                const v = Math.floor(currentValue / currentCount);
                const d = new Date(telemetry[assetIndex].d);
                d.setSeconds(0);
                d.setMinutes(0);
                d.setMilliseconds(0);

                telemetryAverages.push({ i: assetIndex, v, d })
                currentValue = 0;
                currentDate = compareDate;
                currentCount = 0;
                assetIndex = index;
            }
            currentValue += element.v;
            currentCount++;
        }
        // Add last hour
        if (currentCount) {
            const v = Math.floor(currentValue / currentCount);
            const d = new Date(telemetry[assetIndex].d);
            d.setSeconds(0);
            d.setMinutes(0);
            d.setMilliseconds(0);

            telemetryAverages.push({ i: assetIndex, v, d });
        }
        console.log('done');

        return telemetryAverages;
    }



    getAlarmColourForValue(type: 'voc' | 'co2' | 'temperature' | 'humidity' | 'pm1' | 'pm2.5' | 'pm10', value): 'green' | 'amber' | 'red' {
        const { alarms } = this.types[type];

        let colour: 'green' | 'amber' | 'red' = 'green';

        if (alarms.low?.warn?.from) {
            if (value < alarms.low.warn.from) colour = 'red';
        }
        if (!alarms.low?.warn?.from && alarms.low?.action) {
            if (value < alarms.low.action.from) colour = 'amber';
        }
        if (colour === 'green' && alarms.low?.warn?.from && alarms.low?.action?.from) {
            if (value >= alarms.low.action.from && value < alarms.low.action.to) colour = 'amber';
        }

        if (colour === 'green' && alarms.high?.warn?.from && alarms.high?.action?.from) {
            if (value >= alarms.high.action.from && value < alarms.high.action.to) colour = 'amber';
        }
        if (colour === 'green' && !alarms.high?.warn?.from && alarms.high?.action) {
            if (value >= alarms.high.action.from) colour = 'amber';
        }
        if (colour !== 'red' && alarms.high?.warn?.from) {
            if (value >= alarms.high.warn.from) colour = 'red';
        }

        return colour;
    }

    getLimitLines(type: 'voc' | 'co2' | 'temperature' | 'humidity' | 'pm1' | 'pm2.5' | 'pm10'): LimitLine[] {
        const { alarms } = this.types[type];
        let ll: LimitLine[] = [];

        const dayOfWeek = [true, true, true, true, true, true, true];

        if (alarms.low?.warn?.from) {
            ll.push({
                dayOfWeek,
                startTimeAt: "00:00",
                endTimeAt: "23:59",
                text: 'warning',
                colour: 'red',
                value: alarms.low.warn.from
            });
        }
        if (!alarms.low?.warn?.from && alarms.low?.action) {
            ll.push({
                dayOfWeek,
                startTimeAt: "00:00",
                endTimeAt: "23:59",
                text: 'warning',
                colour: 'orange',
                value: alarms.low.action.from
            });
        }

        if (alarms.low?.warn?.from && alarms.low?.action?.from) {
            ll.push({
                dayOfWeek,
                startTimeAt: "00:00",
                endTimeAt: "23:59",
                text: 'warning',
                colour: 'orange',
                value: alarms.low.action.to
            });
        }

        if (alarms.high?.warn?.from && alarms.high?.action?.from) {
            ll.push({
                dayOfWeek,
                startTimeAt: "00:00",
                endTimeAt: "23:59",
                text: 'warning',
                colour: 'orange',
                value: alarms.high.action.from
            });


        }
        if (!alarms.high?.warn?.from && alarms.high?.action) {
            ll.push({
                dayOfWeek,
                startTimeAt: "00:00",
                endTimeAt: "23:59",
                text: 'warning',
                colour: 'orange',
                value: alarms.high.action.from
            });
        }
        if (alarms.high?.warn?.from) {
            ll.push({
                dayOfWeek,
                startTimeAt: "00:00",
                endTimeAt: "23:59",
                text: 'warning',
                colour: 'red',
                value: alarms.high.warn.from
            });
        }

        if (ll.length === 0) {
            ll = undefined;
        }
        return ll;
    }

    getAlarmsForType(type: 'voc' | 'co2' | 'temperature' | 'humidity' | 'pm1' | 'pm2.5' | 'pm10'): IAlarmBlock[] {
        const alarmBlocks: IAlarmBlock[] = [];
        const { alarms, measurement } = this.types[type];

        if (alarms.low?.warn?.from) {
            alarmBlocks.push({ section: 'low-warn', title: `< ${alarms.low.warn.from}${measurement}`, colour: 'red', header: 'Warning' });
        }
        if (!alarms.low?.warn?.from && alarms.low?.action) {
            alarmBlocks.push({ section: 'low-action', title: `< ${alarms.low.action.from}${measurement}`, colour: 'amber', header: 'Action' });
        }
        if (alarms.low?.warn?.from && alarms.low?.action?.from) {
            alarmBlocks.push({ section: 'low-action', title: `≥ ${alarms.low.action.from}${measurement} < ${alarms.low.action.to}${measurement}`, colour: 'amber', header: 'Action' });
        }

        if (!alarms.low?.warn?.from && !alarms.low?.action?.from) {
            alarmBlocks.push({ section: 'normal', title: `< ${alarms.normal.from}${measurement}`, colour: 'green', header: 'Normal' });
        }
        if (alarms.low?.warn?.from || alarms.low?.action?.from) {
            alarmBlocks.push({ section: 'normal', title: `≥  ${alarms.normal.from}${measurement} < ${alarms.normal.to}${measurement}`, colour: 'green', header: 'Normal' });
        }

        if (alarms.high?.warn?.from && alarms.high?.action?.from) {
            alarmBlocks.push({ section: 'high-action', title: `≥ ${alarms.high.action.from}${measurement} < ${alarms.high.action.to}${measurement}`, colour: 'amber', header: 'Action' });
        }
        if (!alarms.high?.warn?.from && alarms.high?.action) {
            alarmBlocks.push({ section: 'high-action', title: `≥ ${alarms.high.action.from}${measurement}`, colour: 'amber', header: 'Action' });
        }
        if (alarms.high?.warn?.from) {
            alarmBlocks.push({ section: 'high-warn', title: `≥ ${alarms.high.warn.from}${measurement}`, colour: 'red', header: 'Warning' });
        }

        return alarmBlocks;
    }

}

export interface IInsightAPITypeItem {
    monthTotals: {
        assets: any,
        grandtotal: {
            count: number, avg: number, value: number, min: number, max: number
        }
    },
    telemetry: Telemetry[]
};

export interface IAlarmBlock {
    section: string;
    title: string;
    colour: string;
    header: string;
}

export interface IGetGateways {
    gateway: Gateway;
    asset: Asset;
}

export interface IGetCollectionAsset {
    collection: { id: number, title: string };
    asset: Asset;
    totals: { count: number, value: number, avg: number, min: number, max: number };
    telemetry: any;
}

export interface IInsightTypeItem {
    measurement: string;
    title: string;
    alarms: {
        low: {
            warn: { from: number, to: number },
            action: { from: number, to: number }
        },
        normal: { from: number, to: number },
        high: {
            warn: { from: number, to: number },
            action: { from: number, to: number }
        }
    }
    , total: number, count: number, avg: number, min: number, max: number, typeid: number[], description: string, colour: string
};

export interface IInsightType {
    co2: IInsightTypeItem,
    humidity: IInsightTypeItem,
    temperature: IInsightTypeItem,
    voc: IInsightTypeItem,
    pm1?: IInsightTypeItem,
    "pm2.5"?: IInsightTypeItem,
    pm10?: IInsightTypeItem,
}

export interface IConfiguration {
    // Assets used in telemetry
    assets: number[];
    // The start date for the month
    start: any; // moment
    end: any; // moment
    // The date ranges used to pull the telemetry
    ranges: IConfigurationRange[];
    // The types included in the telemetry
    types: IConfigurationType[];
    title: string;
}

export interface IConfigurationRange {
    from: Date | string;
    to: Date | string;
    doy?: number;
}

export interface IConfigurationType {
    id: number;
    type: number;
    title: string;
    g: string;
}
