import { Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { Site } from 'app/classes/site';
import { SitePlan } from 'app/classes/site-plan';
import { APIService } from 'app/shared/api.service';
import { SiteFloorplanDashboard } from "app/classes/site-floorplan-dashboard";
import { AssetService } from 'app/shared/asset.service';
import moment from 'moment';
import { IDatesFromTo } from 'app/interfaces/dates-from-to';
import { OccupancyService } from 'app/shared/occupancy.service';
import { ITenantDateConfig } from './button-site-plan-dashboard-config/button-site-plan-dashboard-config.component';
import { IAssetSimple } from 'app/classes/asset';

@Component({
    selector: 'app-site-plan-dashboard',
    templateUrl: './site-plan-dashboard.component.html',
    styleUrls: ['./site-plan-dashboard.component.css'],
    standalone: false
})
export class SitePlanDashboardComponent implements OnInit {

  settings: { tenants: any[], services: any[] };
  _site: Site;
  @Input()

  public set site(site: Site) {
    this._site = site;
    if (site) {
      this.occupancyService.getSettingsForSite(site.id).then(r => {
        this.settings = r;
        this.get();
      });

    }
  }

  public get site(): Site {
    return this._site;
  }

  @Input()
  plans: SitePlan[];

  selectedAsset: any;
  selectedShape: any;
  selectedTenant: number;
  dashboard: SiteFloorplanDashboard;
  sort: { current: any } = { current: {} };
  tabIndex = 0;

  datesFromTo: IDatesFromTo = { from: null, to: null };

  public set dates(d: IDatesFromTo) {
    this.datesFromTo = d;
    if (d) {
      this.setLocalStorage();
    }
  }

  fromDate: Date;
  toDate: Date;
  tenant: string;
  isMaximised: boolean;
  isReady: boolean = false;
  state: 'month' | 'custom' = 'month';
  en =
    {
      firstDayOfWeek: 1,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["S", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    };

  @Output() message = new EventEmitter<string>();

  isAdmin = signal<boolean>(false);
  isShowingDialog = signal<'rebuild'>(null);
  isRebuilding = signal<boolean>(false);
  assetsToRebuild = signal<IAssetToRebuild[]>([]);
  assets = signal<IAssetSimple[]>(null);

  constructor(private assetService: AssetService, private apiService: APIService, private occupancyService: OccupancyService) {
    this.isAdmin.set(apiService.isAdmin());
  }

  customDatesChanged() {
    if (!this.isReady) {
      return;
    }
    console.log('CUSTOM_DATES_CHANGED');
    this.dates = this.datesFromTo;
    //  this.occupancyService.toolbarDatesChanged(this.datesFromTo);
  }

  toolbarGo(config?: ITenantDateConfig) {
    if (config) {
      this.datesFromTo = config.dates;
      this.selectedTenant = config.tenantId;
    }
    this.setLocalStorage();
    let tenant;
    if (this.selectedTenant) {
      tenant = this.settings.tenants.find(t => +t.id === +this.selectedTenant);
    }
    this.occupancyService.toolbarChanged({ tenant, datesFromTo: this.datesFromTo });
  }

  maximise() {
    this.isMaximised = true;
  }

  get() {
    console.log(this.site);
    this.apiService
      .postQuery({ t: 'sites', action: 'site-floorplan-dashboard', id: this._site.id })
      .then(r => {
        this.dashboard = new SiteFloorplanDashboard({ current: r });
        this.getLocalStorage();
        this.isReady = true;
        if (this.selectedTenant) {
          this.occupancyService.toolbarChanged({ tenant: this.settings.tenants.find(t => t.id === +this.selectedTenant), datesFromTo: this.datesFromTo });
        } else {
          this.occupancyService.toolbarDatesChanged(this.datesFromTo);
        }
        this.sortData();
      });
  }

  ngOnInit(): void {
  }

  sortData() {
    if (this.sort) {
      let { field, state } = this.sort.current;
      const stateInt = state === 'asc' ? 1 : -1;
      switch (field) {
        case 'updatedAt':
          this.dashboard.current.sort((a, b) => a.asset.updatedAt > b.asset.updatedAt ? stateInt : stateInt * -1);
          break;
        case 'status':
          this.dashboard.current.sort((a, b) => a.asset.value > b.asset.value ? stateInt : stateInt * -1);
          break;
        case 'floor':
          this.dashboard.current.sort((a, b) => a.plan.floor > b.plan.floor ? stateInt : stateInt * -1);
          break;
      }
    }
  }

  headerClicked(table: string, fieldClicked: string) {
    let { field, state } = this.sort[table];

    if (field !== fieldClicked) {
      state = 'asc';
    } else {
      switch (state) {
        case 'asc':
          state = 'desc';
          break;
        case 'desc':
          // Default sort
          state = 'asc';
          fieldClicked = 'floor';
          break;
        default:
          state = 'asc';
          break;
      }
    }

    if (state) {
      this.sort[table] = { field: fieldClicked, state };
    } else {
      this.sort[table] = {};
    }

    localStorage.setItem('site:plan:dashboard:sort', JSON.stringify(this.sort));
    this.sortData();
  }

  selectDashboardItem(item) {
    // this.selectedShape = { id: item.shape.id, title: item.shape.title };
    this.assetService.getAsset(item.asset.id).then(asset => this.selectedAsset = asset);
  }

  handleTabChange(event) {
    console.log(event);
    this.setLocalStorage();
  }

  hourlyClick() {

  }

  export() {
    this.message.emit('export');
  }
  /*
    previousWeek() {
      if (!this.dates?.from) {
        return;
      }
      this.dates = {
        from: moment(this.dates.from).subtract(1, 'week').isoWeekday(1).startOf('isoWeek').startOf('day').toDate(),
        to: moment(this.dates.from).subtract(1, 'week').isoWeekday(1).endOf('isoWeek').endOf('day').toDate()
      };
    }
  
    nextWeek() {
      this.dates = {
        from: moment(this.fromDate).add(1, 'week').isoWeekday(1).startOf('isoWeek').startOf('day').toDate(),
        to: null
      };
    }
  */
  previousMonth() {
    if (!this.datesFromTo?.from) {
      return;
    }
    // Handle previous from selecting "today"
    const from = moment(this.datesFromTo.from).subtract(1, 'month').startOf('month').startOf('day').toDate();
    const to = moment(this.datesFromTo.to).subtract(1, 'month').endOf('month').endOf('day').toDate();

    this.dates = {
      from,
      to
    };

    this.toolbarGo();
  }

  nextMonth() {
    if (!this.datesFromTo?.from) {
      return;
    }
    this.datesFromTo = {
      from: moment(this.datesFromTo.from).add(1, 'month').toDate(),
      to: moment(this.datesFromTo.to).add(1, 'month').toDate()
    };
    this.toolbarGo();
  }

  today() {
    this.datesFromTo = {
      from: moment().startOf('month').startOf('day').toDate(),
      to: moment().endOf('day').toDate()
    };
    this.toolbarGo();
  }

  yesterday() {

    //this.date = moment().subtract(1, 'day').startOf('day').toDate();
  }

  printPage() {
    window.print();
  }

  setLocalStorage() {
    const storageString = JSON.stringify({ tabIndex: this.tabIndex, fromDate: +this.fromDate, dft: { from: +this.datesFromTo.from, to: +this.datesFromTo.to }, tid: this.selectedTenant || null });
    localStorage.setItem('site-plan-dashboard', storageString);
  }

  getLocalStorage() {
    try {
      const sort = localStorage.getItem('site:plan:dashboard:sort');
      if (sort) {
        this.sort.current = JSON.parse(sort);
      }

      const storageString = localStorage.getItem('site-plan-dashboard');
      if (storageString) {
        const storageObject = JSON.parse(storageString);
        this.tabIndex = storageObject.tabIndex;

        if (storageObject.dft) {
          this.datesFromTo = { from: new Date(storageObject.dft.from), to: new Date(storageObject.dft.to) };
          storageObject.tid = storageObject.tid && +storageObject.tid;
          this.selectedTenant = storageObject.tid || null;
        } else {
          this.fromDate = new Date(storageObject.fromDate);
        }
      } else {
        this.fromDate = moment().subtract(1, 'day').startOf('day').toDate();
      }
    } catch (e) {
      console.log(e);
    }
  }

  wantsToRebuild() {
    this.isShowingDialog.set('rebuild');
    this.assetsToRebuild.set(this.assets().map(a => { return { id: a.id, title: a.title, state: 'todo' } }));
  }


  getNextAsset() {
    const todo = this.assetsToRebuild().find(asset => asset.state === 'todo');

    if (!todo) {
      this.isRebuilding.set(false);
      this.apiService.toastSuccess('Rebuild done', '');

      return;
    }

    this.assetsToRebuild.update(assets => {
      const asset = assets.find(a => a.id === todo.id);
      if (!asset) {
        // fatal error
        debugger;
      }
      asset.state = 'processing';
      return assets;
    });

    setTimeout(() => {
      // process asset
      this.occupancyService.rebuildHourlyForAsset(todo.id, this.datesFromTo.from, this.datesFromTo.to, true).then(_ => {
        this.assetsToRebuild.update(assets => {
          const asset = assets.find(a => a.id === todo.id);
          if (!asset) {
            // fatal error
            debugger;
          }
          asset.state = 'done';
          return assets;
        });

        this.getNextAsset();
      });

    }, 1000);
  }

  async startRebuild() {
    // this.assetsToRebuild = this.ass

    this.apiService.toastSuccess('Rebuilding...', '');
    this.getNextAsset();
  }

  assetsReceived(assets: IAssetSimple[]) {
    console.log(assets);
    this.assets.set(assets);

  }
}

export interface IAssetToRebuild {
  id: number;
  title: string;
  state: 'todo' | 'processing' | 'done';
}
