import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-dashboard-tile-data-telemetry',
    templateUrl: './dashboard-tile-data-telemetry.component.html',
    styleUrls: ['./dashboard-tile-data-telemetry.component.css'],
    standalone: false
})
export class DashboardTileDataTelemetryComponent implements OnInit {

  @Input()
  public get recent(): any {
    return this._recent;
  }
  public set recent(v: any) {
    this.hasValue = !!v;
    this._recent = v;
    setTimeout(() => {
      this.hasValue = false;
    }, 1500);
  }

  hasValue = false;
  _recent: any;

  constructor() { }

  ngOnInit() {
  }

}
