import { Component, Input, signal } from '@angular/core';

@Component({
    selector: 'app-panel',
    templateUrl: './panel.component.html',
    styleUrl: './panel.component.css',
    standalone: false
})
export class PanelComponent {

  _title = signal('');

  @Input()
  public set title(v: string) {
    this._title.set(v);
  }

  @Input()
  public set label(v: string) {
    this._title.set(v);
  }

  @Input()
  public set header(v: string) {
    this._title.set(v);
  }


}
