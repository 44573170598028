import { Component, OnInit } from '@angular/core';
import { Ticket } from '../../classes/ticket';
import { APIService } from '../../shared/api.service';
import { Router, Route, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-ticket-view',
    templateUrl: './ticket-view.component.html',
    styleUrls: ['./ticket-view.component.css'],
    standalone: false
})
export class TicketViewComponent implements OnInit {

  ticket: Ticket;
  usersQuestion: string;
  hasSentMessage: boolean;

  constructor(private apiService: APIService, private router: Router, private route: ActivatedRoute) {
    route.params.subscribe(params => {
      apiService.getTicket(params['ticketid'])
        .then(ticket => this.ticket = ticket);
    });
  }

  ngOnInit() {
  }

  sendQuestion() {
    this.apiService.submitMessageToTicket(this.ticket.id, this.usersQuestion)
      .then(results => {
        console.log(results);
        this.hasSentMessage = true;

      });
  }

}
