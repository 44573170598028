import { Component, OnInit } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import moment from 'moment';

@Component({
    selector: 'app-dialog-date-range',
    templateUrl: './dialog-date-range.component.html',
    styleUrls: ['./dialog-date-range.component.css'],
    providers: [DialogService],
    standalone: false
})
export class DialogDateRangeComponent implements OnInit {
  dates: { from: Date, to: Date, min: Date | null, max: Date };
  dateFrom: Date;
  dateTo: Date;
  asset: Asset | null;

  constructor(public dialogService: DialogService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
  }

  ngOnInit(): void {
    this.dates = this.config.data || { from: new Date(), to: new Date(), min: null, max: new Date() };
    this.dateFrom = this.dates.from;
    this.dateTo = this.dates.to;
    this.asset = this.config.data?.asset || null;

    if (this.asset) {
      if (this.dateFrom < this.asset.createdAt) {
        this.dateFrom = this.asset.createdAt;
      }
      if (this.dateTo > this.asset.updatedAt) {
        this.dateTo = this.asset.updatedAt;
      }
    }
  }

  assetStart() {
    /* move dates to force a redisplay */
    if (+this.dateFrom === +this.asset.createdAt) {
      this.dateFrom = new Date();
    }

    setTimeout(() => {
      this.dateFrom = this.asset.createdAt;
    }, 1);
  }

  assetEnd() {
    /* move dates to force a redisplay */
    if (+this.dateTo === +this.asset.updatedAt) {
      this.dateTo = new Date(+this.dateTo + 86400000);
    }
    setTimeout(() => {
      this.dateTo = this.asset.updatedAt;
    }, 1);
  }

  cancel() {
    this.ref.close();
  }

  submit() {
    let from = moment(this.dateFrom).startOf('day').toDate();
    let to = moment(this.dateTo).endOf('day').toDate();
    if (from.getTime() > to.getTime()) {
      // Ensure from is before to
      [to, from] = [from, to];
    }
    this.ref.close({ from, to });
  }

  update() {
    this.ref.close(this.dates);
  }
}
