import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssetService } from 'app/shared/asset.service';
import moment from 'moment';
import * as c3 from 'c3';
import { Asset } from 'app/classes/asset';
import { ChartService } from 'app/shared/chart.service';
import { AssetChart } from 'app/classes/charts';
import { APIService } from 'app/shared/api.service';

@Component({
    selector: 'app-c3-chart',
    templateUrl: './c3-chart.component.html',
    styleUrls: ['./c3-chart.component.css'],
    standalone: false
})
export class C3ChartComponent implements OnInit, AfterViewInit {
  fav: AssetChart;

  chartid = 'chart_' + (+(new Date()));

  @Output()
  onLayoutChange: EventEmitter<boolean> = new EventEmitter<boolean>();


  @Input()
  days = 1;

  @Input()
  asset: Asset;

  _assetChart: AssetChart;
  @Input()
  public get assetChart(): AssetChart {
    return this._assetChart;
  }
  public set assetChart(v: AssetChart) {
    this.assetService._getAsset(v.id).then(asset => {
      this.asset = asset;
      this._assetChart = v;
      this.fav = v;
      this.chartid = 'chart_asset_' + asset.id;
      this.buildChart();
    });
  }

  isLoading: boolean;

  constructor(
    private apiService: APIService,
    private assetService: AssetService,
    private chartService: ChartService) { }

  ngOnInit() {
    if (this.asset && !this._assetChart) {
      // We have an asset see if it is a favourite
      this.runChecks();
    }
  }

  ngAfterViewInit(): void {
    if (!this.asset) {
      return;
    }
    this.buildChart();
  }

  /**
   * Dont show the chart if no data
   */
  buildChart() {
    const startOfData = moment().subtract(this.days, 'days').startOf('day').toDate();
    const endOfPreviousDay = moment().subtract(this.days, 'days').endOf('day').toDate();

    const startOfToday = moment().startOf('day').toDate();
    const now = moment().toDate();

    const regions = [];
    // let previousDay = -1;
    // let region: any = null;

    this.assetService
      .getTelemetryForRange(this.asset.id, startOfData, now)
      .then(data => {
        this.isLoading = false;
        if (data.length === 0) {

          return;
        }

        const data1: any = [this.asset.title];
        const x: any = ['x'];
        let idx = 0;
        data.forEach(row => {
          data1.push(row.v);
          const dt = new Date(row.c);
          x.push(dt);
          idx++;
        });

        const showPoints = this.assetChart ? this.assetChart.showPoints : false;

        const chartOptions: any = {
          size: {
            height: 300
          },
          bindto: '#' + this.chartid,
          data: {

            x: 'x',
            columns: [
              x,
              data1,
            ]
          },
          point: {
            show: showPoints
          },



          axis: {
            x: {
              type: 'timeseries',
              tick: {
                format: '%d/%m %H:%M', //  format: '%d-%m-%Y',
                count: 4
              }
            }
          }
        };

        if (this.days === 1) {
          chartOptions.regions = [
            { axis: 'x', start: startOfData, end: endOfPreviousDay }
          ];
        }

        const chart = c3.generate(chartOptions);
      });
  }

  favClick() {
    this.chartService.toggleAssetChart(this.asset);
    this.runChecks();
  }

  sizeClick() {
    if (this.assetChart) {
      let size = this.assetChart.size;
      if (size === 3) {
        size = 6;
      } else if (size === 6) {
        size = 9;
      } else if (size === 9) {
        size = 12;
      } else {
        size = 3;
      }
      this.assetChart.size = size;
      this.chartService.saveFavs();
      this.onLayoutChange.emit(true);
    }
  }

  pointToggle() {
    this.assetChart.showPoints = !this.assetChart.showPoints;
    this.chartService.saveFavs();
    this.onLayoutChange.emit(true);
  }

  runChecks() {
    this.fav = <AssetChart>this.chartService.favs.charts.find(c => c.typeName === 'asset' && c.getId() === this.asset.id);
  }
}
