import { Component, Input, OnInit, input } from '@angular/core';
import { User } from 'app/classes/user';
import { StoreService } from 'app/shared/store.service';

@Component({
  selector: 'app-menus-left',
  templateUrl: './menus-left.component.html',
  styleUrls: ['./menus-left.component.css'],
  standalone: false
})
export class MenusLeftComponent implements OnInit {

  _moduleAccess: Set<string>;
  @Input()
  public set moduleAccess(v: Set<string>) {
    this._moduleAccess = v;
    if (!v) {
      return;
    }
    this.items = this.availableItems.filter(m => {
      return m.access ? (this._moduleAccess.has(m.access)) : true;
    });
  }

  @Input()
  public set user(user: User) {

    if (!user) {
      this._moduleAccess = null;
      this.items = [];
      return;
    }

    this._moduleAccess = user.modules;

    this.items = this.availableItems.filter(m => {
      if (m.access === 'camera') {
        if (!user.org.settings['has_cameras']) {
          return false;
        }
      }
      if (!m.access) {
        return true;
      }

      if (m.wildcard) {
        const lookFor = m.access.substring(0, m.access.length);

        return [...this._moduleAccess].some(v => v.startsWith(lookFor));
      } else {
        return this._moduleAccess.has(m.access);
      }
    });
  }

  alarmCount = input<number>(0);

  isTextMenu: boolean = true;

  items: IMenuLeftItem[];

  availableItems: IMenuLeftItem[] = [
    { icon: 'home', title: 'Home', route: '/dashboard', description: 'Home page' },
    { icon: 'chart-line', title: 'Live Feeds', route: '/live', access: 'live', description: 'Live feeds' },
    { icon: 'thermometer', title: 'Telemetry', route: '/telemetry', access: 'telemetry', description: 'Site data' },
    { icon: 'text-box-check-outline', title: 'Issue Tracking', route: '/issuetracking', access: 'issue_tracking', description: 'Track building review issues' },
    { icon: 'bullseye', title: 'Setpoints', route: '/setpoints', access: 'setpoints', description: 'Setpoints' },
    { icon: 'floor-plan', title: 'Floorplans', route: '/profiling', access: 'profiling', description: 'Floorplans' },
    { icon: 'file-chart-outline', title: 'Reports', route: '/reports', access: 'reports', description: 'Reports' },
    { icon: 'format-list-checks', title: 'Rules', route: '/rules', access: 'rules', hasAlarmCount: true, description: 'Rules and Alarms' },
    { icon: 'wifi', title: 'Realtime', route: '/realtime', access: 'realtime_map', description: 'Plot telemetry over a map in realtime' },
    { icon: 'human-male-female', title: 'Footfall', route: '/footfall', access: 'footfall' },
    { icon: 'lan', title: 'Organisation', route: '/org', access: 'organisation', description: 'Organisation settings' },
    { icon: 'crowd', title: 'Engagement', route: '/engagement', access: 'engagement', description: 'User engagement' },
    { icon: 'keyboard-outline', title: 'Engagement', route: '/engage', description: 'How organisations and people engage with sites', access: 'partnership_engagement' },
    { icon: 'google-analytics', title: 'Insights', route: '/insights', access: 'insights', wildcard: true, description: 'Insights into your data' },
    { icon: 'home-lightning-bolt-outline', title: 'Energy', description: 'Energy Consumption', route: '/energy', access: 'energy' },
    { icon: 'motion-sensor', title: 'Occupancy', route: '/occupancy', access: 'occupancy', description: 'Occupancy Administration' },
    { icon: 'camera', title: 'Camera', description: 'Camera', route: '/camera', access: 'camera' },
    { icon: 'head-cog-outline', title: 'Machine Learning', route: '/ml', access: 'machine_learning_', description: 'Machine Learning', size: 'condensed' },
    { icon: 'trophy-outline', title: 'Training', route: '/training', access: 'training', description: 'Training videos' },
    { icon: 'account-circle-outline', title: 'Account', route: '/profile', description: 'Your account details' },
    { icon: 'api', title: 'Developer', description: 'API configuration', route: '/developer', access: 'api' },
    { icon: 'shield-account-outline', title: 'Admin', description: '4D Staff Administration', route: '/admin', access: 'admin' }
  ];

  constructor(private storeService: StoreService) {
    storeService.menuAsText.subscribe(setting => {
      if (typeof setting === 'boolean') {
        this.isTextMenu = setting;
        console.log('CHANGED MENU');
      }
    });
  }

  ngOnInit(): void {

  }

  toggleMenu() {
    this.storeService.setMenuAsText(!this.isTextMenu);
    localStorage.setItem('lm:settings', JSON.stringify({ isTextMenu: this.isTextMenu }));
  }

  scroll(top: number, left: number) {
    window.scroll({
      top: top,
      left: left,
      behavior: 'smooth'
    });
  }
}

export class IMenuLeftItem {
  icon: string;
  route: string;
  title: string;
  description?: string;
  access?: string;
  hasAlarmCount?: true;
  size?: 'normal' | 'condensed';
  wildcard?: boolean;
}
