import { Component, OnInit } from '@angular/core';
import { BillingService } from 'app/shared/billing.service';

@Component({
    selector: 'app-billing-audit',
    templateUrl: './billing-audit.component.html',
    styleUrls: ['./billing-audit.component.css'],
    standalone: false
})
export class BillingAuditComponent implements OnInit {

  audit: any[];
  constructor(private billingService: BillingService) { }

  ngOnInit(): void {
    this.billingService.getAudit()
      .then(a => {
        this.audit = a;
      })
  }

}
