import { identity } from "./continuous";
import { initInterpolator } from "./init";
import { linearish } from "./linear";
import { loggish } from "./log";
import { copy } from "./sequential";
import { symlogish } from "./symlog";
import { powish } from "./pow";
function transformer() {
  var x0 = 0,
    x1 = 0.5,
    x2 = 1,
    t0,
    t1,
    t2,
    k10,
    k21,
    interpolator = identity,
    transform,
    clamp = false,
    unknown;
  function scale(x) {
    return isNaN(x = +x) ? unknown : (x = 0.5 + ((x = +transform(x)) - t1) * (x < t1 ? k10 : k21), interpolator(clamp ? Math.max(0, Math.min(1, x)) : x));
  }
  scale.domain = function (_) {
    return arguments.length ? (t0 = transform(x0 = +_[0]), t1 = transform(x1 = +_[1]), t2 = transform(x2 = +_[2]), k10 = t0 === t1 ? 0 : 0.5 / (t1 - t0), k21 = t1 === t2 ? 0 : 0.5 / (t2 - t1), scale) : [x0, x1, x2];
  };
  scale.clamp = function (_) {
    return arguments.length ? (clamp = !!_, scale) : clamp;
  };
  scale.interpolator = function (_) {
    return arguments.length ? (interpolator = _, scale) : interpolator;
  };
  scale.unknown = function (_) {
    return arguments.length ? (unknown = _, scale) : unknown;
  };
  return function (t) {
    transform = t, t0 = t(x0), t1 = t(x1), t2 = t(x2), k10 = t0 === t1 ? 0 : 0.5 / (t1 - t0), k21 = t1 === t2 ? 0 : 0.5 / (t2 - t1);
    return scale;
  };
}
export default function diverging() {
  var scale = linearish(transformer()(identity));
  scale.copy = function () {
    return copy(scale, diverging());
  };
  return initInterpolator.apply(scale, arguments);
}
export function divergingLog() {
  var scale = loggish(transformer()).domain([0.1, 1, 10]);
  scale.copy = function () {
    return copy(scale, divergingLog()).base(scale.base());
  };
  return initInterpolator.apply(scale, arguments);
}
export function divergingSymlog() {
  var scale = symlogish(transformer());
  scale.copy = function () {
    return copy(scale, divergingSymlog()).constant(scale.constant());
  };
  return initInterpolator.apply(scale, arguments);
}
export function divergingPow() {
  var scale = powish(transformer());
  scale.copy = function () {
    return copy(scale, divergingPow()).exponent(scale.exponent());
  };
  return initInterpolator.apply(scale, arguments);
}
export function divergingSqrt() {
  return divergingPow.apply(null, arguments).exponent(0.5);
}