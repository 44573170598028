import { Component, Input, OnInit, signal } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { Packet } from 'app/classes/packet';

@Component({
    selector: 'app-setpoint-query-table',
    templateUrl: './setpoint-query-table.component.html',
    styleUrls: ['./setpoint-query-table.component.css'],
    standalone: false
})
export class SetpointQueryTableComponent implements OnInit {
  _packets = signal<Packet[]>(null);
  _hasActiveSetpoints = signal<boolean>(false);
  hasWeather = signal<boolean>(false);

  @Input()
  asset: Asset;

  @Input()
  public set packets(v: Packet[]) {
    if (v) {
      this._packets.set([...v].sort((a, b) => a.d > b.d ? -1 : 1));
      console.log('Table view packets received', this.hasActiveSetpoints);
      this.hasWeather.set(v?.some(t => t.w));
    } else {
      this._packets.set(null);
      this.hasWeather.set(false);
    }
  }

  @Input()
  public set hasActiveSetpoints(v: boolean) {
    this._hasActiveSetpoints.set(v);
  }

  constructor() { }

  ngOnInit(): void {
  }

  formattedValue(v) {
    return Asset.formattedValue(v, this.asset.assetType_id, this.asset.purpose?.id, this.asset.updatedAt, this.asset.identifier);
  }
}
