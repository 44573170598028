export { default as schemeCategory10 } from "./categorical/category10.js";
export { default as schemeAccent } from "./categorical/Accent.js";
export { default as schemeDark2 } from "./categorical/Dark2.js";
export { default as schemePaired } from "./categorical/Paired.js";
export { default as schemePastel1 } from "./categorical/Pastel1.js";
export { default as schemePastel2 } from "./categorical/Pastel2.js";
export { default as schemeSet1 } from "./categorical/Set1.js";
export { default as schemeSet2 } from "./categorical/Set2.js";
export { default as schemeSet3 } from "./categorical/Set3.js";
export { default as schemeTableau10 } from "./categorical/Tableau10.js";
export { default as interpolateBrBG, scheme as schemeBrBG } from "./diverging/BrBG.js";
export { default as interpolatePRGn, scheme as schemePRGn } from "./diverging/PRGn.js";
export { default as interpolatePiYG, scheme as schemePiYG } from "./diverging/PiYG.js";
export { default as interpolatePuOr, scheme as schemePuOr } from "./diverging/PuOr.js";
export { default as interpolateRdBu, scheme as schemeRdBu } from "./diverging/RdBu.js";
export { default as interpolateRdGy, scheme as schemeRdGy } from "./diverging/RdGy.js";
export { default as interpolateRdYlBu, scheme as schemeRdYlBu } from "./diverging/RdYlBu.js";
export { default as interpolateRdYlGn, scheme as schemeRdYlGn } from "./diverging/RdYlGn.js";
export { default as interpolateSpectral, scheme as schemeSpectral } from "./diverging/Spectral.js";
export { default as interpolateBuGn, scheme as schemeBuGn } from "./sequential-multi/BuGn.js";
export { default as interpolateBuPu, scheme as schemeBuPu } from "./sequential-multi/BuPu.js";
export { default as interpolateGnBu, scheme as schemeGnBu } from "./sequential-multi/GnBu.js";
export { default as interpolateOrRd, scheme as schemeOrRd } from "./sequential-multi/OrRd.js";
export { default as interpolatePuBuGn, scheme as schemePuBuGn } from "./sequential-multi/PuBuGn.js";
export { default as interpolatePuBu, scheme as schemePuBu } from "./sequential-multi/PuBu.js";
export { default as interpolatePuRd, scheme as schemePuRd } from "./sequential-multi/PuRd.js";
export { default as interpolateRdPu, scheme as schemeRdPu } from "./sequential-multi/RdPu.js";
export { default as interpolateYlGnBu, scheme as schemeYlGnBu } from "./sequential-multi/YlGnBu.js";
export { default as interpolateYlGn, scheme as schemeYlGn } from "./sequential-multi/YlGn.js";
export { default as interpolateYlOrBr, scheme as schemeYlOrBr } from "./sequential-multi/YlOrBr.js";
export { default as interpolateYlOrRd, scheme as schemeYlOrRd } from "./sequential-multi/YlOrRd.js";
export { default as interpolateBlues, scheme as schemeBlues } from "./sequential-single/Blues.js";
export { default as interpolateGreens, scheme as schemeGreens } from "./sequential-single/Greens.js";
export { default as interpolateGreys, scheme as schemeGreys } from "./sequential-single/Greys.js";
export { default as interpolatePurples, scheme as schemePurples } from "./sequential-single/Purples.js";
export { default as interpolateReds, scheme as schemeReds } from "./sequential-single/Reds.js";
export { default as interpolateOranges, scheme as schemeOranges } from "./sequential-single/Oranges.js";
export { default as interpolateCividis } from "./sequential-multi/cividis.js";
export { default as interpolateCubehelixDefault } from "./sequential-multi/cubehelix.js";
export { default as interpolateRainbow, warm as interpolateWarm, cool as interpolateCool } from "./sequential-multi/rainbow.js";
export { default as interpolateSinebow } from "./sequential-multi/sinebow.js";
export { default as interpolateTurbo } from "./sequential-multi/turbo.js";
export { default as interpolateViridis, magma as interpolateMagma, inferno as interpolateInferno, plasma as interpolatePlasma } from "./sequential-multi/viridis.js";