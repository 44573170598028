import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { StoreService } from 'app/shared/store.service';
import { Subscription } from 'rxjs';
import { IGetList, SetpointsService } from "../../shared/setpoints.service";
@Component({
    selector: 'app-setpoint-summary',
    templateUrl: './setpoint-summary.component.html',
    styleUrls: ['./setpoint-summary.component.css'],
    standalone: false
})
export class SetpointSummaryComponent implements OnInit, OnDestroy {

  list: IGetList[];
  selectedAsset: IGetList;
  navigationSubscription: Subscription;
  isLoading: boolean = true;

  constructor(private setpointsService: SetpointsService, private storeService: StoreService, private router: Router) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.selectedAsset = null;
      }
    });
  }

  ngOnInit(): void {
    this.selectedAsset = null;
    this.setpointsService.getList().then(l => {
      this.list = l;
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
