import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { User } from 'app/classes/user';
import { Org } from 'app/classes/org';
import { Gateway } from 'app/classes/gateway';
import { ManageService } from 'app/shared/manage.service';


@Component({
  selector: 'app-manage-create',
  templateUrl: './manage-create.component.html',
  styleUrls: ['./manage-create.component.css'],
  standalone: false
})
export class ManageCreateComponent implements OnInit {

  org: Org;

  _object: User | Org | Gateway | Asset;
  @Input()
  public set object(v: User | Org | Gateway | Asset) {
    this._object = v;
    if (v instanceof Org) {
      this.isTypeOf = 'org';
      this.org = v;
    }
  }

  public get object(): User | Org | Gateway | Asset {
    return this._object;
  }

  isTypeOf: string;

  @Input()
  element: any = { field: '' };

  @Output()
  didSave: EventEmitter<Org> = new EventEmitter<Org>();

  constructor(private manageService: ManageService) { }

  ngOnInit() {
    console.log(this.object, this.object.constructor.name);
  }


  saved(org) {
    this.didSave.emit(org);
  }

}
