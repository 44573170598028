import { provideHttpClient } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/modules/shared.module";
import { WebcamModule } from "ngx-webcam";
import { GoogleMapsModule } from '@angular/google-maps';
/* components */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FilterMenuComponent } from './filter-menu/filter-menu.component';
import { FilterMenusComponent } from './filter-menu/filter-menus.component';
import { LoginComponent } from './login/login.component';

import { AssetCategoryComponent } from './asset-category/asset-category.component';
import { AssetTelemetryComponent } from './asset-telemetry/asset-telemetry.component';
import { RuleOverviewComponent } from './rule-overview/rule-overview.component';
import { SiteDetailsComponent } from './site-details/site-details.component';

import { AlarmActionFromTokenComponent } from './alarm-action-from-token/alarm-action-from-token.component';
import { AlarmButtonsComponent } from './alarms/alarm-buttons/alarm-buttons.component';
import { AssetOverviewComponent } from './chips/asset-overview/asset-overview.component';
import { DateComponent } from './chips/date/date.component';
import { OrgTitleChangeableComponent } from './chips/org-title-changeable/org-title-changeable.component';
import { PercChangeComponent } from './chips/perc-change/perc-change.component';
import { RuleTimeRangeDowComponent } from './chips/rule-time-range-dow/rule-time-range-dow.component';
import { SitesDashboardComponent } from './dashboards/sites-dashboard/sites-dashboard.component';
import { FootfallChartComponent } from './footfall/footfall-chart/footfall-chart.component';
import { FootfallDashboardComponent } from './footfall/footfall-dashboard/footfall-dashboard.component';
import { GatewayLabelComponent } from './gateways/gateway-label/gateway-label.component';
import { GatewaysListComponent } from './gateways/gateways-list/gateways-list.component';
import { OrderByPipe } from './order-by.pipe';
import { OrgDashboardComponent } from './org-dashboard/org-dashboard.component';
import { PadStartPipe } from './pipes/pad-start';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProfileComponent } from './profile/profile.component';
import { PublicFootfallComponent } from './public/public-footfall/public-footfall.component';
import { ReportBlockRenderComponent } from './reports/report-block-render/report-block-render.component';
import { ReportConfigureComponent } from './reports/report-configure/report-configure.component';
import { ReportCountByDayComponent } from './reports/report-count-by-day/report-count-by-day.component';
import { ReportListComponent } from './reports/report-list/report-list.component';
import { ReportViewComponent } from './reports/report-view/report-view.component';
import { ReportWeatherTableComponent } from './reports/report-weather-table/report-weather-table.component';

import { SafeUrlPipe } from "./shared/safe-url.pipe";
import { DashboardTagComponent } from './dashboards/dashboard-tag/dashboard-tag.component';
import { AssetImageComponent } from './assets/asset-image/asset-image.component';
import { AssetViewComponent } from './assets/asset-view/asset-view.component';
import { AssetIndicatorComponent } from './chips/asset-indicator/asset-indicator.component';
import { FieldViewComponent } from './chips/field-view/field-view.component';
import { GatewayIndicatorComponent } from './chips/gateway-indicator/gateway-indicator.component';
import { RulepackageIndicatorComponent } from './chips/rulepackage-indicator/rulepackage-indicator.component';
import { TickButtonComponent } from './chips/tick-button/tick-button.component';
import { TickComponent } from './chips/tick/tick.component';
import { DashboardAssetListComponent } from './dashboards/dashboard-asset-list/dashboard-asset-list.component';
import { DashboardSiteListComponent } from './dashboards/dashboard-site-list/dashboard-site-list.component';
import { DeviceLandingComponent } from './device/device-landing/device-landing.component';
import { InviteLandingComponent } from './invite/invite-landing/invite-landing.component';
import { IssueDashboardComponent } from './issues/issue-dashboard/issue-dashboard.component';
import { IssueUpdateComponent } from './issues/issue-update/issue-update.component';
import { IssuesComponent } from './issues/issues.component';
import { AddressEntryComponent } from './layout/address-entry/address-entry.component';
import { AlarmCardComponent } from './layout/alarm-card/alarm-card.component';
import { AlarmTimelineComponent } from './layout/alarm-timeline/alarm-timeline.component';
import { GatewayPickerComponent } from './layout/gateway-picker/gateway-picker.component';
import { IssueTicketComponent } from './layout/issue-ticket/issue-ticket.component';
import { MessageItemComponent } from './layout/message-item/message-item.component';
import { RuleConditionNewComponent } from './layout/rule-condition-new/rule-condition-new.component';
import { RuleConditionPickerComponent } from './layout/rule-condition-picker/rule-condition-picker.component';
import { RuleRestrictTimeModifyComponent } from './layout/rule-restrict-time-modify/rule-restrict-time-modify.component';
import { SectionBannerComponent } from './layout/section-banner/section-banner.component';
import { SensorPickerComponent } from './layout/sensor-picker/sensor-picker.component';
import { SitePickerComponent } from './layout/site-picker/site-picker.component';
import { LiveGatewayListComponent } from './live/live-gateway-list/live-gateway-list.component';
import { MenuSliderComponent } from './menu-slider/menu-slider.component';
import { OrderCollectionItemComponent } from './ordering/order-collection-item/order-collection-item.component';
import { OrderCreateComponent } from './ordering/order-create/order-create.component';
import { OrderStatusTableRowsComponent } from './ordering/order-status-table-rows/order-status-table-rows.component';
import { OrderViewComponent } from './ordering/order-view/order-view.component';
import { OrderingLandingComponent } from './ordering/ordering-landing/ordering-landing.component';
import { ProfileListComponent } from './profiling/profile-list/profile-list.component';
import { ProfileStackComponent } from './profiling/profile-stack/profile-stack.component';
import { ReportTelemetryByDayComponent } from './reports/report-telemetry-by-day/report-telemetry-by-day.component';
import { ReportTelemetryMinMaxComponent } from './reports/report-telemetry-min-max/report-telemetry-min-max.component';
import { ReportTextComponent } from './reports/report-text/report-text.component';
import { RuleStripAlarmHistoryComponent } from './rules-engine/rule-strip-alarm-history/rule-strip-alarm-history.component';
import { RuleTileComponent } from './rules-engine/rule-tile/rule-tile.component';
import { RulesEngineLandingComponent } from './rules-engine/rules-engine-landing/rules-engine-landing.component';
import { RuleEngineV2HistoryComponent } from './rules-engine/v2/rule-engine-v2-history/rule-engine-v2-history.component';
import { RuleEngineV2ItemComponent } from './rules-engine/v2/rule-engine-v2-item/rule-engine-v2-item.component';
import { RuleEngineV2NewComponent } from './rules-engine/v2/rule-engine-v2-new/rule-engine-v2-new.component';
import { RulesEngineV2Component } from './rules-engine/v2/rules-engine-v2.component';
import { SearchHomeComponent } from './search/search-home/search-home.component';

import { AuthGuardService } from './shared/route-guard.service';
import { SiteSubmitComponent } from './site-submit/site-submit.component';
import { SubsListComponent } from './subs/subs-list/subs-list.component';
import { TrainingLandingComponent } from './training/training-landing/training-landing.component';
import { TrainingModuleListItemComponent } from './training/training-module-list-item/training-module-list-item.component';
import { TrainingOverviewComponent } from './training/training-overview/training-overview.component';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { CameraContainerComponent } from './cameras/camera-container/camera-container.component';
import { CameraLandingComponent } from './cameras/camera-landing/camera-landing.component';
import { CameraLightmonitorComponent } from './cameras/camera-lightmonitor/camera-lightmonitor.component';
import { CameraShotComponent } from './cameras/camera-shot/camera-shot.component';
import { CameraWorkplanComponent } from './cameras/camera-workplan/camera-workplan.component';
import { UserIdCardComponent } from './chips/user-id-card/user-id-card.component';
import { CmsPageComponent } from './cms/cms-page/cms-page.component';
import { CmsVersionsComponent } from './cms/cms-versions/cms-versions.component';
import { SetpointBarsComponent } from './layout/setpoint-bars/setpoint-bars.component';
import { LiveEmbedComponent } from './live/live-embed/live-embed.component';
import { PartnerContainerComponent } from './partner/partner-container/partner-container.component';
import { PartnerLandingComponent } from './partner/partner-landing/partner-landing.component';
import { TrainingContainerComponent } from './training/training-container/training-container.component';
import { TrainingCourseSelectorComponent } from './training/training-course-selector/training-course-selector.component';
import { AlarmViewComponent } from './alarms/alarm-view/alarm-view.component';
import { AssetChartComponent } from './assets/asset-chart/asset-chart.component';

import { BillingAuditComponent } from './billing/billing-audit/billing-audit.component';
import { BillingBuildingsHomeComponent } from './billing/billing-buildings-home/billing-buildings-home.component';
import { BillingBuildingsListComponent } from './billing/billing-buildings-list/billing-buildings-list.component';
import { BillingGatewaysHomeComponent } from './billing/billing-gateways-home/billing-gateways-home.component';
import { BillingHomeComponent } from './billing/billing-home/billing-home.component';
import { BillingLicensesListComponent } from './billing/billing-licenses-list/billing-licenses-list.component';
import { BillingStatsComponent } from './billing/billing-stats/billing-stats.component';
import { BuildingLicenseHomeComponent } from "./billing/building-license-home/building-license-home.component";
import { BuildingLandingComponent } from './buildings/building-landing/building-landing.component';
import { CamaraBoxComponent } from './cameras/camara-box/camara-box.component';
import { CameraControlComponent } from './cameras/camera-control/camera-control.component';
import { CameraPresetListComponent } from './cameras/camera-preset-list/camera-preset-list.component';
import { WorkplanListComponent } from './cameras/workplan-list/workplan-list.component';

import { ChartsLandingComponent } from './charts/charts-landing/charts-landing.component';

import { CameraThumbComponent } from './chips/camera-thumb/camera-thumb.component';
import { TicketsPinnedComponent } from './chips/tickets-pinned/tickets-pinned.component';
import { ComplianceAssetsComponent } from './compliance/compliance-assets/compliance-assets.component';
import { ComplianceDialComponent } from './compliance/compliance-dial/compliance-dial.component';
import { ComplianceHomeComponent } from './compliance/compliance-home/compliance-home.component';
import { DashboardLandingComponent } from './dashboard/dashboard-landing/dashboard-landing.component';
import { DashboardMapComponent } from './dashboard/dashboard-map/dashboard-map.component';
import { DashboardChartsComponent } from './dashboard/dashboard-table/dashboard-charts/dashboard-charts.component';
import { DashboardTableComponent } from './dashboard/dashboard-table/dashboard-table.component';
import { DashboardTileComponent } from './dashboard/dashboard-tile/dashboard-tile.component';
import { DashboardTileDataOfflineComponent } from './dashboard/tiles/data/dashboard-tile-data-offline/dashboard-tile-data-offline.component';
import { DashboardTileDataSetpointsComponent } from './dashboard/tiles/data/dashboard-tile-data-setpoints/dashboard-tile-data-setpoints.component';
import { DashboardTileDataTelemetryComponent } from './dashboard/tiles/data/dashboard-tile-data-telemetry/dashboard-tile-data-telemetry.component';
import { DashboardTelemetryAssetComponent } from './dashboards/dashboard-telemetry-asset/dashboard-telemetry-asset.component';
import { SitesDashboardV2Component } from './dashboards/sites-dashboard-v2/sites-dashboard-v2.component';
import { DevApiCraftComponent } from './dev/dev-api-craft/dev-api-craft.component';
import { DevApiListComponent } from './dev/dev-api-list/dev-api-list.component';
import { DevApiPublicComponent } from './dev/dev-api-public/dev-api-public.component';
import { DevApiUpdateComponent } from './dev/dev-api-update/dev-api-update.component';
import { DevCreateKeyComponent } from './dev/dev-create-key/dev-create-key.component';
import { DevCurlComponent } from "./dev/dev-curl/dev-curl.component";
import { DevDocumentationComponent } from "./dev/dev-documentation/dev-documentation.component";
import { DevLandingComponent } from './dev/dev-landing/dev-landing.component';
import { ExplorerApiComponent } from './developer/explorer/explorer-api/explorer-api.component';
import { AssetRagReportComponent } from './developer/explorer/reports/rag-report/asset-rag-report/asset-rag-report.component';
import { RagReportComponent } from './developer/explorer/reports/rag-report/rag-report.component';
import { DialogAssetEditComponent } from './dialogs/dialog-asset-edit/dialog-asset-edit.component';
import { DialogBillingClientComponent } from './dialogs/dialog-billing-client/dialog-billing-client.component';
import { DialogContactEditComponent } from './dialogs/dialog-contact-edit/dialog-contact-edit.component';
import { DialogGroupSelectComponent } from './dialogs/dialog-group-select/dialog-group-select.component';
import { DialogGroupComponent } from './dialogs/dialog-group/dialog-group.component';
import { DialogInsightsWaitComponent } from './dialogs/dialog-insights-wait/dialog-insights-wait.component';
import { DialogInviteUserComponent } from './dialogs/dialog-invite-user/dialog-invite-user.component';
import { DialogLicenseComponent } from './dialogs/dialog-license/dialog-license.component';
import { DialogManageCollectionsComponent } from './dialogs/dialog-manage-collections/dialog-manage-collections.component';
import { DialogOpeningHoursComponent } from './dialogs/dialog-opening-hours/dialog-opening-hours.component';
import { DialogReviewAnnotationsComponent } from './dialogs/dialog-review-annotations/dialog-review-annotations.component';
import { EngageContainerComponent } from './engage/engage-container/engage-container.component';
import { EngageLandingComponent } from './engage/engage-landing/engage-landing.component';
import { EngageMenusComponent } from './engage/engage-menus/engage-menus.component';
import { EngageOrgComponent } from './engage/engage-org/engage-org.component';
import { EngageUsersComponent } from './engage/engage-users/engage-users.component';
import { ExportContainerComponent } from './export/export-container/export-container.component';
import { ExportLandingComponent } from './export/export-landing/export-landing.component';
import { HomeFooterComponent } from './homepage/home-footer/home-footer.component';
import { HomeHeaderComponent } from './homepage/home-header/home-header.component';
import { HomepageComponent } from './homepage/homepage.component';
import { InsightsReportGatewayComponent } from './insights/aq/insights-report-gateway/insights-report-gateway.component';
import { InsightsReportTypeComponent } from './insights/aq/insights-report-type/insights-report-type.component';
import { InsightsAsAdminComponent } from './insights/as/insights-as-admin/insights-as-admin.component';
import { InsightsAsLandingComponent } from './insights/as/insights-as-landing/insights-as-landing.component';
import { InsightsAsReportComponent } from './insights/as/insights-as-report/insights-as-report.component';
import { InsightsAsUserComponent } from './insights/as/insights-as-user/insights-as-user.component';
import { InsightsFfCompareComponent } from './insights/ff/insights-ff-compare/insights-ff-compare.component';
import { InsightsFfLandingComponent } from './insights/ff/insights-ff-landing/insights-ff-landing.component';
import { InsightsFfReportComponent } from './insights/ff/insights-ff-report/insights-ff-report.component';
import { InsightsAirqualityConfigComponent } from './insights/insights-airquality/insights-airquality-config/insights-airquality-config.component';
import { InsightsAirqualityReportComponent } from './insights/insights-airquality/insights-airquality-report/insights-airquality-report.component';
import { InsightsAirqualityComponent } from './insights/insights-airquality/insights-airquality.component';
import { InsightsLandingComponent } from './insights/insights-landing/insights-landing.component';
import { SelectBuildingComponent } from './insights/layout/select-building/select-building.component';
import { SelectReviewAnnotationComponent } from './insights/layout/select-review-annotation/select-review-annotation.component';
import { AssetsOfflineComponent } from './issues/assets-offline/assets-offline.component';
import { JourneyContainerComponent } from './journey/journey-container/journey-container.component';
import { JourneyLandingComponent } from './journey/journey-landing/journey-landing.component';
import { SectionAssetsComponent } from './journey/section-assets/section-assets.component';
import { SectionIconComponent } from './journey/section-icon/section-icon.component';
import { C3ChartComponent } from './layout/c3-chart/c3-chart.component';
import { C3ChartsComponent } from './layout/c3-charts/c3-charts.component';
import { CollectionAssetsComponent } from './layout/collection-assets/collection-assets.component';
import { CollectionGroupComponent } from './layout/collection-group/collection-group.component';
import { DocumentsListComponent } from './layout/documents-list/documents-list.component';
import { ElogbooksPopupComponent } from './layout/elogbooks-popup/elogbooks-popup.component';
import { FloorplanAssetComponent } from './layout/floorplan-asset/floorplan-asset.component';
import { GatewayAqComponent } from './layout/gateway-aq/gateway-aq.component';
import { HelpPopupComponent } from './layout/help-popup/help-popup.component';
import { InfoBoxComponent } from './layout/info-box/info-box.component';
import { PageLinkComponent } from './layout/page-link/page-link.component';
import { RadiosComponent } from './layout/radios/radios.component';

import { RefreshIndicatorComponent } from './layout/refresh-indicator/refresh-indicator.component';
import { RuleActionNewComponent } from './layout/rule-action-new/rule-action-new.component';
import { RuleActionPickerComponent } from './layout/rule-action-picker/rule-action-picker.component';
import { SensorPopupComponent } from './layout/sensor-popup/sensor-popup.component';
import { UserGroupComponent } from './layout/user-group/user-group.component';
import { ManageCreateOrgHelpComponent } from './manage/create/org/manage-create-org-help/manage-create-org-help.component';
import { ManageCreateOrgComponent } from './manage/create/org/manage-create-org/manage-create-org.component';
import { ManageCreateComponent } from './manage/manage-create/manage-create.component';
import { ManageLandingComponent } from './manage/manage-landing/manage-landing.component';
import { MenusLeftComponent } from './menus/menus-left/menus-left.component';
import { MobileFooterMenuComponent } from './menus/mobile-footer-menu/mobile-footer-menu.component';
import { MonitorContainerComponent } from './monitor/monitor-container/monitor-container.component';
import { MonitorLandingComponent } from './monitor/monitor-landing/monitor-landing.component';
import { MonitorTileGatewayComponent } from './monitor/tiles/monitor-tile-gateway/monitor-tile-gateway.component';
import { OccupancyLandingComponent } from './occupancy/occupancy-landing/occupancy-landing.component';
import { OrgComplianceAddComponent } from './org-dashboard/org-compliance-add/org-compliance-add.component';
import { OrgComplianceHomeComponent } from './org-dashboard/org-compliance-home/org-compliance-home.component';
import { OrgComplianceItemComponent } from './org-dashboard/org-compliance-item/org-compliance-item.component';
import { OrgContactNewComponent } from './org-dashboard/org-contact-new/org-contact-new.component';
import { OrgContactsHomeComponent } from './org-dashboard/org-contacts-home/org-contacts-home.component';
import { OrgGroupHomeComponent } from './org-dashboard/org-group-home/org-group-home.component';
import { OrgIntegrationElogbooksComponent } from './org-dashboard/org-integration-elogbooks/org-integration-elogbooks.component';
import { OrgIntegrationHomeComponent } from './org-dashboard/org-integration-home/org-integration-home.component';
import { OrgMapComponent } from './org-dashboard/org-map/org-map.component';
import { OrgSettingsComponent } from './org-dashboard/org-settings/org-settings.component';
import { OrgUsersHomeComponent } from './org-dashboard/org-users-home/org-users-home.component';
import { PartnerDashboardComponent } from './partner/partner-dashboard/partner-dashboard.component';
import { PartnerOverviewPanelComponent } from './partner/partner-overview-panel/partner-overview-panel.component';
import { PartnerUserListComponent } from './partner/partner-user-list/partner-user-list.component';
import { AcceptableTermsComponent } from './policies/acceptable-terms/acceptable-terms.component';
import { CookiesComponent } from './policies/cookies/cookies.component';
import { DataTermsComponent } from './policies/data-terms/data-terms.component';
import { PoliciesContainerComponent } from './policies/policies-container/policies-container.component';
import { PrivacyComponent } from './policies/privacy/privacy.component';
import { TermsComponentComponent } from './policies/terms-component/terms-component.component';
import { ProfileCatsListComponent } from './profiling/profile-cats-list/profile-cats-list.component';
import { ProfilingAddComponent } from './profiling/profiling-add/profiling-add.component';
import { ProfilingContainerComponent } from './profiling/profiling-container/profiling-container.component';
import { ProfilingLandingComponent } from './profiling/profiling-landing/profiling-landing.component';
import { ProfilingSensorsComponent } from './profiling/profiling-sensors/profiling-sensors.component';
import { SiteChartComponent } from './profiling/site-chart/site-chart.component';
import { ShapeManageOccupancyComponent } from './profiling/site-plan/shape-manage/shape-manage-occupancy/shape-manage-occupancy.component';
import { ShapeManageComponent } from './profiling/site-plan/shape-manage/shape-manage.component';
import { SitePlanAssetComponent } from './profiling/site-plan/site-plan-asset/site-plan-asset.component';
import { SitePlanDashboardComponent } from './profiling/site-plan/site-plan-dashboard/site-plan-dashboard.component';
import { SitePlanShapeComponent } from './profiling/site-plan/site-plan-shape/site-plan-shape.component';
import { SitePlanComponent } from './profiling/site-plan/site-plan.component';
import { SiteTableComponent } from './profiling/site-table/site-table.component';
import { ReportAirqualityComponent } from './reports/report-airquality/report-airquality.component';
import { ReportGatewayListComponent } from './reports/report-configure/report-gateway-list/report-gateway-list.component';
import { ReportMonthlyComponent } from './reports/report-monthly/report-monthly.component';
import { ReviewFindingEditComponent } from './reviews/layout/review-finding-edit/review-finding-edit.component';
import { ReviewGraphComponent } from './reviews/layout/review-graph/review-graph.component';
import { ReviewListItemComponent } from './reviews/layout/review-list-item/review-list-item.component';
import { ReviewsAuditTrailComponent } from './reviews/layout/reviews-audit-trail/reviews-audit-trail.component';
import { ReviewNewComponent } from './reviews/review-new/review-new.component';
import { ReviewUpdateComponent } from './reviews/review-update/review-update.component';
import { ReviewsContainerComponent } from './reviews/reviews-container/reviews-container.component';
import { ReviewsListComponent } from './reviews/reviews-list/reviews-list.component';
import { RuleEngineNotificationsComponent } from './rules-engine/v2/rule-engine-notifications/rule-engine-notifications.component';
import { RuleEngineV2ActionComponent } from './rules-engine/v2/rule-engine-v2-action/rule-engine-v2-action.component';
import { RuleEngineV2IntegrationComponent } from './rules-engine/v2/rule-engine-v2-integration/rule-engine-v2-integration.component';
import { Rulesv3ItemAlarmCalendarComponent } from './rulesv3/item/rulesv3-item-alarm-calendar/rulesv3-item-alarm-calendar.component';
import { Rulesv3ItemAlarmControlComponent } from './rulesv3/item/rulesv3-item-alarm-control/rulesv3-item-alarm-control.component';
import { Rulesv3ItemAlarmElogbooksComponent } from './rulesv3/item/rulesv3-item-alarm-elogbooks/rulesv3-item-alarm-elogbooks.component';
import { Rulesv3ItemAlarmTimelineComponent } from './rulesv3/item/rulesv3-item-alarm-timeline/rulesv3-item-alarm-timeline.component';
import { Rulesv3ItemAlarmComponent } from './rulesv3/item/rulesv3-item-alarm/rulesv3-item-alarm.component';
import { Rulesv3ItemConditionsComponent } from './rulesv3/item/rulesv3-item-conditions/rulesv3-item-conditions.component';
import { Rulesv3ItemElogbooksDetailsComponent } from './rulesv3/item/rulesv3-item-elogbooks-details/rulesv3-item-elogbooks-details.component';
import { Rulesv3ItemHistoryComponent } from './rulesv3/item/rulesv3-item-history/rulesv3-item-history.component';
import { Rulesv3ItemComponent } from './rulesv3/rulesv3-item/rulesv3-item.component';
import { Rulesv3ListComponent } from './rulesv3/rulesv3-list/rulesv3-list.component';
import { SchematicsItemComponent } from './schematics/schematics-item/schematics-item.component';
import { SchematicsLandingComponent } from './schematics/schematics-landing/schematics-landing.component';
import { SetpointContainerComponent } from './setpoints/setpoint-container/setpoint-container.component';
import { SetpointDetailComponent } from './setpoints/setpoint-detail/setpoint-detail.component';
import { SetpointOverviewComponent } from './setpoints/setpoint-overview/setpoint-overview.component';

import { SetpointSummaryComponent } from './setpoints/setpoint-summary/setpoint-summary.component';
import { CmsItemBlockComponent } from './shared/components/cms-item-block/cms-item-block.component';
import { CmsItemComponent } from './shared/components/cms-item/cms-item.component';
import { D3PieComponent } from './shared/d3-pie/d3-pie.component';
import { SpdHourlyComponent } from './site-plan/site-plan-dashboard/spd-hourly/spd-hourly.component';
import { SpdMonthlyComponent } from './site-plan/site-plan-dashboard/spd-monthly/spd-monthly.component';
import { SpdSummaryComponent } from './site-plan/site-plan-dashboard/spd-summary/spd-summary.component';
import { SiteDashboardComponent } from './sites/site-dashboard/site-dashboard.component';

import { SystemUploadListComponent } from './system/system-upload-list/system-upload-list.component';
import { SystemUploadComponent } from './system/system-upload/system-upload.component';
import { TelemetryListComponent } from './telemetry/telemetry-list/telemetry-list.component';
import { AcceptTermsComponent } from './terms/accept-terms/accept-terms.component';

import { TicketViewComponent } from './tickets/ticket-view/ticket-view.component';
import { SitePlanMenuComponent } from './profiling/site-plan/site-plan-menu/site-plan-menu.component';
import { InsightsAsListComponent } from './insights/as/insights-as-list/insights-as-list.component';
import { DialogInsightBuildingReviewsComponent } from './dialogs/dialog-insight-building-reviews/dialog-insight-building-reviews.component';
import { SetpointListOptionsComponent } from './setpoints/setpoint-list/setpoint-list-options/setpoint-list-options.component';
import { DialogDateRangeComponent } from './dialogs/dialog-date-range/dialog-date-range.component';
import { TrainingModuleListItemBoxComponent } from './training/training-module-list-item-box/training-module-list-item-box.component';
import { InsightsAsPage1Component } from './insights/as/insights-as-report/insights-as-page1/insights-as-page1.component';
import { DialogExportSitesComponent } from './dialogs/dialog-export-sites/dialog-export-sites.component';
import { TelemetryListLegendComponent } from './telemetry/telemetry-list/telemetry-list-legend/telemetry-list-legend.component';
import { Rulesv3ItemContactsComponent } from './rulesv3/item/rulesv3-item-contacts/rulesv3-item-contacts.component';
import { PartnerOrgUsersBreakdownComponent } from './partner/partner-org-users-breakdown/partner-org-users-breakdown.component';
import { CircleRingComponent } from './layout/circle-ring/circle-ring.component';
import { PartnerSharedSitesComponent } from './partner/partner-landing/partner-shared-sites/partner-shared-sites.component';
import { OrgTenantsComponent } from './org-dashboard/org-tenants/org-tenants.component';
import { DialogTenantEditComponent } from './dialogs/dialog-tenant-edit/dialog-tenant-edit.component';
import { DialogTenantServiceEditComponent } from './dialogs/dialog-tenant-service-edit/dialog-tenant-service-edit.component';
import { OrgSetpointsComponent } from './org-dashboard/org-setpoints/org-setpoints.component';
import { DialogSetpointTemplateCategoryComponent } from './dialogs/dialog-setpoint-template-category/dialog-setpoint-template-category.component';
import { SetpointTemplatesComponent } from './setpoints/setpoint-templates/setpoint-templates.component';
import { SetpointTemplateItemComponent } from './setpoints/setpoint-templates/setpoint-template-item/setpoint-template-item.component';
import { SetpointTemplateItemSchedulerComponent } from './setpoints/setpoint-template-item-scheduler/setpoint-template-item-scheduler.component';
import { SchedulerHelpComponent } from './setpoints/setpoint-template-item-scheduler/scheduler-help/scheduler-help.component';
import { SetpointSchedulesComponent } from './setpoints/setpoint-schedules/setpoint-schedules.component';
import { SetpointScheduleUpdateComponent } from './setpoints/setpoint-schedules/setpoint-schedule-update/setpoint-schedule-update.component';
import { SetpointSchedulesCalendarComponent } from './setpoints/setpoint-schedules/setpoint-schedules-calendar/setpoint-schedules-calendar.component';
import { MediaCarouselComponent } from './media/media-carousel/media-carousel.component';
import { SetpointSchedulerItemComponent } from './setpoints/setpoint-schedules/setpoint-scheduler-item/setpoint-scheduler-item.component';
import { DialogOccupancyDayComponent } from './dialogs/dialog-occupancy-day/dialog-occupancy-day.component';
import { OccupancyAdminComponent } from './occupancy/occupancy-admin/occupancy-admin.component';
import { OccupancyAdminCollectionsComponent } from './occupancy/occupancy-admin/occupancy-admin-collections/occupancy-admin-collections.component';
import { DialogOccCollectionUpdateComponent } from './dialogs/dialog-occ-collection-update/dialog-occ-collection-update.component';
import { OccupancyListComponent } from './occupancy/occupancy-list/occupancy-list.component';
import { DialogOccupancyExportComponent } from './dialogs/dialog-occupancy-export/dialog-occupancy-export.component';
import { SitePlanDashboardContainerComponent } from './profiling/site-plan/site-plan-dashboard/site-plan-dashboard-container/site-plan-dashboard-container.component';
import { SpdWeeklyComponent } from './site-plan/site-plan-dashboard/spd-weekly/spd-weekly.component';
import { SpdOccMonthlyComponent } from './site-plan/site-plan-dashboard/spd-occ-monthly/spd-occ-monthly.component';
import { DialogAssetViewComponent } from './dialogs/dialog-asset-view/dialog-asset-view.component';

import { TelemetryListSearchComponent } from './telemetry/telemetry-list/telemetry-list-search/telemetry-list-search.component';
import { SpdOccCustomComponent } from './site-plan/site-plan-dashboard/spd-occ-custom/spd-occ-custom.component';
import { OrgUserInviteComponent } from './org-dashboard/org-user-invite/org-user-invite.component';
import { DialogGatewayViewComponent } from './dialogs/dialog-gateway-view/dialog-gateway-view.component';
import { DialogSetpointsDeleteComponent } from './dialogs/dialog-setpoints-delete/dialog-setpoints-delete.component';

import { SitePlanV2Component } from './site-plan/site-plan-v2/site-plan-v2.component';
import { SitePlanFloorplanComponent } from './site-plan/site-plan-v2/site-plan-floorplan/site-plan-floorplan.component';
import { SitePlanFloorplanToolbarComponent } from './site-plan/site-plan-v2/site-plan-floorplan/site-plan-floorplan-toolbar/site-plan-floorplan-toolbar.component';
import { YourdashboardComponent } from './yourdashboard/yourdashboard/yourdashboard.component';
import { VisualiseTileComponent } from "./yourdashboard/visualise-tile/visualise-tile.component";
import { VisualiseDataComponent } from "./yourdashboard/visualise-data/visualise-data.component";
import { VisualiseDataChartComponent } from './yourdashboard/visualise-data-chart/visualise-data-chart.component';
import { DialogUserManageComponent } from './dialogs/dialog-user-manage/dialog-user-manage.component';
import { DialogRuleConditionComponent } from './dialogs/dialog-rule-condition/dialog-rule-condition.component';
import { DialogOrgUsersSelectComponent } from './dialogs/dialog-org-users-select/dialog-org-users-select.component';
import { SitePlanFloorplanContainerComponent } from './site-plan/site-plan-v2/site-plan-floorplan-container/site-plan-floorplan-container.component';
import { UserManageExportComponent } from './dialogs/dialog-user-manage/user-manage-export/user-manage-export.component';
import { OrgExportComponent } from './org-dashboard/org-export/org-export.component';
import { ConditionRowComponent } from './org-dashboard/org-export/condition-row/condition-row.component';
import { UserScreenComponent } from './layout/user-screen/user-screen.component';
import { ReportTriggerComponent } from './reports/report-trigger/report-trigger.component';

import { ButtonGroupComponent } from './layout/button-group/button-group.component';
import { TableHeaderItemComponent } from './layout/table-header-item/table-header-item.component';
import { MenuMobileComponent } from './menus/menu-mobile/menu-mobile.component';
import { ButtonSitePlanDashboardConfigComponent } from './profiling/site-plan/site-plan-dashboard/button-site-plan-dashboard-config/button-site-plan-dashboard-config.component';
import { ButtonYourdashboardConfigComponent } from "./yourdashboard/yourdashboard/button-yourdashboard-config/button-yourdashboard-config.component";
import { ChangelogLandingComponent } from './changelog/changelog-landing/changelog-landing.component';

import { RuleEngineV2AlarmsComponent } from './rules-engine/v2/rule-engine-v2-alarms/rule-engine-v2-alarms.component';
import { AssetOfflineLandingComponent } from './issues/assets-offline/asset-offline-landing/asset-offline-landing.component';
import { AssetOfflineCommunicationComponent } from './issues/assets-offline/asset-offline-communication/asset-offline-communication.component';
import { OfflineCommunicationLandingComponent } from './issues/assets-offline/offline-communication-landing/offline-communication-landing.component';
import { FoorfallAreasComponent } from './footfall/foorfall-areas/foorfall-areas.component';
import { FoorfallAreaComponent } from './footfall/foorfall-area/foorfall-area.component';

import { ReportCustomComponent } from './reports/report-custom/report-custom.component';
import { IacGatewayComponent } from './insights/insights-airquality/insights-airquality-config/iac-gateway/iac-gateway.component';
import { OrgSitesComponent } from './org-dashboard/org-sites/org-sites.component';
import { SiteNotificationsComponent } from './sites/site-notifications/site-notifications.component';
import { SiteCollectionsComponent } from './sites/site-collections/site-collections.component';
import { OrgSwitchComponent } from './org-dashboard/org-switch/org-switch.component';
import { EnergyHomeComponent } from "./energy/energy-home/energy-home.component";
import { SiteCollectionComponent } from './sites/site-collections/site-collection/site-collection.component';
import { EnergyListComponent } from './energy/energy-list/energy-list.component';
import { EnergySiteComponent } from './energy/energy-site/energy-site.component';
import { AqCollectionsComponent } from './insights/aq-collections/aq-collections.component';
import { OrgUserDetailsComponent } from './org-dashboard/org-users-home/org-user-details/org-user-details.component';
import { DirtyMessageComponent } from './layout/dirty-message/dirty-message.component';
import { AqCollectionsReportComponent } from './insights/aq-collections/aq-collections-report/aq-collections-report.component';
import { AqReportTypeComponent } from './insights/aq-collections/aq-collections-report/aq-report-type/aq-report-type.component';
import { DialogHelpComponent } from './dialogs/dialog-help/dialog-help.component';
import { InsightsReviewsLandingComponent } from './insights/reviews/insights-reviews-landing/insights-reviews-landing.component';
import { InsightsReviewItemComponent } from './insights/reviews/insights-review-item/insights-review-item.component';
import { InsightsReviewHelpComponent } from './insights/reviews/insights-review-help/insights-review-help.component';
import { OrgBatteryComponent } from './org-dashboard/org-battery/org-battery.component';
import { AutomationReviewComponent } from './automation/insights/automation-review/automation-review.component';
import { InsightsReviewFirstTimeComponent } from './insights/reviews/insights-review-first-time/insights-review-first-time.component';
import { SiteHoursComponent } from './sites/site-hours/site-hours.component';
import { InsightsReviewsListComponent } from './insights/reviews/insights-reviews-list/insights-reviews-list.component';
import { AqConfigureRagComponent } from './insights/aq-collections/aq-configure-rag/aq-configure-rag.component';
import { InsightsReviewBatteryComponent } from './insights/reviews/insights-review-item/insights-review-battery/insights-review-battery.component';
import { EngageConfigComponent } from './engage/engage-config/engage-config.component';
import { TelemetryTableComponent } from './telemetry/telemetry-table/telemetry-table.component';

import { OrgUsersActivityComponent } from './org-dashboard/org-users-activity/org-users-activity.component';
import { IssueTrackingHomeComponent } from './issue-tracking/issue-tracking-home/issue-tracking-home.component';
import { IssueTrackingAdminComponent } from './issue-tracking/issue-tracking-admin/issue-tracking-admin.component';
import { IssueTrackingListComponent } from './issue-tracking/issue-tracking-list/issue-tracking-list.component';
import { IssueTrackingItemComponent } from './issue-tracking/issue-tracking-item/issue-tracking-item.component';

@NgModule({
	declarations: [
		AppComponent,
		VisualiseTileComponent,
		FilterMenuComponent,
		FilterMenusComponent,
		LoginComponent,
		RuleOverviewComponent,
		AssetCategoryComponent,
		AssetTelemetryComponent,
		CmsItemComponent,
		CmsItemBlockComponent,
		SiteDetailsComponent,
		OrderByPipe,
		PadStartPipe,
		SafeUrlPipe,
		AlarmActionFromTokenComponent,
		PageNotFoundComponent,
		AlarmButtonsComponent,
		GatewayLabelComponent,
		RuleTimeRangeDowComponent,
		OrgTitleChangeableComponent,
		ProfileComponent,
		OrgDashboardComponent,
		FootfallDashboardComponent,
		ReportListComponent,
		ReportViewComponent,
		ReportBlockRenderComponent,
		ReportWeatherTableComponent,
		ReportCountByDayComponent,
		PublicFootfallComponent,
		FootfallChartComponent,
		ReportConfigureComponent,
		DateComponent,
		AssetOverviewComponent,
		PercChangeComponent,
		GatewaysListComponent,
		SitesDashboardComponent,
		DashboardTagComponent,
		DashboardSiteListComponent,
		DashboardAssetListComponent,
		SearchHomeComponent,
		AssetViewComponent,
		FieldViewComponent,
		ReportTelemetryByDayComponent,
		SubsListComponent,
		AlarmViewComponent,
		ReportTelemetryMinMaxComponent,
		LiveGatewayListComponent,
		InviteLandingComponent,
		ProfileListComponent,
		DeviceLandingComponent,
		ReportTextComponent,
		MenuSliderComponent,
		RulesEngineLandingComponent,
		RuleTileComponent,
		RuleStripAlarmHistoryComponent,
		UserDetailComponent,
		SiteSubmitComponent,
		AssetImageComponent,
		TrainingLandingComponent,
		TrainingModuleListItemComponent,
		TrainingOverviewComponent,
		GatewayIndicatorComponent,
		RulepackageIndicatorComponent,
		AssetIndicatorComponent,
		SectionBannerComponent,
		OrderingLandingComponent,
		OrderCreateComponent,
		OrderCollectionItemComponent,
		OrderViewComponent,
		OrderStatusTableRowsComponent,
		AddressEntryComponent,
		RulesEngineV2Component,
		RuleEngineV2ItemComponent,
		AlarmTimelineComponent,
		RuleEngineV2HistoryComponent,
		RuleEngineV2NewComponent,
		SitePickerComponent,
		RuleConditionPickerComponent,
		RuleConditionNewComponent,
		GatewayPickerComponent,
		SensorPickerComponent,
		RuleRestrictTimeModifyComponent,
		ProfileStackComponent,
		TickButtonComponent,
		TickComponent,
		ExplorerApiComponent,
		AlarmCardComponent,
		IssuesComponent,
		IssueTicketComponent,
		MessageItemComponent,
		IssueUpdateComponent,
		IssueDashboardComponent,
		SetpointBarsComponent,
		CmsPageComponent,
		LiveEmbedComponent,
		CmsVersionsComponent,
		PartnerLandingComponent,
		PartnerContainerComponent,
		CameraContainerComponent,
		CameraLandingComponent,
		CameraLightmonitorComponent,
		CameraShotComponent,
		CameraWorkplanComponent,
		UserIdCardComponent,
		TrainingContainerComponent,
		TrainingCourseSelectorComponent,
		AssetChartComponent,
		ExportContainerComponent,
		ExportLandingComponent,
		JourneyContainerComponent,
		JourneyLandingComponent,
		SectionIconComponent,
		SectionAssetsComponent,
		AcceptTermsComponent,
		ProfilingContainerComponent,
		ProfilingLandingComponent,
		ProfilingSensorsComponent,
		TermsComponentComponent,
		PrivacyComponent,
		CookiesComponent,
		AcceptableTermsComponent,
		DataTermsComponent,
		PoliciesContainerComponent,
		CameraControlComponent,
		PartnerUserListComponent,
		PartnerOverviewPanelComponent,
		TicketsPinnedComponent,
		TicketViewComponent,
		SitePlanComponent,
		SetpointDetailComponent,
		InfoBoxComponent,
		MonitorContainerComponent,
		MonitorLandingComponent,
		MonitorTileGatewayComponent,
		SiteChartComponent,
		SiteTableComponent,
		ReviewsListComponent,
		ReviewsContainerComponent,
		ReviewListItemComponent,
		ReviewsAuditTrailComponent,
		ReviewNewComponent,
		ReviewUpdateComponent,
		ReviewGraphComponent,
		ReviewFindingEditComponent,
		RuleActionPickerComponent,
		RuleActionNewComponent,
		RuleEngineV2ActionComponent,
		HelpPopupComponent,
		SiteDashboardComponent,
		UserGroupComponent,
		SensorPopupComponent,
		MobileFooterMenuComponent,
		FloorplanAssetComponent,
		SetpointContainerComponent,
		SetpointOverviewComponent,
		CameraThumbComponent,
		CameraPresetListComponent,
		WorkplanListComponent,
		CamaraBoxComponent,
		SitePlanAssetComponent,
		DocumentsListComponent,
		DashboardLandingComponent,
		DashboardTileComponent,
		DashboardTileDataSetpointsComponent,
		DashboardTileDataTelemetryComponent,
		DashboardTableComponent,
		ChartsLandingComponent,
		DashboardChartsComponent,
		ManageLandingComponent,
		ManageCreateComponent,
		ManageCreateOrgComponent,
		ManageCreateOrgHelpComponent,
		BuildingLandingComponent,
		DevLandingComponent,
		DevApiListComponent,
		RefreshIndicatorComponent,
		HomepageComponent,
		HomeHeaderComponent,
		HomeFooterComponent,
		DevApiPublicComponent,
		DevCreateKeyComponent,
		DevApiCraftComponent,
		OccupancyLandingComponent,
		DevApiUpdateComponent,
		EngageContainerComponent,
		EngageLandingComponent,
		EngageMenusComponent,
		EngageOrgComponent,
		EngageUsersComponent,
		C3ChartComponent,
		C3ChartsComponent,
		ReportAirqualityComponent,
		GatewayAqComponent,
		ExplorerApiComponent,
		RagReportComponent,
		AssetRagReportComponent,
		ReportMonthlyComponent,
		ReportGatewayListComponent,
		PartnerDashboardComponent,
		SitePlanShapeComponent,
		Rulesv3ListComponent,
		Rulesv3ItemComponent,
		Rulesv3ItemHistoryComponent,
		Rulesv3ItemConditionsComponent,
		Rulesv3ItemAlarmComponent,
		Rulesv3ItemAlarmCalendarComponent,
		Rulesv3ItemAlarmControlComponent,
		Rulesv3ItemAlarmTimelineComponent,
		ProfileCatsListComponent,
		ComplianceHomeComponent,
		ComplianceAssetsComponent,
		ComplianceDialComponent,
		D3PieComponent,
		SitePlanDashboardComponent,
		ShapeManageComponent,
		ShapeManageOccupancyComponent,
		SpdSummaryComponent,
		OrgContactNewComponent,
		OrgComplianceHomeComponent,
		OrgComplianceAddComponent,
		OrgComplianceItemComponent,
		BillingHomeComponent,
		BillingGatewaysHomeComponent,
		OrgIntegrationHomeComponent,
		OrgIntegrationElogbooksComponent,
		RuleEngineV2IntegrationComponent,
		ElogbooksPopupComponent,
		Rulesv3ItemAlarmElogbooksComponent,
		BillingBuildingsHomeComponent,
		BuildingLicenseHomeComponent,
		BillingBuildingsListComponent,
		BillingLicensesListComponent,
		SystemUploadComponent,
		SystemUploadListComponent,
		BillingAuditComponent,
		DialogLicenseComponent,
		BillingStatsComponent,
		InsightsAirqualityComponent,
		InsightsAirqualityConfigComponent,
		InsightsAirqualityReportComponent,
		OrgMapComponent,
		DashboardTelemetryAssetComponent,
		SpdHourlyComponent,
		OrgUsersHomeComponent,
		DialogInviteUserComponent,
		SpdMonthlyComponent,
		ProfilingAddComponent,
		DialogOpeningHoursComponent,
		InsightsReportGatewayComponent,
		InsightsReportTypeComponent,
		DashboardTileDataOfflineComponent,
		AssetsOfflineComponent,
		DashboardMapComponent,
		DialogBillingClientComponent,
		InsightsLandingComponent,
		InsightsFfLandingComponent,
		SelectBuildingComponent,
		InsightsFfReportComponent,
		DialogInsightsWaitComponent,
		InsightsFfCompareComponent,
		InsightsAsLandingComponent,
		CollectionAssetsComponent,
		InsightsAsReportComponent,
		InsightsAsAdminComponent,
		DialogReviewAnnotationsComponent,
		SelectReviewAnnotationComponent,
		DialogManageCollectionsComponent,
		Rulesv3ItemElogbooksDetailsComponent,
		CollectionGroupComponent,
		DialogAssetEditComponent,
		SchematicsLandingComponent,
		SchematicsItemComponent,
		InsightsAsUserComponent,
		SetpointSummaryComponent,
		TelemetryListComponent,
		OrgSettingsComponent,
		DialogGroupComponent,
		OrgGroupHomeComponent,
		OrgContactsHomeComponent,
		DialogContactEditComponent,
		MenusLeftComponent,
		PageLinkComponent,
		RuleEngineNotificationsComponent,
		DialogGroupSelectComponent,
		SitesDashboardV2Component,
		RadiosComponent,
		DevCurlComponent,
		DevDocumentationComponent,
		SitePlanMenuComponent,
		InsightsAsListComponent,
		DialogInsightBuildingReviewsComponent,
		SetpointListOptionsComponent,
		DialogDateRangeComponent,
		TrainingModuleListItemBoxComponent,
		InsightsAsPage1Component,
		DialogExportSitesComponent,
		TelemetryListLegendComponent,
		Rulesv3ItemContactsComponent,
		PartnerOrgUsersBreakdownComponent,
		CircleRingComponent,
		PartnerSharedSitesComponent,
		OrgTenantsComponent,
		DialogTenantEditComponent,
		DialogTenantServiceEditComponent,
		OrgSetpointsComponent,
		DialogSetpointTemplateCategoryComponent,
		SetpointTemplatesComponent,
		SetpointTemplateItemComponent,
		SetpointTemplateItemSchedulerComponent,
		SchedulerHelpComponent,
		SetpointSchedulesComponent,
		SetpointScheduleUpdateComponent,
		SetpointSchedulesCalendarComponent,
		MediaCarouselComponent,
		SetpointSchedulerItemComponent,
		DialogOccupancyDayComponent,
		OccupancyAdminComponent,
		OccupancyAdminCollectionsComponent,
		DialogOccCollectionUpdateComponent,
		OccupancyListComponent,
		DialogOccupancyExportComponent,
		SitePlanDashboardContainerComponent,
		SpdWeeklyComponent,
		SpdOccMonthlyComponent,
		DialogAssetViewComponent,
		TelemetryListSearchComponent,
		SpdOccCustomComponent,
		OrgUserInviteComponent,
		DialogGatewayViewComponent,
		DialogSetpointsDeleteComponent,
		SitePlanV2Component,
		SitePlanFloorplanComponent,
		SitePlanFloorplanToolbarComponent,
		YourdashboardComponent,
		VisualiseDataComponent,
		VisualiseDataChartComponent,
		DialogUserManageComponent,
		DialogRuleConditionComponent,
		DialogOrgUsersSelectComponent,
		SitePlanFloorplanContainerComponent,
		UserManageExportComponent,
		OrgExportComponent,
		ConditionRowComponent,
		UserScreenComponent,
		ReportTriggerComponent,
		ButtonGroupComponent,
		TableHeaderItemComponent,
		MenuMobileComponent,
		ButtonSitePlanDashboardConfigComponent,
		ButtonYourdashboardConfigComponent,
		ChangelogLandingComponent,
		RuleEngineV2AlarmsComponent,
		AssetOfflineLandingComponent,
		AssetOfflineCommunicationComponent,
		OfflineCommunicationLandingComponent,
		FoorfallAreasComponent,
		FoorfallAreaComponent,
		ReportCustomComponent,
		IacGatewayComponent,
		OrgSitesComponent,
		SiteNotificationsComponent,
		SiteCollectionsComponent,
		OrgSwitchComponent,
		EnergyHomeComponent,
		SiteCollectionComponent,
		EnergyListComponent,
		EnergySiteComponent,
		AqCollectionsComponent,
		OrgUserDetailsComponent,
		DirtyMessageComponent,
		AqCollectionsReportComponent,
		AqReportTypeComponent,
		DialogHelpComponent,
		InsightsReviewsLandingComponent,
		InsightsReviewItemComponent,
		InsightsReviewHelpComponent,
		OrgBatteryComponent,
		AutomationReviewComponent,
		InsightsReviewFirstTimeComponent,
		SiteHoursComponent,
		InsightsReviewsListComponent,
		AqConfigureRagComponent,
		InsightsReviewBatteryComponent,
		EngageConfigComponent,
		TelemetryTableComponent,
		OrgUsersActivityComponent,
		IssueTrackingHomeComponent,
		IssueTrackingAdminComponent,
		IssueTrackingListComponent,
		IssueTrackingItemComponent
	],
	imports: [
		GoogleMapsModule,
		SharedModule,
		BrowserModule,
		FormsModule,
		AppRoutingModule,
		WebcamModule,
		BrowserAnimationsModule
	],
	providers: [provideHttpClient(), AuthGuardService, { provide: LOCALE_ID, useValue: 'en-GB' }],
	bootstrap: [AppComponent]
})
export class AppModule {

}
