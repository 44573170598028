import { Component, signal } from '@angular/core';
import { Site } from 'app/classes/site';
import { AssetService, IGetEnergyAsset } from 'app/shared/asset.service';
import { SiteService } from 'app/shared/site.service';

@Component({
    selector: 'app-energy-list',
    templateUrl: './energy-list.component.html',
    styleUrl: './energy-list.component.css',
    standalone: false
})
export class EnergyListComponent {

  assets = signal<IGetEnergyAsset[]>(null);
  sites = signal<Site[]>([]);

  assetTypesAllowed = [72, 73];

  months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

  grandTotals: number[];

  tabIndex = signal<number>(0);

  currentYear = new Date().getFullYear();
  onYear = signal<number>(null);

  constructor(private assetService: AssetService, private siteService: SiteService) {
    this.localStorageGet();
    siteService.getSitesContainsAssetTypes(this.assetTypesAllowed).then(this.sites.set);
    this.getAssets();
  }

  getAssets() {
    this.assets.set(null);
    this.grandTotals = [null, null, null, null, null, null, null, null, null, null, null, null];

    this.assetService.getEnergyAssets(this.onYear()).then(assets => {
      this.assets.set(assets);
      assets.forEach(asset => {
        if (asset.months) {
          asset.months.forEach((v, i) => {
            if (v !== null) {
              this.grandTotals[i] = (this.grandTotals[i] || 0) + v;
            }
          });
        }
      });
    });
  }


  localStorageGet() {
    const storageString = JSON.parse(localStorage.getItem('energy:assets:list') || "{}");
    this.onYear.set(storageString.year || new Date().getFullYear());
  }

  localStorageSave() {
    localStorage.setItem('energy:assets:list', JSON.stringify({ year: this.onYear() }));
  }

  goBack() {
    if (this.onYear() === 2022) {
      return;
    }
    this.onYear.set(this.onYear() - 1);
    this.localStorageSave();
    this.getAssets();
  }

  goForward() {
    if (this.onYear() === this.currentYear) {
      return;
    }
    this.onYear.set(this.onYear() + 1);
    this.localStorageSave();
    this.getAssets();
  }

  tabIndexSet(index: number) {
    this.tabIndex.set(index);
  }
}
