import { Component, Input, OnInit } from '@angular/core';
import { Alarm } from 'app/classes/alarm';
import { AlarmService } from "../../../shared/alarm.service";
import { StoreService } from 'app/shared/store.service';

@Component({
    selector: 'app-rulesv3-item-alarm-timeline',
    templateUrl: './rulesv3-item-alarm-timeline.component.html',
    styleUrls: ['./rulesv3-item-alarm-timeline.component.css'],
    standalone: false
})
export class Rulesv3ItemAlarmTimelineComponent implements OnInit {

  _alarm: Alarm;
  @Input()
  public set alarm(v: Alarm) {
    this._alarm = v;
    if (v) {
      this.doWork();
    }
  }

  public get alarm(): Alarm {
    return this._alarm;
  }

  timeline: { icon: string, words: string, date: Date, connector: string, colour: string, name: string, logo: string }[] = [];

  constructor(private alarmService: AlarmService, private storeService: StoreService) { }

  ngOnInit(): void {
  }

  doWork() {
    const theme = this.storeService.getTheme();
    this.timeline = [];
    let findings, history;
    if (!this.alarm.findings) {
      const response = this.alarmService.getHistory(this.alarm.timeline);
      this.alarm.findings = response.findings;
      this.alarm.history = response.history;
    }

    findings = this.alarm.findings;
    history = this.alarm.history;

    if (this.alarm.history) {
      for (let index = 0; index < this.alarm.history.length; index++) {
        const item = this.alarm.history[index];

        let icon = '';
        let logo = null;
        let words = item.value;
        let colour = theme === 'light' ? 'black' : 'white';
        switch (item.action) {
          case 'state_change':
            switch (item.value) {
              case 'new':
                words = 'Alarmed';
                icon = 'fa-bell';
                colour = 'red';
                break;
              case 'acknowledged':
                if (item.name) {
                  words = `Acknowledged`;
                } else {
                  words = 'Acknowledged';
                }
                icon = 'fa-user';
                colour = 'blue';
                break;

              case 'resolved':
                words = this.alarm.findings.text;
                icon = 'fa-check';
                colour = 'green';
                break;
              case 'paused':
              case 'pause':
                words = 'Paused';
                icon = 'fa-pause';
                colour = 'orange';
                break;
            }
            if (item.notes) {
              words += ` <q>${item.notes}</q>`;
            }
            break;
          case 'notes':
            icon = 'fa-pencil';
            colour = 'black';
            words = `<q>${words}</q>`;
            break;
          case 'email':
            words = 'Emailed ' + item.value;
            icon = 'fa-envelope';
            break;
          case 'elogbook_job':
            words = 'Elogbooks job #' + item.value;
            icon = null;
            logo = 'elogbooks';
            break;
        }
        let connector = '|';
        if (index === this.alarm.history.length - 1) {
          connector = '';
        } else {

        }
        this.timeline.push({ icon, words, date: item.createdAt, connector, colour, name: item.name, logo });
      }
    }
  }
}
