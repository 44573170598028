<section>
  <div class="text-center mb-half">
    @for (sensorType of sensorTypes(); track sensorType.id) {
      <div class="sensor-button" (click)="sensorClick(sensorType)" [pTooltip]="sensorType.count + ' sensor' + (sensorType.count > 1 ? 's' : '')" tooltipPosition="top" [class.selected]="sensorTypeSelected()?.id === sensorType.id">
        <div>{{ sensorType.title }}</div>
        <div>{{ sensorType.count }}</div>
      </div>
    }
    @if (sensorTypeSelected() && !sensorExists() && sensorTypes().length) {
      <div class="sensor-button does-not-exist" (click)="sensorClick(sensorTypeSelected())" pTooltip="Sensor type does not exist" tooltipPosition="top" [class.selected]="true">
        <div>{{ sensorTypeSelected().title }}</div>
        <div>0</div>
      </div>
    }
  </div>
</section>
