import { Component, Input } from '@angular/core';
import { VisualiseItem } from 'app/classes/visualise-item';
import { IVisualisationConfig, VisualiseService } from "app/shared/visualise.service";

@Component({
    selector: 'app-visualise-tile',
    templateUrl: './visualise-tile.component.html',
    styleUrls: ['./visualise-tile.component.css'],
    standalone: false
})
export class VisualiseTileComponent {

  @Input()
  title: string;

  _visualisationConfig: IVisualisationConfig;
  @Input()
  public get visualisationConfig(): IVisualisationConfig {
    return this._visualisationConfig;
  }
  public set visualisationConfig(v: IVisualisationConfig) {
    this._visualisationConfig = v;
    if (v) {
      this.getVisualisation();
    }
  }

  visualisationItem: VisualiseItem;

  constructor(private visualiseService: VisualiseService) {

  }

  getVisualisation() {
    const { id, dates, orgId, size, siteId } = this.visualisationConfig;
    this.visualiseService.getVisualisationData(id, dates, orgId, siteId).then(visualisationItem => {
      visualisationItem.height = size?.height;
      this.visualisationItem = visualisationItem;
    });
  }
}



