import { Component, OnInit, OnDestroy, signal } from "@angular/core";
import { Router } from "@angular/router";
import { APIService } from "../../shared/api.service";
import { Asset } from "../../classes/asset";
import { from, Subscription } from "rxjs";
import { StoreService } from "../../shared/store.service";
import { Site } from "../../classes/site";
import { Gateway } from "../../classes/gateway";
import { filter, distinct } from "rxjs/operators";
import { Org } from "app/classes/org";
import { SetpointsService } from "app/shared/setpoints.service";
import { DialogService } from "primeng/dynamicdialog";
import { DialogSetpointsDeleteComponent } from "app/dialogs/dialog-setpoints-delete/dialog-setpoints-delete.component";

@Component({
  selector: "app-setpoint-list",
  templateUrl: "./setpoint-list.component.html",
  styleUrls: ["./setpoint-list.component.css"],
  providers: [DialogService],
  standalone: false
})
export class SetpointListComponent implements OnInit, OnDestroy {
  static readonly componentName: string = "SetpointListComponent";

  isInMultiSelect: boolean;

  leftSlider = "00:00";
  rightSlider = "24:00";
  assets: any[];

  // Assets to display
  filteredAssets: any[];
  // Assets filtered by setpoint dropdown
  setpointFilterAssets: any[] = null;
  rangeValues: number[] = [0, 2400];

  // The asset the user clicked on - expands details
  selectedAsset: Asset;
  sectionBannerBackSub: any;
  setpointFilter = "all";

  sites: Site[] = [];
  gateways: Gateway[] = [];
  selected: any = {
    site: { title: "" },
    org: { shortTitle: "" },
    gateway: { title: "" },
    asset: { id: null },
    options: {
      humidity: true,
      temperature: true,
      pressure: true,
      lux: true,
      distance: true,
      red: true,
      amber: true,
      green: true,
    },
  };

  orgsSubscription: Subscription;
  selectedPagSubscription: Subscription;
  orgs: Org[];
  orgKeys: string[] = [];

  isLoading = signal(true);
  rangeId = 2;

  columns = { org: true, site: true, gateway: true, asset: true };

  filteredCount = signal<number>(null);

  filtered: {
    lists: { assets: Site[] };
    text: { assets: string };
  } = {
      lists: { assets: [] },
      text: { assets: "" },
    };

  constructor(
    public dialogService: DialogService, private setpointService: SetpointsService, private storeService: StoreService, private router: Router, private apiService: APIService) {
    console.log("SetpointListComponent constructor()");
    this.isLoading.set(true);
    this.restoreLocalStorage();

    this.orgsSubscription = storeService.orgs.subscribe((orgs) => {
      if (orgs && orgs.length) {
        // Filter out hidden orgs
        this.orgs = orgs.filter(o => !o.hide).sort((a, b) => (a.shortTitle > b.shortTitle ? 1 : -1));
        if (this.orgs.length === 1) {
          // No need to select orgs, only one
          this.columns.org = false;
        }
      } else {
        this.orgs = [];
      }
    });

    this.selectedPagSubscription = storeService.selectedPageOption.pipe(filter((option) => option !== null)).subscribe((option) => {
      console.log("got option", option);
      switch (option.id) {
        case "export":
          this.router.navigate(["/export", "setpoints"]);
          break;
        case "help":
          this.router.navigate(["/training", 2, "modules", 17]);
          break;
      }
    });

    this.isLoading.set(true);
    setpointService.getSetpointableAssetsForUser().then((assets) => {
      this.assets = assets;
      this.applyOrgs();
      // Distinct sites
      from(assets)
        .pipe(distinct((asset) => asset.gateway.site.title))
        .forEach((asset) => {
          this.sites.push(new Site({ title: asset.gateway.site.title }));
        });

      // Distinct gateways
      from(assets)
        .pipe(distinct((asset) => asset.gateway.title))
        .forEach((asset) => {
          this.gateways.push(new Gateway({ title: asset.gateway.title, id: asset.gateway.id }));
        });

      let viewConfig: any = localStorage.getItem("setpoints:list");

      if (viewConfig) {
        try {
          viewConfig = JSON.parse(viewConfig);
          this.setpointFilter = viewConfig.filter;
          this.leftSlider = viewConfig.leftSlider || "00:00";
          this.rightSlider = viewConfig.rightSlider || "24:00";
          this.filterResults();
        } catch (e) {
          this.setFilteredAssets();
        }
      } else {
        this.setFilteredAssets();
      }
      this.isLoading.set(false);
    });

    this.sectionBannerBackSub = this.storeService.sectionBannerBack.pipe(filter((sbb) => !!sbb && sbb.state === true && sbb.section === "setpoints")).subscribe((state) => {
      console.log("state changed to", state);
      this.resetSelectedAsset();
    });
  }

  ngOnInit() { }



  disableSetpoints() {
    const dialogRef = this.dialogService.open(DialogSetpointsDeleteComponent, {
      width: '800px',
      header: 'Disable ' + (this.rangeId === 2 ? 'Operation Hours' : 'Out of operational hours') + ' Setpoints',
      data: { action: 'setpoints-disable', setpoints: this.filteredAssets.filter(a => a.isSelected), rangeId: this.rangeId }
    });
  }

  deleteSetpoints() {
    const dialogRef = this.dialogService.open(DialogSetpointsDeleteComponent, {
      width: '80%',
      header: 'Delete ' + (this.rangeId === 2 ? 'Operation Hours' : 'Out of operational hours') + ' Setpoints',
      data: { action: 'setpoints-delete', setpoints: this.filteredAssets.filter(a => a.isSelected), rangeId: this.rangeId }
    });
  }

  selectAllToggle() {
    const hasSomeUnchecked = this.filteredAssets.some(a => !a.isSelected);
    if (hasSomeUnchecked) {
      this.filteredAssets.forEach((asset) => {
        asset.isSelected = true;
      });
    } else {
      this.filteredAssets.forEach((asset) => {
        asset.isSelected = false;
      });
    }
  }

  selectAsset(event: MouseEvent, asset) {
    const isShiftKey = event.shiftKey;
    const isCtrlKey = event.ctrlKey;

    if ((isShiftKey || isCtrlKey) || this.isInMultiSelect) {
      this.isInMultiSelect = true;
      asset.isSelected = !asset.isSelected;
    }

    if (this.isInMultiSelect) {
      if (!this.filteredAssets.some(a => a.isSelected)) {
        this.isInMultiSelect = false;
      }
    } else {
      this.selected.asset.id = asset.id;
      this.saveLocalStorage();
      this.router.navigate(["/setpoints", asset.id, this.rangeId]);
    }
  }

  applyOrgs() {
    console.log("applyOrgs()");
    if (this.assets && this.orgs) {
      this.assets.forEach((asset) => {
        const org = this.orgs.find((org) => org.id === asset.gateway.site.org.id);
        if (org) {
          asset.gateway.site.org.title = org.title;
          asset.gateway.site.org.shortTitle = org.shortTitle;
          asset.gateway.site.org.logo = org.logo;
        }
      });
    }
    console.log("applyOrgs() end");
  }

  setFilteredAssets() {
    console.log("setFilteredAssets()");
    this.isInMultiSelect = false;

    this.isLoading.set(false);
    this.filteredAssets = this.assets.filter((asset) => {
      let ok = true;
      asset.isSelected = false;
      if (this.selected.org.shortTitle && asset.gateway.site.org.shortTitle !== this.selected.org.shortTitle) {
        ok = false;
      }
      if (this.selected.site.title && asset.gateway.site.title !== this.selected.site.title) {
        ok = false;
      }
      if (this.selected.gateway.title && asset.gateway.title !== this.selected.gateway.title) {
        ok = false;
      }
      return ok;
    });



    // Now dropdown filter
    if (this.setpointFilterAssets) {
      this.filteredAssets = this.filteredAssets.filter((asset) => this.setpointFilterAssets.filter((setpointAsset) => setpointAsset.id === asset.id).length > 0);
    }

    this.filteredAssets = this.filteredAssets.filter((a) => {
      if (!this.selected.options[a.rag || "grey"]) {
        // Don't want this rag
        return false;
      }
      if (this.selected.options.bell && a.setpointRuleCount <= 0) {
        return false;
      }
      switch (a.assetType_id) {
        case 2:
        case 9:
          return this.selected.options.temperature;
        case 8:
          return this.selected.options.humidity;
        case 3:
          return this.selected.options.pressure;
        case 7:
        case 11:
          return this.selected.options.lux;
        case 24:
          return this.selected.options.distance;
        default:
          return true;
      }
    });
    this.applySearchFilter();
    this.filteredCount.set(this.filteredAssets.length);
    this.isLoading.set(false);
  }

  setpointFilterChange(forcedState?: string) {
    if (forcedState) {
      this.setpointFilter = forcedState;
    }
    localStorage.setItem("setpoints:list", JSON.stringify({ filter: this.setpointFilter, left: this.leftSlider, right: this.rightSlider }));
    this.filterResults();
  }

  /**
   * Toggle asset types on
   */
  toggle(type) {
    if (!type) return;

    switch (type) {
      case "bell":
        break;
      case "red":
      case "amber":
      case "green":
        const simulated = { ...this.selected.options };
        simulated[type] = !simulated[type];
        if (!simulated.red && !simulated.amber && !simulated.green) {
          // All rags disabled, nothing will be displayed!
          this.apiService.toastWarn("You must have at least one selected", "");
          return false;
        }
    }

    this.selected.options[type] = this.selected.options[type] ? false : true;
    this.saveLocalStorage();
    this.setFilteredAssets();
  }

  rangeChanged() {
    this.saveLocalStorage();
    this.filterResults();
  }

  handleSliderChange(event) {
    this.leftSlider = event.values[0] / 100 + ":00";
    this.rightSlider = event.values[1] / 100 + ":00";
  }

  handleSliderEnd(event) {
    this.filterResults();
  }

  filterResults() {
    console.log("filterResults()");

    if (this.setpointFilter === "all") {
      // No filter required
      this.setpointFilterAssets = null;
      this.setFilteredAssets();
    } else {
      // Get all the setpoints for the range
      this.setpointService.getAssetsWithSetpointsForTime(this.rangeId, this.leftSlider, this.rightSlider).then((r) => {
        if (this.setpointFilter === "configured") {
          // Filter the configured assets
          this.setpointFilterAssets = r;
        } else {
          // Filter assets that have NOT been configured
          this.setpointFilterAssets = this.assets.filter((asset) => r.filter((setpointAsset) => setpointAsset.id === asset.id).length === 0);
        }
        this.setFilteredAssets();
      });
    }
    console.log("filterResults() end");
  }

  postRoute(suffix: string, action?: string, changes?: string) {
    this.apiService.postRoute(`/setpoints/${suffix}`, action, changes);
  }

  resetSelectedAsset() {
    this.apiService.postRoute("/setpoints");
    this.selectedAsset = null;
  }

  orgChanged(event) {
    this.selected.org.shortTitle = event.target.value;
    this.selected.gateway.title = "";
    this.selected.site.title = "";
    this.saveLocalStorage();
    this.setFilteredAssets();
  }

  siteChanged(event) {
    this.selected.site.title = event.target.value;
    this.selected.gateway.title = "";
    this.saveLocalStorage();
    this.setFilteredAssets();
  }

  gatewayChanged(event) {
    this.selected.site.title = "";
    this.selected.gateway.title = event.target.value;
    this.saveLocalStorage();
    this.setFilteredAssets();
  }

  saveLocalStorage() {
    const orgId = this.apiService.getUserOrg().id;
    if (orgId) {
      let payload = {
        org: { shortTitle: this.selected.org.shortTitle },
        site: { title: this.selected.site.title },
        gateway: { title: this.selected.gateway.title },
        asset: { id: this.selected.asset.id },
        options: this.selected.options,
        rangeId: this.rangeId,
        filtered: this.filtered.text
      };

      localStorage.setItem(`${orgId}:` + this.apiService.getUserId() + ":" + SetpointListComponent.componentName, JSON.stringify(payload));
      localStorage.setItem(`org:${orgId}:setpointlist:filter`, JSON.stringify(this.filtered.text));
    }
  }

  restoreLocalStorage() {
    const orgId = this.apiService.getUserOrg().id;
    if (!orgId) {
      return;
    }
    let payload: any = localStorage.getItem(`${orgId}:` + this.apiService.getUserId() + ":" + SetpointListComponent.componentName);
    try {
      payload = JSON.parse(payload);
      if (payload.org) {
        this.selected.org.shortTitle = payload.org.shortTitle;
      }
      this.selected.site.title = payload.site.title;
      this.selected.gateway.title = payload.gateway.title;
      this.selected.asset.id = payload.asset.id;
      this.selected.options = { bell: false, ...this.selected.options, ...payload.options };
      this.rangeId = +(payload.rangeId || 2);
      if (payload?.filtered) {
        this.filtered.text = payload.filtered;
        this.filterResults();
      }
    } catch (e) { }
  }

  clickShowMore() {
    window.location.href = "https://4dmonitoring.co.uk";
  }

  searchChanged(section: "assets", text: string) {
    console.log(text);
    this.filtered.text[section] = text.toLowerCase().trim();
    this.saveLocalStorage();
    this.filterResults();
  }

  getLocalStorage() {
    try {
      const orgId = this.apiService.getUserOrg().id;
      if (!orgId) {
        return;
      }
      const storage = localStorage.getItem(`org:${orgId}:setpointlist:filter`);
      if (!storage) {
        return;
      }
      this.filtered.text = JSON.parse(storage);
    } catch (error) { }
  }

  applySearchFilter() {
    if (this.filtered.text.assets === "") {
      return;
    }

    const searchFor = this.filtered.text.assets;
    console.log("SEARCH_FILTER_START");
    this.filteredAssets = this.filteredAssets.filter((s) => {
      if (s.title.toLowerCase().includes(searchFor)) return true;
      if (s.gateway.title.toLowerCase().includes(searchFor)) return true;
      if (s.gateway.site.title?.toLowerCase().includes(searchFor)) return true;
      if (s.gateway.site.org?.title?.toLowerCase().includes(searchFor)) return true;
      if (s.gateway.site.org?.shortTitle?.toLowerCase().includes(searchFor)) return true;
      if (s.id === +searchFor) return true;
      if (s.gateway.id.toLowerCase().includes(searchFor)) return true;
      /*
      if (s.address?.address1.toLowerCase().includes(searchFor)) return true;
      if (s.address?.addressCounty.toLowerCase().includes(searchFor)) return true;
      if (s.address?.addressTown.toLowerCase().includes(searchFor)) return true;
      if (s.address?.addressPostcode.toLowerCase().includes(searchFor)) return true;
      */
    });
    console.log("SEARCH_FILTER_END");
  }

  ngOnDestroy() {
    if (this.orgsSubscription) {
      this.orgsSubscription.unsubscribe();
    }
    if (this.selectedPagSubscription) {
      this.selectedPagSubscription.unsubscribe();
    }
    this.sectionBannerBackSub.unsubscribe();
    console.log("SUBSCRIPTIONS DESTROYED");
  }
}
