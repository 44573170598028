import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-dashboard-tile',
    templateUrl: './dashboard-tile.component.html',
    styleUrls: ['./dashboard-tile.component.css'],
    standalone: false
})
export class DashboardTileComponent implements OnInit {

  /**
   * The tile to display
   */
  @Input()
  tileName: string;

  @Input()
  active: boolean;

  @Input()
  recent: any;

  constructor() { }

  ngOnInit() {
  }

}
