<article>
  <div class="tabview-list">
    <div [class.active]="tabIndex() === 0" (click)="tabClick(0)"><i class="mdi mdi-view-list"></i> Issues</div>
    <div [class.active]="tabIndex() === 1" (click)="tabClick(1)"><i class="mdi mdi-shield-account-outline"></i> Admin</div>
    <div></div>
  </div>
  <main>
    <router-outlet></router-outlet>
  </main>
</article>
