import { Component, computed, input } from '@angular/core';
import { CollectionAsset } from 'app/classes/collection-for-assets';
import { IAlarmBlock, IGetCollectionAsset, IInsightAPITypeItem, Insight } from 'app/classes/insight';
import { LimitLine } from 'app/setpoints/setpoint-query/setpoint-query.component';
import { StoreService } from 'app/shared/store.service';

@Component({
    selector: 'app-aq-report-type',
    templateUrl: './aq-report-type.component.html',
    styleUrl: './aq-report-type.component.css',
    standalone: false
})
export class AqReportTypeComponent {
  insight = input.required<Insight>();
  type = input.required<'voc' | 'co2' | 'temperature' | 'humidity' | 'pm1' | 'pm2.5' | 'pm10'>();

  typeIndex: number;

  wantsPageBreak = input<boolean>(false);
  collections = input.required<CollectionAsset[]>();
  grandTotals = input.required<IGrandTotals>();
  alarmBlocks: IAlarmBlock[];
  data: IInsightAPITypeItem;
  limitLines: LimitLine[];
  svgLocation = 'assets/svg/';
  totals: IGetCollectionAsset[];

  constructor(private storeService: StoreService) {
    if (storeService.getTheme(true) === 'dark') {
      this.svgLocation = 'assets/svg/white/';
    }
  }

  ngOnInit(): void {
    if (this.insight().telemetryCollection) {

      const obj = { 'co2': 0, 'humidity': 1, 'temperature': 2, 'voc': 3, 'pm1': 4, 'pm2.5': 5, 'pm10': 6 };
      this.typeIndex = obj[this.type()];

      this.calc();
    }
  }

  calc() {
    this.alarmBlocks = this.insight().getAlarmsForType(this.type());
    const collectionIndex = this.insight().typeList.findIndex(v => v === this.type());
    this.data = this.insight().telemetryCollection[collectionIndex];
    this.limitLines = this.insight().getLimitLines(this.type());
    this.totals = this.insight().getCollectionAssetsForType(this.type());

  }
}


export interface IGrandTotals {
  avg: number;
  max: number;
  min: number;
  value: number;
  count: number;
}
