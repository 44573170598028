import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetType } from 'app/classes/asset';
import { Setpoint } from 'app/classes/setpoint';
import { SetpointTemplate } from 'app/classes/setpoint-template';
import { SetpointTemplateCategory } from 'app/classes/setpoint-template-category';
import { AlarmRAGState } from 'app/setpoints/setpoint-detail/setpoint-detail.component';
import { APIService } from 'app/shared/api.service';
import { AssetService } from 'app/shared/asset.service';
import { ClipboardService } from 'app/shared/clipboard.service';
import { SetpointsService } from 'app/shared/setpoints.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-setpoint-template-item',
  templateUrl: './setpoint-template-item.component.html',
  styleUrls: ['./setpoint-template-item.component.css'],
  standalone: false
})
export class SetpointTemplateItemComponent implements OnInit, OnDestroy {
  templateId: number;
  template: SetpointTemplate;

  rangeId = 2;
  alarms: AlarmRAGState = new AlarmRAGState();
  weekdays: any[] = [
    { title: "Monday", idx: 1 },
    { title: "Tuesday", idx: 2 },
    { title: "Wednesday", idx: 3 },
    { title: "Thursday", idx: 4 },
    { title: "Friday", idx: 5 },
    { title: "Saturday", idx: 6 },
    { title: "Sunday", idx: 7 },
  ];
  copySetpoint: any;
  cats: SetpointTemplateCategory[] = [];
  subscriptions: Subscription[] = [];
  selectedWeekday: any;

  constructor(private clipboardService: ClipboardService, public setpointsService: SetpointsService, private route: ActivatedRoute, private router: Router, private assetService: AssetService, public apiService: APIService) {

    setpointsService.getTemplateCategories().then(cats => this.cats = cats.filter(cat => cat.isSelectable));
  }

  onBlur() {
    throw new Error('not implemented');
  }
  change(e: any) {
    throw new Error('not implemented');
  }
  generateGreenRAGTooltip() {
    throw new Error('not implemented');
  }

  sensorsSelected(sensorList: number[]) {

    console.log(sensorList);
    this.template.assetTypeIds = sensorList;
  }
  ngOnInit(): void {
    const templateId = this.route.snapshot.params['templateid'];
    if (templateId === 'new') {
      this.templateId = null;
    } else {
      this.templateId = templateId;
    }
    console.log(`templateid`, this.templateId);
    this.clipboardService.clipboard.subscribe((event) => {
      if (!event) {
        return;
      }
      console.log("clipboard is ", event);

      this.copySetpoint = event.obj;
    });

    this.init();
  }

  init() {
    if (this.templateId) {
      this.setpointsService.getTemplate(this.templateId).then(template => {
        this.template = template;
      });
    } else {
      this.template = new SetpointTemplate();
    }
  }

  linkRule() { }

  async toolbarClick(event: any) {
    this.template.clearSetpoints();
  }

  enableWeekday(index: number) {
    console.log(` enableWeekday(${index})`, this.template.setpoints[index].isActive);
  }

  allday(index: number) {
    if (this.template.setpoints[index].allday) {
      this.template.setpoints[index].allday = false;
    } else {
      this.template.setpoints[index].allday = true;
      this.template.setpoints[index].startsAt = null;
      this.template.setpoints[index].endsAt = null;
    }
  }

  doCopy(index) {
    const sp = this.template.setpoints[index];
    // Copy all of setpoint for paste into other assets.
    const copySetpoint = {
      title: `Setpoint template "${this.template?.title || ''}"`,
      index: index,
      startsAt: sp.startsAt,
      endsAt: sp.endsAt,
      min: sp.min,
      max: sp.max,
      red_min: sp.red_min,
      red_max: sp.red_max,
      amber_max: sp.amber_max,
      amber_min: sp.amber_min,
      allday: sp.allday,
      isActive: true,
    };

    this.clipboardService.copy("setpoint", copySetpoint);
  }

  copy(index: number) {

    return this.doCopy(index);
  }

  paste(index: number) {
    if (this.copySetpoint === null) {
      return;
    }

    const sp = this.copySetpoint;

    this.template.setpoints[index] = new Setpoint(null, index, sp.startsAt, sp.endsAt, sp.allday, sp.min, sp.max, true, sp.amber_min, sp.amber_max, sp.red_min, sp.red_max);
  }

  async save() {
    await this.setpointsService.updateTemplate(this.template);
  }

  cancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }


  async delete() {
    await this.setpointsService.deleteTemplate(this.template.id);
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  async submit() {
    await this.save();
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  async scheduler() {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
