import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-journey-landing',
    templateUrl: './journey-landing.component.html',
    styleUrls: ['./journey-landing.component.css'],
    standalone: false
})
export class JourneyLandingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  toggleFullscreen() {
    var doc:any = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen = docEl.requestFullscreen || docEl.webkitRequestFullScreen;
    var cancelFullScreen = doc.exitFullscreen || doc.webkitExitFullscreen;

    if (!doc.fullscreenElement && !doc.webkitFullscreenElement) {
      requestFullScreen.call(docEl);
    }
    else {
      cancelFullScreen.call(doc);
    }
  }

}
