import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { APIService } from '../../shared/api.service';
import { Asset } from '../../classes/asset';
import { Setpoint } from '../../classes/setpoint';
import moment from 'moment';
import { SetpointsService } from 'app/shared/setpoints.service';

@Component({
    selector: 'app-setpoint-bars',
    templateUrl: './setpoint-bars.component.html',
    styleUrls: ['./setpoint-bars.component.css'],
    standalone: false
})
export class SetpointBarsComponent implements OnInit {
  static readonly DATE_FORMAT = 'DD-MM-YYY';

  @Input()
  days: any[];

  info: string;
  hoverIndex: number;
  hoverDay: any;
  barWidth = 911;

  _maximumDays: number;
  @Input()
  public set maximumDays(v: number) {
    v = +v;
    if (v && this._maximumDays && this._maximumDays !== v) {
      if (v > 90) {
        // Maximum is 90 days
        v = 90;
      }
      // console.log(`maximumDays change from ${this._maximumDays} to ${v}`);
      this._maximumDays = v;
      this.days = this.initDays(this.days);

    } else {
      this._maximumDays = v;
    }
  }

  public get maximumDays(): number {
    return this._maximumDays
  }

  @Input()
  asset: Asset;

  @Input()
  screenAdjust = true;

  @Output()
  onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(private setpointService: SetpointsService, private apiService: APIService) { }

  ngOnInit() {
    // Load days if not provided

    if (!this.days) {
      this.getDays();
    } else {
      // console.log('using', this.days)
      this.days = this.initDays(this.days);
    }
  }

  getDays() {
    this.setpointService.getSetPointsHistoryForAsset(this.asset, 2, this._maximumDays || 90).then(r => {
      // console.log(r.length);
      this.days = this.initDays(r);
    });
  }

  hasClicked(day: number) {
    const selectedDay = this.days[day];
    if (!selectedDay) {
      // Nothing to see, no data here
      return;
    }
    this.onClick.emit({ day, asset_id: this.asset.id, info: selectedDay.date });
  }

  /**
   * Prepare the days array for display, create a fixed width array and place
   * each day in the correct slot
   * @param days
   */
  initDays(days): any[] {
    if (!days || days.length === 0) {
      return null;
    }

    this.barWidth = this._maximumDays * 10.1;

    const slots = [];
    // Fill with nulls, days that don't exist will show as grey
    slots.fill(null, 0, this._maximumDays - 1);

    const firstDate: moment.Moment = moment(days[0].date, SetpointBarsComponent.DATE_FORMAT);
    const lastDate: moment.Moment = moment(days[days.length - 1].date, SetpointBarsComponent.DATE_FORMAT);
    if (firstDate.isAfter(lastDate)) {
      // Reverse the order, current date must be rightmost.
      days.reverse();
    }

    days.forEach(day => {
      const date: moment.Moment = moment(day.date, SetpointBarsComponent.DATE_FORMAT);

      const slotIndex = Math.abs(lastDate.diff(date, 'days'));
      slots[slotIndex] = day;
    });

    return slots;
  }

  mouseenter(index: number) {
    if (!this.days[index]) {
      return;
    }
    this.info = this.days[index].date;
    this.hoverIndex = index;
    this.hoverDay = this.days[index];
  }

  mouseleave() {
    this.info = '';
    this.hoverIndex = null;
    this.hoverDay = null;
  }

}
