@defer (when hasLoaded()) {
  <article class="content-container">
    <main>
      <section class="settings">
        @if (isEnabled) {
          <app-info-panel state="success">Issue tracking is enabled</app-info-panel>

          <div class="data">
            <p>You can go back to any version 2 building review in the past, and add it as an issue.</p>
            <p>Select the "Track these issues" button in the building review to add it.</p>
          </div>

          <div class="data">
            <p class="text-center">When automatically adding issues from reviews, the RAG values below are added as issues.</p>
            <div class="flex text-center">
              <div [class.is-not-selected]="ragSelected() !== 'green'">
                <app-button label="Green" (click)="setRAG('green')" [isMuted]="ragSelected() !== 'green'" [selected]="ragSelected() === 'green'" />
                <i class="mdi mdi-arrow-right"></i>
              </div>
              <div [class.is-not-selected]="ragSelected() === 'red'">
                <app-button label="Amber" (click)="setRAG('amber')" [isMuted]="ragSelected() === 'red'" [selected]="ragSelected() === 'amber'" />
                <i class="mdi mdi-arrow-right"></i>
              </div>
              <div>
                <app-button label="Red" (click)="setRAG('red')" [selected]="ragSelected() === 'red'" />
              </div>
            </div>
          </div>

          <div class="data">
            <p class="text-center">You can disable issue tracking at any time, disabling removes access but does not remove any data.</p>
            <p class="text-center">
              <app-button icon="mdi mdi-clipboard-text-off" label="Disable issue tracking" (click)="disable()"></app-button>
            </p>
          </div>
        } @else {
          <app-info-panel state="warning">Issue tracking not enabled</app-info-panel>

          <div class="data">
            <p>
              Issue tracking has not been enabled for
              <span class="text-emphasise">{{ org().title }}.</span>
            </p>
            <p>After enabling issue tracking, the system starts tracking new building reviews and adds issue tracking records for all annotations on new reviews.</p>
            <p>If this has been enabled for the first time, you will will not see any existing building reviews by default.</p>
            <p class="text-center">
              <app-button icon="mdi mdi-text-box-check-outline" label="Enable issue tracking" (click)="enable()"></app-button>
            </p>
          </div>
        }
      </section>
    </main>
  </article>
} @placeholder {
  <i class="mdi mdi-loading mdi-spin-x2"></i>
}
