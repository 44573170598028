import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit, NgZone, Output, EventEmitter } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { APIService } from 'app/shared/api.service';
import * as d3 from 'd3';
import moment from 'moment';
import { AssetService } from 'app/shared/asset.service';
import { D3ChartService } from 'app/shared/d3chart.service';
import { StoreService } from 'app/shared/store.service';

@Component({
    selector: 'app-d3-bar',
    templateUrl: './d3-bar.component.html',
    styleUrls: ['./d3-bar.component.css'],
    standalone: false
})
export class D3BarComponent implements OnInit, AfterViewInit {

  @Input()
  colours: { from: number, to: number, colour: string }[];

  // Days that are enabled, one row per enabled date {from: to:}
  @Input()
  ranges: { from: Date, to: Date }[];

  @Input()
  weather: { d: Date, icon: string }[];

  @Input()
  max: number;

  @Input()
  min: number;

  @Input()
  telemetry: any[];

  @Input()
  width = 800;

  @Input()
  height = 400;

  @Input()
  setpoints: any[];

  @Input()
  dateFrom: Date;

  @Input()
  dateTo: Date;

  @Input()
  showLegend = true;

  @Input()
  legendHasMultipleRows = false;

  @Input()
  minMaxFromData = false;

  @Input()
  assetId: number;

  _asset: Asset;
  @Input()
  public get asset(): Asset {
    return this._asset;
  }
  public set asset(v: Asset) {
    if (!v) {
      this._asset = null;
      return;
    }
    this._asset = v;
  }

  @Output()
  dimensionsChanged = new EventEmitter<DOMRect>();

  data: any[];
  originalData: any[];
  svg: any;
  g: any;
  // Telemetry dates
  telemetryFrom: Date;
  telemetryTo: Date;
  margin: { top: number, right: number, bottom: number, left: number } = { top: 20, right: 30, bottom: 20, left: 40 };
  history: any[] = [];
  hticks = 60;
  colorScale;
  hostElement;
  x;
  y;
  yWeather;
  xDomain;
  yDomain;

  /*
   * 1 = week
   * 2 = month
   * 3 = 3 months // Hourly
   * 4 = 6 months // Hourly
   * 5 = 1 year // Day
   */
  previousZoom = 3;
  zoom = 3;
  penWidth = 1;
  dataAccuracy: 'true' | 'hourly' | 'day' = 'hourly';
  dateSelected: moment.Moment;
  showLabel = 1;
  item: any;
  range: any[];
  //fromDate: Moment;
  //toDate: Moment;
  pause: boolean;
  // free - nothing selected , range - start selected (itemFrom), ranged (itemTo/ItemFrom selected)
  cursorMode: 'free' | 'range' | 'ranged' = 'free';
  ragColours = { red: 'red', amber: 'orange', green: 'green' };
  inputMode: 'annotate' = null;
  inputText: string;
  isShowingPanel: boolean = null;
  isWorking: boolean = true;
  isShowingAssetDashboard: boolean;
  annotation: any = { text: '', users: [] };
  isLight: boolean;

  // Organisation users for annotations
  orgUsers: any[];

  @ViewChild('info') infoBox: ElementRef;

  constructor(private apiService: APIService, private d3Service: D3ChartService, private assetService: AssetService, private elRef: ElementRef, private ngZone: NgZone, private storeService: StoreService) {
    this.hostElement = this.elRef.nativeElement;
    this.isLight = storeService.getTheme(true) === 'light';
  }

  ngOnInit(): void {
    const self = this;
    this.isWorking = true;
    this.width = this.hostElement.parentNode.getBoundingClientRect().width;
    this.dimensionsChanged.emit(this.hostElement.parentNode.getBoundingClientRect());
    this.buildFromTelemetry();
  }

  ngAfterViewInit() {
    // console.log(this.hostElement);
  }

  private removeExistingChartFromParent() {
    d3.select(this.hostElement).select('svg').remove();
  }

  private setColorScale() {

  }

  private setChartDimensions() {
    let viewBoxHeight = this.height
    let viewBoxWidth = this.width
    this.svg = d3.select(this.hostElement)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .style('user-select', 'none')
      .attr('viewBox', '0 0 ' + viewBoxWidth + ' ' + viewBoxHeight);
  }

  private addGraphicsElement() {
    this.g = this.svg.append("g")
      .attr("transform", "translate(0,0)");
  }

  private addDefs() {
    this.svg
      .append('defs')
      .append("pattern")
      .attr("id", "hatch-1")
      .attr("width", 4)
      .attr("height", 4)
      .attr("patternTransform", `rotate(250)`)
      .attr("patternUnits", "userSpaceOnUse")
      .append("rect")
      .attr("width", 2)
      .attr("height", 4)
      .style("fill", "#e8e8e8");
  }

  transform(d) {
    return { date: d3.timeParse("%Y-%m-%d")(d.d), value: d.v }
  }

  mouseOver(d, i) {
    //  console.info(d, i);
    this.item = d;
  }

  // User wants to start a range
  nextPointClick() {
    this.range = [this.item];
    this.cursorMode = 'range';
  }

  // Do something with this selection
  addPoint() {

  }

  svgClick() {
    if (!this.pause) {
      // We have our first click! 
      this.pause = true;
    } else {
      // Stop the pause
      this.pause = false;
    }

    if (this.cursorMode === 'range') {
      this.cursorMode = 'ranged';
    } else {
      /* DISABLED UNTIL ANNOTATIONS IMPLEMENTED 
      this.pause = !this.pause;
      */
    }
  }

  svgDoubleClick() {
    this.dateSelected = moment(this.item.d);
  }

  buildFromTelemetry() {
    let self = this;
    this.dataAccuracy = 'true';

    console.log('building chart');
    this.removeExistingChartFromParent();
    this.setChartDimensions();
    this.addDefs();
    this.setColorScale();
    this.addGraphicsElement();
    if (!this.telemetry || this.telemetry.length === 0) {
      return;
    }

    let xFrom, xTo;

    if (this.dateFrom && this.dateTo) {
      xFrom = this.dateFrom;
      xTo = this.dateTo;
      if (moment.isMoment(xFrom)) {
        // Dates must be native
        xFrom = xFrom.toDate();
        xTo = xTo.toDate();
      }
    } else {
      // Get dates from telemetry
      // Clone as we will be modifiying this

      xFrom = this.telemetry[0].d;
      xTo = this.telemetry[this.telemetry.length - 1].d;
    }

    // Make sure low to high dates
    if (xFrom > xTo) {
      [xTo, xFrom] = [xFrom, xTo];
    }

    // Make sure telemetry is in the right order
    if (this.telemetry.length > 1 && +this.telemetry[0].d > +this.telemetry[1].d) {
      this.telemetry.reverse();
    }

    // dates start from time 00:00:00 so hourly weather is aligned.
    if (!this.dateFrom) {
      xFrom.setHours(0, 0, 0);
      xTo.setHours(23, 59, 59);
    }

    this.telemetryFrom = xFrom;
    this.telemetryTo = xTo;
    // const filtered = this.telemetry.filter(row => row.d >= xFrom && row.d <= xTo);

    const days = Math.abs(moment(xTo).diff(moment(xFrom), 'days') + 1);
    console.log(`Chart has ${days} days`);

    const filtered = this.telemetry;

    if (this.dateFrom) {
      // Explicity set the start/end date
      this.xDomain = [xFrom, xTo];
    } else {
      this.xDomain = d3.extent(filtered, (d: any) => d.d);
    }

    if (this.min !== null && this.max !== null) {
      const max = Math.max(...filtered.map(f => f.v));
      const min = Math.min(...filtered.map(f => f.v));
      console.log('MIN/MAX', min, max);


      this.max = this.max > max ? this.max : max;
      this.min = this.min < min ? this.min : min;

      this.yDomain = [this.max, this.min];
    } else {
      this.yDomain = d3.extent(filtered, (d: any) => d.v).reverse();
    }
    console.log('Domain calc...done');
    this.x = d3
      .scaleBand()
      .range([this.margin.left, this.width - this.margin.right])
      .padding(0.1);
    // .domain(this.xDomain);


    this.x.domain(filtered.map((d) => {
      return d.i;
    }));

    if (this.showLegend) {
      this.svg.append('g')
        .attr('class', 'xaxis')
        .attr("transform", `translate(0,${(this.height - (this.margin.top + this.margin.bottom))})`)
        .call(d3.axisBottom(this.x));

      if (this.legendHasMultipleRows) {

        const row2 = this.svg.selectAll("null").data(filtered).enter().append("g")
          .attr("class", "xaxis2")
          .attr("font-size", "10")
          .attr("transform", `translate(0,${(this.height - (this.margin.top + this.margin.bottom)) + 20})`);
        row2.append("text")
          .attr("dx", d => {
            return this.x(d.i);
          })
          .attr("dy", "0.8em")
          .style("fill", "#666")
          .text(function (d) {
            return moment(d.d).format('ddd');
          });
      }
    }
    this.y = d3.scaleLinear()
      .domain(this.yDomain)
      .range([this.margin.top, this.height - (this.margin.top + this.margin.bottom)]);

    if (this.showLegend) {
      this.svg.append('g')
        .attr('class', 'yaxis')
        .attr("transform", `translate(${this.margin.left},0)`)
        .call(d3.axisLeft(this.y));
    }

    const xAxis2 = [];
    for (let day = 0; day < days; day++) {
      xAxis2.push({ d: moment(xFrom).add(day, 'days') });
      //const wd = moment(xFrom).add(day, 'days').weekday();
    }

    this.svg.append('g')
      .attr('class', 'ranges');

    const bisectDate = d3.bisector((d: any) => {
      return d.d;
    }).left;

    // Remove items not in ranges

    if (this.ranges && this.weather) {
      this.weather = this.weather.filter(w => {
        // On want weather in the ranges
        return this.ranges.findIndex(r => moment(w.d).dayOfYear() === moment(r.from).dayOfYear()) !== -1;
      });
    }

    const barType: string = 'bar';

    switch (barType) {
      case "bar":

        this.svg.append("g")
          .attr("fill", "steelblue")
          .selectAll("rect")
          .data(filtered)
          .join("rect")
          .attr("x", d => {

            return this.x(d.i);
          })
          .attr("y", d => {

            if (d.v === null) {
              return this.y(this.max);
            } else if (d.v >= 0) {
              return this.y(d.v);
            } else {
              return this.y(0);
            }
          })
          .attr("height", d => {
            if (d.v === null) {
              return Math.abs(self.y(this.min) - self.y(this.max));
            } else {
              const v = d.v || 0;
              const rectHeight = Math.abs(self.y(0) - self.y(v));

              return rectHeight;
            }
          })
          .attr("width", this.x.bandwidth())
          .style("fill", d => {
            if (d.v === null) {
              return 'url(#hatch-1)';
            } else {
              if (this.colours) {
                const colour = this.colours.find(c => {
                  return d.v > c.from && d.v < c.to;
                });
                if (colour) {
                  return colour.colour;
                }
              } else {
                return null;
              }
            }
          });

        // WEATHER

        const fillColour = this.isLight ? 'black' : 'white';
        if (this.weather) {
          this.svg.append("g")
            .attr("class", "weather-block")
            .selectAll("rect")
            .data(this.weather)
            .join("text")
            .attr("x", row => {
              const i = row.d.getDate();

              return this.x(i);
            })
            .attr("y", 20)
            .attr("height", 10)
            .attr("width", this.x.bandwidth())

            .attr("textLength", "1")
            .attr("font-family", "MeteoconsRegular")
            .attr("font-size", "20px")
            .attr("fill", fillColour)
            .attr("font-weight", 100)
            .text(d => {
              switch (d.icon) {
                case 'snow':
                  return 'W';
                case 'sleet':
                  return 'X'

                case "drizzle-thunder":
                case "thunder-rain":
                case "thunder":
                case "rain-thunder":
                  return 'O';

                case "rain":
                case "rain-drizzle-mist":
                case "rain-drizzle":
                case "rain-drizzle-fog":
                case "fog-rain":
                case "rain-mist":
                case "rain-fog":
                case "mist-rain":
                case "mist-rain-drizzle":
                  return 'R';

                case "mist":
                  return "J";

                case "mist-fog":
                case "fog-mist":
                case "fog":
                  return 'M';

                case "mist-drizzle":
                case "drizzle-rain":
                case "drizzle-mist-rain":
                case "drizzle-mist":
                case "drizzle-fog":
                case "drizzle":
                  return 'Q';

                case 'partly-cloudy-night':
                case "cloudy-night":
                  return '4';
                case 'partly-cloudy-day':
                case "cloudy-day":
                case 'cloudy':
                  return 'H';
                case "clear-night":
                  return '2';
                case "clear-day":
                case 'sun':
                  return 'B';
              }
            });
        }
        break;
      default:
        //console.log('bandwidth', this.x.bandwidth());
        const barWidth = this.width / filtered.length;
        this.svg.append("g")
          .selectAll("rect")
          .data(filtered)
          .join("rect")
          .attr("fill", d => d.v === 0 ? '#f6f6f6' : 'steelblue')
          .attr("x", d => this.x(d.d))
          .attr("y", d => this.y(1))
          .attr("height", self.y(0) - self.y(1))
          .attr("width", d => {
            const index = filtered.findIndex(i => i.i === d.i);
            const start = self.x(d.d);

            const end = index < filtered.length - 1 ? self.x(filtered[index + 1].d) : (self.width - (self.margin.right));
            console.log(start, end);
            if (start > end) {
              console.log('oops');
            }
            return (end - start);
          });
        break;
    }

    this.isWorking = false;
  }

  padLeft(nr) {
    return Array(2 - String(nr).length + 1).join('0') + nr;
  }

  assetDashboardClick() {
    console.log(this.item);
    this.isShowingAssetDashboard = true;
  }

  zoomOut() {
    const restore = this.history.pop();
    if (!restore || !restore.dateFrom || !restore.dateTo) {
      return;
    }
    this.dateFrom = new Date(restore.dateFrom);
    this.dateTo = new Date(restore.dateTo);
    this.getTelemetry();
  }

  zoomIn(date: Date) {
    console.log(`Zoom into ${date}`);

    if (this.dateTo) {
      this.history.push({ dateFrom: +this.dateFrom, dateTo: +this.dateTo });
    } else {
      this.history.push({ dateFrom: +this.telemetryFrom, dateTo: +this.telemetryTo });
    }
    const df = moment(date).subtract(12, 'hours').startOf('day').toDate();
    const dt = moment(date).add(12, 'hours').endOf('day').toDate();
    this.dateFrom = df;
    this.dateTo = dt;
    this.getTelemetry();
  }

  getTelemetry() {
    this.d3Service.getTelemetry(this.asset.id, this.dateFrom, this.dateTo, this.setpoints)
      .then(r => {
        this.item = null;
        this.pause = false;
        this.telemetry = r.packets;

        this.buildFromTelemetry();
      });
  }

  onResize(event: any) {
    this.width = this.hostElement.parentNode.getBoundingClientRect().width;
    this.buildFromTelemetry();
  }
}
