import { Component } from '@angular/core';
import { IAlarmHistoryItem, RuleService } from 'app/shared/rule.service';

@Component({
    selector: 'app-rule-engine-v2-alarms',
    templateUrl: './rule-engine-v2-alarms.component.html',
    styleUrl: './rule-engine-v2-alarms.component.css',
    standalone: false
})
export class RuleEngineV2AlarmsComponent {

  alarmHistory: IAlarmHistoryItem[];
  page: number = 1;

  constructor(private ruleService: RuleService) {
    this.get();
  }

  async get() {
    this.ruleService.getAlarmHistory(this.page).then(alarms => {
      this.alarmHistory = alarms;
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      });
    });
  }

  nextPage() {
    this.page++;
    this.get();
  }

  previousPage() {
    this.page--;
    if (this.page < 1) {
      this.page = 1;
    }
    this.get();
  }

  toPage(page: number) {
    this.page = page;
    this.get();
  }
}
