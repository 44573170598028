import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'safeUrl',
    standalone: false
})
export class SafeUrlPipe implements PipeTransform {

	constructor(private domSanitizer: DomSanitizer) { }

	transform(value: any, args?: any): any {
		return this.domSanitizer.bypassSecurityTrustUrl(value);
	}
}