import { Component, OnInit } from '@angular/core';
import { APIService } from 'app/shared/api.service';
import { AQItem, AQService } from "../../shared/aq.service";

declare var google:any;

@Component({
    selector: 'app-report-airquality',
    templateUrl: './report-airquality.component.html',
    styleUrls: ['./report-airquality.component.css'],
    standalone: false
})
export class ReportAirqualityComponent implements OnInit {

  aqlist: AQItem[];
  options: any;
  overlays: any[] = [];
  map: any;
  mapDimensions: any;
  aqSelected: AQItem;
  
  constructor(private apiService: APIService, private aqService: AQService) {
    this.options = {
      center: { lat: 53.7057599, lng: -1.912399 },
      zoom: 5
    };

    apiService.
      getAQForOrg()
      .then(aqlist => {
        this.aqlist = aqlist;
        this.build();
      });
  }

  build() {
    this.overlays = [];
    const bounds = new google.maps.LatLngBounds();
    const sites = [...new Set(this.aqlist.map(item => item.siteId))];

    // Red 1
    // Online 2
    // Offline 3

    this.aqlist.sort( item =>  (item.isOffline) ? 1 : -1);
    this.aqlist.sort( item =>  (item.state === 'red' && !item.isOffline) ? -1 : 1);
    this.aqlist.forEach(aqItem => {
      if (aqItem.lat) {
        const myLatLng = new google.maps.LatLng(Number(aqItem.lat), Number(aqItem.lng));
        //console.log(site.address.addressLat, site.address.addressLong, myLatLng);
        const gdata = {
          position: { lat: Number(aqItem.lat), lng: Number(aqItem.lng) },
          title: '#' + aqItem.id + ' ' + aqItem.title,
          animation: google.maps.Animation.DROP,
          zIndex: aqItem.state === 'green' ? 0 : 1,
          icon: 'https://staticnode.batchgeo.com/marker/svg?type=circlePlain&size=20&fill=' + aqItem.state
        };
        this.overlays.push(
          new google.maps.Marker(gdata)
        );

        bounds.extend(myLatLng);
      }
    });

    setTimeout(() => {
      // Onl fit bounds if there are 2 or more unique sites
      if (sites.length > 1) {
        this.map.fitBounds(bounds);
      }
    });
  }

  ngOnInit(): void {

  }

  setMap(event) {
    this.map = event.map;
    console.log(this.map);
  }

  handleOverlayClick(event) {
    const isMarker = event.overlay.getTitle != undefined;

    if (isMarker) {
      //  const site: Site = this.sites.filter((v) => v.id == event.overlay.getTitle().split(' ')[0].substring(1))[0];

      //   this.focusSite(site);
    }
  }

}
