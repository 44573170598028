import { Component, EventEmitter, Input, Output, input, model, signal } from '@angular/core';
import { IExplorerSelectedItems } from 'app/developer/explorer/explorer.component';

@Component({
    selector: 'app-button-assets',
    templateUrl: './button-assets.component.html',
    styleUrl: './button-assets.component.css',
    standalone: false
})
export class ButtonAssetsComponent {
  _onDialog = signal<boolean>(false);
  label = model<string>('All Sensors');
  _selectedItems = signal<IExplorerSelectedItems>({ sites: {}, gateways: {}, assets: {} });

  @Input()
  public set selectedItems(v: IExplorerSelectedItems) {
    if (!v) {
      v = { sites: {}, gateways: {}, assets: {} };
    }
    this._selectedItems.set(v);
    this.changed();
  }
  public get selectedItems(): any {
    return this._selectedItems();
  }

  @Output()
  onSelect = new EventEmitter<IExplorerSelectedItems>();

  @Output()
  size = signal<string>('medium');

  _caption = signal<string>(null);

  @Input()
  public set caption(v: string) {
    this._caption.set(v);
  }

  // Act purely as an asset picker
  selectOnly = input<boolean>(false);

  constructor() {

  }

  explorerSelected(data: IExplorerSelectedItems) {
    this._onDialog.set(false);

    console.log(data);

    if (!data) {
      return;
    } else {
      this._selectedItems.set(data);
      this.onSelect.emit(data);
    }
    this.buildLabel();
  }

  changed() {

    this.buildLabel();
  }

  buildLabel() {
    if (this.selectOnly()) {

      return;
    }
    const label = [];
    // Count sites
    const sites = Object.keys(this._selectedItems().sites).filter(s => this._selectedItems().sites[s]);
    if (sites.length) {
      label.push(`${sites.length} site${sites.length > 1 ? 's' : ''}`);
    }
    // Count gateways
    const gateways = Object.keys(this._selectedItems().gateways).filter(s => this._selectedItems().gateways[s]);
    if (gateways.length) {
      label.push(`${gateways.length} gateway${gateways.length > 1 ? 's' : ''}`);
    }
    // Count assets
    const assets = Object.keys(this._selectedItems().assets).filter(s => this._selectedItems().assets[s]);
    if (assets.length) {
      label.push(`${assets.length} asset${assets.length > 1 ? 's' : ''}`);
    }
    this.label.set(label.join(', ') || 'All Sensors');
  }
}

