import { Component, Input, OnChanges, SimpleChanges, SimpleChange, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-help-popup',
    templateUrl: './help-popup.component.html',
    styleUrls: ['./help-popup.component.css'],
    standalone: false
})
export class HelpPopupComponent implements OnChanges {

  @Input()
  help: any;

  title: string;
  content: string[];

  _show: string;
  @Input()
  public get show(): string {
    return this._show;
  }
  public set show(v: string) {
    this._show = v;
    if (!v) {
      return;
    }
    this.title = this.help.popup(v).title;
    this.content = this.help.popup(v).body;
  }

  @Output()
  showChange: EventEmitter<string> = new EventEmitter<string>();


  close() {
    this.show = null;
    this.showChange.emit(this.show);
  }

  ngOnChanges(changes: SimpleChanges) {
    const name: SimpleChange = changes.show;
    console.log('prev value: ', name.previousValue);
    console.log('got name: ', name.currentValue);
    this.show = name.currentValue;
  }
}
