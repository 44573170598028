import { Component, OnInit } from '@angular/core';
import { Grouping } from 'app/classes/grouping';
import { User, UserSelection } from 'app/classes/user';
import { APIService } from 'app/shared/api.service';
import { OrgService } from 'app/shared/org.service';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-dialog-group',
    templateUrl: './dialog-group.component.html',
    styleUrls: ['./dialog-group.component.css'],
    providers: [DialogService],
    standalone: false
})
export class DialogGroupComponent implements OnInit {

  grouping: Grouping;
  dialogRef: DynamicDialogRef;
  usersSelected: UserSelection[];
  usersAvailable: UserSelection[];

  users: UserSelection[];

  constructor(public dialogService: DialogService, public config: DynamicDialogConfig, public ref: DynamicDialogRef, public messageService: MessageService, private orgService: OrgService, private apiService: APIService) { }

  ngOnInit(): void {
    this.apiService
      .getUsersForOrg()
      .then(users => {
        this.users = users.sort((a, b) => { return a.name > b.name ? 1 : -1 }).map(u => new UserSelection(u));

        if (this.config.data) {
          //this.grouping = this.config.data;
          this.orgService.getGrouping(this.config.data.id).then(g => {
            this.grouping = g;
            this.processGrouping();
          });
        } else {
          this.grouping = new Grouping();
          this.processGrouping();
        }
      });
  }

  removeUser(user: User) {
    this.grouping.users = this.grouping.users.filter(u => u.id !== user.id).sort((a, b) => a.name < b.name ? -1 : 1);
    this.processGrouping();
  }

  addUser(user: User) {
    if (this.grouping.users.findIndex(u => u.id === user.id) === -1) {
      this.grouping.users.push(user);
      this.grouping.users = this.grouping.users.sort((a, b) => a.name < b.name ? -1 : 1);
      this.processGrouping();
    }
  }

  processGrouping() {
    this.usersAvailable = this.users.filter(u => this.grouping.users.findIndex(gu => gu.id === u.id) === -1);
  }

  cancel() {
    this.ref.close();
  }

  async submit() {
    await this.orgService.updateGrouping(this.grouping);
    this.ref.close(this.grouping);
  }

}
