import { Component, OnInit } from '@angular/core';
import { APIService } from '../../shared/api.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Gateway } from '../../classes/gateway';
import { Asset } from '../../classes/asset';

@Component({
    selector: 'app-dashboard-asset-list',
    templateUrl: './dashboard-asset-list.component.html',
    styleUrls: ['./dashboard-asset-list.component.css'],
    standalone: false
})
export class DashboardAssetListComponent implements OnInit {

  gatewayId: string;
  results: any;
  assets: Asset[];
  viewGateways: boolean = true;

  constructor(private router: Router, private route: ActivatedRoute, private apiService: APIService) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.gatewayId = params['gateway'];
      this.apiService.getAssetsForGatewayId(this.gatewayId).then((assets) => this.assets = assets);
    });

    console.log('DashboardSiteListComponent()');
      this.router.navigate(['org', { outlets: { 'dashboard-site-list':null } }]);
  }

  gotoGateway(gateway: Gateway) {
    this.viewGateways = false;
    this.router.navigate(['org', { outlets: { 'dashboard-asset-list': [gateway.id] } }]);
  }
}
