import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-select-review-annotation',
    templateUrl: './select-review-annotation.component.html',
    styleUrls: ['./select-review-annotation.component.css'],
    standalone: false
})
export class SelectReviewAnnotationComponent implements OnInit {

  @Output()
  onSelected: EventEmitter<any> = new EventEmitter();

  @Input()
  adminAnnotations: { text: string, rag: string }[];

  selectedAnnotation: { text: string, rag: string } = { text: null, rag: null };


  constructor() { }

  ngOnInit(): void {
  }

  selected(annotation) {
    if (!annotation?.value || !annotation?.originalEvent) {
      return;
    }
    this.onSelected.emit(annotation.value);
    setTimeout(() => {
      this.selectedAnnotation = null;
    }, 1);

  }

}
