import { Component, ContentChildren, OnInit, AfterViewInit, OnDestroy, QueryList, signal } from '@angular/core';
import { timer } from 'rxjs';
import { APIService } from '../../shared/api.service';
import { Asset } from '../../classes/asset';
import { Gateway } from '../../classes/gateway';
import { ChartModule } from 'primeng/chart';
import moment from 'moment';

@Component({
    selector: 'app-footfall-dashboard',
    templateUrl: './footfall-dashboard.component.html',
    styleUrls: ['./footfall-dashboard.component.css'],
    standalone: false
})
export class FootfallDashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  @ContentChildren(ChartModule) chartList: QueryList<ChartModule>;

  data: any;
  options: any;
  now: any = new Date();
  gateways: Gateway[] = [];
  gateway: Gateway;
  telemetry: any[] = [];
  noFootfallgateways: boolean;

  asset: Asset = new Asset();
  assets: Asset[];
  count: any = { in: 0, out: 0, counter: 0, lastId: 0 };
  charts: any[];
  timer: any;
  timerSubscription: any;
  subdomain: string = APIService.SUBDOMAIN;

  PURPOSE_FOOTFALL_IN = 9;
  PURPOSE_FOOTFALL_OUT = 10;

  useThis: 'sql' | 'redis' = 'redis';

  tabIndex = signal<number>(1);

  constructor(private apiService: APIService) {
    this.assets = [];

    this.data = {
      labels: [],
      datasets: [
        {
          label: 'Recent footfall',
          data: [],
          backgroundColor: '#02ACEE'
        }
      ]
    };

    this.options = {
      scales: {
        yAxes: [{
          ticks: {
            max: 30,
            min: -30,
            stepSize: 5
          }
        }]
      }
    };
  }

  ngOnInit() {
    this.apiService.getGateways(this.apiService.GATEWAY_TYPES_BY_TITLE.FOOTFALL).then(
      (gateways) => {
        console.log(gateways);
        this.gateways = gateways;
        if (!this.gateways || this.gateways.length === 0) {
          this.noFootfallgateways = true;
        } else {
          if (this.gateways.length === 1) {
            this.gateway = this.gateways[0];
            this.startTimer();
          }
        }
      }
    );

    /*
    this.apiService.getTelemetryAssets().then(
      (footfallAssets) => {
        console.log(footfallAssets);
        if (footfallAssets.length === 1) {
          // If there is only one asset default to this.
          this.asset = footfallAssets[0];
          this.startTimer();
        } else if (footfallAssets.length === 0) {
          // Let the user know there are no footfall devices
          this.noFootfallgateways = true;
        }

        this.assets = footfallAssets;
      }
    );
    */
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  use(what: 'sql' | 'redis') {
    this.useThis = what;
  }

  clickShowMore() {
    window.location.href = "https://4dmonitoring.co.uk";
  }
  /**
   * 
   * @param gateway Gateway selected from a list
   */
  selectGateway(gateway: Gateway) {
    this.gateway = gateway;
    this.startTimer();
  }

  selectAsset(asset: Asset) {
    this.asset = asset;
    this.startTimer();
    this.now = new Date();
  }

  startTimer() {
    this.count = { in: 0, out: 0, counter: 0, lastId: 0 };
    if (!this.timerSubscription) {
      let telemetryTick = timer(1, 60000);
      this.timerSubscription = telemetryTick.subscribe(t => {
        console.log('getTelemetry tick', t);
        this.getGatewayTelemetry();
      });
    } else {
      this.getGatewayTelemetry();
    }
  }

  getAsset(asset: number) {

    throw new Error('UNHANDLED');

    /*    this.apiService. getAsset(837).then(
          (asset) => {
            this.asset = asset;
            console.log(asset);
          });*/
  }

  /**
   * Get telemetry for the selected gateway
   */
  getGatewayTelemetry() {

    this.apiService.getFootfallTelemetry(this.gateway.id).then(
      (response) => {
        let results: {
          asset_id: number,
          value: number,
          createdAt: Date
        }[] = [];

        const redis = response.redis;

        if (this.useThis === 'redis') {
          const assets = response.assets;
          for (let index = 0; index < assets.length; index++) {
            const asset = assets[index];
            redis[index].splice(10);
            redis[index].forEach(assetEntry => {
              const parts = assetEntry.split(':');
              results.push({
                asset_id: asset.id,
                value: asset.pid === this.PURPOSE_FOOTFALL_IN ? +parts[1] : (+parts[1]) * -1,
                createdAt: new Date(+(parts[0]) * 1000)
              });

            });
            results = results.sort((a, b) => +(a.createdAt) > +(b.createdAt) ? -1 : 1);
          }

          //console.log('REDIS RESULTS', results);
        } else {
          results = response.db;
          //console.log('DB RESULTS', results);
        }


        //console.log('got telemetry');
        // console.log(+results[results.length - 1].createdAt, this.count.lastId);
        if (+results[results.length - 1].createdAt <= this.count.lastId) {
          return;
        }
        this.data.datasets[0].data = [];
        this.data.labels = [];
        this.charts = [];
        for (var idx = 0; idx < results.length; idx++) {
          var line = results[idx];
          line.value = Number(line.value);
          if (this.count.lastId !== 0) {
            if (+line.createdAt > this.count.lastId) {
              if (line.value < 0) {
                this.count.out = this.count.out + Math.abs(line.value);
              }
              if (line.value > 0) {
                this.count.in = this.count.in + line.value;
              }
            }
          }
          this.data.datasets[0].label = this.gateway.title + (this.gateway.location ? '(' + this.gateway.location + ')' : '');
          this.data.datasets[0].data.push(line.value);
          this.data.labels.push(moment(line.createdAt).fromNow());
        }
        this.count.lastId = +results[results.length - 1].createdAt;
        this.charts = [{ id: this.count.lastId }];
        //console.log(this.charts);
      }
    )
  }

  getTelemetry() {
    this.apiService.getTelemetry(this.asset.id, 20).then(
      (results) => {
        //console.log('got telemetry');
        console.log(results[results.length - 1].id, this.count.lastId);
        if (results[results.length - 1].id <= this.count.lastId) {
          return;
        }
        this.data.datasets[0].data = [];
        this.data.labels = [];
        this.charts = [];
        for (var idx = 0; idx < results.length; idx++) {
          var line = results[idx];
          line.value = Number(line.value);
          if (this.count.lastId !== 0) {
            if (line.id > this.count.lastId) {
              if (line.value < 0) {
                this.count.out = this.count.out + Math.abs(line.value);
              }
              if (line.value > 0) {
                this.count.in = this.count.in + line.value;
              }
            }
          }
          this.data.datasets[0].label = this.asset.title + (this.asset.location ? '(' + this.asset.location + ')' : '');
          this.data.datasets[0].data.push(line.value);
          this.data.labels.push(moment(line.createdAt).fromNow());
        }
        this.count.lastId = results[results.length - 1].id;
        this.charts = [{ id: this.count.lastId }];
        console.log(this.charts);
      });
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
}
