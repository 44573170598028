import { Component, Input, OnInit, signal, ChangeDetectorRef, NgZone } from '@angular/core';
import { Org } from 'app/classes/org';
import { User, UserExportItem } from 'app/classes/user';
import { Site } from "app/classes/site";
import { APIService } from 'app/shared/api.service';
import { SiteService } from 'app/shared/site.service';
import { MessageService, SelectItem } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { Subscription } from 'rxjs';
import { UserService } from 'app/shared/user.service';
@Component({
    selector: 'app-dialog-user-manage',
    templateUrl: './dialog-user-manage.component.html',
    styleUrls: ['./dialog-user-manage.component.css'],
    providers: [DialogService],
    standalone: false
})
export class DialogUserManageComponent implements OnInit {

  user: User;
  sites: Site[];
  originalSiteIds: number[];
  hasChanged: boolean;
  tabIndex = signal(0);
  exportItem = signal<UserExportItem>(null);
  originalExports: UserExportItem[];
  isAdmin: boolean;

  constructor(private changeRef: ChangeDetectorRef, private ngZone: NgZone, private userService: UserService, private siteService: SiteService, private apiService: APIService, public dialogService: DialogService, public ref: DynamicDialogRef, public config: DynamicDialogConfig, public messageService: MessageService) {

    this.user = this.config.data.user;
    this.isAdmin = this.apiService.isAdmin();
    this.userService.getUser(this.user.id, 'extended=1').then(user => {
      this.user = user;
      this.exportItem.set(this.user.exports ? this.user.exports[0] : null);
      this.originalSiteIds = user.sites.map(site => site.id).sort((a, b) => a > b ? -1 : 1);
      this.originalExports = this.user.exports;
      this.siteService.getSites().then(sites => {
        // Convert sites into a multi select list
        this.sites = sites;

        this.combineUserSites();
      });
    });
  }

  exportChanged(exportItem: UserExportItem) {

    this.exportItem.set(exportItem);
    this.hasChanged = true;
  }

  ngOnInit(): void {

  }

  combineUserSites() {
    this.sites.forEach(site => {
      site.checked = this.user.sites.some(userSite => userSite.id === site.id);
    });
  }

  cancel() {
    this.messageService.add({ severity: 'info', summary: 'Cancelled', detail: '' });
    this.ref.close();
  }

  checkModified() {
    const sitesSelected = this.sites.filter(site => site.checked);
    this.hasChanged = sitesSelected.length !== this.originalSiteIds.length || this.originalSiteIds.some(id => !sitesSelected.some(site => site.id === id));

  }

  siteClick(site: Site) {
    site.checked = !site.checked
    this.user.sites = this.sites.filter(site => site.checked);
    this.checkModified();
  }

  reset() {
    const idx = this.tabIndex();
    this.tabIndex.set(null);
    this.ngZone.run(() => {
      this.sites.forEach(site => site.checked = this.originalSiteIds.some(id => id === site.id));
      this.user.exports = this.originalExports;
      this.exportItem.set(this.originalExports ? this.originalExports[0] : null);
      this.checkModified();
      this.changeRef.detectChanges();
      this.changeRef.markForCheck();
      this.tabIndex.set(idx);
    });
  }

  async save() {

    const siteIds: number[] = this.sites.filter(site => site.checked).map(site => site.id);

    if (!siteIds || !siteIds.length) {
      this.apiService.toastWarn('No Sites', '');
      return;
    }
    if (this.originalSiteIds.toString() !== this.user.sites.map(site => site.id).sort((a, b) => a > b ? -1 : 1).toString()) {
      await this.userService.updateSites(this.user.id, siteIds);
      this.messageService.add({ severity: 'success', summary: 'Update submitted', detail: '' });
    }
    if ((!this.originalExports && this.exportItem()) || (this.exportItem().sqlCommand !== this.originalExports[0].sqlCommand) || (this.originalExports[0].isActive !== this.exportItem().isActive)) {
      // Export sql was created or changed.
      await this.userService.updateExport(this.user.id, this.exportItem());
      this.messageService.add({ severity: 'success', summary: 'Export updated' });
    }

    this.ref.close(this.user);
  }

  allSites() {
    this.sites.forEach(site => site.checked = true);
    this.checkModified();
  }

  tabClick(index: number) {
    this.tabIndex.set(index);
  }
}
