import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from '../../shared/api.service';
import { StoreService } from '../../shared/store.service';
import { filter } from 'rxjs/operators';
import { AssetService } from 'app/shared/asset.service';
import moment from 'moment';

@Component({
    selector: 'app-setpoints-explorer',
    templateUrl: './setpoints-explorer.component.html',
    styleUrls: ['./setpoints-explorer.component.css'],
    standalone: false
})
export class SetpointsExplorerComponent implements OnInit {

    @Input()
    theme: string = 'light';

    @Input()
    asset: any;

    _setpoints: any;
    @Input()
    public get setpoints(): any {
        return this._setpoints;
    }
    public set setpoints(v: any) {
        console.log('got setpoints', v);
        this._setpoints = v;
    }

    assetId: number;
    viewbox: string;
    points: string;
    pointsFrom: string; // each point animates from here
    fill: string = 'url(#solid)';
    values: any[];

    ystep: number;
    height: number;
    width: number;
    //spacing at the bottom
    offset: number = 40;
    graphYBase: number;
    graphMin: number; // Graph min

    dow: any[];
    dowIndex: number[];

    dowClicked: any;
    sub: any;

    ready: boolean;
    legend: any[];
    constructor(
        private assetService: AssetService,
        private apiService: APIService,
        private route: ActivatedRoute,
        private router: Router,
        private storeService: StoreService) {
    }

    ngOnInit() {
        this.getValues();

        this.sub = this.storeService.trackChanges
            .pipe(filter(v => v !== null))
            .subscribe(v => {
                this.getValues();
            });
    }

    getAssetData() {
        return this.assetService.getAsset(this.assetId).then(
            result => {
                console.log(result);
                this.asset = result;

            }
        );
    }

    getValues() {

        // Retrieve the weeks data
        let end = moment().toDate();
        let start = moment().subtract(6, 'days').toDate();
        return this.apiService.getTelemetryForRange(this.asset.id, start, end).then(
            values => {
                this.values = values.reverse();
                if (this.values && this.values.length > 0) {
                    this.plotGraph();
                }
            }
        );
    }

    plotGraph() {
        let gmax = 0;
        let gmin = 999999;
        console.log('looking for min/max');

        this.setpoints.forEach(setpoint => {
            if (setpoint.isActive) {
                if (setpoint.max != null && setpoint.max > gmax) {
                    gmax = setpoint.max;
                    //console.log('sp GMAX NOW ', gmax);
                }
                if (setpoint.min != null && setpoint.min < gmin) {
                    gmin = setpoint.min;
                    //console.log('sp GMIN NOW ', gmin);
                }
            }
        });

        this.values.forEach(point => {

            if (point.v != null && +point.v > gmax) {
                gmax = +point.v;
                //console.log('GMAX NOW ', gmax);
            }
            if (point.v != null && +point.v < gmin) {
                gmin = +point.v;
                //console.log('GMIN NOW ', gmin);
            }
        });

        console.log(`min: ${gmin}, max:${gmax}`);
        // Viewbox
        this.offset = 40;
        this.height = 200;
        this.graphYBase = this.height + this.offset;

        let width = this.values.length;
        this.width = width;

        let spmax = 50;
        let spmin = 30;

        // Each value increments the Y axis by this amount
        if (gmax === gmin) {
            this.ystep = 1;
        } else {
            this.ystep = this.height / (gmax - gmin);
        }

        this.viewbox = `0 0 ${width} ${this.height + this.offset}`;

        //console.log('got data');
        let points = '';
        // Where to animate from, the bottom of the chart
        let pointsFrom = '';
        let x = width;
        let closing: string = `${width},${this.graphYBase}`;
        let closingFrom: string = `${width},${this.graphYBase}`;
        let dow = [null, null, null, null, null, null, null];
        let dowIndex = [];
        let legend = [];
        let id: 1;
        let dowPoints = '';
        let previousDowX: number = x;
        let weekMin = 99999;
        let weekMax = 0;
        // The number of datapoints (values in db) per DOW
        let numberOfDataPoints = 0;
        this.values.forEach(point => {
            // Don't plot strings
            if (!isNaN(+point.v)) {

                console.log(typeof point.v);
                let y = +(((this.graphYBase) - ((+point.v - gmin) * this.ystep)).toFixed(1));
                //console.log(`${this.graphYBase} - (${+point.v} - ${gmin}) * ${this.ystep} =  ${y} `);
                if (isNaN(y)) {
                    console.error(y, isNaN(y));
                }
                points += `${x},${y} `;
                pointsFrom += `${x},${this.graphYBase} `;
                let m = moment(point.c);
                let weekday = m.weekday();
                if (!dow[weekday]) {

                    if (dowIndex.length > 0) {
                        dowPoints += `${x + 1},${this.graphYBase} ${previousDowX},${this.graphYBase} `;
                        // define the previous box.
                        this.closeDay(dowPoints, legend, dowIndex, dow, x, weekMin, weekMax, numberOfDataPoints);
                        numberOfDataPoints = 0;
                        weekMin = 99999;
                        weekMax = 0;
                    }

                    dow[weekday] = { id: id++, x: x, v: +point.v, y: y, date: m, label: m.format('dddd'), selected: false };

                    dowPoints = '';
                    dowIndex.push(weekday);
                    previousDowX = x;

                }
                point.plot = { x: x, y: y, w: weekday };
                dowPoints += `${x.toFixed(1)},${y.toFixed(1)} `;
                if (weekMin > +point.v) weekMin = +point.v;
                if (weekMax < +point.v) weekMax = +point.v;
                x -= 1;
                numberOfDataPoints++;
            }

        });
        dowPoints += `${x + 1},${this.graphYBase} ${previousDowX},${this.graphYBase}`;
        this.closeDay(dowPoints, legend, dowIndex, dow, x, weekMin, weekMax, numberOfDataPoints);
        // Close off the left had side by moving to the bottom, then to the far right to complete the fill.
        // NOT REQUIRED IF CHART NOT FILLED
        points += `${x},${this.graphYBase} ` + closing;
        this.points = points;
        this.pointsFrom = pointsFrom + `${x},${this.graphYBase} ` + closingFrom;

        this.dow = dow;
        this.dowIndex = dowIndex;
        this.legend = legend;
        //console.log(dow);
        this.graphMin = gmin;
        //console.log(` MIN [0] LINE Y : "${this.graphYBase} - ((${this.setpoints[0].min} - ${this.graphMin}) * ${this.ystep})`);
        this.ready = true;
    }

    closeDay(dowPoints, legend, dowIndex, dow, x, min, max, numberOfDataPoints) {
        let previousWeekDayIndex = dowIndex[dowIndex.length - 1];
        let previousDow = dow[previousWeekDayIndex];
        let lwidth = (previousDow.x - x);
        legend.push({ x: x, y: 0, min: min, max: max, width: lwidth, height: this.graphYBase, title: previousDow.label, selected: false, idx: dowIndex.length - 1, w: previousWeekDayIndex, dp: numberOfDataPoints });
        previousDow.points = dowPoints;
    }

    plotSelected() {

    }

    legendEnter(event, item) {

        //console.log(event, item);
        let dayOfWeek = this.dowIndex[item.idx];
        let onDow = this.dow[dayOfWeek];
        // console.log('DOW:', onDow);

        this.legend.forEach(item => {
            item.selected = false;

        });

        this.dow.forEach(item => {
            if (item) {
                item.selected = false;
            }
        })

        item.selected = true;
        onDow.selected = true;

        this.plotSelected();
    }

    legendClick(event, legend) {
        console.log(event, legend);
        if (legend === null) {
            this.dowClicked = null;
            return;
        }

        if (legend.plotted) {
            // Calculations already performed, use cache.
        } else {
            let coords = '';
            // x is the width of the chart
            let x = this.width;

            // stepX is the stepping pixels per point on this new wider space
            let stepX = x / legend.dp;
            console.log(`plotting  ${legend.dp} points`);
            let hours = [];
            let from = '';
            let co = 0;
            let stats = { dateStart: '', dateEnd: '', co: 0 };
            let xy = { min: { value: 9999, x: 0, y: 0 }, max: { value: -9999, x: 0, y: 0 } };
            // Itterate through the weekday points and plot them
            // Right side needs to be last point.
            this.values.forEach(point => {
                let m = moment(point.c);
                let weekday = m.weekday();

                if (weekday === legend.w) {
                    let dow = this.dow[legend.idx];
                    if (!stats.dateStart) {
                        stats.dateStart = m.format('HH:mm');
                    }
                    let y = +(+(((this.graphYBase) - ((+point.v - this.graphMin) * this.ystep)).toFixed(1))).toFixed(1);
                    //console.log(`${this.graphYBase} - (${+point.v} - ${gmin}) * ${this.ystep} =  ${y} `);
                    coords += `${x.toFixed(1)},${y} `;
                    from += `${this.width},${y} `;

                    if (+point.v > xy.max.value) {
                        xy.max.value = +point.v;
                        xy.max.x = x;
                        xy.max.y = y;
                    }

                    if (+point.v < xy.min.value) {
                        xy.min.value = +point.v;
                        xy.min.x = x;
                        xy.min.y = y;
                    }

                    let hour = m.hour();
                    if (!hours[hour]) {
                        hours[hour] = { x: x, hour: m.format('HH:mm') };
                        console.log(`${x} ${hours[hour].hour}`);
                    }
                    x -= stepX;

                    stats.co++;
                    stats.dateEnd = m.format('HH:mm');
                }
            });
            legend.plotted = { toPoints: coords, fromPoints: from, hours: hours.reverse(), stats: stats, xy };
        }

        //console.log(`Plotted ${co} points`);

        this.dowClicked = legend;
    }
}
