import { Component, Input, OnInit } from '@angular/core';
import { SetpointSchedule } from 'app/classes/setpoint-schedule';

import moment from 'moment';

@Component({
    selector: 'app-setpoint-schedules-calendar',
    templateUrl: './setpoint-schedules-calendar.component.html',
    styleUrls: ['./setpoint-schedules-calendar.component.css'],
    standalone: false
})
export class SetpointSchedulesCalendarComponent implements OnInit {

  _schedules: SetpointSchedule[];
  @Input()
  public set schedules(v: SetpointSchedule[]) {
    this._schedules = v;
    if (!v) {
      return;
    }

    this.weeks.forEach(week => week.forEach(day => {
      day.count = 0;
      day.hasProcessing = false;
    }));


    v.forEach(schedule => {
      const processAt = moment(schedule.processAt).startOf('day');
      this.weeks.forEach(week => {
        const day = week.find(day => day.timestamp === +processAt);
        if (day) {
          console.log('found', day);
          day.hasProcessing = true;
          day.count++;
        }
      });
    });
  }

  public get schedules(): SetpointSchedule[] {
    return this._schedules;
  }

  weeks: WeekDay[][] = [];

  constructor() {
    const today = moment().isoWeekday(1).startOf('isoWeek').startOf('day');
    const startofToday = moment().startOf('day');
    let day = 0;
    for (let iweek = 0; iweek < 4; iweek++) {
      const days: WeekDay[] = [];
      for (let iday = 0; iday < 7; iday++) {
        const dt = moment(today).add(day++, 'day');
        days.push({ moment: dt, day, date: dt.date(), timestamp: +dt, hasProcessing: false, count: 0, isInvalid: +startofToday > +dt, isToday: +startofToday === +dt });
      }
      this.weeks.push(days);
    }
    console.log('today', today, this.weeks);
  }

  ngOnInit(): void {

  }
}

export interface WeekDay {
  moment: moment.Moment,
  day: number, // Day of month
  date: number, // Day index 0...
  timestamp: number,
  hasProcessing: boolean,
  count: number,
  isInvalid: boolean,
  isToday: boolean
}
