import { Component, Input, OnInit, signal } from '@angular/core';
import { Org } from 'app/classes/org';
import { Tenant } from 'app/classes/tenant';
import { DialogTenantEditComponent } from 'app/dialogs/dialog-tenant-edit/dialog-tenant-edit.component';
import { DialogTenantServiceEditComponent } from 'app/dialogs/dialog-tenant-service-edit/dialog-tenant-service-edit.component';
import { TenantService } from 'app/shared/tenant.service';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ServiceForTenants } from "app/classes/service-for-tenant";
import { WindowService } from 'app/shared/window.service';
import { APIService } from 'app/shared/api.service';

@Component({
    selector: 'app-org-tenants',
    templateUrl: './org-tenants.component.html',
    styleUrls: ['./org-tenants.component.css'],
    providers: [DialogService],
    standalone: false
})
export class OrgTenantsComponent implements OnInit {

  @Input() org: Org;

  tenants: Tenant[];
  servicesForTenants: ServiceForTenants[];
  tabIndex = signal<number>(0);
  isAdmin = signal<boolean>(false);
  assets = signal<any[]>(null);
  dialogOpen = signal<'tenant' | 'service'>(null);

  onTenant: Tenant;
  onService: ServiceForTenants;

  constructor(private apiService: APIService, private tenantService: TenantService, public dialogService: DialogService, public messageService: MessageService, private windowService: WindowService) {

  }

  ngOnInit() {
    console.log('nginit');
    this.isAdmin.set(this.apiService.isAdmin());
    this.get();
  }

  floorplanClick(event: any, floorplan: any) {
    event.preventDefault();
    if (!this.isAdmin()) {
      return;
    }
    window.open('https://manage.4dml.com/sites/' + floorplan.site_id + '/floorplans/' + floorplan.id, "_blank");
  }

  get() {
    this.tenantService.getTenants().then(tenants => {
      this.tenants = tenants.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true }));
    });
    this.tenantService.getServices().then(servicesForTenants => {
      this.servicesForTenants = servicesForTenants;
    });
  }

  edit(tenant: Tenant) {
    this.onTenant = tenant;
    this.dialogOpen.set('tenant');

  }

  editServiceForTenants(servicesForTenants: ServiceForTenants) {
    this.onService = servicesForTenants;
    this.dialogOpen.set('service');
  }

  create() {
    this.onTenant = new Tenant();
    this.dialogOpen.set('tenant');
    /*
    const width = this.windowService.isMobile ? '100%' : '90%';
    const dialogRef = this.dialogService.open(DialogTenantEditComponent, {
      header: `Create tenant`,
      width,
      data: { tenant: new Tenant() }
    });
    dialogRef.onClose.subscribe((tenant: Tenant) => {
      if (tenant)
        this.get();
    });
    */
  }

  closeDialog() {
    this.dialogOpen.set(null);
    this.get();
  }

  createServiceForTenant() {
    this.onService = new ServiceForTenants();
    this.dialogOpen.set('service');
    /*
    const width = this.windowService.isMobile ? '100%' : '90%';
    const dialogRef = this.dialogService.open(DialogTenantServiceEditComponent, {
      header: `Create service`,
      width,
      data: { servicesForTenants: new ServiceForTenants() }
    });

    dialogRef.onClose.subscribe((servicesForTenants: ServiceForTenants) => {
      if (servicesForTenants)
        this.get();
    });
  */
  }
}
