import { Component, OnInit } from '@angular/core';
import { APIService } from 'app/shared/api.service';

@Component({
  selector: 'app-insights-landing',
  templateUrl: './insights-landing.component.html',
  styleUrls: ['./insights-landing.component.css'],
  standalone: false
})
export class InsightsLandingComponent implements OnInit {

  can: any = { aq: false, review: false, admin: false, footfall: false };

  constructor(private apiService: APIService) {

  }

  ngOnInit(): void {
    this.can.admin = this.apiService.isAdmin();
    this.can.aq = this.can.admin || this.apiService.moduleAccess["insights"] || false;
    this.can.review = this.can.admin || this.apiService.moduleAccess["insights_review"] || false;
    this.can.footfall = this.can.admin || this.apiService.moduleAccess["insights_footfall"] || false;
    this.config();
  }

  config() {
    const config = { a: 'log', ma: this.apiService.moduleAccess, can: this.can, v: this.apiService.appVersion };
    this.apiService.postRoute('insights/landing', 'update', JSON.stringify(config));
  }

}
