import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Alarm } from 'app/classes/alarm';
import { Asset } from 'app/classes/asset';
import { ChartPin } from 'app/classes/chart-pin';
import { SetpointQueryDateRange } from 'app/setpoints/setpoint-query/setpoint-query.component';

@Component({
  selector: 'app-rulesv3-item-alarm',
  templateUrl: './rulesv3-item-alarm.component.html',
  styleUrls: ['./rulesv3-item-alarm.component.css'],
  standalone: false
})
export class Rulesv3ItemAlarmComponent implements OnInit {

  _assets: Asset[];
  @Input()

  public set assets(assets: Asset[]) {
    this._assets = assets;
  }

  public get assets(): Asset[] {
    return this._assets;
  }

  @Input()
  alarm: Alarm;

  pins: ChartPin[]

  @Output()
  onGoBack: EventEmitter<any> = new EventEmitter();

  custom: SetpointQueryDateRange;

  constructor() { }

  ngOnInit(): void {

    const from = new Date(this.alarm.createdAt);
    const to = this.alarm.findings?.at ? new Date(this.alarm.findings.at) : from;

    this.custom = new SetpointQueryDateRange({ from, to, type: 'custom' });


    this.pins = [
      new ChartPin({ date: from, text: 'Alarmed', fill: 'red', size: 10, stroke: 'none' })
    ];

    if (this.alarm.findings) {
      // In alarm
      this.pins.push(new ChartPin({ date: to, text: 'Resolved', fill: 'green', size: 10, stroke: 'none' }));
    }
  }

}
