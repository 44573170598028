import { Component, Input, OnInit } from '@angular/core';
import { S3SiteRag, S3SiteRagAssetDay } from 'app/classes/s3-chart/s3-chart';
import { Site } from 'app/classes/site';
import { APIService } from 'app/shared/api.service';
import { DataItemShared } from './asset-rag-report/asset-rag-report.component';

import moment from 'moment';

@Component({
    selector: 'app-rag-report',
    templateUrl: './rag-report.component.html',
    styleUrls: ['./rag-report.component.css'],
    standalone: false
})
export class RagReportComponent implements OnInit {

  @Input()
  site: Site;

  isFullscreen = false;
  isSelectingDate = false;
  selectingDate = null;
  siteRag: S3SiteRag;
  header: {
    year: number,
    month: string,
    toYear?: number,
    toMonth?: string
  };

  // The day focused on a report, used to sync reports
  focusDay: DataItemShared;
  defaults: { layout: 'grid' | 'row', months: 3 | 1 };

  maxDate: Date = new Date();
  minDate: Date = new Date(2019, 1);

  constructor(private apiService: APIService) {
    this.getDate();
    this.loadDefaults();
  }

  getDate() {
    const now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth() + 1;

    // Get previous month data
    month = month - 1;
    if (month < 1) {
      year = year - 1;
      month = 12;
    }

    this.selectingDate = moment(new Date(year, month)).toDate();
  }

  dateChanged() {
    this.isSelectingDate = false;
    this.get();
  }

  ngOnInit(): void {
    this.get();
  }

  get() {
    let year = this.selectingDate.getFullYear();
    let month = this.selectingDate.getMonth() + 1;

    this.header = {
      year,
      month: moment(this.selectingDate).format('MMMM')
    };

    if (this.defaults.months === 1) {
      this.header.toYear = null;
      this.header.toMonth = null;
    } else {
      this.header.toMonth = moment(this.selectingDate).add(this.defaults.months - 1, 'month').format('MMMM');
      this.header.toYear = +moment(this.selectingDate).add(this.defaults.months - 1, 'month').format('YYYY');
    }

    this.apiService
      .getArrayOfSiteRAGS3(this.site.id, year, month, this.defaults.months)
      .then(siteRag => {
        this.siteRag = siteRag;
      });
  }

  hover(asset: any, day: DataItemShared) {
    //  console.log('Hover',asset, day);
    this.focusDay = day;
  }

  layoutClick() {
    if (this.defaults.layout === 'grid') {
      this.defaults.layout = 'row';
    } else {
      this.defaults.layout = 'grid';
    }
    this.saveDefaults();
  }

  monthsClick() {
    this.defaults.months = (this.defaults.months === 3 ? 1 : 3);
    this.saveDefaults();
  }

  loadDefaults() {
    const defaults = localStorage.getItem('rag-report:defaults') || '{}';
    this.defaults = { layout: 'grid', months: 3, ...JSON.parse(defaults) };
  }

  saveDefaults() {
    localStorage.setItem('rag-report:defaults', JSON.stringify(this.defaults));
  }
}
