import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { APIService } from '../../shared/api.service';
import { User } from '../../classes/user';
import { Org } from '../../classes/org';

@Component({
    selector: 'app-subs-list',
    templateUrl: './subs-list.component.html',
    styleUrls: ['./subs-list.component.css'],
    standalone: false
})
export class SubsListComponent implements OnInit {

  @Input()
  token: String;
  @Input()
  subscriptions: any[];

  constructor(private router: Router, private apiService: APIService, private route: ActivatedRoute) {

	}

  ngOnInit() {
  }

}
