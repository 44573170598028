<div [class.caption]="_caption()">
  @if (_caption()) {
    <label>{{ _caption() }}</label>
  }
  <app-button [size]="size()" [label]="label()" (click)="_onDialog.set(true)"></app-button>
</div>

@if (_onDialog()) {
  <app-dialog [canScroll]="false" (onClose)="closed($event)" [dimensions]="{ width: 660, height: 350 }">
    <app-toolbar>
      @if (_tenants().length) {
        <div class="left">
          <app-select [value]="_config().tenantId" [list]="_tenants()" caption="Tenant" (onSelected)="tenantSelected($event)" />
        </div>
      }
      <div class="right" [class.mt-1]="_tenants().length">
        <app-button size="medium" (click)="clear()"> <i class="mdi mdi-eraser"></i>&nbsp; Clear </app-button>
        <app-button size="medium" (click)="save()"> <i class="mdi mdi-check"></i>&nbsp; Use Selected </app-button>
      </div>
    </app-toolbar>

    <section class="dates">
      @if (_config().dates) {
        <app-calendar [value]="_config().dates.from" (onSelect)="changeDate('from', $event)"></app-calendar>
        <app-calendar [value]="_config().dates.to" (onSelect)="changeDate('to', $event)"></app-calendar>
      }
    </section>
  </app-dialog>
}
