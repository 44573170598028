import { AfterContentChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AssetReview, InsightReview } from 'app/classes/insight-review';
import { APIService } from 'app/shared/api.service';
import { DialogService } from 'primeng/dynamicdialog';
import moment from 'moment';
import { ChartPin } from 'app/classes/chart-pin';
import { IToolbarItem } from "../../../theme/theme-page-section/theme-page-section.component";
import { InsightsReviewService } from 'app/shared/insights.review.service';
import { Title } from '@angular/platform-browser';
import { IChartCompletedMinMax } from 'app/charts/d3-chart/d3-chart.component';
import { AssetService } from 'app/shared/asset.service';
@Component({
    selector: 'app-insights-as-admin',
    templateUrl: './insights-as-admin.component.html',
    styleUrls: ['./insights-as-admin.component.css'],
    standalone: false
})
export class InsightsAsAdminComponent implements OnInit, AfterContentChecked, OnDestroy {

  @Input()
  weather: any;

  @Input()
  insight: InsightReview;

  @Input()
  adminAnnotations: { annotation: string, rag: string }[];

  @Output()
  onUpdated: EventEmitter<InsightReview> = new EventEmitter()

  // Asset titles stored against asset id keys
  assetTitles: any = {};

  assetIds: string[];
  isReady: boolean;

  alarmPins: { [key: number]: ChartPin[] } = {};
  telemetryAnnotations: { [key: number]: any[] } = {};

  toolbars: { [key: number]: IToolbarItem[] } = {};

  isPortraitView = true;

  assetTypes: any;

  constructor(public assetService: AssetService, private titleService: Title, private apiService: APIService, private insightsService: InsightsReviewService, public dialogService: DialogService) { }

  ngOnInit(): void {
    this.insight.collection.assets.forEach(a => {
      this.assetTitles[a.id] = a.assetTitle;
      if (!this.insight.telemetry[a.id]) {
        this.insight.telemetry[a.id] = { asset: a, telemetry: [], annotations: [], review: new AssetReview(), alarms: [] };
      }
    });
    this.assetIds = this.insight.configuration.assets.map(a => String(a.id));
    this.assetIds.forEach(id => {
      this.toolbars[id] = [
        { select: [] },
        { icon: 'mdi-bullseye', label: 'Setpoints', tag: 'SETPOINTS' },
        { icon: 'mdi-cube', label: 'View in manage', tag: 'MANAGE_ASSET' }];
    });

    this.getSupporting();

  }

  ngAfterContentChecked() {

  }

  getSupporting() {
    this.insightsService.getReviewAdmin(this.insight).then(r => {
      console.log(r);
      if (r.alarms) {
        this.insight.setAlarms(r.alarms);
        r.alarms.forEach(a => {
          const assetId = a.la || a.ra;
          const toolbar = this.toolbars[assetId];
          toolbar[0].select.push({ id: a.id, label: `${moment(a.d).format('DD/MM/YY HH:mm')} ${a.t}` });
          if (a.la) {
            this.alarmPins[String(a.la)] = this.alarmPins[String(a.la)] || [];
            this.alarmPins[String(a.la)].push(new ChartPin({ date: a.d, stroke: 'red', text: a.t, size: 4, fill: 'white' }));
          }
          if (a.ra) {
            this.alarmPins[String(a.ra)] = this.alarmPins[String(a.ra)] || [];
            this.alarmPins[String(a.ra)].push(new ChartPin({ date: a.d, stroke: 'red', text: a.t, size: 4, fill: 'white' }));
          }
        });
      }
      this.adminAnnotations = r.commonAnnotations;
      if (r.telemetryAnnotations) {
        r.telemetryAnnotations.forEach(ta => {
          ta.createdAt = new Date(ta.createdAt);
          if (ta.createdAt >= this.insight.configuration.start && ta.createdAt <= this.insight.configuration.end) {
            this.telemetryAnnotations[String(ta.asset_id)] = this.telemetryAnnotations[String(ta.asset_id)] || [];
            this.telemetryAnnotations[String(ta.asset_id)].push(ta);
          }
        });
      }
      this.isReady = true;
      this.titleService.setTitle(`${this.insight.configuration.title}`);
    });

    this.assetTypes = {};
    this.assetService.getAssetTypes().forEach(at => this.assetTypes[at.id] = at);
  }

  updateAnnotations(stage: 'new' | 'done') {
    const undefinedPlaceholders = this.assetIds.find(key => this.insight.telemetry[key]?.review.annotation?.indexOf('__') >= 0);

    if (undefinedPlaceholders) {
      this.apiService.toastWarn('There are annotations with missing properties', undefinedPlaceholders);
      return;
    }

    this.insight.stage = stage;
    this.insightsService
      .updateReviewAnnotations(this.insight)
      .then(() => {
        this.apiService.toastSuccess('Updated', '');
        this.onUpdated.emit(this.insight);
      });
  }

  annotationRequested(id, $event) {
    if (!$event) {
      return;
    }
    this.insight.telemetry[id].review.annotation = $event.annotation;
    this.insight.telemetry[id].review.rag = $event.rag;
  }

  toolbarClick(id, event: any) {
    switch (event.tag) {
      case 'MANAGE_ASSET':
        const gatewayId = this.insight.telemetry[id].asset.gatewayId;
        window.open(`https://manage.4dml.com/list/${gatewayId}/assets/${id}`, '_blank');
        break;
      case 'SETPOINTS':
        window.open(`https://portal.4dml.com/setpoints/${id}`, '_blank');
        break;
    }
  }

  chartCompleted(id: number, data: IChartCompletedMinMax) {
    this.insight.telemetry[id].chartCompleted = data;
  }

  ngOnDestroy(): void {
    this.titleService.setTitle(`4D Portal`);
  }
}
