<article>
  <section class="users">
    <app-page-title label="Organisations" [canGoBack]="true" (click)="goBack()"></app-page-title>
  </section>
  <table class="table" aria-label="users">
    <thead>
      <tr>
        <th style="min-width: 80px">Date<br /></th>
        <th>Time</th>
        <th>
          User
          <br />
          <p-multiSelect [maxSelectedLabels]="1" [options]="users" [(ngModel)]="usersSelected" selectedItemsLabel="{0} selected" (onChange)="userSelectionChanged($event)"> </p-multiSelect>
        </th>
        <th>Path<br /></th>
      </tr>
    </thead>
    <tbody>
      @for (result of userLog; track result; let i = $index) {
        <tr *ngIf="result.orgId === selectedOrg">
          <td>
            <span class="isMobile">{{ result.createdAt | date }}</span>
            <span class="isDesktop">{{ result.createdAt | amDateFormat: 'ddd Do' }}</span>
          </td>
          <td>{{ result.createdAt | amDateFormat: 'HH:mm' }}</td>
          <td title="{{ result.path }}">{{ result.userName }}</td>

          <td class="title" *ngIf="result.assetTitle && result.siteTitle">
            <i class="fa fa-fw fa-heartbeat"></i>
            <span *ngIf="result.path.indexOf('telemetry') >= 0">Telemetry</span>{{ result.siteTitle }} -> {{ result.assetTitle }}
          </td>

          <td class="title" *ngIf="result.assetTitle && !result.siteTitle">
            <i class="fa fa-fw fa-heartbeat"></i>
            <span *ngIf="result.path.indexOf('telemetry') >= 0">Telemetry</span>{{ result.assetTitle }}
          </td>

          <td class="title" *ngIf="result.siteTitle && result.path.indexOf('live') === -1 && !result.assetTitle">
            <i class="fa fa-fw fa-heartbeat"></i>
            <span *ngIf="result.path.indexOf('telemetry') >= 0">Telemetry</span>{{ result.siteTitle }}
          </td>

          <td class="title" *ngIf="result.gatewayTitle">
            <i class="fa fa-fw fa-area-chart"></i>
            <span *ngIf="result.path.indexOf('live') >= 0">Live</span>{{ result.gatewayTitle }}
          </td>

          <td class="title" *ngIf="result.gatewayProfileTitle">
            <i class="fa fa-fw fa-heart"></i>
            <span *ngIf="result.path.indexOf('profiling')">Live</span>{{ result.gatewayProfileTitle }}
          </td>

          <td class="title" *ngIf="result.reportTitle">
            <i class="fa fa-fw fa-line-chart"></i>
            <span *ngIf="result.path.indexOf('reports')">Report</span>{{ result.reportTitle }}
          </td>

          <td class="title" *ngIf="result.ruleTitle">
            <i class="fa fa-fw fa-flask"></i>
            <span *ngIf="result.path.indexOf('rule')">Rule</span>{{ result.ruleTitle }}
          </td>

          <td class="title" *ngIf="!result.reportTitle && !result.gatewayProfileTitle && !result.ruleTitle && !result.gatewayTitle && !result.assetTitle && !result.siteTitle">
            <span *ngIf="result.title"><i class="fa fa-fw"></i> {{ result.title }}</span>
          </td>
        </tr>
        <tr *ngIf="result.orgId === selectedOrg">
          <td class="title text-right" colspan="4">
            <span
              ><i class="fa fa-fw"></i>
              <div class="isMobile">{{ result.title }}</div>
              <div style="color: #888" class="isMobile">
                <a routerLink="{{ result.path }}" target="_blank">{{ result.path }}</a>
              </div>
              <span style="color: #888" class="isDesktop">
                <a routerLink="{{ result.path }}" target="_blank">{{ result.path }}</a>
              </span>
            </span>
          </td>
        </tr>
      }
    </tbody>
  </table>
  <span *ngIf="!isFiltering && !loading && userLog.length === 0">No results</span>
</article>
