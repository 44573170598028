<article>
  <main>
    @for (item of _dataForm().items; track item.id) {
      <div>
        <div>
          <span [style.width.px]="_dataForm().config.labels.width">{{ item.label }}</span>
        </div>
        <div [ngClass]="{ 'width-100': item.width === '100%' || _dataForm().config.inputs.width === '100%' }">
          @switch (item.type) {
            @case ('select') {
              <app-select [block]="true" [list]="item.options" [value]="item.value" (onSelected)="item.value = $event"> </app-select>
            }
            @case ('password') {
              <input class="fourd" type="password" [(ngModel)]="item.value" (input)="changed()" />
            }
            @case ('string') {
              <input class="fourd" type="text" [(ngModel)]="item.value" (input)="changed()" />
            }
            @case ('textarea') {
              <textarea class="fourd" [(ngModel)]="item.value" [rows]="item.options.rows || 4" (input)="changed()"></textarea>
            }
            @case ('number') {
              <input class="fourd" type="number" [(ngModel)]="item.value" (input)="changed()" />
            }
            @case ('date') {
              <input class="fourd" type="date" [(ngModel)]="item.value" (input)="changed()" />
            }
            @case ('time') {
              <input class="fourd" type="time" [(ngModel)]="item.value" (input)="changed()" />
            }
            @case ('group') {
              @for (option of item.options; track option) {
                <app-chip [isRadio]="true" [checked]="option.id === item.value" (click)="optionClick(item, option)" [label]="option.title"></app-chip>
              }
            }
          }
        </div>
      </div>
    }
  </main>
  @if (_isDebugging()) {
    <footer>
      {{ _dataForm().items | json }}
    </footer>
  }
</article>
