@defer (when rows() && dataTable()) {
  <header>
    @if (canFilterByAssetType()) {
      <app-data-table-filter-types [sensorTypes]="sensorTypes()" (onSensorTypeSelected)="sensorTypeChange($event)"></app-data-table-filter-types>
    }
  </header>
  <table class="table" [class.table-hover]="canClick()">
    <thead>
      <tr>
        @for (column of dataTable().columns; track column.attribute) {
          @if (!column.isHidden) {
            <th [width]="column.width ? column.width + 'px' : ''">
              <div (click)="sortColumnClick(column)">
                <div>{{ column.title }}</div>
                <div>
                  <i tooltipPosition="top" [pTooltip]="column.sortDirection === 'asc' ? 'Ascending, sort by descending' : column.sortDirection === 'desc' ? 'Descending, sort by ascending' : 'Not sorted, sort ascending'" class="mdi" [class.mdi-sort-ascending]="column.sortDirection === 'asc'" [class.mdi-sort-descending]="column.sortDirection === 'desc'" [class.mdi-sort-reverse-variant]="column.sortDirection !== 'asc' && column.sortDirection !== 'desc'"></i>
                </div>
              </div>
            </th>
          }
        }
      </tr>
    </thead>
    <tbody>
      @for (row of rows(); track row) {
        <tr (click)="rowClick(row)" [class.is-disabled]="row?.isEnabled === false">
          @for (column of dataTable().columns; track column) {
            @if (!column.isHidden) {
              @switch (column.dataType) {
                @case ('boolean') {
                  <td class="text-{{ dataTable().dataClassConfig[column.attribute].align }}">{{ row[column.attribute] ? 'Yes' : 'No' }}</td>
                }
                @case ('date') {
                  <!-- prettier-ignore -->
                  @switch(column.dataFormat) {
                   @case ('time') {
                       <td class="text-{{ dataTable().dataClassConfig[column.attribute].align }}">{{ row[column.attribute] | date:'HH:mm' }}</td>
                    }
                    @case ('date') {
                       <td class="text-{{ dataTable().dataClassConfig[column.attribute].align }}">{{ row[column.attribute] | date:'dd/MM/YY' }}</td>
                    }
                    @default {
                      <td class="text-{{ dataTable().dataClassConfig[column.attribute].align }}">{{ row[column.attribute] | date:'dd/MM/YY HH:mm' }}</td>
                    }
                   }
                }
                @default {
                  <td class="text-{{ dataTable().dataClassConfig[column.attribute].align }}">{{ row[column.attribute] }}</td>
                }
              }
            }
          }
        </tr>
      }
    </tbody>
  </table>
} @placeholder {
  <i class="mdi mdi-loading mdi-spin-x2"></i>
}
