import { Component, OnInit, Input, OnDestroy, signal, input } from '@angular/core';
import { Asset } from '../../classes/asset';
import { APIService } from '../../shared/api.service';
import { timer } from 'rxjs';
import { WindowService } from 'app/shared/window.service';

@Component({
    selector: 'app-asset-monitor',
    templateUrl: './asset-monitor.component.html',
    styleUrls: ['./asset-monitor.component.css'],
    standalone: false
})
export class AssetMonitorComponent implements OnInit, OnDestroy {

  asset = input.required<Asset>();

  @Input()
  everySeconds = 30;

  @Input()
  notitle: boolean;

  @Input()
  fullscreen: boolean;

  reportType = 'list';
  private timerSubscription: any;
  packets: any[];

  defaultTab: number = 0;
  isLoading = true;
  isMobile: boolean = true;

  tabIndex = signal(0);

  constructor(private apiService: APIService, private windowService: WindowService) {
    this.defaultTab = +(localStorage.getItem('assetmonitor:tab') || 0);
    this.tabIndex.set(this.defaultTab);
  }

  ngOnInit() {
    this.isMobile = this.windowService.isMobile();

    this.startTimer();
  }

  formattedValue(value: any) {
    return Asset.formattedValue(value, this.asset().assetType_id, this.asset().purpose?.id, this.asset().updatedAt, this.asset().identifier);
  }

  getData() {
    this.isLoading = true;
    const purposeId = this.asset().purposeId || this.asset().purpose.id;
    const id = this.asset().parentAsset_id && purposeId !== 6 ? purposeId : this.asset().id;
    this.apiService.getTelemetry(id, 50, "v=2").then(packets => {
      this.packets = packets.reverse();
      this.isLoading = false;
    });
  }

  startTimer() {
    if (this.timerSubscription) {
      return;
    }

    const assetMonitorTick = timer(1, this.everySeconds * 1000);
    this.timerSubscription = assetMonitorTick.subscribe(t => {
      console.log('asset monitor tick', t);
      this.getData();
    });
  }

  tabChange(index: number) {
    this.tabIndex.set(index);
    localStorage.setItem('assetmonitor:tab', String(index));
  }

  handleTabChange(e: any) {
    this.tabIndex.set(e.index);
    localStorage.setItem('assetmonitor:tab', String(e.index));
  }

  ngOnDestroy() {
    this.timerSubscription.unsubscribe();
  }


}
