import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Alarm } from 'app/classes/alarm';
import { AlarmTimeline } from 'app/classes/alarm-timeline';
import { Asset } from 'app/classes/asset';
import { RulePackage } from 'app/classes/rule-service/rule-package';
import { AlarmService } from 'app/shared/alarm.service';
import { APIService } from 'app/shared/api.service';

@Component({
    selector: 'app-rulesv3-item-history',
    templateUrl: './rulesv3-item-history.component.html',
    styleUrls: ['./rulesv3-item-history.component.css'],
    standalone: false
})
export class Rulesv3ItemHistoryComponent implements OnInit {
  @Input()
  rule: RulePackage;

  @Input()
  assets: Asset[];

  _showDelayed: boolean;
  @Input()
  public set showDelayed(v: boolean) {
    this._showDelayed = v;
    if (this.filteredAlarms) {
      this.filter();
    }
  }

  public get showDelayed(): boolean {
    return this._showDelayed;
  }

  alarm: Alarm;
  history: any[];
  filteredAlarms: any[];

  constructor(private apiService: APIService, private router: Router, private route: ActivatedRoute, private alarmService: AlarmService) { }

  async ngOnInit() {
    this.history = await this.apiService.getRulePackageAlarms(this.rule.id);

    if (this.history.length === 0) {
      this.filteredAlarms = [];

      return;
    }

    this.history[0].forEach(alarm => {

      alarm.history = this.history[1].filter(item => item.alarm_id === alarm.id).map(i => new AlarmTimeline(i));

      let { history, findings } = this.alarmService.getHistory(alarm.history);
      alarm.history = history;
      alarm.findings = findings;
    });

    this.filter();
  }

  filter() {
    this.filteredAlarms = this.history[0].filter(h => {
      if (h.findings?.state === 'rdelay' && !this.showDelayed) {
        return false;
      }

      return true;
    });

  }

  alarmClick(alarm: Alarm) {
    this.alarm = alarm;
  }
}
