import { Component, Input, OnInit } from '@angular/core';
import { Building } from 'app/classes/building';
import { Gateway } from 'app/classes/gateway';
import { License, LicenseBuilding, LicenseGateway } from 'app/classes/license';
import { DialogLicenseComponent } from 'app/dialogs/dialog-license/dialog-license.component';
import { APIService } from 'app/shared/api.service';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BillingBuilding, BillingService } from "../../shared/billing.service";

@Component({
    selector: 'app-billing-buildings-home',
    templateUrl: './billing-buildings-home.component.html',
    styleUrls: ['./billing-buildings-home.component.css'],
    providers: [DialogService],
    standalone: false
})
export class BillingBuildingsHomeComponent implements OnInit {

  static readonly STORAGE_KEY = 'app-billing-buildings-home:default';

  @Input()
  building: Building;
  dialogRef: DynamicDialogRef;

  list: BillingBuilding[];
  gateways: Gateway[];
  sort: any = {};
  isReady = 0;
  license: License;

  constructor(private billingService: BillingService, private apiService: APIService, public dialogService: DialogService, public messageService: MessageService) {

  }

  ngOnInit(): void {
    this.get();
  }

  get() {
    this.isReady = 0;
    this.billingService
      .getLicensesForBuilding(this.building.id)
      .then(r => {
        this.building.licenses = r;
        this.isReady++;
      });

    this.billingService
      .getLicensesForBuildingGateways(this.building.id)
      .then(g => {
        this.gateways = g;
        this.isReady++;
      });
  }

  setBuildingLicense(building: Building, license: License) {
    const lBuilding = new LicenseBuilding(license);
    lBuilding.setBuilding(building);

    this.openLicense(lBuilding);
  }

  setGatewayLicense(gateway: Gateway) {
    const licenseGateway = new LicenseGateway(gateway.license);
    licenseGateway.setGateway(gateway);

    this.openLicense(licenseGateway);
  }

  openLicense(license: LicenseBuilding | LicenseGateway) {

    this.dialogRef = this.dialogService.open(DialogLicenseComponent, {
      header: `License for ${license.title}`,
      width: '80%',
      data: { license }
    });
    this.dialogRef.onClose.subscribe((license: License) => {
      if (license) {
        this.messageService.add({ severity: 'info', summary: 'License updated', detail: license.title });
        this.get();
      }
    });
  }

  licenseUpdated() {
    this.apiService.toastSuccess('License submitted', '');
    this.get();
  }

}
