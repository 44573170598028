import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { License, LicenseBuilding, LicenseGateway } from '../../classes/license';
import { ActivatedRoute, Router } from "@angular/router";
import { Building } from "../../classes/building";
import { LicensePackage } from '../../classes/license-package';
import { APIService } from "../../shared/api.service";
import { BillingService } from "../../shared/billing.service";
import { Gateway } from '../../classes/gateway';
import { CalendarEntry } from "../../classes/calendar-entry";

import moment from 'moment';
import { BillingClient } from 'app/classes/billing-client';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogBillingClientComponent } from 'app/dialogs/dialog-billing-client/dialog-billing-client.component';

@Component({
    selector: 'app-building-license-home',
    templateUrl: './building-license-home.component.html',
    styleUrls: ['./building-license-home.component.css'],
    standalone: false
})
export class BuildingLicenseHomeComponent implements OnInit {

  @Input()
  building: Building;

  @Input()
  gateway: Gateway;

  @Input()
  license: LicenseBuilding | LicenseGateway;

  @Output()
  onSave: EventEmitter<License> = new EventEmitter();


  states: any[] = [{ id: 'new', title: 'New, to be assigned' }, { id: 'active', title: 'Being worked on' }, { id: 'done', title: 'Completed' }];

  billingCycles = [
    { id: 'annually', title: 'Annually' },
    { id: 'quarterly', title: 'Quarterly' },
    { id: 'none', title: 'None' }
  ];

  timer: any;
  en = {
    firstDayOfWeek: 1,
    dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  };
  canAdjustExpiry: boolean;
  packages: any[];
  entries: any[];

  addReportsToCalendar = true;

  billingClients: BillingClient[];

  constructor(private billingService: BillingService, private router: Router, private route: ActivatedRoute, private apiService: APIService, public dialogService: DialogService) {
    this.packages = LicensePackage.packages;
  }

  ngOnInit() {
    if (!this.license) {
      if (this.gateway) {
        this.license = new LicenseGateway();
        this.license.setGateway(this.gateway);
      } else {
        this.license = new LicenseBuilding();
        this.license.setBuilding(this.building);
      }
    }
    this.getBillingClients();
  }


  getBillingClients() {
    this.billingService.getBillingClients().then(bc => {
      this.billingClients = bc;
    });
  }

  get(buildingId: number) {
    this.billingService
      .getForBuilding(buildingId)
      .then(billingBuilding => {
        this.building = billingBuilding.building;
      });
  }

  createGatewayLicense() {
    this.license = new LicenseGateway();

    // Attach new license to gateway
    this.gateway.license = this.license;
  }

  dateBlur() {
    if (this.license.licensedAt && this.canAdjustExpiry) {
      const newDate = new Date(+this.license.licensedAt);
      if (this.license.billingCycle === 'annually') {
        this.license.expiresAt = new Date(newDate.setFullYear(newDate.getFullYear() + 1));
      } else {
        this.license.expiresAt = newDate;
        this.license.expiresAt.setMonth(newDate.getMonth() + 3);
      }

      this.entries = this.generateQuarterlyLabelEntries('report', this.license.licensedAt, this.license.expiresAt);
    }
  }

  submit() {
    //  if (!this.license.value || this.license.value < 0) {
    //    this.apiService.toastWarn('Please enter a valid license value', '');
    //    return;
    //  }

    this.billingService
      .postLicense(this.license)
      .then(r => {
        this.onSave.emit(this.license);
      });
  }

  generateQuarterlyLabelEntries(label: string, startsFrom: Date, expiresAt: Date): CalendarEntry[] {
    const entries: CalendarEntry[] = [];
    let quarter = 0;
    let year = 0;
    for (let index = 1, finished = false; index < 99 && !finished; index++) {
      quarter++;
      const reportDate = moment(startsFrom).add(index * 3, 'months');
      if (quarter === 1 || quarter > 4) {
        quarter = 1;
        year = Math.ceil(Number(moment(reportDate).add(-1, 'days').diff(startsFrom, 'days') / 360));
      }

      if (+reportDate > +expiresAt) {
        finished = true;
        continue;
      }
      const entry = new CalendarEntry();

      entry.startAt = reportDate.toDate();
      entry.type = CalendarEntry.TYPES.ACTION;
      entry.allDay = true;
      entry.title = `Year ${year}, Q${quarter} ${label}.`;
      entry.addLabel(label);
      entry.isCompleted = false;
      entries.push(entry);
    }

    return entries;
  }

  billingClientChanged() {
    if (+this.license.billingClient.id === 9999) {
      const ref = this.dialogService.open(DialogBillingClientComponent, {
        header: 'Billing Client',
        width: '70%'
      });
      ref.onClose.subscribe((client: BillingClient) => {
        if (client) {
          this.license.billingClient = client;
          this.getBillingClients();
        } else {
          this.license.billingClient.id = null;
        }
      });
    }
  }
}
