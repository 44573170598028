import { Component, OnInit, Input } from '@angular/core';
import { Asset } from '../../classes/asset';

@Component({
    selector: 'app-asset-indicator',
    templateUrl: './asset-indicator.component.html',
    styleUrls: ['./asset-indicator.component.css'],
    standalone: false
})
export class AssetIndicatorComponent implements OnInit {
 
  @Input()
  asset: Asset;

  constructor() { }

  ngOnInit() {
  }

}
