export class DataTable {
	// Describes the class attributes (from app/classes) of the columns and data
	dataClassConfig: IDataClassConfig;

	columns: IDataTableColumnItem[];
	sortedColumns: IDataTableSortItem[];
	filteredColumns: IDataTableFilterItem[];
	data: any[];

	constructor(data: { columns?: IDataTableColumnItem[], sortedColumns?: IDataTableSortItem[], filteredColumns?: IDataTableFilterItem[], data: any[] }, dataClassConfig: IDataClassConfig) {
		if (!dataClassConfig) {
			throw new Error('dataClassConfig is required');
		}
		this.dataClassConfig = dataClassConfig;
		this.sortedColumns = data.sortedColumns;
		this.filteredColumns = data.filteredColumns;
		this.data = data.data;

		this.init();
	}

	init() {
		if (!this.columns) {
			this.initColumns();
		}
	}

	initColumns() {
		// The keys included in the data
		if (!this.data?.length) {
			return;
		}

		this.columns = [];

		// Use the class config to get the order of keys
		const classKeys = Object.keys(this.dataClassConfig);

		for (let index = 0; index < classKeys.length; index++) {
			const classKey = classKeys[index];
			const isHidden = this.dataClassConfig[classKey].isHidden;
			const width = this.dataClassConfig[classKey].width || null;
			const dataType = this.dataClassConfig[classKey].valueType || 'string';
			const dataFormat = this.dataClassConfig[classKey].dataFormat || null;
			if (this.data[0][classKey] !== undefined) {
				this.columns.push({ title: this.dataClassConfig[classKey].title, attribute: classKey, isHidden, width, dataType, dataFormat });
			}
		}

		this.initSorting();
	}

	initSorting() {
		if (!this.sortedColumns) {
			return;
		}
		this.sortedColumns.forEach(sortColumn => {
			const column = this.columns.find(c => c.attribute === sortColumn.attribute);
			if (column) {
				column.sortDirection = sortColumn.direction;
			}
		});
	}

}

export interface IDataTableColumnItem {
	// The title of the key
	title: string;
	// The key in the data
	attribute: string;
	isHidden?: boolean;
	width?: number;
	sortDirection?: 'asc' | 'desc' | null;
	dataType: 'string' | 'number' | 'date' | 'boolean';
	dataFormat?: 'date' | 'time' | 'datetime';
}

export interface IDataTableSortItem {
	// The key being sorted
	attribute: string;
	direction: 'asc' | 'desc' | null;
}

export interface IDataTableFilterItem {
	// The key being sorted
	attribute: string;
	value: string;
	operation: 'eq' | 'lt' | 'gt' | 'ne';
}

export interface IDataClassConfig {
	[key: string]: { title: string, dataFormat?: 'date' | 'time' | 'datetime', valueType: 'string' | 'number' | 'date' | 'boolean', align: 'left' | 'center' | 'right', isHidden?: boolean, width?: number, sortUsingKey?: string };
}
