<div class="manage-container" *ngIf="object">
  <div class="left">
    @if (org) {
      <app-manage-create-org-help [element]="element" *ngIf="object.constructor.name === 'Org'" [org]="org" />
    }
  </div>
  <div class="right">
    @if (org) {
      <app-manage-create-org *ngIf="object.constructor.name === 'Org'" [org]="org" (didSave)="saved($event)" />
    }
  </div>
</div>
