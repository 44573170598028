import { Component } from '@angular/core';

@Component({
    selector: 'app-org-battery',
    templateUrl: './org-battery.component.html',
    styleUrl: './org-battery.component.css',
    standalone: false
})
export class OrgBatteryComponent {

}
