import { Component, OnInit, Input } from '@angular/core';
import { Message } from '../../classes/message';

@Component({
    selector: 'app-message-item',
    templateUrl: './message-item.component.html',
    styleUrls: ['./message-item.component.css'],
    standalone: false
})
export class MessageItemComponent implements OnInit {

  @Input() 
  message: Message;
  
  constructor() { }

  ngOnInit() {
  }

}
