import { Component, OnInit } from '@angular/core';
import { APIService } from '../shared/api.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'app-site-submit',
    templateUrl: './site-submit.component.html',
    styleUrls: ['./site-submit.component.css'],
    standalone: false
})
export class SiteSubmitComponent implements OnInit {

  title: string;
  address: string;
  notes: string;

  constructor(private router: Router, private apiService: APIService) {}

  ngOnInit() {
  }

  submit() {
      this.apiService.submitSite(this.title, this.address, this.notes).then(
        r=> {
          this.apiService.toastSuccess('Submitted','Site has been submitted, thankyou.');
          this.router.navigate(['/org']);
        }
      );
  }
}
