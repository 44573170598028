import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Asset, AssetType } from 'app/classes/asset';
import { SetpointTemplate } from 'app/classes/setpoint-template';
import { SetpointSchedule } from 'app/classes/setpoint-schedule';
import { Site } from 'app/classes/site';
import { AssetService } from 'app/shared/asset.service';
import { SetpointsService } from 'app/shared/setpoints.service';
import { SiteService } from 'app/shared/site.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-setpoint-template-item-scheduler',
    templateUrl: './setpoint-template-item-scheduler.component.html',
    styleUrls: ['./setpoint-template-item-scheduler.component.css'],
    standalone: false
})
export class SetpointTemplateItemSchedulerComponent implements OnInit, OnDestroy {

  template: SetpointTemplate;
  schedule: SetpointSchedule;

  step: Steps = 'sites';
  usersAssets: Asset[];

  sites: Site[];
  assets: Asset[];
  assetTypes: AssetType[];
  isLoading: boolean;
  selectAllSites = false;
  selectAllAssets = false;
  subscriptions: Subscription[] = [];
  selectedAssetTypes: AssetType[] = [];

  counts = { sites: { selected: 0, count: 0 }, assets: { selected: 0, count: 0 } };

  requestedDate: Date;
  reason: string;
  operations = [
    { name: 'Contains', code: '=' },
    { name: 'Does not contain', code: '!=' }
  ];

  repeatOptions = [{ name: 'Does not repeat', code: null }, { name: 'Annually on this date', code: 'annually' }];

  repeatSelected: any;

  constructor(private setpointService: SetpointsService, private siteService: SiteService, private assetService: AssetService, private route: ActivatedRoute, private router: Router) {
    const templateId = this.route.snapshot.params['templateid'];
    const schedulerId = this.route.snapshot.params['schedulerid'];
    if (!templateId) {
      throw new Error('NO TEMLPLATE ID');
    }

    console.log(templateId, schedulerId);

    this.subscriptions.push(this.assetService.assetTypes.subscribe(types => {
      if (types) {
        this.assetTypes = types;
        console.log(types);
        setpointService.getTemplate(templateId).then(template => {
          this.template = template;
          if (!schedulerId) {
            this.schedule = new SetpointSchedule();
            this.schedule.template = this.template;
            this.getSites();
          } else {
            setpointService.getSchedule(schedulerId).then(schedule => {
              this.schedule = schedule;
              this.getSites();

              this.getDate();
            });
          }

          this.selectedAssetTypes = types.filter(type => template.assetTypeIds.some(id => type.id === id));
        });
      }
    }));
    if (!schedulerId) {
      this.getSites();
    }
  }

  ngOnInit(): void {

  }

  locationUpdated(event: any) {
    if (!event?.value || !event?.originalEvent) {
      return;
    }
    console.log('u');
    this.filterAssets();
  }

  getSites() {
    this.isLoading = true;

    this.siteService
      .getSites('rags=1')
      .then(sites => {
        this.sites = sites;
        if (!this.schedule?.sites) {
          console.error(this.schedule, ' no sites');
        }

        this.schedule.sites.forEach(ss => {
          const site = this.sites.find(s => s.id === ss.id);
          if (site) {
            site.checked = true;
          }
        });
        this.isLoading = false;
      });
  }

  getAssets() {
    this.step = 'assets';
    if (!this.usersAssets) {
      this.assetService.getAssetsForUser().then(assets => {
        this.usersAssets = assets;
        this.schedule.assets.forEach(sa => {
          const asset = assets.find(s => s.id === sa.id);
          if (asset) {
            asset.checked = true;
          }
        });
        this.calculateTotals();
        this.filterAssets();
      });
    } else {
      this.filterAssets();
    }
  }

  getDate() {
    this.step = 'date';
    this.calculateTotals();
  }

  filterAssets() {
    const checkedSites = this.sites.filter(site => site.checked);
    if (checkedSites.length) {
      this.assets = this.usersAssets
        .filter(asset => checkedSites.some(site => site.id === asset.site_id && site.checked));
    } else {
      // User wants all sites
      this.selectAllSites = true;
      this.assets = this.usersAssets;
    }

    const assetTypes = this.template.assetTypeIds || [];
    if (assetTypes.length) {
      this.assets = this.assets.filter(asset => assetTypes.some(id => asset.assetType_id === id));
    }

    if (this.schedule.filter?.location?.value) {
      this.schedule.filter.location.op = this.schedule.filter.location.op || '=';

      this.assets = this.assets.filter(asset => {
        const location = asset.location?.toLowerCase();
        switch (this.schedule.filter.location.op) {
          case '=':
            return location && location.indexOf(this.schedule.filter.location.value) !== -1;
          case '!=':
            return !location || location.indexOf(this.schedule.filter.location.value) === -1;
        }
      });
    }

    if (this.schedule.filter?.asset?.value) {
      this.schedule.filter.asset.op = this.schedule.filter.asset.op || '=';

      this.assets = this.assets.filter(asset => {
        const assetTitle = asset.title.toLowerCase();
        switch (this.schedule.filter.asset.op) {
          case '=':
            return assetTitle && assetTitle.indexOf(this.schedule.filter.asset.value) !== -1;
          case '!=':
            return !assetTitle || assetTitle.indexOf(this.schedule.filter.asset.value) === -1;
        }
      });
    }
  }

  cancel() {
    this.router.navigate(['/setpoints', 'templates']);
  }

  async delete() {
    await this.setpointService.deleteSchedule(this.schedule.id);
    this.router.navigate(['/setpoints', 'schedules']);
  }

  previous() {
    switch (this.step) {
      case 'assets':
        this.step = 'sites';
        break;
      case 'date':
        this.step = 'assets';
        break;
    }
  }

  next() {
    this.calculateTotals();

    switch (this.step) {
      case 'sites':
        this.getAssets();
        break;
      case 'assets':
        this.getDate();
        break;
      case 'date':
        this.submit();
        break;
    }
  }

  calculateTotals() {
    this.counts.sites.count = this.sites?.length || 0;
    this.counts.assets.count = this.assets?.length || 0;
    this.counts.sites.selected = this.sites?.filter(site => site.checked).length || 0;
    this.counts.assets.selected = this.assets?.filter(a => a.checked).length || 0;
    this.selectAllAssets = !this.counts.assets.selected;
  }

  selectAllClick(step: Steps) {
    switch (step) {
      case 'sites':
        this.selectAllSites = !this.selectAllSites;
        this.sites.forEach(site => {
          site.checked = this.selectAllSites;
        });
        break;
      case 'assets':
        this.selectAllAssets = !this.selectAllAssets;
        this.assets.forEach(asset => {
          asset.checked = this.selectAllAssets;
        });
        break;
      case 'date':
        this.submit();
        break;
    }
  }

  submit() {
    this.schedule.assets = this.assets.filter(a => a.checked);
    this.schedule.sites = this.sites.filter(s => s.checked);
    this.setpointService.updateSchedule(this.schedule).then(response => {
      console.log(response);
      this.cancel();
    });
  }

  tabClick(tab: Steps) {
    this.step = tab;

    switch (this.step) {
      case 'assets':
        this.getAssets();
        break;
      case 'date':
        this.getDate();
        break;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

export type Steps = 'sites' | 'assets' | 'date';
