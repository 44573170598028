import { Component, input, OnInit, output, signal } from '@angular/core';
import { sign } from 'crypto';

@Component({
    selector: 'app-month-select',
    templateUrl: './month-select.component.html',
    styleUrl: './month-select.component.css',
    standalone: false
})
export class MonthSelectComponent implements OnInit {

  value = input<Date | string>();
  years: { id: string, title: string }[] = [];
  months = Array.from({ length: 12 }, (e, i) => new Date(0, i).toLocaleString('en', { month: 'short' }));
  year = '!!';
  month = signal<string>(null);
  useValue = signal<Date>(null);
  onSelected = output<Date>();

  constructor() {
    const year = new Date().getFullYear();

    for (let i = 0; i < 30; i++) {
      this.years.push({ id: `${year - i}`, title: `${year - i}` });
    }
  }
  ngOnInit(): void {
    console.log(this.value());
    let value: Date;
    if (this.value()) {
      if (this.value() instanceof Date) {
        value = <Date>this.value();
      } else {
        value = new Date(this.value());
      }
    }
    this.useValue.set(value ?? new Date());
    this.year = this.useValue().getFullYear().toString();
    this.month.set(this.months[this.useValue().getMonth()]);
  }

  monthClick(month: string) {
    this.month.set(month);
    this.useValue.set(new Date(+this.year, this.months.indexOf(month)));
    this.onSelected.emit(this.useValue());
  }

  yearSelected(year: string) {
    console.log(year);
    this.year = year;
  }

}
