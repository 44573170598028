import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { User, UserExportItem } from '../classes/user';
import { WindowService } from './window.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	API_URL = 'https://3iub0aq0l8.execute-api.eu-west-2.amazonaws.com/4d';

	private _isEditing: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public isEditing: Observable<boolean> = this._isEditing.asObservable();

	constructor(private apiService: APIService, private http: HttpClient, private windowService: WindowService) { }

	getUsersForOrg(): Promise<User[]> {
		return new Promise((resolve) => this.get(`a=get-users`).then(u => {
			resolve(u.map(u => new User(u)));
		}));
	}

	updateExport(userId: number, exportItem: UserExportItem) {
		if (!userId || !exportItem) {
			return;
		}

		const qs = `&i=${userId}`;

		console.log(qs);
		const payload = {
			id: exportItem.id,
			sqlCommand: exportItem.sqlCommand,
			isActive: exportItem.isActive
		};

		return new Promise((resolve) => this.post(payload, `a=update-export${qs}`).then(response => resolve(response)));
	}

	updateSites(id: number, siteIds: number[]) {
		if (!siteIds || siteIds.length === 0) {
			console.log('MUST_HAVE_SITES');

			return;
		}

		const siteIdsJoined = siteIds.join(',');

		const qs = `&i=${id}&s=${siteIdsJoined}`;

		console.log(qs);

		return new Promise((resolve) => this.get(`a=update-sites${qs}`).then(response => resolve(response)));
	}

	getUser(id: number, qs = ''): Promise<User> {
		return new Promise((resolve) => this.get(`a=get-user&i=${id}&${qs}`).then(u => resolve(new User(u))));
	}

	adminCommandPost(path: string, postData: any): Promise<any> {
		return new Promise((resolve) => {
			const url = `${this.API_URL}/admin/${path}`;
			return this.http
				.post<any>(url, postData, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

	deleteUser(id: number): Promise<User> {
		return new Promise((resolve) => this.get(`a=delete-user&i=${id}`).then(u => resolve(new User(u))));
	}

	setIsEditing(isEditing: boolean) {
		this._isEditing.next(true);
	}

	sendFeedback(feedback: string): Promise<User> {
		return new Promise((resolve) => this.post({ feedback }, 'a=send-feedback').then(response => resolve(response)));
	}


	get(qs: string = ''): Promise<any> {
		return new Promise((resolve) => {
			const url = `${this.API_URL}?${qs}`;
			return this.http.get<any>(url, this.apiService.getUAOHeaders()).subscribe(b => resolve(b));
		});
	}

	post(body: any, qs: string = ''): Promise<any> {
		return new Promise((resolve) => {
			const url = `${this.API_URL}?${qs}`;
			return this.http
				.post<any>(url, body, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

	getOrgUsersForEngagement(): Promise<User[]> {
		return new Promise((resolve) => {
			const url = `${this.API_URL}/orgs?engagement=1`;
			return this.http.get<any>(url, this.apiService.getUAOHeaders()).subscribe(response => {
				const users: User[] = response.users.map(user => new User(user));
				resolve(users);
			});
		});
	}

	async getRouteConfig(url: string, action?: string, changes?: string, browser?: boolean): Promise<any> {
		return new Promise((resolve) => {
			// Must be a valid user for retrieving config for user
			if (this.apiService.isAuthorised()) {
				const body: any = { url };
				if (action) {
					body.action = action;
				}

				if (changes) {
					body.changes = changes;
				}

				if (browser) {
					body.browser = this.windowService.getBrowserName();
				}

				body.v = APIService.VERSION;
				const postURL = this.API_URL + `route/config`;

				this.http.post<any>(postURL, body, this.apiService.getUAOHeaders())
					.subscribe(response => resolve(response));
			} else {
				resolve({});
			}
		});
	}

	/**
		* Invite user into the current org
		*/
	async inviteUserToOrg(invite: any): Promise<any> {
		return this.http.post(this.API_URL + '/invite', { invite }, this.apiService.getUAOHeaders())
			.toPromise();
	}


}
