<form class="form-horizontal" *ngIf="form">
  <div class="form-group">
    <label for="occupancyUtilisation" class="col-sm-3 control-label" pTooltip="Enter hours as a number or as text if you'd like to include minutes, example: ('1&nbsp;hour&nbsp;20&nbsp;minutes')">100% Utilised in hours</label>
    <div class="col-sm-9">
      <input [(ngModel)]="form.occFullUtilisationText" type="text" class="form-control" name="occFullUtilisationText" (ngModelChange)="formChanged()" id="occupancyUtilisation" placeholder="" />
      <span class="help-block">{{ form.occFullUtilisationMins }} minutes</span>
    </div>
    <label for="title" class="col-sm-3 control-label" pTooltip="What would you like to call this space?">Title</label>
    <div class="col-sm-9">
      <input [(ngModel)]="form.title" type="text" class="form-control" name="title" (ngModelChange)="formChanged()" id="title" placeholder="" />
      <span class="help-block"></span>
    </div>
  </div>
  <div class="form-group">
    <label for="daysOccupied" class="col-sm-3 control-label" pTooltip="How many days expected to be occupied in a week.">Days Occupied</label>
    <div class="col-sm-9 days">
      <app-chip label="Monday" [checked]="!!form.occDays[0]" (click)="setDay(0)"></app-chip>
      <app-chip label="Tuesday" [checked]="!!form.occDays[1]" (click)="setDay(1)"></app-chip>
      <app-chip label="Wednesday" [checked]="!!form.occDays[2]" (click)="setDay(2)"></app-chip>
      <app-chip label="Thursday" [checked]="!!form.occDays[3]" (click)="setDay(3)"></app-chip>
      <app-chip label="Friday" [checked]="!!form.occDays[4]" (click)="setDay(4)"></app-chip>
      <app-chip label="Saturday" [checked]="!!form.occDays[5]" (click)="setDay(5)"></app-chip>
      <app-chip label="Sunday" [checked]="!!form.occDays[6]" (click)="setDay(6)"></app-chip>
      <span pTooltip="Days to count for occupancy over a week">{{ daysOccupied }} days</span>
    </div>
  </div>
  <div class="text-right">
    <app-button label="Submit" icon="mdi mdi-check" (click)="submit()" [isCTA]="true" />
  </div>
</form>
