import { Component, signal } from '@angular/core';
import { APIService } from 'app/shared/api.service';
import { EngageService } from '../engage.service';
import { SiteService } from 'app/shared/site.service';
import { Site } from 'app/classes/site';
import { EngagementService } from 'app/shared/engagement.service';
import { Org } from 'app/classes/org';

@Component({
    selector: 'app-engage-config',
    templateUrl: './engage-config.component.html',
    styleUrl: './engage-config.component.css',
    standalone: false
})
export class EngageConfigComponent {
  sites = signal<Site[]>([]);
  orgs = signal<Org[]>([]);
  reports = signal<any[]>([]);

  constructor(private engagementService: EngagementService, private apiService: APIService, private siteService: SiteService) {

    this.siteService.getSites().then(sites => this.sites.set(sites));

    this.engagementService.getConfig().then(response => {
      this.orgs.set(response.orgs.map(org => new Org(org)));
    });
  }
}
