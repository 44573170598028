import { Component, OnInit, OnDestroy } from '@angular/core';
import { APIService } from '../../shared/api.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SitePlan } from '../../classes/site-plan';
import { SiteFloorplanArea } from '../../classes/site-floorplan-area';
import { SiteFloorplanAsset } from '../../classes/site-floorplan-asset';
import { SiteFloorplanObject } from '../../classes/site-floorplan-object';
import { Viewbox } from '../../classes/svg/viewbox';
import { Site } from '../../classes/site';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { SiteFloorplanShape } from 'app/classes/site-floorplan-shape';
import { FloorplanService, SitePlanComplete } from "app/shared/floorplan.service";
import { RangeInterface } from 'app/profiling/site-chart/site-chart.component';

@Component({
  selector: 'app-site-plan-v2',
  templateUrl: './site-plan-v2.component.html',
  styleUrls: ['./site-plan-v2.component.css'],
  standalone: false
})
export class SitePlanV2Component implements OnInit, OnDestroy {
  static readonly componentName: string = 'SitePlanV2Component';

  getTelemetyTimer: any;
  siteFloorplans: SitePlan[];
  siteFloorplan: SitePlan;
  sitePlanComplete: SitePlanComplete;

  // Selected dashboard

  can: { admin: boolean } = { admin: false };

  assets: SiteFloorplanAsset[];
  areas: SiteFloorplanArea[] = [];
  shapes: SiteFloorplanShape[] = [];
  objects: SiteFloorplanObject[] = [];
  hasLoaded: boolean;
  viewbox: Viewbox;
  viewboxString: string;
  mode = '';

  stats = {
    clientX: 0,
    clientY: 0,
    svgX: 0,
    svgY: 0
  };

  areaFocus: SiteFloorplanArea;
  site: Site;
  // Set to an asset to view full screen data
  viewAsset: SiteFloorplanAsset;
  selected: { presentation: 'floorplan' | 'table' | 'chart', range: RangeInterface } = {
    presentation: 'floorplan',
    range: { startsAt: '08:00', endsAt: '17:00', type: 'avg' }
  };

  sectionBannerBackSub: Subscription;
  scales: any = { r: 20, labelYOffset: 34 };
  dataRefreshTimer = null;

  selectedArea: SiteFloorplanArea;
  selectedShape: SiteFloorplanShape;

  isSVGImageRendered: boolean;


  viewShape: SiteFloorplanShape;

  hasDashboard: boolean;

  userSubscription: Subscription;

  planId: number;

  zoomLevel = 0;

  svgMod = { x: 0, y: 0, width: 0, height: 0 };
  siteId: number;
  rangeOptions = [
    { id: 'avg', title: 'Average' },
    { id: 'min', title: 'Minimum' },
    { id: 'max', title: 'Maximum' }
  ];

  constructor(private location: Location, private apiService: APIService, private router: Router, private route: ActivatedRoute, private floorplanService: FloorplanService) {
    this.hasLoaded = false;
    this.planId = +(this.route.snapshot.queryParamMap.get('planid') || 0);

    route.params.subscribe((params: Params) => {
      this.siteId = +params.siteid;
      apiService.getSite(params.siteid).then(site => {
        this.site = site;

        this.hasDashboard = !!site.settings.floorplan_dashboard;
        apiService.getSiteFloorplans(params.siteid)
          .then(siteFloorPlans => {
            this.siteFloorplans = siteFloorPlans;
            this.planId = +(this.planId || siteFloorPlans[0].id);

            this.hasLoaded = true;
          });
      });
    });

    this.restoreLocalStorage();
    this.userSubscription = this.apiService.user.subscribe((user) => {
      if (user) {
        this.can.admin = (user.role === 'admin' || user.orgRoles.join(',').indexOf('floorplan_admin') !== -1);
      }
    });
  }

  ngOnInit() {
  }

  navigateBack() {
    this.location.back();
  }

  back() {
    this.router.navigate(['/profiling'], { relativeTo: this.route });
  }

  updateOptions() {
    this.saveLocalStorage();
    this.floorplanService.setDataSubject("range", this.selected.range);
  }

  onClickSitePlanLabel(siteFloorplan: SitePlan) {
    this.location.replaceState(window.location.pathname, `planid=${siteFloorplan.id}`);
    this.selectedArea = null;
    this.planId = null;


    setTimeout(() => {
      this.selected.presentation = 'floorplan';
      this.planId = siteFloorplan.id
    }, 10);
  }

  presentationClick(event: any) {
    if (event.target.tagName === 'LI') {
      switch (event.target.textContent.trim()) {
        case 'Charts':
          return this.switchTo('chart');
        case 'Table':
          return this.switchTo('table');
        case 'Floor plan':
          return this.switchTo('floorplan');
      }
    }
  }

  switchTo(presentation: 'floorplan' | 'table' | 'chart') {
    this.selected.presentation = presentation;
    if (presentation === 'table') {
      this.updateOptions();
    } else {
      this.saveLocalStorage();
    }
  }

  saveLocalStorage() {
    localStorage.setItem(this.apiService.getUserOrg().id + ':' + this.apiService.getUserId() + ':' + SitePlanV2Component.componentName, JSON.stringify(this.selected));
  }

  restoreLocalStorage() {
    let payload: any = localStorage.getItem(this.apiService.getUserOrg().id + ':' + this.apiService.getUserId() + ':' + SitePlanV2Component.componentName);
    if (!payload) {
      return;
    }
    try {
      this.selected = JSON.parse(payload);
    } catch (e) {
      console.log(e);

    }
    // if (!this.selected.presentation) {
    // Must have a default
    this.selected.presentation = 'floorplan';
    // }
  }

  onMouseEnterAreaLabel(area: SiteFloorplanArea) {
    this.areaFocus = area;
    //this.clickFloorplanLabel(area);
  }

  previewArea(area: SiteFloorplanArea) {
    this.clickFloorplanLabel(area);
  }

  clickFloorplanLabel(area?: SiteFloorplanArea) {
    this.floorplanService.setDataSubject('area', area);
  }

  floorplanLoaded(sitePlanComplete: SitePlanComplete) {
    this.sitePlanComplete = sitePlanComplete;
  }

  onMouseLeaveAreaLabel(area: SiteFloorplanArea) {
    this.areaFocus = null;
  }

  ngOnDestroy() {

    clearInterval(this.dataRefreshTimer);
    if (this.getTelemetyTimer) {
      clearTimeout(this.getTelemetyTimer);
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    console.log('DESTROY SitePlanComponent');
  }
}
