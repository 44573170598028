import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-occ-percentage-bar',
    templateUrl: './occ-percentage-bar.component.html',
    styleUrls: ['./occ-percentage-bar.component.css'],
    standalone: false
})
export class OccPercentageBarComponent {

  percentage: number;
  rating: 'a' | 'b' | 'c' | 'd' | 'e';

  @Input()
  set perc(value: number) {
    this.percentage = value;
    if (value < 20) {
      this.rating = 'e';
    } else if (value < 40) {
      this.rating = 'd';
    } else if (value < 60) {
      this.rating = 'c';
    } else if (value < 80) {
      this.rating = 'b';
    } else {
      this.rating = 'a';
    }
  }

}
