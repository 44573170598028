import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { BuildingReview } from 'app/classes/building-review';
import { DateService } from './date.service';

@Injectable({
  providedIn: 'root'
})
export class IssueTrackingService {

  static readonly API_URL = 'https://eapkrq89fk.execute-api.eu-west-2.amazonaws.com/v1';

  constructor(private apiService: APIService, private http: HttpClient,) { }


  addReview(review: BuildingReview): Promise<any> {
    const payload = {
      buildingId: review.building.id,
      start: DateService.dbDate(review.review.dates.start),
      end: DateService.dbDate(review.review.dates.end)
    };
    return this.post<any>(payload, 'reviews/add');
  }

  getIssues(): Promise<IGetIssuesResponse> {
    return new Promise((resolve) => {
      this.get<IGetIssuesResponse>().then(issuesResponse => {
        if (issuesResponse.issues) {
          issuesResponse.issues.forEach(issue => {
            issue.reviewDate = new Date(issue.reviewDate);
            issue.createdAt = new Date(issue.createdAt);
          });
        }
        resolve(issuesResponse);
      });
    });
  }

  getAdmin(): Promise<any> {
    return this.get<any>('admin');
  }

  setAdmin(data: any): Promise<any> {
    return this.post<any>(data, 'admin');
  }

  enableForOrg(): Promise<any> {
    return this.post<any>({ isEnabled: true }, 'admin');
  }

  disableForOrg(): Promise<any> {
    return this.post<any>({ isEnabled: false }, 'admin');
  }

  get<T>(path: string = '', qs: string = ''): Promise<any> {
    return new Promise((resolve) => {
      const url = `${IssueTrackingService.API_URL}/${path}?${qs}`;
      return this.http
        .get<T>(url, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  post<T>(body, path: string = '', qs: string = ''): Promise<any> {
    return new Promise((resolve) => {
      const url = `${IssueTrackingService.API_URL}/${path}?${qs}`;
      return this.http
        .post<T>(url, body, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }
}

export interface IGetIssuesResponse {
  issues?: IGetTrackingIssue[];
  master: { isEnabled: boolean };
}

export interface IGetTrackingIssue {
  annotation: string;
  assetTitle: string;
  assetType_id: number;
  asset_id: number;
  buildingTitle: string;
  building_id: number;
  createdAt: Date;
  createdBy: number;
  document_id: number;
  noOfMonths: number;
  proposedAction: string;
  rag: string;
  reviewDate: Date;
  state: string;
  status: string;
  uuid: string;
}
