import { Component, OnInit, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-insights-airquality',
    templateUrl: './insights-airquality.component.html',
    styleUrls: ['./insights-airquality.component.css'],
    standalone: false
})
export class InsightsAirqualityComponent implements OnInit {

  useCollections = signal<boolean>(false);
  constructor(private activatedRoute: ActivatedRoute) {

    this.useCollections.set(!!(activatedRoute.snapshot.queryParamMap.get('collections') || false));
  }

  ngOnInit(): void {
  }

}
