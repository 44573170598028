import { Component, OnInit } from '@angular/core';
import { BillingClient } from 'app/classes/billing-client';
import { BillingService } from 'app/shared/billing.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-dialog-billing-client',
    templateUrl: './dialog-billing-client.component.html',
    styleUrls: ['./dialog-billing-client.component.css'],
    providers: [DialogService],
    standalone: false
})
export class DialogBillingClientComponent implements OnInit {

  client: BillingClient;

  constructor(public dialogService: DialogService, public ref: DynamicDialogRef, public config: DynamicDialogConfig, public billingService: BillingService) { }

  ngOnInit(): void {
    if (this.config.data?.client) {
      this.client = this.config.data.client;
    } else {
      this.client = new BillingClient();
    }
  }

  cancel() {
    this.ref.close();
  }

  submit() {
    this.billingService
      .postBillingClient(this.client)
      .then(id => {
        this.client.id = id;
        this.ref.close(this.client);
      });
  }
}
