import { Component, OnInit, Input } from '@angular/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Org } from '../../classes/org';

@Component({
    selector: 'app-org-title-changeable',
    templateUrl: './org-title-changeable.component.html',
    styleUrls: ['./org-title-changeable.component.css'],
    standalone: false
})
export class OrgTitleChangeableComponent implements OnInit {

	_org: Org = new Org();
	orgs: Org[] = [];
	hasLogo: boolean;

	@Input()
	set org(value: Org) {
		if (value) {
			this._org = value;
			if (value.settings.logo_menu) {
				// If the org has a logo, don't display the ord title
				this.hasLogo = true;
			}
		} else {
			this._org = new Org();
		}
	}

	constructor() { }

	ngOnInit() { }

	showOrgs() {
	}
}
