import { Component, EventEmitter, Output, input, signal } from '@angular/core';
import { Grouping } from "../../classes/grouping";
import { OrgService } from "../../shared/org.service";

@Component({
    selector: 'app-button-notifications',
    templateUrl: './button-notifications.component.html',
    styleUrl: './button-notifications.component.css',
    standalone: false
})
export class ButtonNotificationsComponent {

  config = input<INotificationConfig>();
  groupings = input.required<Grouping[]>();

  groupingsAvailable = signal<Grouping[]>([]);

  caption = input<string>(null);
  label = signal<string>('Modify');
  size = input<string>('small');


  list: any[];
  dialogRef: any;
  isModifying: null | "user-groups";
  showDialog = signal(false);
  selected: Grouping[];
  groupingActive: Grouping;
  pleaseWait = signal(false);

  @Output() onUpdate = new EventEmitter<Grouping[]>();

  constructor(private orgService: OrgService) { }

  ngOnInit(): void {

  }

  combine() {
    // this.list = this.rule.notifications;
  }



  buttonClick() {
    this.get();
  }

  get() {
    this.pleaseWait.set(true);
    this.orgService.getGroupingsAndUsers().then((groupings) => {
      this.groupingsAvailable.set(groupings);
      this.groupingActive = groupings[0];
      this.groupingsAvailable().forEach(g => g.selected = this.groupings().some(s => s.id === g.id));
      this.showDialog.set(true);
      this.pleaseWait.set(false);
    });
  }

  selectedGroups(event: any) {
    if (event) {
      // this.rule.groupings = this.dialogGroupings;
    }
  }

  cancel() {
    this.showDialog.set(false);
  }

  async submit(event: any) {
    this.showDialog.set(false);
    if (event) {
      this.onUpdate.emit(this.groupingsAvailable().filter(g => g.selected));
    }
  }
}

export interface INotificationConfig {
  groups: { id: number, selected?: boolean }[];
}
