import { Component, OnInit, Input } from '@angular/core';
import { Ticket } from '../../classes/ticket';

@Component({
    selector: 'app-tickets-pinned',
    templateUrl: './tickets-pinned.component.html',
    styleUrls: ['./tickets-pinned.component.css'],
    standalone: false
})
export class TicketsPinnedComponent implements OnInit {

  @Input()
  tickets: Ticket[];
  
  constructor() { }

  ngOnInit() {
  }

}
