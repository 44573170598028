import { Injectable } from '@angular/core';


declare var window: any;
declare var opr: any;
declare var safari: any;
declare var document: any;
declare var InstallTrigger: any;

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  constructor() { }

  isMobile(): boolean {
    return window.matchMedia("only screen and (min-width: 320px) and (max-width: 767px)").matches;
  }

  goFullscreen() {
    window.document.documentElement.requestFullscreen();
  }

  getBrowserName(): 'opera' | 'firefox' | 'safari' | 'chrome' | 'edge' | 'blink' | 'samsung' | 'iphone' | 'unknown' | 'ie' {

    // Opera 8.0+
    const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== 'undefined';

    // Safari 3.0+ "[object HTMLElementConstructor]"
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/false || !!document.documentMode;

    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1+
    const isChrome = !!window.chrome && !!window.chrome.webstore;

    // Blink engine detection
    const isBlink = (isChrome || isOpera) && !!window.CSS;

    const isIphone = ((navigator.userAgent.indexOf('iPhone') != -1) || (navigator.userAgent.indexOf('iPod') != -1));

    const isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/i);
    const isMobileChrome = !!(navigator.userAgent.match(/Chrome/i));
    const isMobileSafari = !!(navigator.userAgent.match(/Mobile Safari/i));

    return isOpera ? 'opera' :
      isFirefox ? 'firefox' :
        isSafari ? 'safari' :
          isChrome ? 'chrome' :
            isMobileChrome ? 'chrome' :
              isMobileSafari ? 'safari' :
                isIE ? 'ie' :
                  isEdge ? 'edge' :
                    isBlink ? 'blink' :
                      isSamsungBrowser ? 'samsung' :
                        isIphone ? 'iphone' :
                          'unknown';
  };
}
