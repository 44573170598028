
import { Component, Input, signal } from '@angular/core';
import { IgetHourlyForAssetFromRangeResponse, OccupancyService } from 'app/shared/occupancy.service';

@Component({
    selector: 'app-setpoint-query-occ-squares',
    templateUrl: './setpoint-query-occ-squares.component.html',
    styleUrls: ['./setpoint-query-occ-squares.component.css'],
    standalone: false
})
export class SetpointQueryOccSquaresComponent {

  mini: boolean;

  _stats: IgetHourlyForAssetFromRangeResponse;

  @Input()
  public set stats(v: IgetHourlyForAssetFromRangeResponse) {
    this._stats = v;
    if (v) {
      this.calcHours();
    }
  }

  // If we dont have pre-built stats, allow from asset id/from/to
  @Input()
  public set config(obj: { id: number, from: Date, to: Date }) {
    this.mini = true;

    if (!obj.from) {
      obj.from = new Date();
      obj.to = new Date();
    }

    this.occupancyService
      .getHourlyForAssetFromRange(obj.id, obj.from, obj.to)
      .then(r => {
        console.log('Got', r);
        this.stats = r;
        this.calcHours();
      });

  }

  // If we dont have pre-built stats, allow from asset id/from/to
  @Input()
  public set assetId(id: number) {

    this.mini = true;

    const from = new Date();
    const to = new Date();


    this.occupancyService
      .getHourlyForAssetFromRange(id, from, to)
      .then(r => {
        console.log('Got', r);
        this.stats = r;
        this.calcHours();
      });

  }

  hours: any[];
  days = signal<any>(null);

  constructor(private occupancyService: OccupancyService) { }


  calcHours() {
    const f = this._stats.extra.dates.from;
    const t = this._stats.extra.dates.to;
    const days = this._stats.extra.dates.days;

    const hours = [];

    const assetId = this._stats.floorplans[0].asset_id;
    this.days.set([]);

    for (let iDay = 0; iDay < days; iDay++) {
      const day = this._stats.assets[assetId].daysOfHours[iDay];

      if (day.hours.length === 0) {
        day.hours = Array(24).fill({ h: null, d: 0 });
      }
      for (let index = 0; index < 24; index++) {
        const dayHours = this._stats.assets[assetId].daysOfHours[iDay].hours || [];
        const dayHour = dayHours.find(hour => hour.h === index);
        hours.push(dayHour?.d || 0);
      }
      this.days().push(day);
    }
    this.hours = hours;

    console.log(this.days());
    console.log(f, t);
  }
}
