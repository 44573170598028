import { Component, OnInit } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { Router } from '@angular/router';
import { SetpointsService } from 'app/shared/setpoints.service';
import { AssetService } from 'app/shared/asset.service';
import moment from 'moment';

@Component({
    selector: 'app-setpoint-overview',
    templateUrl: './setpoint-overview.component.html',
    styleUrls: ['./setpoint-overview.component.css'],
    standalone: false
})
export class SetpointOverviewComponent implements OnInit {

  startsAt: any = moment().subtract(6, 'days');
  endsAt: any = moment();

  results: any[];
  sensorSelected: Asset;
  loading: boolean = true;
  maximumDays: number = 30;
  requestedMaximumDays: number = 30;

  constructor(private assetService: AssetService, private setpointService: SetpointsService, private router: Router) { }

  ngOnInit() {
    this.getData();
  }

  maximumDaysChanged() {
    this.getData();
  }

  getData() {
    this.results = [];
    this.loading = true;
    this.setpointService.getSetPointsHistoryForUserOrg(2, this.requestedMaximumDays)
      .then(assets => {
        const gateways = [];

        assets.forEach(asset => {
          if (gateways[asset.gatewayId]) {

          } else {
            gateways[asset.gatewayId] = {
              title: asset.gatewayTitle,
              assets: {}
            };
          }
          const aId = 'A' + asset.assetId;
          if (gateways[asset.gatewayId].assets[aId]) {

          } else {
            gateways[asset.gatewayId].assets[aId] = {
              title: asset.assetTitle,
              id: asset.assetId,
              days: []
            };
          }

          gateways[asset.gatewayId].assets[aId].days.push(asset);


        });
        // Convert object keys into arrays
        Object.keys(gateways).forEach(gatewayId => {
          const gateway = gateways[gatewayId];
          gateway.id = gatewayId;
          const gatewayAssets = []
          Object.keys(gateway.assets).forEach(assetId => {
            gatewayAssets.push(gateway.assets[assetId]);
          });
          gateway.assets = gatewayAssets;
          this.results.push(gateway);
        });

        this.loading = false;
        this.maximumDays = this.requestedMaximumDays;
      });
  }

  hasClicked(event: any) {
    try {
      this.startsAt = moment(event.info, 'DD-MM-YYYY').day('Monday');
      this.endsAt = moment(event.info, 'DD-MM-YYYY').day('Monday').add(6, 'days');
    } catch (error) {
      console.log(error);
    }

    this.assetService.getAsset(event.asset_id)
      .then(asset => {
        this.sensorSelected = asset;
      });
  }

  gotoList() {
    this.router.navigate(['/setpoints']);
  }

}
