import { Component, OnInit } from '@angular/core';
import { APIService } from 'app/shared/api.service';
import { Router } from '@angular/router';
import { Asset } from 'app/classes/asset';

@Component({
    selector: 'app-dev-api-list',
    templateUrl: './dev-api-list.component.html',
    styleUrls: ['./dev-api-list.component.css'],
    standalone: false
})
export class DevApiListComponent implements OnInit {

  apis: any[];
  apiglobal = { limit: 100, usage: 0 };
  assets: any[];
  apikeys: any[];
  totals = {
    pushedCountDay: 0
  };
  canRefresh: boolean;
  isLoading: boolean;
  nothingToDo: boolean;
  apikeySelected: any;
  usage: any;
  selectedAsset: Asset;
  auditLog: any;

  can = { createKey: false };

  constructor(public apiService: APIService, private router: Router) { }

  ngOnInit() {
    this.get();
  }

  refresh() {
    if (!this.canRefresh) {
      return;
    }
    this.get();
  }

  assetClick(asset: Asset) {
    this.auditLog = null;
    this.selectedAsset = asset;
    this.apiService.apiController('get', 'audit&id=' + asset.id)
      .then(log => this.auditLog = log);
  }

  get() {
    this.canRefresh = false;
    this.isLoading = true;
    this.apiService
      .apiController('get', 'list')
      .then(apis => {
        this.isLoading = false;
        this.apis = apis.apis;
        this.assets = apis.assets;
        this.apikeys = apis.apikeys;
        this.usage = apis.usage;
        this.totals.pushedCountDay = 0;
        apis.ia.forEach(asset => {
          // add unique additions
          const filter = this.assets.filter(a => a.id === asset.id);
          console.log('filter', asset, filter);
          if (filter.length === 0) {
            this.assets.push(asset);
          }
        });

        if (apis.transforms) {
          apis.transforms.forEach(transfom => {
            this.assets.forEach(assetCheck => {
              if (assetCheck.assetType_id === transfom.assetType_id) {
                console.log('**MATCH**', assetCheck, transfom);
                if (!assetCheck.transforms) {
                  assetCheck.transforms = [];
                }
                assetCheck.transforms.push(transfom);
              }
            });
          });
        }

        setTimeout(() => {
          // Set a 5 second wait time to refresh
          this.canRefresh = true;
        }, 1000 * 5);

        if (!this.assets || this.assets.length === 0) {
          this.nothingToDo = true;
          return;
        }
        apis.assets.forEach(asset => {
          this.totals.pushedCountDay += asset.pushedCountDay;
        });

      });
  }

  clickedKey(apiKey) {
    this.router.navigate(['developer', 'keys', apiKey.apikey]);
  }
}
