import { Component, Input, output, signal } from '@angular/core';

@Component({
  selector: 'app-data-form',
  templateUrl: './data-form.component.html',
  styleUrl: './data-form.component.css',
  standalone: false
})
export class DataFormComponent {

  _dataForm = signal<DataForm>(null);
  _isDebugging = signal<boolean>(false);
  onDataChange = output<boolean>();

  @Input()
  public set dataForm(v: DataForm) {
    this._dataForm.set(v);
  }

  @Input()
  public set isDebugging(v: boolean) {
    this._isDebugging.set(v);
  }

  optionClick(item, option) {
    item.value = option.id;
  }

  changed() {
    this.onDataChange.emit(true);
  }
}

export class DataForm {
  id: number | string;
  items: DataFormItem[];
  config: { labels: { width: number }, inputs?: { width: string | number } } = { labels: { width: 110 }, inputs: { width: "100%" } };

  constructor(data?: any) {
    this.items = data?.items || [];
    if (data.config) {
      this.config = { ...this.config, ...data.config };
    }
    this.id = data?.id || crypto.randomUUID();
  }

  getValue(key: string) {
    return this.items.find(i => i.id === key).value;
  }

  setValue(key: string, value: any) {
    this.items.find(i => i.id === key).value = value;
  }

  serialise(): any {
    const payload = {};
    for (const item of this.items) {
      payload[item.id] = item.value;
    }

    return payload;
  }
}

export class DataFormItem {
  id: number | string;
  label: string;
  type: 'string' | 'number' | 'select' | 'textarea' | 'time' | 'date' | 'group' | 'password';
  options?: any;
  value: string | number;
  width?: string;
}
