import { Transition } from "./transition/index.js";
import { SCHEDULED } from "./transition/schedule.js";
var root = [null];
export default function (node, name) {
  var schedules = node.__transition,
    schedule,
    i;
  if (schedules) {
    name = name == null ? null : name + "";
    for (i in schedules) {
      if ((schedule = schedules[i]).state > SCHEDULED && schedule.name === name) {
        return new Transition([[node]], root, name, +i);
      }
    }
  }
  return null;
}