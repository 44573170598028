import { Component, input } from '@angular/core';
import { IGetTrackingIssue } from 'app/shared/issue-tracking.service';

@Component({
  selector: 'app-issue-tracking-item',
  standalone: false,

  templateUrl: './issue-tracking-item.component.html',
  styleUrl: './issue-tracking-item.component.css'
})
export class IssueTrackingItemComponent {

  issue = input.required<IGetTrackingIssue>();

}
