<article>
  @if (documents) {
    <section class="documents" [class.drilldown]="drillDown">
      @if (isMobile) {
        <app-search-input [searchText]="filtered.text" [resultCount]="filtered.count" (onSearchChanged)="searchChanged($event)" tooltipText="Search document title, 'rag:green', 'rag:amber', 'rag:red', 'docs:read', 'docs:unread', 'notes:green', 'notes:amber', 'notes:red', 'notes:any', 'org:???'"> </app-search-input>
      } @else {
        <app-toolbar>
          <div class="left place-center">
            @if (documents?.length) {
              <div class="search">
                <app-search-input [searchText]="filtered.text" [resultCount]="filtered.count" (onSearchChanged)="searchChanged($event)" tooltipText="Search document title, 'rag:green', 'rag:amber', 'rag:red', 'docs:read', 'docs:unread', 'notes:green', 'notes:amber', 'notes:red', 'notes:any', 'org:???'"> </app-search-input>
              </div>
            }
          </div>
          <div class="right place-center">
            <app-button icon="mdi mdi-rotate-3d-variant" [label]="view === 'default' ? 'Default view' : 'Notes View'" size="small" (click)="view = view === 'default' ? 'notes' : 'default'" tooltipPosition="left" [pTooltip]="view === 'default' ? 'Click to show notes' : 'Click for a more compact view'" />
            <app-button icon="mdi mdi-rotate-3d-variant" tooltipPosition="left" [pTooltip]="config.onlyActiveSites ? 'Click to show inactive sites' : 'Click to show only active sites'" [label]="config.onlyActiveSites ? 'Active sites' : 'All sites'" size="small" (click)="toggleActive()" />
          </div>
        </app-toolbar>
      }
      @if (filtered.list?.length) {
        <div class="table-wrapper content-container">
          @if (view === 'default') {
            <table class="table table-sm sticky under-tabs" [class.table-hover]="drillDown" aria-label="List of building documents">
              <thead>
                <tr>
                  <th class="isDesktop"></th>
                  <th>RAG</th>
                  @if (drillDown) {
                    <th>Building</th>
                  }
                  <th class="not-mobile">Date</th>
                  <th class="isDesktop">View</th>
                  <th class="isDesktop" class="notes-icon">Notes</th>
                </tr>
              </thead>
              <tbody>
                @for (document of filtered.list; track document) {
                  @if (!config.onlyActiveSites || (config.onlyActiveSites && document.isActive)) {
                    <tr [class.is-read]="document.isRead">
                      <td class="isDesktop" [pTooltip]="document.orgShortTitle" tooltipPosition="bottom" appendTo="body" (click)="buildingClick(document)">
                        @if (document.orgLogo) {
                          <img class="document-logo" src="https://s3.eu-west-2.amazonaws.com/4d-documents/{{ document.orgLogo }}" alt="logo" />
                        }
                      </td>
                      <td [pTooltip]="document.rag ? 'Status is ' + document.rag : 'No RAG'" tooltipPosition="bottom" class="text-center" appendTo="body" (click)="buildingClick(document)">
                        <i class="fa fa-fw fa-circle rag-{{ document.rag }}"></i>
                      </td>
                      @if (drillDown) {
                        <td (click)="buildingClick(document)">
                          <span>{{ document.buildingTitle }}</span>
                          @if (!document.siteIsActive) {
                            <span class="chip">site inactive</span>
                          }
                          @if (!document.orgIsActive) {
                            <span class="chip">org inactive</span>
                          }
                        </td>
                      }
                      <td class="not-mobile" pTooltip="Uploaded {{ document.createdAt | amDateFormat: apiService.getDateFormat() }}" tooltipPosition="bottom" appendTo="body" (click)="buildingClick(document)">
                        {{ document.calendarStartAt | amDateFormat: 'Do MMMM YYYY' }}
                      </td>

                      <td class="actions isDesktop">
                        <a target="_blank" (click)="hasViewed(document)" href="https://s3.eu-west-2.amazonaws.com/4d-documents/{{ document.key }}">
                          <span>
                            @switch (document.contentType) {
                              @case ('application/pdf') {
                                <span pTooltip="View PDF document" tooltipPosition="bottom" appendTo="body">
                                  <i class="mdi mdi-file-document-outline"></i>
                                </span>
                              }
                              @case ('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                                <span pTooltip="View Excel spreadsheet" tooltipPosition="bottom" appendTo="body">
                                  <i class="mdi mdi-file-excel-box-outline"></i>
                                </span>
                              }
                              @default {
                                <span>
                                  <i class="mdi mdi-download"></i>
                                </span>
                              }
                            }
                          </span>
                        </a>
                      </td>
                      <td class="notes notes-icon">
                        <i (click)="updateNotes(document)" tooltipPosition="bottom" appendTo="body" pTooltip="{{ document.notes ? document.notes.substring(0, 20) + '...' : 'No notes' }}" class="fa fa-fw fa-sticky-note" [class.note-colour-red]="document.notesColour === 'red'" [class.note-colour-green]="document.notesColour === 'green'" [class.note-colour-orange]="document.notesColour === 'orange'" [class.has-notes]="document.notes"> </i>
                      </td>
                    </tr>
                  }
                }
              </tbody>
            </table>
          } @else {
            <table class="table table-sm" aria-label="Building documents">
              <thead>
                <tr>
                  <th></th>
                  <th>RAG</th>
                  @if (drillDown) {
                    <th>Building</th>
                  }
                  <th class="not-mobile">Date</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                @for (document of documents; track document.id) {
                  <tr [class.is-read]="document.isRead">
                    <td [pTooltip]="document.orgShortTitle" tooltipPosition="bottom">
                      @if (document.orgLogo) {
                        <img src="https://s3.eu-west-2.amazonaws.com/4d-documents/{{ document.orgLogo }}" alt="logo" />
                      }
                    </td>
                    <td [pTooltip]="document.rag ? 'Status is ' + document.rag : 'No RAG'" tooltipPosition="bottom" class="text-center" appendTo="body">
                      <i class="fa fa-fw fa-circle rag-{{ document.rag }}"></i>
                    </td>
                    @if (drillDown) {
                      <td [class.link]="drillDown" (click)="buildingClick(document)">
                        <span pTooltip="Show documents" appendTo="body" tooltipPosition="bottom">{{ document.buildingTitle }}</span>
                      </td>
                    }
                    <td class="not-mobile" pTooltip="Uploaded {{ document.createdAt | amDateFormat: apiService.getDateFormat() }}" tooltipPosition="bottom">
                      {{ document.calendarStartAt | amDateFormat: 'Do MMMM YYYY' }}
                    </td>
                    <td class="actions">
                      <a target="_blank" (click)="hasViewed(document)" href="https://s3.eu-west-2.amazonaws.com/4d-documents/{{ document.key }}">
                        @switch (document.contentType) {
                          @case ('application/pdf') {
                            <span pTooltip="View PDF document" tooltipPosition="bottom" appendTo="body">
                              <i class="mdi mdi-file-document-outline"></i>
                            </span>
                          }
                          @case ('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                            <span pTooltip="View Excel spreadsheet" tooltipPosition="bottom" appendTo="body">
                              <i class="mdi mdi-file-excel-box-outline"></i>
                            </span>
                          }
                          @default {
                            <span>
                              <i class="mdi mdi-download"></i>
                            </span>
                          }
                        }
                      </a>
                    </td>
                  </tr>
                  <tr class="notes">
                    <td colspan="5" (click)="updateNotes(document)" tooltipPosition="bottom" appendTo="body" [class.has-notes]="document.notes">
                      <div [class.bg-red]="document.notesColour === 'red'" [class.bg-green]="document.notesColour === 'green'" [class.bg-orange]="document.notesColour === 'orange'">
                        @if (document.notes) {
                          <span> {{ document.notes }} </span>
                        } @else {
                          <span class="text-right"><button class="btn btn-sm btn-outline">add notes</button></span>
                        }
                      </div>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          }
        </div>
      }
    </section>
  }
  <section>
    @if (showExploreDocument) {
      <app-dialog width="1000" (onClose)="showExploreDocument = false" [header]="exploreDocument?.buildingTitle" [canScroll]="true">
        @if (building && !building.license?.hasExpired) {
          <div class="building-documents">
            <table class="table" aria-label="Documents">
              <thead>
                <tr>
                  <th>RAG</th>
                  <th>Document</th>
                  <th class="isDesktop">Date</th>
                  <th>View</th>
                  <th class="isDesktop">Notes</th>
                </tr>
              </thead>
              <tbody>
                @for (document of building.documents; track document.key) {
                  <tr>
                    <td><i class="fa fa-fw fa-circle rag-{{ document.rag }}"></i></td>
                    <td>{{ document.title }}</td>
                    <td class="isDesktop">{{ document.calendarStartAt | amDateFormat: 'Do MMMM YYYY' }}</td>
                    <td class="actions">
                      <a target="_blank" (click)="hasViewed(document)" href="https://s3.eu-west-2.amazonaws.com/4d-documents/{{ document.key }}">
                        @switch (document.contentType) {
                          @case ('application/pdf') {
                            <span pTooltip="View PDF document" tooltipPosition="bottom" appendTo="body">
                              <i class="mdi mdi-file-document-outline"></i>
                            </span>
                          }
                          @case ('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                            <span pTooltip="View Excel spreadsheet" tooltipPosition="bottom" appendTo="body">
                              <i class="mdi mdi-file-excel-box-outline"></i>
                            </span>
                          }
                          @default {
                            <span tooltip="{{ document.contentType }}">
                              <i class="mdi mdi-file-document-outline"></i>
                            </span>
                          }
                        }
                      </a>
                    </td>
                    <td class="notes isDesktop">
                      <i (click)="updateNotes(document)" tooltipPosition="bottom" pTooltip="{{ document.notes ? document.notes.substring(0, 20) + '...' : 'No notes' }}" class="fa fa-fw fa-sticky-note" [class.note-colour-red]="document.notesColour === 'red'" [class.note-colour-green]="document.notesColour === 'green'" [class.note-colour-orange]="document.notesColour === 'orange'" [class.has-notes]="document.notes"></i>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        } @else {
          @if (building?.license) {
            <app-info-panel>
              <div>Documents not available for this building</div>
              @if (isAdmin()) {
                <p>
                  <b><i class="mdi mdi-shield-account-outline"></i> Note for Admins</b>: The building may not be licensed, or other license issue.
                </p>
              }
            </app-info-panel>
          } @else {
            <i class="mdi mdi-loading mdi-spin-x2"></i>
          }
        }
      </app-dialog>
    }
  </section>
  <div class="drop-target">
    <div>
      @if (isUploading) {
        <span><i class="fa fa-fw fa-refresh fa-spin"></i> Uploading....</span>
      } @else {
        <span><i class="fa fa-fw fa-cloud-upload"></i> UPLOAD PDF</span>
      }
    </div>
  </div>
  @if (hasDropped) {
    <div class="__modal0"></div>
    <!-- UPLOAD DOCUMENT MODAL -->

    <div class="__modal">
      <header>
        Upload <span>{{ uploadFile.name }}</span>
      </header>
      <div>
        <app-theme-page-section [label]="" [rag]="uploadRAG" [canChangeRag]="true">
          <div>
            <div [class.red]="!uploadTitle">
              <div>
                <label>Title</label>
              </div>
              <input [(ngModel)]="uploadTitle" class="form-control" />
            </div>
            <div class="selects" [class.red]="!uploadBuildingId">
              <div class="text-left">
                <div>
                  <label>Building</label>
                </div>
                <app-select placeholder="Select a building..." [list]="buildingList" [value]="uploadBuildingId" (onSelected)="uploadBuildingChanged($event)"> </app-select>
              </div>
            </div>
            <div [class.red]="!uploadDate">
              <div>
                <label>Calendar</label>
              </div>
              <p-calendar [(ngModel)]="uploadDate" [inline]="true" [showWeek]="true"></p-calendar>
            </div>
            <div>
              <div class="colours">
                @for (colour of ['red', 'amber', 'green']; track colour) {
                  <div class="note-colour-{{ colour }}">
                    <i (click)="uploadRAG = colour" class="fa fa-fw" [class.fa-circle-o]="uploadRAG !== colour" [class.fa-circle]="uploadRAG === colour"></i>
                  </div>
                }
              </div>
            </div>
          </div>
        </app-theme-page-section>
      </div>
      <footer>
        <p-button [disabled]="!uploadDate || !uploadBuildingId || !uploadDocument || !uploadTitle" label="Upload document" icon="mdi mdi-cloud-upload-outline" styleClass="p-button-sm" (click)="uploadDocument()" pTooltip="{{ !uploadDate }}  {{ !uploadBuilding }}  {{ !uploadDocument }}"> </p-button>
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-sm p-button-outlined p-button-secondary ml-1" (click)="this.hasDropped = false"> </p-button>
      </footer>
    </div>
  }
</article>

@if (showUpdate) {
  <app-dialog [canScroll]="false" [zIndex]="12" [header]="'Notes for ' + updateDocument?.title" (onClose)="showUpdate = false" [dimensions]="{ width: 650, height: 270 }">
    @if (updateDocument) {
      <div>
        <div>
          <textarea pInputTextarea [rows]="9" [(ngModel)]="updateDocument.notes"></textarea>
        </div>
        <div class="colours">
          <div class="note-colour-red" (click)="updateDocument.notesColour = 'red'" pTooltip="Set note colour red" tooltipPosition="bottom" appendTo="body">
            <i class="fa fa-fw" [class.fa-circle-o]="updateDocument.notesColour !== 'red'" [class.fa-circle]="updateDocument.notesColour === 'red'"></i>
          </div>
          <div class="note-colour-orange" (click)="updateDocument.notesColour = 'orange'" pTooltip="Set note colour orange" tooltipPosition="bottom" appendTo="body">
            <i class="fa fa-fw" [class.fa-circle-o]="updateDocument.notesColour !== 'orange'" [class.fa-circle]="updateDocument.notesColour === 'orange'"></i>
          </div>
          <div class="note-colour-green" (click)="updateDocument.notesColour = 'green'">
            <i pTooltip="Set note colour green" tooltipPosition="bottom" appendTo="body" class="fa fa-fw" [class.fa-circle-o]="updateDocument.notesColour !== 'green'" [class.fa-circle]="updateDocument.notesColour === 'green'"></i>
          </div>
          <div class="note-colour-" pTooltip="Set note colour black" appendTo="body" tooltipPosition="bottom" (click)="updateDocument.notesColour = null">
            <i class="fa fa-fw" [class.fa-circle-o]="updateDocument.notesColour" [class.fa-circle]="!updateDocument.notesColour"></i>
          </div>
          @if (updateDocument.notesAt) {
            <div pTooltip="Last updated {{ updateDocument.notesAt | date: 'dd-MM-YY HH:mm' }}" appendTo="body" class="updatedat">
              @if (updateDocument.notesAt) {
                <span> Last updated {{ updateDocument.notesAt | amTimeAgo }}</span>
              }
            </div>
          }
        </div>
        <footer>
          <app-toolbar [hasBackground]="false">
            <div class="right">
              <app-button label="Update" icon="mdi mdi-check" (click)="saveNotes()" />
            </div>
          </app-toolbar>
        </footer>
      </div>
    }
  </app-dialog>
}

@if (isLoading()) {
  <app-please-wait></app-please-wait>
}
