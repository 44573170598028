import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { Building } from 'app/classes/building';
import { CollectionAsset } from 'app/classes/collection-for-assets';
import { Gateway } from 'app/classes/gateway';
import { Insight } from 'app/classes/insight';
import { OpeningHours } from 'app/classes/opening-hours';
import { Org } from 'app/classes/org';
import { DialogOpeningHoursComponent } from 'app/dialogs/dialog-opening-hours/dialog-opening-hours.component';
import { APIService } from 'app/shared/api.service';
import { BuildingsService } from 'app/shared/buildings.service';
import { CombinedCollectionItem, SiteService } from 'app/shared/site.service';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-insights-airquality-config',
    templateUrl: './insights-airquality-config.component.html',
    styleUrls: ['./insights-airquality-config.component.css'],
    providers: [DialogService],
    standalone: false
})
export class InsightsAirqualityConfigComponent implements OnInit, OnDestroy {

  readonly LOCALSTORAGE = 'iaq:config';

  previousBuildings = signal<IBuildingQuickPick[]>(null);

  isGettingBuilding = signal<boolean>(false);
  buildings: Building[];
  building = signal<Building>(null);
  dialogOpen = signal<'buildings'>(null);

  buildingForReport: Building;
  gateways: Gateway[];

  gatewaysSelected: Gateway[];

  dialogRef: DynamicDialogRef;
  month: any;

  subs: Subscription[] = [];

  runReport: boolean;
  reportWasGenerated: boolean;
  reportWasGeneratedAt: Date;
  // Re-run report with latest data if true
  clearCache: boolean;

  generateItems = [
    {
      label: 'Clear cache & run', icon: 'pi pi-refresh', command: () => {
        this.generate(true);
      }
    }];

  org: Org;

  today = new Date();

  useOrgLogo: boolean;

  gatewaysNotAvailable = signal<Gateway[]>(null);

  collections = signal<CollectionAsset[]>(null)


  gateway = signal<Gateway>(null);

  combinedCollections = signal<CombinedCollectionItem[]>(null);

  constructor(public messageService: MessageService, private apiService: APIService, private buildingService: BuildingsService, public dialogService: DialogService, public siteService: SiteService) {
    this.getLocalStorage();
  }

  ngOnInit(): void {
    const buildingSub = this.buildingService.buildingList.subscribe(list => {
      if (!list) {
        return;
      }
      this.buildings = list;
    });

    this.subs.push(buildingSub);
    this.org = this.apiService.getUserOrg();
  }

  clickCollection(collection: CollectionAsset) {
    console.log(collection);
  }

  useAssetsForGateway(gateway: Gateway, assets: Asset[]) {
    this.gateway.set(null);
    assets.forEach(asset => gateway.assets.add(asset));

    this.gateways = [gateway];
  }

  buildingChange(building: Building) {
    this.dialogOpen.set(null);

    if (!building) {
      return;
    }

    this.building.set(building);
    this.addToPreviousBuildings(building);
    this.runReport = false;
    this.reportWasGenerated = false;
    this.buildingForReport = null;

    this.getBuilding(this.building().id);
  }


  addToPreviousBuildings(building: Building) {
    this.previousBuildings.set([{ id: building.id, title: building.title }]);
    this.saveLocalStorage();
  }

  getLocalStorage() {
    try {
      const storageString = localStorage.getItem(this.LOCALSTORAGE);
      if (storageString) {
        const storageObject = JSON.parse(storageString);
        this.previousBuildings.set(storageObject.previous);
      }
    } catch (e) { }
  }

  saveLocalStorage() {
    const payload = JSON.stringify({ previous: this.previousBuildings() });
    localStorage.setItem(this.LOCALSTORAGE, payload);
  }

  getBuilding(id: number) {
    this.isGettingBuilding.set(true);
    this.buildingService.getOne(id)
      .then(building => {
        this.buildingForReport = building;

        this.siteService.getCollections(building.site.id).then(collections => this.combinedCollections.set(collections.filter(c => c.type.id === CombinedCollectionItem.TYPE_AQ)));

        this.buildingService.getAQGatewaysAndCollectionsWithAssets(building.id)
          .then(response => {

            const { gateways, collections } = response;

            this.collections.set(collections);
            if (gateways.length) {

              this.gateways = gateways.map(g => {
                g.selected = true;
                return g;
              });

              this.isGettingBuilding.set(false);
            } else {
              /*
              this.buildingService.getGateways(building.id)
                .then(gateways => {
                  this.gatewaysNotAvailable.set(gateways);
                  this.isGettingBuilding.set(false);
                });
                */
            }

          });
      });
  }

  clickGatewaysNotAvailable(gateway: Gateway) {
    this.gateway.set(gateway);
  }

  getGateways() {

  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  toolbarClick(event: any) {
    const building = this.buildingForReport;
    this.dialogRef = this.dialogService.open(DialogOpeningHoursComponent, {
      header: `Hours for ${building.title}`,
      data: { openingHours: building.openingHours }
    });
    this.dialogRef.onClose.subscribe((openingHours: OpeningHours) => {
      if (openingHours) {
        this.buildingForReport.openingHours = openingHours;
        this.buildingService.updateHours(this.buildingForReport).then(b => {
          this.messageService.add({ severity: 'info', summary: 'hours updated', detail: building.title });
          this.getBuilding(building.id);
        });
      }
    });
  }

  generate(reGenerateDate = false) {
    this.clearCache = reGenerateDate;
    this.gatewaysSelected = this.gateways?.filter(g => g.selected) || [];

    const collectionsSelected = this.collections().filter(c => c.selected);

    if (this.gatewaysSelected.length === 0 && collectionsSelected.length === 0) {
      this.messageService.add({ detail: 'Please select one or more gateways', severity: 'info' });

      return;
    }
    if (!this.month) {
      this.messageService.add({ detail: 'Please select a month', severity: 'info' });

      return;
    }

    if (this.runReport && !this.reportWasGenerated) {
      console.log('REPORT_GENERATING_PLEASE_WAIT');

      return;
    }

    if (this.runReport) {
      this.runReport = false;
      setTimeout(() => {
        this.runReport = true;
        this.reportWasGenerated = false;
      }, 10);
    } else {
      this.reportWasGenerated = false;
      this.runReport = true;
    }
  }

  onReportWasGenerated(insight: Insight) {
    if (insight === null) {
      this.reportWasGenerated = false;
      this.runReport = false;
      this.reportWasGeneratedAt = null;
    } else {
      this.reportWasGenerated = true;
      this.reportWasGeneratedAt = insight.generatedAt;
    }
  }

  printPage() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      window.print();
    }, 100);
  }
}

export interface IBuildingQuickPick {
  id: number;
  title: string;
}
