import { Component, input, model, OnInit, output, signal } from '@angular/core';
import { Building } from 'app/classes/building';
import { OpeningHours } from 'app/classes/opening-hours';
import { Site } from 'app/classes/site';
import { APIService } from 'app/shared/api.service';
import { BuildingsService } from 'app/shared/buildings.service';

@Component({
    selector: 'app-site-hours',
    templateUrl: './site-hours.component.html',
    styleUrl: './site-hours.component.css',
    standalone: false
})
export class SiteHoursComponent implements OnInit {
  // Required if no buildingId
  site = input<Site>();
  // Required if no Site
  buildingId = input<number>(null);

  building = signal<Building>(null);
  openingHours = signal<OpeningHours>(null);

  view = model<'edit' | 'display'>('edit');

  onSave = output<OpeningHours>();

  viewMode = 'edit';
  readonly OpeningHours = OpeningHours;

  constructor(private buildingService: BuildingsService, private apiService: APIService) {

  }
  ngOnInit(): void {

    if (this.buildingId()) {
      this.buildingService.getOne(this.buildingId()).then(building => {
        this.building.set(building);
        this.openingHours.set(building.openingHours ?? new OpeningHours());
      });
    } else {
      this.buildingService.getBuildingForSite(this.site().id).then(building => {
        if (!building) {
          throw new Error('no building.');
        }
        this.building.set(building);
        this.openingHours.set(building.openingHours ?? new OpeningHours());
      });
    }
  }

  set24Hours(index: number) {
    this.openingHours().hours[index].from = "00:00";
    this.openingHours().hours[index].to = "23:59";
  }

  clickDay(index: number) {
    this.openingHours().hours[index].isClosed = !this.openingHours().hours[index].isClosed;
  }

  save() {
    this.apiService.toastSuccess('updating hours...', '', 1000);
    this.buildingService.updateHoursForId(this.building().id, this.openingHours()).then(b => {
      this.apiService.toastSuccess('updated hours.', '', 2000);
      this.onSave.emit(this.openingHours());
    });
  }

  duplicate() {
    const getFirstEntry = this.openingHours().hours.find(day => day.to.length > 0);
    this.openingHours().hours.filter(o => !o.isClosed).forEach(o => {
      o.from = getFirstEntry.from;
      o.to = getFirstEntry.to;
    })
  }
}
