import { ElementRef, EventEmitter, ViewChild, signal, Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Alarm } from 'app/classes/alarm';
import { AlarmTimeline } from 'app/classes/alarm-timeline';
import { RulePackage } from 'app/classes/rule-service/rule-package';
import { AlarmService } from 'app/shared/alarm.service';
import { APIService } from 'app/shared/api.service';

@Component({
    selector: 'app-rulesv3-item-alarm-control',
    templateUrl: './rulesv3-item-alarm-control.component.html',
    styleUrls: ['./rulesv3-item-alarm-control.component.css'],
    standalone: false
})
export class Rulesv3ItemAlarmControlComponent implements OnInit, OnDestroy {

  isWorking = signal(false);

  @Output()
  onNoteAdded: EventEmitter<string> = new EventEmitter();

  @Output()
  onStateChanged: EventEmitter<string> = new EventEmitter();

  _rule: RulePackage;
  @Input()
  public get rule(): RulePackage {
    return this._rule;
  }
  public set rule(v: RulePackage) {
    this._rule = v;
    if (v) {
      this.newState = this.alarmService.getNewState(this.rule.alarm);
      this.notes = '';
      this.alarm = v.alarm;
      this.isReady = true;
      setTimeout(() => {
        if (this.timelineRef) {
          this.timelineRef.nativeElement.scrollTop = this.timelineRef.nativeElement.scrollHeight;
        }
      }, 100);
    }
  }

  @ViewChild('timeline')
  timelineRef: ElementRef;

  alarm: Alarm;
  notes: string;
  newState: AlarmTimeline;
  dirty: boolean;
  isReady: boolean;

  constructor(private alarmService: AlarmService, private apiService: APIService) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    /* if (this.notes !== this.newState.notes) {
       this.notes = this.notes.trim();
       this.alarmService.saveNotes(this.newState, this.notes);
     }*/
  }

  saveNotes() {
    if (this.notes !== this.newState.notes) {
      this.isReady = false;
      this.notes = this.notes.trim();
      this.alarmService.saveNotes(this.newState, this.notes).then(r => {
        this.onNoteAdded.emit(this.notes);
        this.isReady = true;
      })
    }
  }

  changeState(state, paused = 0) {
    this.isReady = false;
    this.isWorking.set(true);
    this.apiService.alarmAction(this.alarm, state, this.notes, paused)
      .then(result => {
        console.log(result);
        this.onStateChanged.emit(state);
        this.isReady = true;
        this.isWorking.set(false);
      });
  }
}
