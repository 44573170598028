import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SiteFloorplanShape } from 'app/classes/site-floorplan-shape';
import { APIService } from 'app/shared/api.service';

@Component({
    selector: 'app-shape-manage-occupancy',
    templateUrl: './shape-manage-occupancy.component.html',
    styleUrls: ['./shape-manage-occupancy.component.css'],
    standalone: false
})
export class ShapeManageOccupancyComponent implements OnInit {

  @Input()
  shape: SiteFloorplanShape;

  @Output()
  onSubmit: EventEmitter<any> = new EventEmitter();

  form: {
    occFullUtilisationText?: string,
    occFullUtilisationMins?: number,
    title: string,
    occDays: number[]
  };

  daysOccupied: number;

  constructor(private apiService: APIService) { }

  ngOnInit(): void {
    this.apiService.postQuery({ t: 'shapes', action: 'settings', id: this.shape.id })
      .then(r => {
        const [data] = r;

        if (!data) {
          this.form = {
            occFullUtilisationMins: null,
            occDays: [0, 0, 0, 0, 0, 0, 0], title: null
          };
        } else {
          this.form = {
            title: data.title,
            occFullUtilisationMins: data.occFullUtilisationMins,
            occDays: JSON.parse(data.occDays)
          };
          this.form.occFullUtilisationText = this.minsToHours(this.form.occFullUtilisationMins);
        }

        this.formChanged();
      });
  }

  setDay(day: number) {
    this.form.occDays[day] = this.form.occDays[day] === 0 ? 1 : 0;
    this.formChanged();
  }

  formChanged() {
    this.form.occFullUtilisationMins = this.textHoursToMins(this.form.occFullUtilisationText);
    this.daysOccupied = this.form.occDays.reduce((a, b) => a + b);

  }

  minsToHours(mins) {
    let hours: any = mins / 60;
    if (hours * 60 < mins || hours < 1) {
      let hoursInt = parseInt(String(hours));
      if (hours > 1) {
        hours = hoursInt + ' hours, ' + parseInt(String(hours)) + (mins - (hoursInt * 60)) + ' minutes.';
      } else {
        hours = mins + ' minutes';
      }
    }

    return hours;
  }

  textHoursToMins(text) {
    const parse = text;
    try {
      if (isNaN(parse)) {
        const e = parse.split(' ');
        let hours = +e[0];
        if (hours > 23) hours = 23;
        let mins;
        if (e.length < 3) {
          mins = 0;
        } else {
          if (isNaN(e[2])) {
            mins = +e[3];
          } else {
            mins = +e[2];
          }
        }

        if (mins > 59) mins = 59;
        return ((hours * 60) + mins);
      } else {
        return (+parse) * 60;
      }
    } catch (e) { return 0 }
  }

  submit() {
    // let occFullUtilisationMins = this.textHoursToMins(this.form.occFullUtilisationMins);

    const payload: any = { ...this.form };

    this.apiService.postQuery({ t: 'shapes', action: 'settings', changes: { shape_id: this.shape.id, ...payload } })
      .then(r => {
        this.apiService.toastSuccess('Updated', '');
        this.onSubmit.emit(this.form);
      });
  }
}
