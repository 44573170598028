<article>
  <main>
    <section *ngFor="let collection of collections">
      <h3>{{ collection.t }}</h3>
      <table>
        <tbody>
          <tr *ngFor="let day of collection.days">
            <td>{{ day.startTime.substr(0, 5) }} to {{ day.endTime.substr(0, 5) }}</td>
            @for (dayIndex of day.arrayOfDays; track dayIndex; let i = $index) {
              <td>
                <span [ngClass]="{ 'is-enabled': dayIndex }">
                  <i
                    class="mdi"
                    [ngClass]="{
                      'mdi-checkbox-marked-outline': dayIndex,
                      'mdi-checkbox-blank-outline': !dayIndex
                    }"></i>
                  {{ dayNames[i] }}
                </span>
              </td>
            }
          </tr>
        </tbody>
      </table>
    </section>
  </main>
</article>
