import { Component, Input, OnInit, signal } from '@angular/core';
import { StoreService } from 'app/shared/store.service';

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.css'],
  standalone: false
})
export class InfoPanelComponent implements OnInit {

  // Pre configured styles
  @Input()
  type: 'default' | 'note' | 'admin';

  @Input()
  state: 'default' | 'error' | 'warning' | 'success' = 'default';

  @Input()
  iconColour: string;

  @Input()
  iconPosition: 'text-left' | 'top-left' = 'text-left';

  @Input()
  iconRotate: string = '0deg';

  @Input()
  iconTooltip: string;

  @Input()

  public set icon(v: string) {
    this._iconName = v;
    switch (v) {
      case 'admin':
        this._icon.set('mdi-shield-account-outline');
        this.iconColour = 'red';
        this.iconPosition = 'top-left';
        this.iconTooltip = 'Admin Staff only';
        break;
      case 'check':
        this._icon.set('mdi-check-circle-outline');
        this.iconColour = 'green';
        break;
      case 'info':
        this._icon.set('mdi-alert-circle-outline');
        break;
      case 'bulb':
        this._icon.set('mdi-lightbulb-on');
        this.iconPosition = 'top-left';

        this.iconRotate = '320deg';
        break;
      default:
        this._icon.set(v);
        break;
    }
  }

  _icon = signal<string>(null);
  _iconName: string;
  theme: any;

  constructor(private storeService: StoreService) { }

  ngOnInit(): void {
    this.theme = this.storeService.getThemeColours();
    switch (this.type) {
      case 'note':
        this.icon = 'bulb';
        break;
    }
  }

}
