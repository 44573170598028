import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { Router } from '@angular/router';

@Component({
    selector: 'app-site-plan-asset',
    templateUrl: './site-plan-asset.component.html',
    styleUrls: ['./site-plan-asset.component.css'],
    standalone: false
})
export class SitePlanAssetComponent implements OnInit {

  @Input()
  asset: Asset;

  @Input()
  fullscreen: boolean;

  @Output()
  onBack: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log(event.key);

    if (event.key === "Escape") {
      // User pressed escape on the modal
      this.onBack.emit();
    }
  }

  constructor(private router: Router) { }

  ngOnInit() {
  }

  navigateBack() {
    this.onBack.emit();
  }
}
