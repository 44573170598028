import { Component, Input, OnInit } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { RulePackage } from 'app/classes/rule-service/rule-package';
import { APIService } from 'app/shared/api.service';
import { RuleService } from 'app/shared/rule.service';

@Component({
    selector: 'app-rulesv3-item-conditions',
    templateUrl: './rulesv3-item-conditions.component.html',
    styleUrls: ['./rulesv3-item-conditions.component.css'],
    standalone: false
})
export class Rulesv3ItemConditionsComponent implements OnInit {

  @Input()
  rule: RulePackage;

  assets: { left: Asset, right: Asset } = { left: null, right: null };

  rightValue: string;
  operand: string;

  liveAsset: Asset;

  constructor(private ruleService: RuleService, private apiService: APIService) { }

  ngOnInit(): void {
    const condition = this.rule.conditions[0];
    if (condition.leftAsset.asset.id) {
      this.assets.left = condition.leftAsset.asset;
      this.assets.left.value = condition.leftAsset.computedValue;
    } else {
      this.assets.left = null;
    }

    if (condition.rightAsset.asset.id) {
      this.assets.right = condition.rightAsset.asset;
      this.assets.right.value = condition.rightAsset.computedValue;
    } else {
      this.assets.right = null;
    }

    this.rightValue = condition.rightAsset.value;
    this.operand = this.ruleService.operatorAsWords(condition.operator);
    this.getAsset();
  }

  getAsset() {
    const id = this.assets?.left?.id;
    if (id) {
      this.apiService.getAsset(id).then(a => this.liveAsset = a);
    }
  }

}
