import { Component, Input } from '@angular/core';
import { ICollection, IGetHourlyForAssetFromRangeAsset, IgetHourlyForAssetFromRangeResponse } from 'app/shared/occupancy.service';

@Component({
  selector: 'app-setpoint-query-occ-config',
  templateUrl: './setpoint-query-occ-config.component.html',
  styleUrls: ['./setpoint-query-occ-config.component.css'],
  standalone: false
})
export class SetpointQueryOccConfigComponent {
  dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  asset: IGetHourlyForAssetFromRangeAsset;
  collections: any[] = [];

  @Input()
  public set stats(stats: IgetHourlyForAssetFromRangeResponse) {
    // There will only be one asset id
    this.asset = stats.assets[Object.keys(stats.assets)[0]]
    // Get the collection(s)
    this.collections = stats.collections.filter(c => stats.occupationCollectionAssets.some(a => a.collection_id === c.i));

    this.collections.forEach(collection => collection.days.forEach(day => day.arrayOfDays = new Array(7)));
    // Set the collection(s) days of hours c.i )
    console.log(this.collections);
  }

}
