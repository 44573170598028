import ascending from "./ascending";
import number from "./number";
import quantile from "./quantile";
export default function (values, valueof) {
  var n = values.length,
    i = -1,
    value,
    numbers = [];
  if (valueof == null) {
    while (++i < n) {
      if (!isNaN(value = number(values[i]))) {
        numbers.push(value);
      }
    }
  } else {
    while (++i < n) {
      if (!isNaN(value = number(valueof(values[i], i, values)))) {
        numbers.push(value);
      }
    }
  }
  return quantile(numbers.sort(ascending), 0.5);
}