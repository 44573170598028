import { Component, OnInit, Input, NgZone } from '@angular/core';
import { Org } from 'app/classes/org';
import { ManageService } from 'app/shared/manage.service';

@Component({
    selector: 'app-manage-create-org-help',
    templateUrl: './manage-create-org-help.component.html',
    styleUrls: ['./manage-create-org-help.component.css'],
    standalone: false
})
export class ManageCreateOrgHelpComponent implements OnInit {

  @Input()

  public set element(v: string) {
    this._element = v;
    console.log('SET ', v);
  }

  public get element(): string {
    return this._element;
  }

  _element: string;

  @Input()
  org: Org;

  constructor(private manageService: ManageService, private zone: NgZone) { }

  ngOnInit() {
    this.manageService.fieldFocus.subscribe(value => {
      this.zone.run(() => {
        this.element = value;
      });
    })
  }

}
