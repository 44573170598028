import { Component, OnInit, Input } from '@angular/core';
import { WebsocketIn } from 'app/classes/websocket-in';
import { DashboardService } from 'app/shared/dashboard.service';

@Component({
    selector: 'app-dashboard-charts',
    templateUrl: './dashboard-charts.component.html',
    styleUrls: ['./dashboard-charts.component.css'],
    standalone: false
})
export class DashboardChartsComponent implements OnInit {
  filter: string;

  recentSubscription: any;
  _rows: WebsocketIn[];
  @Input()
  public set rows(v: WebsocketIn[]) {
    this._rows = v;
    console.log(`got ${v.length} rows`);
    this.buildAssets();
  }

  assets: IChartAsset[] = [];

  constructor(public dashboardService: DashboardService) { }

  ngOnInit() {
    this.recentSubscription = this.dashboardService.recent.subscribe(telemetry => {
      this.addTelemetry(telemetry);
    })
  }

  addTelemetry(telemetry: WebsocketIn) {
    // List of assets in the telemetry packet
    for (let index2 = 0; index2 < telemetry.assets.length; index2++) {
      const assetIn = telemetry.assets[index2];
      if (+assetIn.value !== +assetIn.value) {
        // Asset must be a value for the graph
        continue;
      }
      const asset = this.assets.find(element => element.i === assetIn.id);
      const payload = { v: +assetIn.value, t: +telemetry.receivedAt };

      if (asset) {
        // Make a copy of the asset, remove the original then insert it at the top for display
        const updateAsset = { ...asset };
        this.assets = this.assets.filter(element => element.i !== assetIn.id);
        updateAsset.d.datasets[0].data = [...updateAsset.d.datasets[0].data, { x: telemetry.receivedAt, y: +assetIn.value }];
        updateAsset.v.push(payload);
        this.assets.unshift(updateAsset);
      } else {
        const newAsset = {
          i: assetIn.id,
          ty: assetIn.ty,
          t: assetIn.t,
          v: [payload],
          d: {
            options: {
              scales: {
                xAxes: [{
                  type: 'time',
                  time: {
                    unit: 'minute'
                  }
                }]
              }
            },
            datasets: [{ label: assetIn.t, data: [{ x: telemetry.receivedAt, y: +assetIn.value }] }]
          }
        };
        this.assets.unshift(newAsset);
      }
    }
  }

  buildAssets() {
    for (let index = 0; index < this._rows.length; index++) {
      const websocketIn = this._rows[index];
      this.addTelemetry(websocketIn);
    }
  }
}


export interface IChartAsset {
  i: number; // Asset id
  ty: number; // Type of asset
  t: string; // title of asset
  v: IChartAssetValue[]; // Value series
  d: any;
}

export interface IChartAssetValue {
  v: number; // Value
  t: number; // Timestamp
}
