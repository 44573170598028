<article>
  <div class="date-picker">
    <div class="content">
      <div>
        <section>
          <h4>From</h4>
          <p-calendar [(ngModel)]="dateFrom" [inline]="true" [showWeek]="true" [minDate]="dates.min || null" [maxDate]="dates.max" [firstDayOfWeek]="1"> </p-calendar>
        </section>
      </div>
      <div>
        <section>
          <h4>To</h4>
          <p-calendar [(ngModel)]="dateTo" [inline]="true" [showWeek]="true" [minDate]="dates.min || null" [maxDate]="dates.max" [firstDayOfWeek]="1"> </p-calendar>
        </section>
      </div>
    </div>
    <div class="actions">
      <div>
        <ng-template [ngIf]="asset">
          <!--<button pButton type="button" (click)="assetStart()" icon="pi pi-arrow-left" class="p-button-outlined"
						label="Asset start"></button>
					<button pButton type="button" (click)="assetEnd()" icon="pi pi-arrow-right" class="p-button-outlined"
						label="Asset end"></button>-->
        </ng-template>
      </div>
      <div>
        <button pButton type="button" (click)="cancel()" icon="pi pi-times" class="p-button-outlined" label="Cancel"></button>
        <button pButton type="button" (click)="submit()" icon="pi pi-check" label="Export date range"></button>
      </div>
    </div>
  </div>
</article>
