import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Address } from "../../classes/address";
import { APIService } from "../../shared/api.service";
import { CalendarService } from 'app/shared/calendar.service';
@Component({
    selector: 'app-address-entry',
    templateUrl: './address-entry.component.html',
    styleUrls: ['./address-entry.component.css'],
    standalone: false
})
export class AddressEntryComponent implements OnInit {

  @Input()
  address: Address = new Address();

  @Input()
  titleLabel: string;

  @Output()
  submit: EventEmitter<Address> = new EventEmitter<Address>();

  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  required = true;

  @Input()
  canSave = true;

  @Output()
  gotFocus: EventEmitter<string> = new EventEmitter<string>();

  constructor(private apiService: APIService) {
  }

  ngOnInit() {

  }

  submitAddress() {
    this.submit.emit(this.address);
  }

  cancelAddress() {
    this.cancel.emit();
  }

  /**
  * Check postcode from postcode api, fill in missing elements of the address
  */
  checkPostcode() {
    console.log(`Got postcode ${this.address.addressPostcode}`);
    this.apiService.getPostcodeData(this.address.addressPostcode).then(data => {
      console.log(data);
      if (this.address.addressLat === '') {
        this.address.addressLat = data.lat;
      }
      if (this.address.addressLong === '') {
        this.address.addressLong = data.long;
      }
      if (this.address.addressCounty === '') {
        this.address.addressCounty = data.county;
      }
      if (this.address.addressTown === '') {
        this.address.addressTown = data.town;
      }
    });
  }

  focus(element: string) {
    console.log('got ', element);
    this.gotFocus.emit(element);
  }

}
