import { Component, OnInit, OnDestroy } from '@angular/core';
import { APIService } from '../../shared/api.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { CameraService } from 'app/shared/camera.service';
import { Camera } from 'app/classes/asset';
import { AssetPreset } from 'app/classes/asset-preset';
import { AssetService } from 'app/shared/asset.service';

@Component({
    selector: 'app-camera-control',
    templateUrl: './camera-control.component.html',
    styleUrls: ['./camera-control.component.css'],
    standalone: false
})
export class CameraControlComponent implements OnInit, OnDestroy {
  sessionId: string;
  hasImage: boolean;
  isGettingImage: boolean;
  assetId: number;
  gatewayId: string;
  imageCheckCounter = 0;
  imageLastModified: string;
  camera: Camera;

  key: string;

  constructor(private router: Router, private route: ActivatedRoute, private apiService: APIService, private cameraService: CameraService) {
    this.route.params.subscribe((params: Params) => {
      this.cameraService
        .getCamera(params.assetid)
        .then(camera => {
          this.camera = camera;
        });
    });

  }

  startSession() {

    this.apiService.startAssetSession(this.camera.id)
      .then(results => {
        console.log(results);
        this.sessionId = results.session;
        this.gatewayId = results.gatewayId;
        this.getImage();
      })
      .catch(e => console.log(e))

  }

  ngOnInit() {
    // Start a session with the server for control of the camera.
    // A check against the user role is carried out server side.
  }

  getImage() {
    this.isGettingImage = true;


    this.imageCheckCounter = 0;
    this.apiService
      .assetSessionAction(this.camera.id, this.sessionId, 'camera:image:upload', {})
      .then(results => {
        if (results.key) {
          this.key = results.key;
        }
        this.checkImage();
      });

  }

  panToString(p1: string) {
    throw new Error("NOT_IMPLEMENTED");
  }

  panTo(preset: AssetPreset) {

    if (this.camera.assetType_id === 43) {
      // A fixed camera, no pan - just take pickture
      return this.startSession();
    }

    this.apiService.startAssetSession(this.camera.id)
      .then(results => {
        console.log(results);
        this.sessionId = results.session;
        this.gatewayId = results.gatewayId;

        this.isGettingImage = true;
        this.imageCheckCounter = 0;
        this.apiService
          .assetSessionAction(this.camera.id, this.sessionId, 'camera:pan:preset', { preset: `${preset.presetId}` })
          .then(() => {
            // Allow 15 seconds to pan and focus, take picture, upload
            setTimeout(() => {
              this.checkImage();
            },
              8000);
          });
      });
  }

  takePicture() {
    this.startSession();
  }

  checkImage() {

    console.log();
    if (this.imageCheckCounter++ > 50) {
      return;
    }
    const url = this.key ?
      `https://s3.eu-west-2.amazonaws.com/4d-assets/${this.key}` :
      `https://s3.eu-west-2.amazonaws.com/4d-public/sessions/${this.camera.id}/images/_session_${this.sessionId}.jpg`;

    console.log('does', url, 'exist?');

    const http = new XMLHttpRequest();
    const self = this;

    try {
      http.open('HEAD', url);
      http.send();

      if (http.status === 404) {
        setTimeout(() => {
          this.checkImage();
        }, 500);
      }

      http.onreadystatechange = function () {
        console.log('readstate', http.readyState)
        if (http.readyState === 4) {

          const lastModified = http.getResponseHeader('last-modified');
          console.log(lastModified, http.getAllResponseHeaders());
          if (lastModified !== null && lastModified !== self.imageLastModified) {

            self.imageLastModified = lastModified;
            self.isGettingImage = false;
            self.hasImage = false;
            setTimeout(() => {
              self.hasImage = true;
              self.camera.last_image_url = url;
            }, 500);
          } else {
            setTimeout(() => {
              self.checkImage();
            }, 500);
          }
        }
      }
    } catch (e) {
      console.log('http crashed with ', e);
      setTimeout(() => {
        this.checkImage();
      }, 500);
    }

  }

  ngOnDestroy() {
    this.imageCheckCounter = 999;
  }
}
