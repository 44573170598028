import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-export-container',
    templateUrl: './export-container.component.html',
    styleUrls: ['./export-container.component.css'],
    standalone: false
})
export class ExportContainerComponent implements OnInit {
  
  constructor() { }

  ngOnInit() {
  }

}
