import { Component, OnInit, Input } from '@angular/core';
import { Gateway } from '../../classes/gateway';

@Component({
    selector: 'app-gateway-indicator',
    templateUrl: './gateway-indicator.component.html',
    styleUrls: ['./gateway-indicator.component.css'],
    standalone: false
})
export class GatewayIndicatorComponent implements OnInit {
  
  @Input()
  gateway: Gateway;

  constructor() { }

  ngOnInit() {
  }

}
