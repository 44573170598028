import { Component, OnInit, Input } from '@angular/core';
import { Site } from '../../classes/site';
import { Inventory } from "../../classes/inventory";
import { APIService } from '../../shared/api.service';

@Component({
    selector: 'app-order-collection-item',
    templateUrl: './order-collection-item.component.html',
    styleUrls: ['./order-collection-item.component.css'],
    standalone: false
})
export class OrderCollectionItemComponent implements OnInit {

  @Input()
  item: any;

  @Input()
  expanded: boolean;

  constructor(private apiService: APIService) { }

  ngOnInit() {

  }

  clickCollectionQty(value) {
    this.item.qty += value;
    if (this.item.qty < 0) {
      // Don't let qty fall below 0
      this.item.qty = 0;
    }
  }

  incdec(p1, p2) {
    throw new Error("NOT_HANDLED");

  }
}
