/**
 * Export dialog.
 * Pass user:User to open up admin columns
 * 
 * @author tonymporter@gmail.com
 */
import { Component, Input, OnInit } from '@angular/core';
import { User } from 'app/classes/user';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-dialog-export-sites',
    templateUrl: './dialog-export-sites.component.html',
    styleUrls: ['./dialog-export-sites.component.css'],
    providers: [DialogService],
    standalone: false
})
export class DialogExportSitesComponent implements OnInit {

  user: User;

  columnsAvailable = {
    "id": { "title": "Asset ID", checked: true },
    "title": { "title": "Asset Title", "alias": "display", checked: true },
    "assetTypeTitle": { "title": "Asset Type", checked: true },
    "value": { "title": "Asset Value", checked: true },
    "location": { "title": "Asset Location" },
    "createdAt": { "title": "Asset Created Date", "type": "date" },
    "gateway_id": { "title": "Gateway ID", "type": "string", checked: true },
    "identifier": { "title": "Identifier", "role": "admin" },
    "status": { "title": "Asset Status", "role": "admin" },
    "updatedAt": { "title": "Asset Value Updated Date", "role": "user", checked: true },
    "inError": { "title": "Asset in error", "role": "user" },
    "notes": { "title": "Asset notes", "role": "admin" },
    "parentAsset_id": { "title": "Asset parent asset id", "role": "admin" },
    "purposeTitle": { "title": "Asset purpose", "role": "admin" },
    "alias": { "title": "Asset alias", "role": "admin" },
    "correction": { "title": "Asset correction", "role": "admin" },
    "battery": { "title": "Asset battery %", "role": "admin" },
    "setpointCount": { "title": "Asset Setpoint count", "role": "admin" },
    "gatewayTitle": { "title": "Gateway Title", "role": "user", checked: true },
    "siteTitle": { "title": "Site Title", "role": "user", checked: true },
    "siteId": { "title": "Site Title", "role": "admin", checked: false },
    "assetTypeCategory": { "title": "Asset Type Category", "role": "admin" },
    "isSetpointable": { "title": "Asset can be a setpoint", "role": "admin" },
    "orgId": { "title": "Org id", "role": "admin" },
    "rag": { "title": "Asset RAG", "role": "user", checked: true }
  };

  columns: IColumn[];

  constructor(public dialogService: DialogService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {

  }

  ngOnInit(): void {
    if (this.config.data) {
      this.user = this.config.data.user || null;
    }

    this.columns = Object.keys(this.columnsAvailable).map(key => {
      const column: IColumn = { ...this.columnsAvailable[key], key };

      return column;
    }).filter(column => column.role !== 'admin' || this.user?.role === 'admin')
      .sort((a, b) => a.title > b.title ? 1 : -1);

    const storedString = localStorage.getItem('export:sites:columns');
    if (storedString) {
      this.columns.forEach(column => column.checked = storedString.indexOf(column.key) > 0);
    }
  }

  changed() {

  }

  cancel() {
    this.ref.close();
  }

  submit() {
    const selected: string[] = this.columns.filter(column => column.checked).map(column => column.key);
    if (selected.length === 0) {
      return this.cancel;
    }

    localStorage.setItem('export:sites:columns', `:${selected.join(':')}:`);
    this.ref.close(selected);
  }

}


export interface IColumn {
  key: string;
  title: string;
  role?: string;
  alias?: string;
  checked: boolean;
}
