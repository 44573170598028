import { Component, OnInit, Input } from '@angular/core';
import { SiteFloorplanShape } from 'app/classes/site-floorplan-shape';

@Component({
    selector: 'app-shape-manage',
    templateUrl: './shape-manage.component.html',
    styleUrls: ['./shape-manage.component.css'],
    standalone: false
})
export class ShapeManageComponent implements OnInit {

  @Input()
  shape: SiteFloorplanShape;

  constructor() { }

  ngOnInit(): void {
  }

  occupancySubmitted() {

  }
}
