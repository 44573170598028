import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'amTimeAgo',
    standalone: false
})
export class AmTimeAgoPipe implements PipeTransform {

  transform(value: any, removeSuffix: boolean = false): string {
    return moment(value).fromNow(removeSuffix);
  }
}
