import { Component, signal } from '@angular/core';

@Component({
    selector: 'app-changelog-landing',
    templateUrl: './changelog-landing.component.html',
    styleUrl: './changelog-landing.component.css',
    standalone: false
})
export class ChangelogLandingComponent {

  version = signal('3.2');
}
