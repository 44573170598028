import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Alert } from '../classes/alert';
import { User } from '../classes/user';
import { Rule } from '../classes/rule';
import { Alarm } from '../classes/alarm';
import { Asset, Camera } from '../classes/asset';
import { AssetPreset } from '../classes/asset-preset';
import { Site } from '../classes/site';
import { Profile } from '../classes/profile';
import { Org } from '../classes/org';
import { Inventory } from '../classes/inventory';
import { Gateway } from '../classes/gateway';
import { Order } from '../classes/order';
import { AlertAction } from '../classes/alert-action';
import { SelectedAsset } from '../classes/rule-service/selected-asset';
import { RuleCondition } from '../classes/rule-service/rule-condition';
import { RuleNotification } from '../classes/rule-service/rule-notification';
import { RuleTimeRange } from '../classes/rule-service/rule-hours';
import { RuleAlarm } from '../classes/rule-service/rule-alarm';
import { RulePackage } from '../classes/rule-service/rule-package';
import { Report } from '../classes/reports/report';
import { ReportBlock } from '../classes/reports/report-block';
import { ReportConfig } from '../classes/reports/report-config';
import { TrainingModule } from '../classes/training-module';
import { environment } from 'environments/environment';
import { Ticket } from '../classes/ticket';
import { Collection } from '../classes/collection';
import { Exporter } from '../classes/export';
import { ProfilingHistoryItem } from '../classes/profiling-history-item';
import { SitePlan } from '../classes/site-plan';
import { Review, SiteReview } from '../classes/reviews/review';
import { CMSItem } from '../classes/cms-item';
import { CmsItemBlock } from '../classes/cms-item-block';
import { RuleAction } from '../classes/rule-service/rule-action';
import { MessageService } from 'primeng/api';
import { createAsset, AssetKind } from '../classes/factory';
import { GatewayIS } from 'app/classes/gateway-is';
import { Api } from 'app/classes/api';
import { AQItem } from './aq.service';
import { SessionService } from "./session.service";
import { Session } from "app/classes/session";
import { S3SiteRag } from "../classes/s3-chart/s3-chart";
import moment from 'moment';
import { AlarmTimeline } from 'app/classes/alarm-timeline';
import { ShapeCategories } from "../classes/shape-categories";
import { ComplianceCollection } from "../classes/compliance/compliance-collection";
import { ComplianceItem } from 'app/classes/compliance/compliance-item';
import { Grouping } from 'app/classes/grouping';

declare const window: any;
declare const opr: any;
declare const safari: any;
declare const document: any;
declare const InstallTrigger: any;

@Injectable({ providedIn: 'root' })
export class APIService {
  public static readonly VERSION = '3.4.14';
  public static SUBDOMAIN = 'public'; // Ensure public is default in case of hostname issues
  public static TLD = null;

  private _user: BehaviorSubject<User> = new BehaviorSubject(null);
  private _loggedIn: BehaviorSubject<User> = new BehaviorSubject(null);

  private _alerts: BehaviorSubject<Alert[]> = new BehaviorSubject([]);
  private _rules: BehaviorSubject<Rule[]> = new BehaviorSubject([]);
  private _assets: BehaviorSubject<Asset[]> = new BehaviorSubject([]);
  private _sites: BehaviorSubject<Site[]> = new BehaviorSubject([]);
  private _asset: BehaviorSubject<Asset> = new BehaviorSubject(null);
  private _rulePackage: BehaviorSubject<RulePackage> = new BehaviorSubject(null);
  private _gateway: BehaviorSubject<Gateway> = new BehaviorSubject(null);
  private _report: BehaviorSubject<Report> = new BehaviorSubject(null);
  private _authStateChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  public alerts: Observable<Alert[]> = this._alerts.asObservable();
  public loggedIn: Observable<User> = this._loggedIn.asObservable();
  public user: Observable<User> = this._user.asObservable();

  public rules: Observable<Rule[]> = this._rules.asObservable();
  public assets: Observable<Asset[]> = this._assets.asObservable();
  public sites: Observable<Site[]> = this._sites.asObservable();
  public asset: Observable<Asset> = this._asset.asObservable();
  public gateway: Observable<Gateway> = this._gateway.asObservable();
  public rulePackage: Observable<RulePackage> = this._rulePackage.asObservable();
  public report: Observable<Report> = this._report.asObservable();
  public authStateChanged: Observable<any> = this._authStateChanged.asObservable();

  /*
  private _growl: BehaviorSubject<any> = new BehaviorSubject(null);
  public growl: Observable<any> = this._growl.asObservable();
  */
  private _fullscreen: BehaviorSubject<any> = new BehaviorSubject(null);
  public fullscreen: Observable<any> = this._fullscreen.asObservable();

  // Cached collections of objects
  public reportCollection: any = new Map();
  // Cached collection of list views
  public reportListCollection: any = [];

  public moduleAccess: any = {};

  private authenticated = false;
  public apiUrl: string;
  private appDomain: string;
  // The subdomain (portal | public)
  public subdomain: string;
  public appVersion: string;

  public MODULES = {
    rules: 1,
    footfall: 3,
    profiling: 2,
    live: 4,
    reports: 5,
    setpoints: 6,
    account: 7,
    organisation: 8,
    training: 10,
    dashboard: 11,
    engagement: 16,
    reviews: 18,
    camera: 19,
    integration: 20,
    api: 21,
    partnership_engagement: 24,
    billing: 26,
    compliance: 27,
    insights: 28,
    insight_footfall: 30,
    energy: 36,
    telemetry: 37
  };

  public MODULE_TOOLTIPS = {
    rules: 'Telemetry rules for setpoints and notifications',
    profiling: 'Floorplans',
    live: 'Telemetry charts',
    livefeed: 'Telemetry charts',
    reviews: 'Building reviews',
    reports: 'Reporting',
    setpoints: 'Manage setpoints and alarms',
    account: 'Your user details (logout from here)',
    org: 'Organisation management',
    organisation: 'Organisation details and management',
    training: 'Training videos on using 4D',
    dashboard: 'The landing page when logging in',
    footfall: 'Live footfall',
    billing: 'Billing details and management',
    compliance: 'Compliance details and management',
    api: 'Ingest 4D telemetry into your API',
    realtime_map: 'Realtime map',
    camera: 'camera upload in organsiation',
    engagement: 'Your users usage of 4D',
    insight_footfall: 'Footfall reports',
    integration: 'Third party integrations',
    occupancy: 'Module not avvailable',
    partnership_engagement: 'Partners engagement with 4D',
    telemetry: 'Sites telemetry',
    energy: 'Energy reporting'
  };

  public ASSET_TYPES_BY_TITLE = {
    ROOM_TEMPERATURE: 2,
    WIFI: 12,
    FOOTFALL: 15,
    IMAGE: 18,
    MOTION: 22,
    MOISTURE: 23,
    DISTANCE: 24,
    OUTSIDE_TEMPERATURE: 34,
    TODAYS_SUM: 58,
    IP: 31
  };

  public ASSET_TYPES_BY_ID = {
    2: 'ROOM_TEMPERATURE',
    12: 'WIFI',
    15: 'FOOTFALL',
    18: 'IMAGE',
    22: 'MOTION',
    23: 'MOISTURE',
    24: 'DISTANCE',
    34: 'OUTSIDE_TEMPERATURE',
    58: 'TODAYS_SUM'
  };
  public GATEWAY_TYPES_BY_TITLE = { FOOTFALL: 4, PROFILING: 5 };
  public RANGES_BY_TITLE = { ALLDAY: 1, OPERATIONAL_HOURS: 2, OUT_OF_HOURS: 3 };

  // API calls inflight, if a key exists we are waiting for the API to to return,
  // a lookup here with a matching table.id aborts the API call and awaits the observable result.
  private inflight: string[] = [];

  public lastNotificationRead = null;
  public lastNotification: any = [];

  constructor(private http: HttpClient, private messageService: MessageService, public meta: Meta, public title: Title, private sessionService: SessionService) {
    this.meta.updateTag({ name: 'version', content: APIService.VERSION });
    this.meta.addTag({ name: 'og:type', content: 'website' });

    this.setDomain(window.location.hostname);
    console.log('subdomain ', APIService.SUBDOMAIN);

    this.apiUrl = environment.api.url;
    this.appDomain = window.location.hostname;

    if (environment.production) {

    } else {


      console.log(`%c
 ██████████                               ████             
░░███░░░░███                             ░░███               
 ░███   ░░███  ██████  █████ █████ ██████ ░███   ██████  ████████  
 ░███    ░███ ███░░███░░███ ░░███ ███░░███░███  ███░░███░░███░░███
 ░███    ░███░███████  ░███  ░███░███████ ░███ ░███ ░███ ░███ ░███ 
 ░███    ███ ░███░░░   ░░███ ███ ░███░░░  ░███ ░███ ░███ ░███ ░███ 
 ██████████  ░░██████   ░░█████  ░░██████ █████░░██████  ░███████  
░░░░░░░░░░    ░░░░░░     ░░░░░    ░░░░░░ ░░░░░  ░░░░░░   ░███░░░  
                                                         ░███
                                                         █████
                                                        ░░░░░
      `, 'color:blue');

      console.log(environment.api);
    }

    if (APIService.SUBDOMAIN === 'public') {
      // Only allow public endpoints if the domain is not trusted.
      this.apiUrl += 'public/';

      return;
    }

    // Get last authentication from localstorage
    try {
      let session = JSON.parse(localStorage.getItem('session'));
      if (!session.timestamp) {
        // No timestamp, remove session
        session = null;
        console.log('NO_ACTIVE_SESSION');
      } else {
        // Check timestamp
        const now = new Date().getTime();
        const timestamp = new Date(session.timestamp).getTime();
        const hours = Math.abs(now - timestamp) / 36e5;
        console.log('Session token has been alive for ' + Number(hours).toFixed(2) + ' hours.');
        if (hours >= 23) {
          // Session token has timed out when 23 hours or more has passed.
          session = null;
        }
      }
      const userFromStorage = JSON.parse(localStorage.getItem('user'));
      // Allow previous version
      //const modules = JSON.parse(localStorage.getItem('modules'));
      //if (modules && !userFromStorage.modules) {
      //  userFromStorage.modules = modules.split(',').toLowerCase().trim();
      //}

      const user = new User(userFromStorage);
      this.moduleAccess = user.modulesAsObject();


      if (session) {
        sessionService.setSession(new Session(session));
        this._user.next(user);
      }
    } catch (e) {

    }
  }

  clearUser() {
    this._user.next(new User());
    this.sessionService.setSession(null);
    this._loggedIn.next(null);
    localStorage.removeItem('modules');
  }

  getThisUser() {
    return this._user.value;
  }

  setFullscreen(isFullscreen: boolean) {
    this._fullscreen.next(isFullscreen);
  }

  isAuthorised(): boolean {
    // If we don't have a token - we've never logged in
    if (!this.authenticated) {
      try {
        // Get the user from local storage
        console.error('NOT AUTHENTICATED');

      } catch (e) {
        // Failed to parse the local storage, failed authorisation
        return false;
      }
    }

    return (this.authenticated);
  }

  getUAOHeaders(timeout?: number): { headers: HttpHeaders } {
    const token = this.getAuthToken();
    if (!token) {
      console.log('no token');
      return null;
    }

    const headers: any = {
      'Content-Type': 'application/json',
      'authorization': token
    };

    if (timeout) {
      headers.timeout = timeout;
    }

    return {
      headers: new HttpHeaders(headers)
    };
  }


  getAuthToken(): string {
    const session = this.sessionService.getSessionValue();

    let authorizationToken: string;
    if (APIService.SUBDOMAIN !== 'public' && session) {
      authorizationToken = btoa((session ? session.identityId : '') + '|' + (session ? session.token : ''));
    };

    return authorizationToken;
  }

  getHttpHeaders(): HttpHeaders {
    const token = this.getAuthToken();
    if (token) {
      return new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('authorizationToken', this.getAuthToken());
    } else {
      return new HttpHeaders()
        .set('Content-Type', 'application/json');
    }
  }

  getContentTypeHttpHeader(): HttpHeaders {
    return new HttpHeaders()
      .set('Content-Type', 'application/json');
  }

  /**
   * Get the gateway and notify observers
   */
  getGateway(id: string): Promise<Gateway | GatewayIS> {
    if (!id) {
      return new Promise((resolve, reject) => reject(null));
    }
    const cacheKey = 'gateway.' + id;
    console.log('getGateway(' + id + ')', this.inflight[cacheKey]);

    const options = { headers: this.getHttpHeaders() };

    return this.http.get<any[]>(this.apiUrl + 'gateways/' + id, options)
      .toPromise()
      .then(response => {
        this.inflight[cacheKey] = null;
        const body = response[0][0];
        if (!body) {
          return null;
        }
        let gateway;
        if (body.isEmbedLink) {
          gateway = new GatewayIS(body);
        } else {
          gateway = new Gateway(body);
        }
        this._gateway.next(gateway);

        return gateway;
      })
      .catch(this.handleError);
  }

  /**
   * Get gateways
   * integrationType can be "is" for initial state integrated gateways
   */
  getGateways(type?: number, sort?: any, integrationType?: string, qs?: string): Promise<Gateway[]> {
    let query = type ? '?type=' + type : '';
    if (sort) {
      sort = encodeURIComponent(JSON.stringify(sort));
      if (query) {
        query += `&sort=${sort}`;
      } else {
        query = `?sort=${sort}`;
      }
    }
    if (integrationType) {
      if (query) {
        query += `&integration=${integrationType}`;
      } else {
        query = `?integration=${integrationType}`;
      }
    }

    if (qs) {
      query += query ? `&${qs}` : `?${qs}`;
    }

    return this.http
      .get<any[]>(this.getUserAPI() + '/gateways' + query, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => {
        switch (integrationType) {
          case 'is':
            return response.map(gateway => new GatewayIS(gateway));
          default:
            return response.map(gateway => new Gateway(gateway));
        }
      })
      .catch(this.handleError);
  }

  getGatewaysForDataset(dataset: string) {
    const options = { headers: this.getHttpHeaders() };
    const query = `?dataset=${dataset}`;

    return this.http.get<any[]>(this.apiUrl + 'gateways' + query, options)
      .toPromise()
      .then(response => {
        const results = response;
        let gateways: Gateway[] = [];

        switch (dataset) {
          case 'issues':
            gateways = results[0].map(gateway => new Gateway(gateway));
            // Attach assets to gateway
            results[1].forEach(asset => {
              gateways.forEach(gateway => {
                if (gateway.id === asset.gateway_id) {
                  gateway.assets.add(asset);
                }
              });
            });
            break;
          default:
            gateways = results.map(gateway => new Gateway(gateway));
            break;
        }

        return gateways;
      })
      .catch(this.handleError);
  }

  /**
   * Get gateways for site
   */
  getGatewaysForSiteId(siteId: number): Promise<Gateway[]> {
    const options = { headers: this.getHttpHeaders() };

    return this.http.get<any[]>(this.apiUrl + 'sites/' + siteId + '/gateways', options)
      .toPromise()
      .then(response => response.map(gateway => new Gateway(gateway)))
      .catch(this.handleError);
  }

  /**
   * Get assets for gateway
   */
  getAssetsForGatewayId(gatewayId: string, query = ''): Promise<Asset[]> {

    const options = { headers: this.getHttpHeaders() };

    if (query) {
      query = '?' + query;
    }

    return this.http.get<any[]>(this.apiUrl + 'gateways/' + gatewayId + '/assets' + query, options)
      .toPromise()
      .then((response) => {

        return response
          .map(asset => new Asset(asset));
      })
      .catch(this.handleError);
  }

  /**
   * Get sites
   */
  getSites(querystring?: any): Promise<Site[]> {
    return this.http
      .get<any[]>(this.getUserAPI() + '/sites' + (querystring ? '?' + querystring : ''), { headers: this.getHttpHeaders() })
      .toPromise()
      .then((response) => {
        let sites = [];
        let rags = [];
        /* if (querystring === 'rags=1' 1) {
           rags = response[1].reverse();
           response = response[0];
         }*/

        response.filter(s => s.isActive).forEach(element => {
          const site = new Site(element);
          if (rags.length) {
            rags.forEach(rag => {
              if (rag.site_id === site.id) {
                site.rags.push(rag);
              }
            });
          }
          sites.push(site);
        });

        return sites;
      })
      .catch(this.handleError);
  }

  /**
   * Get inventory
   */
  getInventory(): Promise<Inventory[]> {
    return this.http.get<any[]>(this.apiUrl + 'inventory?dataset=order', { headers: this.getHttpHeaders() })
      .toPromise()
      .then((response) => {
        const items = response as Inventory[];

        items.map(item => {
          return Object.assign(item, { order: { qty: 0 } });
        });

        return items;
      })
      .catch(this.handleError);
  }

  /**
 * Get inventory collections
 */
  getInventoryCollections(): Promise<any[]> {
    return this.http.get<any[]>(this.apiUrl + 'inventory/collections?dataset=order', { headers: this.getHttpHeaders() })
      .toPromise()
      .then((response) => {

        let items = response;
        let collections = items[0].map(v => Object.assign(v, { qty: 0, selected: false }));
        let inventorys = items[1];


        inventorys.map(item => {
          let col = collections.filter(v => {
            return v.id === item.survey_id;
          });
          if (col.length) {
            if (!col[0].inventorys) {
              col[0].inventorys = [];
            }
            col[0].inventorys.push(item);
          }
          return item;
        });

        return collections;
      })
      .catch(this.handleError);
  }

  /**
   * Get all users for org where they are role 'user'
   */
  getAQForOrg(params = {}): Promise<any[]> {
    return this.http.get<any[]>(this.getUserAPI() + `/aq`, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => {
        console.log('got', response);

        return response.map(item => new AQItem(item));
      });
  }

  /**
   * Get all users for org where they are role 'user' or 'contact'
   */
  getUsersForOrg(querystring = ''): Promise<User[]> {
    if (querystring) {
      querystring = '?' + querystring;
    }
    return this.http.get<any[]>(this.getUserAPI() + `/users${querystring}`, { headers: this.getHttpHeaders() })
      .toPromise()
      .then((response) => {
        const users = [...response.reduce((u, c) => {
          if (!u.has(c.id)) {
            u.set(c.id, c);
          }
          return u;
        }, new Map()).values()];

        const r = users.map(user => new User(user));

        return r;
      })
      .catch(this.handleError);
  }

  /**
   * Invite user into the current org
   */
  inviteUserToOrg(invite: any): Promise<any> {
    let options = { headers: this.getHttpHeaders() };

    return this.http.post(this.getUserAPI() + '/users/invite', { invite: invite }, options)
      .toPromise()
      .then((response) => {

        return response;
      })
      .catch(this.handleError);
  }

  /**
   * Create contact
   */
  postContactForSite(siteId: number, name: string, email: string, notes: string, settings: any, id?: number): Promise<any> {
    let options = { headers: this.getHttpHeaders() };

    return this.http.post(this.getUserAPI() + `/sites/${siteId}/usergroups`, { id, name, email, notes, settings }, options)
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  /**
   * Submit a new site
   */
  submitSite(title: string, address: string, notes: string): Promise<any> {
    let options = { headers: this.getHttpHeaders() };

    return this.http.post(this.getUserAPI() + '/sites/submit', { title, address, notes }, options)
      .toPromise()
      .then((response) => {

        return response;
      })
      .catch(this.handleError);
  }

  /**
   * Activate user into the invited org
   */
  activateUser(token: string, password): Promise<ActivatedUserInterface> {
    // Don't send password as readable plain text
    const pwd = btoa(password);

    const options = { headers: this.getContentTypeHttpHeader() };

    let activatedResponse: ActivatedUserInterface;

    return this.http.post(this.apiUrl + 'invite/' + token, { password: pwd }, options)
      .toPromise()
      .then(response => {

        const resp: any = response;

        if (typeof resp === 'object') {
          activatedResponse = {
            errorMessage: resp.errorMessage,
            email: resp.email,
            token: resp.token
          };
        } else {
          activatedResponse = {
            errorMessage: resp,
            email: null,
            token: null
          };
        }

        return activatedResponse;
      })
      .catch(e => {
        console.log(e);

        activatedResponse = {
          errorMessage: e.message,
          email: null,
          token: null
        };
        return activatedResponse;
      });
  }

  getTokenStatus(token: string): Promise<any> {
    return this.http.get<any[]>(this.apiUrl + 'invite/' + token)
      .toPromise()
      .then(response => {

        return response;
      })
      .catch(e => {
        console.log(e);
      });
  }

  postActivateRoute(token): Promise<any> {
    const url = `invite/${token}`;
    const options = { headers: this.getContentTypeHttpHeader() };
    const browser = this.browserCheck();
    return this.http.post(`${this.apiUrl}${url}/config`, { url: `/${url}/${browser}`, browser: browser }, options)
      .toPromise()
      .then(response => {

        const data: any = response;
        const message = data && data.errorMessage ? data.errorMessage : '';

        return {
          text: data?.statusText ? data.statusText.toLowerCase() : 'ok',
          message: message
        };
      })
      .catch(e => {
        console.log(e);
      });
  }

  /**
   * Get status of device after certificate assigned
   *
   * @param token
   * @param password
   */
  getDeviceStatus(token: string): Promise<any> {
    let options = { headers: this.getHttpHeaders() };

    return this.http.get<any[]>(this.apiUrl + 'device/' + token, options)
      .toPromise()
      .then((response) => {

        return response;
      })
      .catch(this.handleError);
  }

  getAlerts(): Promise<Alert[]> {
    console.log('getAlerts()');
    const options = { headers: this.getHttpHeaders() };
    return this.http.get<any[]>(this.apiUrl + 'alerts', options)
      .toPromise()
      .then((response) => {
        console.log('APIService: got the alerts');
        this._alerts.next(response[0] as Alert[]);
        return response[0] as Alert[];
      })
      .catch(this.handleError);
  }

  getAlarms(state): Promise<Alarm[]> {
    console.log('getAlarms()');
    const options = { headers: this.getHttpHeaders() };
    return this.http.get<any[]>(this.getUserAPI() + '/alarms?state=' + state + '&offset=0&rows=10', options)
      .toPromise()
      .then((response) => {

        return response as Alarm[];
      })
      .catch(this.handleError);
  }

  getDashboard(): Promise<any[]> {
    console.log('getDashboard()');
    let options = { headers: this.getHttpHeaders() };
    return this.http.get<any[]>(this.getUserAPI() + '/dashboards', options)
      .toPromise()
      .then((response) => {
        console.log('APIService: got the dashboard');

        return response;
      })
      .catch(this.handleError);
  }

  /**
   * Get the API prefix for the user "HOST_NAME/ORG_PATH"
   *
   * @returns string
   */
  getUserAPI() {

    return this.apiUrl + 'orgs/' + (this._user.value ? this._user.value.org.id : 0);
  }

  /**
   * Get assets for footfall-dashboard.component
   * @returns Asset[]
   */
  getTelemetryAssets(): Promise<Asset[]> {
    console.log('getTelemetryAssets()');
    const options = { headers: this.getHttpHeaders() };
    return this.http.get<any[]>(this.getUserAPI() + '/assets?type=15&sort=%7B%22gateway_id%22:1%2C%22satellite_id%22:1%7D', options)
      .toPromise()
      .then(response => response

        .map(asset => new Asset(asset)))
      .catch(this.handleError);
  }

  /**
   * Get all of the assets the user has access to
   * @param type The asset type
   */
  getAssetsForUser(type?: number, sortBy?: string, dataset?: string): Promise<Asset[]> {
    console.log('getAssetsForUser()');
    const options = { headers: this.getHttpHeaders() };
    let query = 'sort=%7B';
    if (!sortBy) {
      query += '%22gateway_id%22:1%2C%22satellite_id%22:1';
    } else {
      query += sortBy;
    }
    query += '%7D';

    if (type) {
      query += `&type=${type}`;
    }

    if (dataset) {
      query += `&dataset=${dataset}`;
    }
    return this.http.get<any[]>(`${this.apiUrl}orgs/${this._user.value.org.id}/assets?${query}`, options)
      .toPromise()
      .then((response) => {
        const body = response;
        const assets = body.map(asset => new Asset(asset));
        this._assets.next(assets);

        return assets;
      })
      .catch(this.handleError);
  }

  /**
   * Get assets with setpoints of rangeType provided (1 = operational rules)
   * @param {number} rangeType
   */
  getAssetsWithSetpointsForUser(rangeType: number = 1): Promise<Asset[]> {
    console.log('getAssetsWithSetpointsForUser()');
    const options = { headers: this.getHttpHeaders() };
    return this.http.get<any[]>(this.apiUrl + 'orgs/' + this._user.value.org.id + '/assets?sort=%7B%22gateway_id%22:1%2C%22satellite_id%22:1%7D&setpoints=', options)
      .toPromise()
      .then((response) => {
        const body = response;
        const assets = body.map(asset => new Asset(asset));
        this._assets.next(assets);

        return assets;
      })
      .catch(this.handleError);
  }

  /**
   * Get the setpoint asset ids that fall within the time range
   * - used to filter out a list of setpoint assets
   * - 24 hour setpoints are ignored as all assets are included on 24hr
   * @param {string} timeFrom
   * @param {string} timeTo
   */
  getAssetsWithSetpointsForTime(rangeId: number, timeFrom: string, timeTo: string): Promise<any[]> {
    console.log('getAssetsWithSetpointsForTime()');
    let columns = encodeURIComponent('ar.id');
    let filter = encodeURIComponent(``);
    const options = { headers: this.getHttpHeaders() };

    /*
        let data = {
          filter: [{ c: 'startsAt', v: timeFrom, o: '>=' }, { c: 'endsAt', v: timeTo, o: '<=' }],
          columns: ''
        };
    */

    let data = { startsAt: timeFrom, endsAt: timeTo };

    return this.http.post(this.getUserAPI() + '/assets/setpoints/range/' + rangeId, data, options)
      .toPromise()
      .then((response) => {

        return response;
      })
      .catch(this.handleError);
  }

  getSetpointsForAssetAndRange(asset: Asset, rangeId: number): Promise<any> {
    // console.log('getSetpointsForAsset()');
    const options = { headers: this.getHttpHeaders() };
    const orgId = asset.gateway.site.org.id;

    return this.http.get<any[]>(this.apiUrl + `gateways/${asset.gateway_id}/assets/${asset.id}/setpoints?v=2&rules=1&master=1&groupings=1&contacts=1&range=${rangeId}&orgid=${orgId}`, options)
      .toPromise()
      .then((response) => {
        let [setpointRows, rules, master, contacts, groupings] = response;
        console.log('PRE RULES', rules);
        // Strip out ooh tag, the rangeid is used instead
        for (let index = 0; index < rules.length; index++) {
          rules[index].tag = rules[index].tag.replace(' ooh', '');
        }
        console.log('POST RULES', rules);

        let setpoints = [];
        for (let idx = 0; idx < 7; idx++) {
          setpoints.push({ weekday: idx, startsAt: null, endsAt: null, allday: false, isActive: false, min: null, max: null, amber_min: null, amber_max: null, red_min: null, red_max: null });
        }
        if (setpointRows && setpointRows.length) {
          setpointRows.forEach((sp) => setpoints[sp.weekday] = sp);
        } else {
          console.log('ERROR ', setpointRows);
        }

        if (master.length === 0) {
          master.push({ notes: '' });
        }

        return { setpoints, rules, master: master[0], contacts, groupings };
      })
      .catch(this.handleError);
  }

  setSetPointsForAsset(asset: Asset, rangeId: number, setpoints: any[], alarms: PostAlarmInterface, master: any, groupings: any): Promise<any> {
    const options = { headers: this.getHttpHeaders() };
    const url = this.getUserAPI() + '/assets/' + asset.id + '/setpoints/ranges/' + rangeId;
    const payload = { setpoints, alarms, master, groupings };
    if (!master || !groupings) {
      console.error('NO_MASTER_OR_GROUPINGS', master, groupings);
      return;
    }
    return this.http.post(url, payload, options)
      .toPromise()
      .then(response => {

        return response;
      })
      .catch(this.handleError);
  }

  /**
   * Get setpoint values by day for the number of days - starting with today.
   * @param asset
   * @param rangeId
   * @param days
   */
  getSetPointsHistoryForAsset(asset: Asset, rangeId: number, days: number): Promise<any> {
    return this.http.get<any[]>(this.getUserAPI() + `/assets/${asset.id}/setpoints/ranges/${rangeId}/history?days=${days}`, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  /**
   * Get setpoint values by day for the number of days - starting with today.
   * @param asset
   * @param rangeId
   * @param days
   */
  getSetPointsHistoryForUserOrg(rangeId: number, days: number): Promise<any> {
    return this.http.get<any[]>(this.getUserAPI() + `/assets/setpoints/range/${rangeId}/history?days=${days}`, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  /**
   * Get the date format for the users organisation
   */
  getDateFormat() {

    return this._user.value.org.dateFormat || 'DD/MM/YYYY';
  }

  /**
   * Get the date format + 24 hor time for the users organisation
   */
  getDateTimeFormat() {

    return this._user.value.org.dateFormat + ` HH:mm`;
  }

  /**
   * Return ACTIVE orgs
   * -- pass access=1 for orgs the user has access to.
   */
  async getOrgs(queryString?: string): Promise<Org[]> {
    return new Promise(async (resolve, reject) => {
      let url = this.apiUrl + 'orgs';
      if (queryString) {
        url += `?${queryString}`;
      }
      return this.http
        .get<any[]>(url, { headers: this.getHttpHeaders() })
        .toPromise()
        .then((response) => {

          resolve(response.map(org => new Org(org)).filter(org => org.isActive || queryString?.includes('inactive=1')));
        })
        .catch(e => reject(e));
    });
  }

  getRules(): Promise<Rule[]> {
    const options = { headers: this.getHttpHeaders() };
    return this.http.get<any[]>(this.apiUrl + 'rules', options)
      .toPromise()
      .then((response) => {
        this._rules.next(response[0] as Rule[]);
        return response[0] as Rule[];
      })
      .catch(this.handleError);
  }

  getRule(ruleId: number): Promise<Rule> {
    for (let idx = 0; idx < this._rules.value.length; idx++) {
      if (this._rules.value[idx].id === ruleId) {
        console.log('Rule from local cache');

        return new Promise((resolve, reject) => resolve(this._rules.value[idx]));
      }
    }
    const options = { headers: this.getHttpHeaders() };

    return this.http.get<any[]>(this.apiUrl + 'rules/' + ruleId, options)
      .toPromise()
      .then((response) => {
        console.log(this._alerts);
        const rule = response[0] as Rule;
        this._rules.value.push(rule);

        for (let idx = 0; idx < this._alerts.value.length; idx++) {
          const alert = this._alerts.value[idx];
          if (!alert.rule && alert.rule_id === rule.id) {
            alert.rule = rule;
            console.log('alert ' + alert.id + ' updated with rule');
          }
        }

        return rule;
      })
      .catch(this.handleError);
  }

  getCamera(assetId): Promise<Camera> {
    return new Promise((resolve, reject) => {
      const options = { headers: this.getHttpHeaders() };

      this.http.get<any[]>(this.getUserAPI() + `/assets/${assetId}?presets=1`, options)
        .toPromise()
        .then(response => {

          const body = response;
          const asset = body[0][0];
          const presets = body[1];

          resolve(
            <Camera>createAsset(AssetKind.Camera,
              {
                asset,
                presets
              }
            ));
        })
        .catch(this.handleError);
    });
  }

  getAssetAPI(assetId: number): Promise<AssetAPICollection> {
    return this.http
      .get<any[]>(this.apiUrl + 'assets/' + assetId + '?apis=1', { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => {
        let apis = response[2];
        if (apis) {
          apis = apis.map(api => new Api(api));
        }
        return {
          asset: new Asset(response[0][0]),
          apis,
          actions: response[1]
        };
      })
      .catch(this.handleError);
  }

  getAsset(assetId: number | string, options: { cache?: boolean } = { cache: true }): Promise<Asset> {

    if (options.cache) {
      for (let idx = 0; idx < this._assets.value.length; idx++) {
        if (this._assets.value[idx].id === assetId) {
          if (this._assets.value[idx] instanceof Asset) {
            // console.log('Asset class object from local cache');
          }
          console.log(`from stack`, this._assets.value[idx]);
          return new Promise((resolve, reject) => resolve(this._assets.value[idx]));
        }
      }
    }

    return this._getAsset(assetId);
  }

  getFloorplanForAsset(assetId: number): Promise<any> {
    const options = { headers: this.getHttpHeaders() };

    return this.http.get<any[]>(this.getUserAPI() + `/assets/${assetId}/floorplans`, options)
      .toPromise()
      .then((response) => {
        return response[0];
      })
      .catch(this.handleError);
  }

  /**
   * Get the telemetry for a gateway, used for footfall devices
   *
   * @param gatewayId Gateway id
   */
  getFootfallTelemetry(gatewayId: string): Promise<any> {

    return this.http.get<any[]>(this.getUserAPI() + '/gateways/' + gatewayId + '/telemetry?v=2', { headers: this.getHttpHeaders() })
      .toPromise()
      .then((response) => {

        return response;
      })
      .catch(this.handleError);
  }

  /**
   * Get the telemetry for the users organisation
   *
   * @param gatewayId Gateway id
   */
  getOrgTelemetry(filter: any): Promise<IGetOrgTelemetryResponse[]> {
    let query;
    if (filter) {
      filter = encodeURIComponent(JSON.stringify(filter));
      query = `?filter=${filter}`;
    }

    return this.http.get<IGetOrgTelemetryResponse[]>(this.getUserAPI() + `/telemetry${query}`, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => {
        response = response.reverse().map(value => {
          return {
            identifier: value.identifier,
            formattedValue: Asset.formattedValue(value.value, value.assetType, value.purposeId, value.updatedAt, value.identifier),
            assetId: value.assetId,
            assetType: value.assetType,
            purposeId: value.purposeId,
            gatewayId: value.gatewayId,
            gatewayOnline: value.gatewayOnline,
            gatewayTitle: value.gatewayTitle,
            max: this.numberFix(value.max),
            min: this.numberFix(value.min),
            rag: value.rag,
            siteId: value.siteId,
            siteTitle: value.siteTitle,
            setting: value.setting,
            title: value.title,
            updatedAt: value.updatedAt,
            value: this.numberFix(value.value)
          };
        });

        return response;
      })
      .catch(this.handleError);
  }

  numberFix(value) {
    if (+value === +value && ((+value % 1) != 0)) {
      let places = (+value).toString().split(".")[1].length;
      return (+value).toFixed(1);
    }

    return value;
  }

  getTelemetry(assetId: number, limit: number = 10, querystring?: any): Promise<any> {
    const options = { headers: this.getHttpHeaders() };

    let url = this.apiUrl + 'orgs/' + this._user.value.org.id + '/assets/' + assetId + '/telemetry';
    if (querystring) {
      url += `?${querystring}&`;
    }

    if (limit) {
      url += (typeof querystring === 'undefined' ? '?' : '') + 'limit=' + limit;
    }

    return this.http.get<any[]>(url, options)
      .toPromise()
      .then(response => {
        if (response && response.length) {
          // Check for string to number conversions.
          if (response[0].length) {
            // Data exists
            if (response[0][0].v === 'No Leak' || response[0][0].v === '*LEAK*') {
              // This is a leak sensor using a button
              response[0] = response[0].map(r => {
                return { v: r.v === 'No Leak' ? 0 : 1, i: r.i, d: r.d };
              });
            }
          }

          if (response.length === 2) {
            response[0].reverse();
          } else {
            response.reverse();
          }
        }
        return response;
      })
      .catch(this.handleError);
  }

  /**
   * Get telemetry for a date range
   *
   * @param assetId
   * @param start
   * @param end
   */
  getTelemetryForRange(assetId: number, start: Date | string, end: Date | string): Promise<any> {
    // Convert for backend
    let sdate, edate;
    if (start instanceof Date && end instanceof Date) {
      sdate = start.toISOString().substring(0, 10);
      edate = end.toISOString().substring(0, 10);
    } else {
      sdate = start;
      edate = end;
    }
    return this.getTelemetry(assetId, 0, `start=${sdate}&end=${edate}`);
  }


  //
  getOrg(orgId: number): Promise<Org> {
    return this.http.get<any[]>(this.apiUrl + 'orgs/' + orgId, { headers: this.getHttpHeaders() })
      .toPromise()
      .then((response) => {
        return new Org(response[0]);
      })
      .catch(this.handleError);
  }

  // Get an extended site for management
  getSiteExtended(siteId): Promise<Site> {
    return this.http.get<any[]>(this.getUserAPI() + '/sites/' + siteId + '?extended=1', { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => {
        return new Site(response);
      });
  }

  getSite(siteId: number): Promise<Site> {
    for (let idx = 0; idx < this._sites.value.length; idx++) {
      if (this._sites.value[idx].id === siteId) {
        console.log('Site from local cache');

        return new Promise((resolve, reject) => resolve(this._sites.value[idx]));
      }
    }

    return this._getSite(siteId);
  }

  _getSite(siteId): Promise<Site> {
    const options = { headers: this.getHttpHeaders() };

    return this.http.get<any[]>(this.apiUrl + 'sites/' + siteId, options)
      .toPromise()
      .then((response) => {
        const site = new Site(response[0]);

        this._sites.next([site]);
        for (let idx = 0; idx < this._sites.value.length; idx++) {

          if (this._sites.value[idx] && this._sites.value[idx].id === site.id) {
            this._sites.value[idx] = site;
            console.log('site ' + site.id + ' updated', site);

            return site;
          }
        }
        this._sites.value.push(site);

        return site;
      })
      .catch(this.handleError);
  }

  getAssetSettings(assetId: number): Promise<any> {
    const options = { headers: this.getHttpHeaders() };

    return this.http.get<any[]>(this.apiUrl + 'assets/' + assetId + '?settings=2&refs=1', options).toPromise()
      .then(response => {
        return response;
      });
  };

  _getAsset(assetId): Promise<Asset> {
    const options = { headers: this.getHttpHeaders() };

    return this.http.get<any[]>(this.apiUrl + 'assets/' + assetId, options)
      .toPromise()
      .then((response) => {
        const asset = new Asset(response[0]);

        this._asset.next(asset);
        for (let idx = 0; idx < this._assets.value.length; idx++) {

          if (this._assets.value[idx] && this._assets.value[idx].id === asset.id) {
            this._assets.value[idx] = asset;
            console.log('asset ' + asset.id + ' updated', asset);

            return asset;
          }
        }
        console.log(`new on stack`, asset);
        this._assets.value.push(asset);

        return asset;
      })
      .catch(this.handleError);
  }

  getAlert(alertId: number): Promise<Alert> {
    for (let idx = 0; idx < this._alerts.value.length; idx++) {
      const alert = this._alerts.value[idx];
      console.log(alert);
      if (this._alerts.value[idx].actions && this._alerts.value[idx].id === alertId && this._alerts.value[idx].actions.length) {
        return new Promise((resolve, reject) => resolve(this._alerts.value[idx]));
      }
    }

    return this._getAlert(alertId);
  }

  _getAlert(alertId): Promise<Alert> {
    const options = { headers: this.getHttpHeaders() };

    return this.http.get<any[]>(this.apiUrl + 'alerts/' + alertId, options)
      .toPromise()
      .then((response) => {
        const alert = response[0][0] as Alert;
        const actions = response[1] as AlertAction[];
        alert.actions = actions;
        this._alerts.next([alert]);
        for (let idx = 0; idx < this._alerts.value.length; idx++) {
          if (this._alerts.value[idx] && this._alerts.value[idx].id === alert.id) {
            this._alerts.value[idx] = alert;

            return alert;
          }
        }
        this._alerts.value.push(alert);

        return alert;
      })
      .catch(this.handleError);
  }

  /**
   * Amend a rule package
   * @param id
   * @param data
   */
  putRulePackage(id: number, data: PostRuleInterface): Promise<any> {
    const options = { headers: this.getHttpHeaders() };

    return this.http.put(this.apiUrl + 'rulepackage/' + id, data, options)
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  /**
   * Delete a rule package
   * @param id
   * @param data
   */
  deleteRulePackage(id: number): Promise<any> {
    const options = { headers: this.getHttpHeaders() };

    return this.http.delete(this.apiUrl + 'rulepackage/' + id, options)
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  testRule(conditions: RuleCondition[], notifications: RuleNotification[], message: string): Promise<number> {
    const options = { headers: this.getHttpHeaders() };
    // Create a new rule package to upload
    const rulePackage = new RulePackage(conditions, notifications, message);
    const data = rulePackage.serialise();
    console.log(data);

    return this.http.post<any>(this.apiUrl + 'rules', data, options)
      .toPromise()
      .then(response => {
        console.log('testRule() response', response);
        let id = 0;
        try {
          id = response.id;
        } catch (e) {
        }

        return id;
      })
      .catch(this.handleError);
  }

  postRulePackage(rulePackage: RulePackage, makeCopy: boolean = false): Promise<number | string> {
    const options = { headers: this.getHttpHeaders() };
    // Create a new rule package to upload

    const data = makeCopy ? rulePackage.serialiseForCopy() : rulePackage.serialise();
    console.log(data);

    return this.http.post(this.apiUrl + 'rules', data, options)
      .toPromise()
      .then(response => {
        let json: any = response;
        if (json && json.errorType) {
          console.log('postRulePackage() FAILED', response);

          return json.errorMessage;
        } else {
          console.log('postRulePackage() OK', response);
          let id = 0;

          try {
            if (json instanceof Array) {
              id = json.pop().id;
            } else {
              id = json.id;
            }
          } catch (e) {
          }

          return id;
        }
      })
      .catch(this.handleError);
  }

  forgotPassword(email: string): Promise<string> {
    const options = { headers: this.getHttpHeaders() };
    return this.http.post(this.apiUrl + 'login/forgot', { email: email, app: this.appDomain }, options)
      .toPromise()
      .then(response => {
        console.log('lostPassword() response', response);
        const body = <any>response;
        let msg = 'There was a problem sending the email.';
        if (body && body.sent) {
          msg = 'Instructions to reset your password have been sent to you. Please check your email.';
        }

        return msg;
      })
      .catch(this.handleError);
  }

  resetPassword(email: string, tokenId: string, newPassword: string): Promise<boolean> {
    const options = { headers: this.getHttpHeaders() };
    return this.http.post(this.apiUrl + 'login/reset', { email: email, lost: tokenId, password: newPassword }, options)
      .toPromise()
      .then(response => {
        console.log('resetPassword() response', response);
        const body = <any>response;

        if (body) {
          return body.changed;
        }

        return false;
      })
      .catch(this.handleError);
  }

  changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
    const options = { headers: this.getHttpHeaders() };
    return this.http.put<any>(this.apiUrl + 'profile/password', { email: this._user.value.email, oldPassword: oldPassword, newPassword: newPassword }, options)
      .toPromise()
      .then(response => {
        console.log('changePassword() response', response);
        let body = response;

        if (body) {
          return body.changed;
        }

        return false;
      })
      .catch(this.handleError);
  }

  switchOrg(org: Org | number): Promise<any> {
    const id = typeof org === 'object' ? org.id : org;
    const options = { headers: this.getHttpHeaders() };
    return this.http.put(this.apiUrl + 'profile', { org: id }, options)
      .toPromise()
      .then(response => {
        console.log('switchOrg() response', response);
        let body = response;

        return body;
      })
      .catch(this.handleError);
  }

  login(email, password): Promise<User> {
    email = email.trim().toLowerCase();

    const options = { headers: this.getContentTypeHttpHeader() };
    const self = this;
    const data = {
      email: email,
      password: password,
      browser: this.browserCheck(),
      ua: window.navigator.userAgent,
      version: this.appVersion,
      otp: '',
      loginByEmail: false,
      app: 'web.portal',
      returnType: 1
    };

    return this.http.post(this.apiUrl + 'login/v2', data, options)
      .toPromise()
      .then(response => {
        this.reportListCollection = [];
        const body = <any>response;
        // Errors do not reurn an array of data
        const identityResponse = body.length ? body[0] : body;
        if (!identityResponse || !identityResponse.session || !identityResponse.session.token) {
          // Login failed
          return null;
        }
        const userResponse = body.user;
        const orgResponse = body.org;
        const orgRoles = body.globalRoles ? body.globalRoles.split(',') : [];
        body.orgRoles.forEach(role => { orgRoles.push(role.role) });

        const org = new Org(orgResponse);

        const prefs = {};
        if (body.settings) {
          body.settings.forEach(settingRow => {
            prefs[settingRow.setting] = settingRow.value;
          });
        }

        const user = new User({
          id: userResponse.id,
          role: userResponse.role,
          email,
          name: userResponse.name,
          password: '',
          isActive: true,
          hasAcceptedTerms: userResponse.hasAcceptedTerms,
          orgRoles,
          prefs,
          modules: body.modules
        });

        this.moduleAccess = user.modulesAsObject();
        user.org = org;
        // User acceptance not required, future use.
        user.hasAcceptedTerms = true;

        // User has logged in, notify the event
        this._loggedIn.next(user);
        // Push the new user to subscribers
        this._user.next(user);

        const session = new Session({
          token: body.session.token,
          passwordToken: body.session.passwordToken,
          identityId: body.session.identityId,
          timestamp: new Date()
        });
        this.sessionService.setSession(session);

        localStorage.setItem('user', JSON.stringify(user.serialise()));
        localStorage.setItem('session', JSON.stringify(session));

        this.authenticated = true;

        return user;
      })
      .catch(this.handleError);
  }

  updateAlertState(alertId, state, notes, externalRef) {
    const options = { headers: this.getHttpHeaders() };
    const data = { state: state, externalRef: externalRef, notes: notes };

    return this.http.put(this.apiUrl + 'alerts/' + alertId + '/state', data, options)
      .toPromise()
      .then(() => this._getAlert(alertId))
      .catch(this.handleError);
  }

  getAssetsHistoricData(assets: Asset[], limit?: number): Promise<any> {
    let query = '?assets=';
    for (let idx = 0; idx < assets.length; idx++) {
      query += (idx > 0 ? '%2C' : '') + assets[idx].id;
    }
    const options = { headers: this.getHttpHeaders() };

    return this.http.get<any[]>(this.apiUrl + 'assets/historic' + query, options)
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  /**
   * Get a rule package
   * @param id
   * @returns {Promise<RulePackage>}
   */
  getRulePackage(id: number): Promise<RulePackage> {
    const options = { headers: this.getHttpHeaders() };

    return this.http.get<any[]>(this.apiUrl + 'rulepackage/' + id, options)
      .toPromise()
      .then((response) => {
        const body = response;
        const rulePackage = this.importRulePackage(body);
        this._rulePackage.next(rulePackage);

        return rulePackage;
      })
      .catch(this.handleError);
  }
  /**
  * Get a rule package alarms
  * @param rulePackageId
  * @returns {Promise<any[]>}
  */
  getRulePackageAlarms(rulePackageId: number, offset = 0, limit = 10): Promise<any[]> {
    const options = { headers: this.getHttpHeaders() };
    const querystring = `offset=${offset}&limit=${limit}`;
    return this.http.get<any[]>(this.apiUrl + `rulepackage/${rulePackageId}/alarms?${querystring}`, options)
      .toPromise()
      .then((response) => {
        // Make sure dates are converted to js
        if (response[1]) {
          for (let index = 0; index < response[1].length; index++) {
            const element = response[1][index];
            if (element.value === 'null acknowledged') {
              element.value = 'acknowledged';
            }
            if (element.value === 'null paused') {
              element.value = 'paused';
            }
            element.createdAt = new Date(element.createdAt);
          }
        }
        return response;
      })
      .catch(this.handleError);
  }

  /**
   * Enable or disable a rule package
   * @param rulePackageId
   * @param isEnabled
   */
  enableRulePackage(rulePackageId: number, isEnabled: boolean) {
    const options = { headers: this.getHttpHeaders() };
    const enableOrDisable = isEnabled ? 'enable' : 'disable';
    return this.http.put(this.apiUrl + `rulepackage/${rulePackageId}/${enableOrDisable}`, {}, options)
      .toPromise()
      .then(response => {

        return response;
      })
      .catch(this.handleError);
  }

  /**
   * Get rule packages for the user
   *
   * @returns {<Promise<RulePackage[]>}
   */
  getRulePackages(dataset?: string, sort = null, sort2 = null): Promise<RulePackage[]> {
    const options = { headers: this.getHttpHeaders() };
    const self = this;
    dataset = dataset ? '/dataset/' + dataset : '';
    let querystring = sort ? encodeURIComponent(JSON.stringify(sort)) : '';

    if (querystring) {
      dataset += '?sort=' + querystring;
      if (sort2) {
        dataset += '&sort2=' + encodeURIComponent(JSON.stringify(sort2));
      }
    }
    return this.http.get<any[]>(this.getUserAPI() + '/rulepackages' + dataset, options)
      .toPromise()
      .then((response) => {

        const body = response;
        const packages = body[0].map(rp => {

          const conds = body[1].filter(c => {
            return (c.rulePackage_id === rp.id);
          });

          if (conds.length) {
            const gwTitle = conds[0].leftAssetGatewayTitle;
            if (!rp.gatewayTitle) {
              // Use left sensor gateway title if no title
              rp.gatewayTitle = gwTitle;
            }
          }

          const alarm = body[2].filter(a => {
            return (a.rulePackage_id === rp.id);
          });

          const ranges = body[4].filter(rtr => {
            return (rtr.rulePackage_id === rp.id);
          });

          return self.importRulePackage([[rp], conds, alarm, null, null, ranges]);
        });

        return packages;
      })
      .catch(this.handleError);
  }

  /**
   * Import a received array into a RulePackage object
   * @param receivedPackage an array received by the api
   */
  importRulePackage(receivedPackage: any[]): RulePackage {
    const master = receivedPackage[0][0];
    const conditions = receivedPackage[1];
    const alarms = receivedPackage[2] || [];
    const timeline = receivedPackage[3] || [];
    const contacts = receivedPackage[4] || [];
    const timeranges = receivedPackage[5] || [];
    const actions = receivedPackage[6] || [];
    const counts = (receivedPackage[8] || [])[0];
    const integration = receivedPackage[9];
    const elogbooks = receivedPackage[10];
    const groupings = receivedPackage[11];
    const rulePackage = new RulePackage();
    if (!master) {

      return rulePackage;
    }
    rulePackage.title = master.title;
    rulePackage.id = master.id;
    rulePackage.instructions = master.instructions;
    rulePackage.inAlert = master.inAlert;
    rulePackage.isEnabled = master.isEnabled;
    rulePackage.userId = master.user_id;
    rulePackage.userName = master.createdByUserName;
    rulePackage.severity = master.severity;
    rulePackage.triggerAfter = master.triggerAfter;
    rulePackage.tag = master.tag;
    if (rulePackage.tag?.indexOf('_m') > 0) {
      // A setpoint rule
      rulePackage.setpointRangeId = (rulePackage.tag.indexOf('ooh') > 0) ? 3 : 2;
    }
    rulePackage.autoResolve = master.autoResolve;
    rulePackage.siteId = master.siteId;
    rulePackage.siteTitle = master.siteTitle;
    rulePackage.gatewayId = master.gatewayId;
    rulePackage.gatewayTitle = master.gatewayTitle;
    rulePackage.bankHoliday = (master.bankHoliday === 'Y');

    if (master.asset_id) {
      rulePackage.asset = new Asset({ id: master.asset_id });
    }
    rulePackage.includeSiteNotifications = master.includeSiteNotifications === 'Y';
    if (master.lastAlertedAt) {
      rulePackage.lastAlertedAt = new Date(master.lastAlertedAt);
    }
    // Rule conditions
    for (let idx = 0; idx < conditions.length; idx++) {
      const leftSelected = new SelectedAsset(master.id, 'leftPicker', new Asset(conditions[idx].leftAsset_id), conditions[idx].leftAssetValue);
      leftSelected.asset.gateway_id = conditions[idx].leftAssetGatewayId;
      leftSelected.computedValue = conditions[idx].computedLeft;
      leftSelected.asset.title = conditions[idx].leftAssetTitle;
      leftSelected.asset.assetType_id = conditions[idx].leftAssetType;
      leftSelected.asset.identifier = conditions[idx].leftAssetIdentifier;
      leftSelected.asset.purpose.id = conditions[idx].leftPurposeId;

      leftSelected.asset.gateway = new Gateway();
      leftSelected.asset.gateway.id = conditions[idx].leftAssetGatewayId;
      leftSelected.asset.gateway.title = conditions[idx].leftAssetGatewayTitle;
      leftSelected.asset.gateway.site = new Site();
      leftSelected.asset.gateway.site.id = conditions[idx].leftAssetSiteId;
      leftSelected.asset.gateway.site.title = conditions[idx].leftAssetSiteTitle;

      const rightSelected = new SelectedAsset(master.id, 'rightPicker', new Asset(conditions[idx].rightAsset_id), conditions[idx].rightAssetValue);
      rightSelected.computedValue = conditions[idx].computedRight;
      rightSelected.asset.title = conditions[idx].rightAssetTitle;
      rightSelected.asset.gateway_id = conditions[idx].rightAssetGatewayId;
      rightSelected.asset.assetType_id = conditions[idx].rightAssetType;
      rightSelected.asset.identifier = conditions[idx].rightAssetIdentifier;
      rightSelected.asset.purpose.id = conditions[idx].rightPurposeId;
      rightSelected.asset.gateway = new Gateway();
      rightSelected.asset.gateway.title = conditions[idx].rightAssetGatewayTitle;
      rightSelected.asset.gateway.id = conditions[idx].rightAssetGatewayId;
      rightSelected.asset.gateway.title = conditions[idx].rightAssetGatewayTitle;
      rightSelected.asset.gateway.site = new Site();
      rightSelected.asset.gateway.site.id = conditions[idx].rightAssetSiteId;
      rightSelected.asset.gateway.site.title = conditions[idx].rightAssetSiteTitle;

      const condition = new RuleCondition(master.id, conditions[idx].id, leftSelected, conditions[idx].operator, rightSelected, conditions[idx].logic);

      condition.inAlert = (conditions[idx].inAlert === 'Y');
      condition.updatedAt = conditions[idx].updatedAt;
      rulePackage.conditions.push(condition);
    }
    // Rule notifications
    for (let idx = 0; idx < contacts.length; idx++) {
      const contact = new RuleNotification(contacts[idx].id, contacts[idx].method, contacts[idx].value, contacts[idx].states, !!contacts[idx].isSuppressed);
      rulePackage.notifications.push(contact);
    }
    // Rule time ranges
    for (let idx = 0; idx < timeranges.length; idx++) {
      const timerange = new RuleTimeRange(timeranges[idx].id, timeranges[idx].dows, timeranges[idx].startTimeAt, timeranges[idx].endTimeAt);
      rulePackage.ruleTimeRanges.push(timerange);
    }

    rulePackage.actions = actions ? actions.map(action => new RuleAction(action)) : [];

    // Alarm raised on this rule (if any)
    rulePackage.alarm = alarms[0];
    if (rulePackage.alarm) {
      rulePackage.alarm = new Alarm(rulePackage.alarm);
      rulePackage.alarm.timeline = timeline.map(t => new AlarmTimeline(t));
      // There may be multiple jobs (not currently)
      if (elogbooks) {
        rulePackage.alarm.addElogbooksJobs(elogbooks);
      }
    }
    rulePackage.counts = counts;

    if (integration && integration.length) {
      const i = {
        integrator: integration[0].integrator,
        states: {}
      };
      for (let index = 0; index < integration.length; index++) {
        const element = integration[index];
        const settings = JSON.parse(element.settings || "{}");
        i.states[element.state] = settings;
        if (i.states[element.state]?.siteId) {
          rulePackage.hasIntegration = true;
        }
      }
      rulePackage.integration = i;
    }

    if (groupings) {
      rulePackage.groupings = groupings.map(g => new Grouping(g));
    }

    return rulePackage;
  }

  /**
   * process a token action
   * @param id
   * @param {string} action
   * @param {object} data
   * @returns {Promise<R>|Promise<Promise<any>>}
   */
  tokenAction(id: string, action: string, data?: any): Promise<any> {
    const options = { headers: this.getHttpHeaders() };

    return this.http.post(this.apiUrl + 'alarms/token/' + id, { action: action, data: data }, options)
      .toPromise()
      .then((response) => {

        return response;
      });
  }

  updateAlarmNotes(timeline: AlarmTimeline, notes: string): Promise<any> {
    const options = { headers: this.getHttpHeaders() };

    return this.http.post(this.apiUrl + 'alarms/' + timeline.alarm_id + '/state', { id: timeline.id, state: 'notes', notes: notes }, options)
      .toPromise()
      .then(response => response);
  }

  /**
   * @deprecated 
   */
  alarmAction(alarm: RuleAlarm, state: string, notes: string, pause: number): Promise<any> {
    const options = { headers: this.getHttpHeaders() };

    return this.http.post(this.apiUrl + 'alarms/' + alarm.id + '/state', { state: state, notes: notes, pause: pause }, options)
      .toPromise()
      .then((response) => {

        return response;
      });
  }

  getIsRestricted(): boolean {
    return this._user.value.isRestricted;
  }

  getRestrictedModule(): string {
    return this.moduleAccess ? Object.keys(this.moduleAccess)[0] : '';
  }

  getUserOrg(): Org {
    return this._user.value.org;
  }

  isAdmin(): boolean {
    return this._user.value && this._user.value.role === 'admin';
  }

  hasOrgRole(role: string): boolean {
    return !!this._user.value.orgRoles.filter(checkRole => checkRole === role).length;
  }

  hasModule(module: string): boolean {
    return !!this._user.value.modules?.has(module);
  }

  getUserId(): number {
    try {
      return this._user.value.id;
    } catch (e) {
      return null;
    }
  }

  /**
   * The user must accept the terms and conditions before they can use any authenticated page
   */
  hasApprovedTerms(): boolean {

    return this._user.value.hasAcceptedTerms;
  }


  approvedTermsAndConditions() {
    this._user.value.hasAcceptedTerms = true;

  }

  /**
   * Get a report
   * @param id
   * @returns {Promise<Report>}
   */
  getReport(id: number): Promise<Report> {
    const options = { headers: this.getHttpHeaders() };
    /*
        if (this.reportCollection.has(id)) {
    
          return Promise.resolve(this.reportCollection.get(id));
        }
    */
    return this.http.get<any[]>(this.apiUrl + 'orgs/' + this._user.value.org.id + '/reports/' + id, options)
      .toPromise()
      .then((response) => {
        const body = response;
        const report = this.importReport(body);
        this.reportCollection.set(report.id, report);
        this._report.next(report);

        return report;
      })
      .catch(this.handleError);
  }

  /**
   * Get a rule packages
   * @returns {<Promise<Report[]>}
   */
  getReports(): Promise<Report[]> {

    if (this.reportListCollection.length) {

      return Promise.resolve(this.reportListCollection);
    }

    const options = { headers: this.getHttpHeaders() };

    return this.http.get<any[]>(this.apiUrl + 'orgs/' + this._user.value.org.id + '/reports', options)
      .toPromise()
      .then((response) => {
        const body: Report[] = response.map(item => new Report(item));
        this.reportListCollection = body;

        return body;
      })
      .catch(this.handleError);
  }

  /**
   * Get a report blocks data (uses first gateway in list)
   * @param id
   * @returns {Promise<Report>}
   */
  getReportBlockData(reportId: number, blockId: number, config: ReportConfig): Promise<any> {
    const options = { headers: this.getHttpHeaders() };

    return this.http.post(this.apiUrl + 'orgs/' + this._user.value.org.id + '/reports/' + reportId + '/blocks/' + blockId + '/data', {
      asset: (config.forAsset ? config.forAsset.id : null),
      gateway: (config.forGateways ? config.forGateways[0].id : null),
      forIdentifier: (config.forIdentifier ? config.forIdentifier : null),
      startAt: config.startAt,
      startOfMonth: config.startOfMonth,
      endAt: config.endAt
    }, options)
      .toPromise()
      .then((response) => {

        return response;

      })
      .catch(this.handleError);
  }

  /**
   * Import a received array into a RulePackage object
   * @param receivedPackage an array received by the api
   */
  importReport(record: any[]): Report {
    let master = record[0][0];
    let blocks = record[1];

    let report = new Report();

    report.title = master.title;
    report.id = master.id;
    report.icon = master.icon;
    report.forAssetType = master.forAssetType;
    report.forAsset = master.forAsset;
    report.forGatewayType = master.forGatewayType;
    report.forGateway = master.forGateway;
    report.dateSpan = master.dateSpan;

    // Rule conditions
    for (let idx = 0; idx < blocks.length; idx++) {
      let block = blocks[idx];
      const reportBlock = new ReportBlock(block.id, block.title, block.fragment_id, block.pagebreak, block.options);
      report.blocks.push(reportBlock);
    }

    return report;
  }

  /**
   * Set the subdomain (public or portal), if public set the environment up
   * for demo usage.
   */
  setDomain(domain: string) {
    APIService.SUBDOMAIN = domain.split('.')[0];
    APIService.TLD = domain.split('.').pop();

    if (APIService.TLD === 'test') {
      APIService.SUBDOMAIN = '4dmltest';
    }

    if (APIService.SUBDOMAIN === 'public') {
      // Public domain has no logged in user
      const user = new User();
      user.name = 'Demo User';
      user.role = 'demo';
      user.org = new Org({ id: 5, title: 'Demo Organisation' });
      this._user.next(user);
    }

    return APIService.SUBDOMAIN;
  }

  clearSession() {
    console.log('CLEAR_SESSION');
    localStorage.removeItem('session');
    this._user.next(null);
  }

  /**
   * Return the title for an asset type
   *
   * @param asset
   * @returns srting
   */
  getAssetTypeTitle(asset: Asset): string {

    return this.ASSET_TYPES_BY_ID[asset.assetType_id];
  }

  getAllTypes() {
    return this.http.get<any[]>(this.apiUrl + 'types')
      .toPromise()
      .then(response => response);
  }

  /**
   * Save a property on an object
   * @param id - the id
   * @param propertyRoute - {table}.{property} (eg asset.title)
   * @param value - the value to write
   */
  save(id, propertyRoute, value) {
    console.log('api save()', id, propertyRoute, value);
    const options = { headers: this.getHttpHeaders() };
    const data = { id: id, propertyRoute: propertyRoute, value: value };

    return this.http.post(this.getUserAPI() + '/patch', data, options)
      .toPromise()
      .then(response => {
        console.log('got response', response);
        const body = response;

        return body;
      })
      .catch(this.handleError);
  }

  getSavedOrders() {
    return this.http.get<any[]>(this.apiUrl + 'orders/saved', { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => {
        console.log('got response', response);
        const orders = response;

        return orders.map(o => {
          return new Order(o);
        });
      })
      .catch(this.handleError);
  }

  /**
   * Get the order, complete with inventory items
   * @param orderId Purchase order id
   */
  getOrder(orderId: number): Promise<any> {
    return this.http.get<any[]>(this.apiUrl + `orders/${orderId}`, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => {

        const order = new Order(response);
        // let orderItems = response[1];

        return { order, roles: response[2] };
      })
      .catch(this.handleError);
  }

  /**
   * Post pr put a purchase order
   *
   * @param orderId
   * @param site
   * @param deliverAt
   * @param installAt
   * @param status
   * @param items
   * @param submit
   */
  updateOrder(orderId: number, site: Site, deliverAt: Date, installAt: Date, status: string, items: any[], submit?: boolean) {
    const options = { headers: this.getHttpHeaders() };

    const details = {
      deliverAt: deliverAt,
      installAt: installAt,
      status: status,
      site: site
    };

    const data = { details: details, collections: items };
    const orderIdUrl = orderId ? `/${orderId}` : '';
    const submitUrl = submit ? '/submit' : '';

    if (orderId) {
      return this.http.put(`${this.apiUrl}orders/${orderId}${submitUrl}`, data, options)
        .toPromise()
        .then(response => response)
        .catch(this.handleError);
    } else {
      return this.http.post(`${this.apiUrl}orders${submitUrl}`, data, options)
        .toPromise()
        .then(response => response)
        .catch(this.handleError);
    }
  }

  deleteOrder(orderId: number, deleteSite: boolean): Promise<any> {
    let querystring = '';
    if (deleteSite) {
      querystring = '?deleteSite=true';
    }
    return this.http.delete(`${this.apiUrl}orders/${orderId}${querystring}`, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => {
        console.log('got response', response);

        return response;
      })
      .catch(this.handleError);
  }

  orderStatus(orderId: number, status: string): Promise<any> {
    return this.http.put(`${this.apiUrl}orders/${orderId}/status/${status}`, {}, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => {
        console.log('got response', response);

        return response;
      })
      .catch(this.handleError);
  }


  getTimelineForOrg(offset) {
    console.log('api getTimelineForOrg()');
    const options = { headers: this.getHttpHeaders() };
    const query = '?yesterday=true&connection=1&ver=3&offset=' + offset;

    return this.http.get<any[]>(this.getUserAPI() + '/gateways/timeline' + query, options)
      .toPromise()
      .then(response => {
        const body = response;

        return body;
      })
      .catch(this.handleError);
  }

  manageDevice(token, action) {
    const options = { headers: this.getHttpHeaders() };
    return this.http.post(`${this.apiUrl}device/${token}`, { action: action }, options)
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  toastSuccess(title: string, message: string = '', life = 3000) {
    this.messageService.add({ severity: 'success', summary: title, detail: message, life });
  }

  toastWarn(title: string, message: string = '', life = 3000) {
    this.messageService.add({ severity: 'warn', summary: title, detail: message, life });
  }

  getStatus(): Promise<any> {
    return this.http.get<any[]>(this.apiUrl + 'status')
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  getProfilingItem(id?: number): Promise<Profile> {
    return this.http.get<any[]>(this.apiUrl + `profiling/${id}`, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => new Profile(response))
      .catch(this.handleError);
  }

  getSitePlanAssetHistory(sitePlan: SitePlan, startAt: string, endAt: string): Promise<GetSitePlanAssetHistory> {
    const url = this.getUserAPI() + `/sites/${sitePlan.site.id}/floorplans/${sitePlan.id}/history?timeStartsAt=${startAt}&timeEndsAt=${endAt}&includeRAGS=1`;

    return this.http.get<any>(url, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => {

        return response;
      })
      .catch(this.handleError);
  }

  getProfilingItemHistory(id: number, startAt: string, endAt: string): Promise<ProfilingHistoryItem[]> {
    const url = this.apiUrl + `profiling/${id}?dataset=history,${startAt},${endAt}`;
    return this.http.get<any[]>(url, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  /**
   * Get profiling plans for org
   *
   * @param id Optional, gatewayid|profilingid, otherwise get all for org
   */
  getProfiling(): Promise<any[]> {
    return this.http.get<any[]>(this.getUserAPI() + `/profiling`, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  getTimelapse(): Promise<any> {
    return this.http.get<any[]>(this.apiUrl + 'timelapse/', { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  getAssetsInError(): Promise<Asset[]> {
    console.log('api getAssetsInError()');
    const query = '?inerror=true';
    const options = { headers: this.getHttpHeaders() };
    return this.http
      .get<any[]>(this.getUserAPI() + '/assets/inerror' + query, options)
      .toPromise()
      .then(response => response.map(asset => new Asset(asset)))
      .catch(this.handleError);
  }

  /**
   * Get all assets attached to a purpose
   */
  getAssetsWithPurpose(): Promise<Asset[]> {
    console.log('api getAssetsWithPurpose()');
    const query = '?purposeid=*';
    const options = { headers: this.getHttpHeaders() };
    return this.http.get<any[]>(this.getUserAPI() + '/assets' + query, options)
      .toPromise()
      .then(response => response.map(asset => new Asset(asset)))
      .catch(this.handleError);
  }

  getAssetsWithCategory<T>(category: string, factory: any): Promise<T[]> {
    console.log(`api getAssetsWithCategory(${category})`);
    const query = `?forCategory=${category}`;
    const options = { headers: this.getHttpHeaders() };
    return this.http.get<any[]>(this.getUserAPI() + '/assets' + query, options)
      .toPromise()
      .then(response => {

        return response.map(asset => createAsset(AssetKind.Camera, { asset, presets: [] }));
      })
      .catch(this.handleError);
  }

  /**
   * Get all assets with an asset preset (cameras)
   * Return asset and preset info.
   */
  getAssetsWithPresets(): Promise<Collection<Asset>> {
    console.log('api getAssetPresets()');
    const options = { headers: this.getHttpHeaders() };
    return this.http.get<any[]>(this.getUserAPI() + '/assets/cameras', options)
      .toPromise()
      .then(response => {
        const body = response;
        const collection = new Collection<Asset>();

        body.map(asset => {
          const a = collection.get(asset.id) || new Asset(asset);
          const preset = new AssetPreset();
          preset.id = asset.presetId;
          preset.title = asset.presetTitle;
          preset.recentBucketKey = asset.presetRecentBucketKey;
          preset.ref = asset.presetRef;
          preset.shotNight = asset.presetShotNight;

          a.presets.push(preset);
          if (!collection.exists(a)) {
            collection.add(a);
          }
        });

        return collection;
      })
      .catch(this.handleError);
  }

  /**
   * Retrieve asset image(s) from a capture image type.
   *  - No response is returned if no more images
   * @param gatewayId Gateway id
   * @param assetId Asset id
   * @param index Position in index, or null
   * @param limit How many images to return from the index
   */
  getAssetImage(gatewayId: string, assetId: number, index?: number, limit?: number): Promise<string[]> {
    console.log('api getAssetImage()');
    const query = index ? `?index=${index}&limit=${limit}` : '';
    const options = { headers: this.getHttpHeaders() };
    return this.http.get<any[]>(this.getUserAPI() + `/gateways/${gatewayId}/assets/${assetId}/images${query}`, options)
      .toPromise()
      .then(response => response.map(asset => new Asset(asset)))
      .catch(this.handleError);
  }

  /**
   * Request to capture an image from the gateway.
   *  - Image is not returned, the gateway takes the image within seconds
   *    and records the results to the users inbox.
   * @param gatewayId Gateway id
   * @param assetId Asset id
   */
  requestCaptureAssetImage(gatewayId: string, assetId: number): Promise<Asset[]> {
    console.log('api getAssetImage()');
    const options = { headers: this.getHttpHeaders() };
    return this.http.get<any[]>(this.getUserAPI() + `/gateways/${gatewayId}/assets/${assetId}/images/capture`, options)
      .toPromise()
      .then(response => response.map(asset => new Asset(asset)))
      .catch(this.handleError);
  }

  /**
   * Get postcode data from postcode.io
   *
   * @param postcode string
   */
  getPostcodeData(postcode: string): Promise<any> {
    if (!postcode) {

      return;
    }
    return this.http.get<any>(`https://api.postcodes.io/postcodes/${postcode}`)
      .toPromise()
      .then(response => {
        const data = response;

        const postcodeData = {
          long: data.result.longitude,
          lat: data.result.latitude,
          county: data.result.admin_county,
          town: data.result.admin_district
        };

        return postcodeData;
      })
      .catch(this.handleError);
  }

  getTrainingModules(trainingPackageId?: number): Promise<TrainingModule[]> {
    return this.http.get<any[]>(this.apiUrl + `training/${trainingPackageId}/modules?v=3`, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => {
        const modules = [];
        // Remove widgets
        response.filter(module => module.id !== 14).forEach(module => {
          modules.push(new TrainingModule(module));
        });

        return modules;
      })
      .catch(this.handleError);
  }

  getTrainingModule(courseId: number, moduleId: number): Promise<TrainingModule> {
    return this.http.get<any[]>(this.apiUrl + `training/${courseId}/modules/${moduleId}?v=3`, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => new TrainingModule(response[0]))
      .catch(this.handleError);
  }

  /**
   * Get Weather stats
   */
  getWeatherStats(querystring = ''): Promise<any[]> {
    const options = { headers: this.getHttpHeaders() };

    return this.http.get<any[]>(this.apiUrl + 'weather?' + querystring, options)
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  putTrainingModuleProgress(courseId: number, moduleId: number, timestamp, isCompleted): Promise<any> {
    const options = { headers: this.getHttpHeaders() };
    const data = { timestamp: timestamp, isCompleted: isCompleted };

    return this.http.put(this.apiUrl + `training/${courseId}/modules/${moduleId}/progress`, data, options)
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  browserCheck(): string {

    // Opera 8.0+
    const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== 'undefined';

    // Safari 3.0+ "[object HTMLElementConstructor]"
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/false || !!document.documentMode;

    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1+
    const isChrome = !!window.chrome && !!window.chrome.webstore;

    // Blink engine detection
    const isBlink = (isChrome || isOpera) && !!window.CSS;

    const isIphone = ((navigator.userAgent.indexOf('iPhone') != -1) || (navigator.userAgent.indexOf('iPod') != -1));

    const isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/i);
    const isMobileChrome = !!(navigator.userAgent.match(/Chrome/i));
    const isMobileSafari = !!(navigator.userAgent.match(/Mobile Safari/i));

    return isOpera ? 'opera' :
      isFirefox ? 'firefox' :
        isSafari ? 'safari' :
          isChrome ? 'chrome' :
            isMobileChrome ? 'chrome' :
              isMobileSafari ? 'safari' :
                isIE ? 'ie' :
                  isEdge ? 'edge' :
                    isBlink ? 'blink' :
                      isSamsungBrowser ? 'samsung' :
                        isIphone ? 'iphone' :
                          'unknown';
  };

  /*
   * Post URL to get configuration saved for that page
   */
  postRoute(url: string, action?: string, changes?: string, browser?: boolean) {
    // Must be a valid user for retrieving config for user
    if (this.getUserId()) {
      const body: any = { url };
      if (action) {
        body.action = action;
      }

      if (changes) {
        body.changes = changes;
      }

      if (browser) {
        body.browser = this.browserCheck();
      }

      body.v = APIService.VERSION;
      const postURL = this.apiUrl + `users/${this.getUserId()}/config`;

      return this.http
        .post(postURL, body, { headers: this.getHttpHeaders() })
        .toPromise()
        .then(response => response)
        .catch(this.handleError);
    }
  }

  getAlarm(alarmId: number): Promise<Alarm> {
    return this.http.get<any[]>(this.apiUrl + `alarms/${alarmId}`, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => new Alarm(response))
      .catch(this.handleError);
  }

  /**
   * Get logged in users notifications
   */
  getNotifications(): Promise<any> {
    const lastReadAt = this.lastNotificationRead ? this.lastNotificationRead.toISOString() : '';
    const url = `${this.apiUrl}users/${this._user.value.id}/notifications?lastReadAt=${lastReadAt}`;
    this.lastNotificationRead = new Date();
    return this.http
      .get<any[]>(url, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => {
        const notification = response;
        this.lastNotification = notification;

        return notification;
      })
      .catch(this.handleError);
  }

  /**
   * Get logged in users notifications
   */
  getTickets(): Promise<any[]> {
    return this.http
      .get<any[]>(this.apiUrl + `users/${this._user.value.id}/tickets`, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  getTicket(ticketId: number): Promise<Ticket> {
    return this.http
      .get<any[]>(this.apiUrl + `tickets/${ticketId}`, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => new Ticket(response))
      .catch(this.handleError);
  }

  submitMessageToTicket(ticketId: number, message: string): Promise<any> {
    return this.http
      .post(this.apiUrl + `tickets/${ticketId}/messages`, { message: message }, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => new Ticket(response))
      .catch(this.handleError);
  }

  /**
  * submit an issue ticket
  */
  postTicket(ticket: Ticket): Promise<any[]> {
    const postURL = this.apiUrl + `users/${this._user.value.id}/tickets`;
    return this.http
      .post(postURL, ticket.serialise(), { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => <any>response)
      .catch(this.handleError);
  }


  patchAssetRef(assetId: number, targetOrgId: number, value: string): Promise<any> {
    if (!assetId || !targetOrgId) {
      console.log('error');
      return;
    }
    const postURL = this.apiUrl + `assets/${assetId}`;
    return this.http
      .patch(postURL,
        {
          item: 'ref',
          changes: [{
            orgId: this._user.value.org.id,
            value,
            targetOrgId
          }]
        }, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  patchAssetSettings(asset: Asset): Promise<any> {
    const postURL = this.apiUrl + `assets/${asset.id}`;
    return this.http
      .patch(postURL,
        {
          item: 'settings',
          changes: asset.settings
        }, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  patchAssetAPI(assetId: number, api: Api): Promise<any> {
    if (!api.payload) {
      console.log('error');
      return;
    }
    const postURL = this.apiUrl + `assets/${assetId}`;
    return this.http
      .patch(postURL,
        {
          item: 'apis',
          changes: [{
            title: api.title,
            url: api.url,
            orgId: this._user.value.org.id,
            payload: api.payload,
            authorization: api.authorization,
            id: api.id
          }]
        }, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  /**
   * Get orgs partners
   */
  getPartners(): Promise<PartnerInterface[]> {
    return this.http.get<any[]>(this.getUserAPI() + `/partners`, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  /**
   * Get a user
   */
  getUser(id: number, dataset?: string): Promise<User> {
    const options = { headers: this.getHttpHeaders() };
    if (!id) {
      console.log('getUser -- no user id');
      return;
    }
    return this.http
      .get<any[]>(this.apiUrl + `users/${id}?dataset=${dataset || ''}`, options)
      .toPromise()
      .then(response => new User(response[0]))
      .catch(this.handleError);
  }

  /**
   * Get stats
   *
   * @param action
   */
  GetStats(action: string): any {
    return this.http.get<any[]>(this.apiUrl + `stats/${action}`, { headers: this.getHttpHeaders() })
      .toPromise()
      .then((response) => {

        return response;
      });
  }

  postExporter(exporter: Exporter): Promise<any> {
    return this.http.post(this.apiUrl + `exporter`, exporter.forExport(), { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  startAssetSession(assetId: number): Promise<any> {
    return this.http.post(this.apiUrl + `assets/${assetId}/session`, {}, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  assetSessionAction(assetId: number, sessionId: string, action: string, payload?: any): Promise<any> {
    return this.http.post(this.apiUrl + `assets/${assetId}/session/${sessionId}`, { action, payload }, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  /**
   * Get stats
   * @param type
   * @param startsAt
   * @param endsAt
   * @param view 'month' | 'week' - not used (just uses dates currently)
   */
  getEngagementController(type: string, startsAt: Date, endsAt: Date, view?: string, wantsAdjustment?: boolean): any {
    let query = `/partners/engagement?type=${type}&startsAt=`;
    if (startsAt && endsAt) {
      query += this.dbDate(startsAt) + `&endsAt=`;
      query += this.dbDate(endsAt);
    }
    if (wantsAdjustment) {

    } else {
      // Remove adjustement
      query += '&adjustBy=0';
    }
    return this.http.get<any[]>(this.getUserAPI() + query, { headers: this.getHttpHeaders() })
      .toPromise()
      .then((response) => {

        return response;
      });
  }

  /**
     * Get a site floorplan, the assets and areas
     */
  getAllSiteFloorplans(): Promise<{ plans: SitePlan[], cats: ShapeCategories }> {
    const url = `/sites/floorplans?cats=1`;

    return this.http.get<any>(this.getUserAPI() + url, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => {
        return {
          plans: response.plans.map(sitePlan => new SitePlan(sitePlan)),
          cats: new ShapeCategories(response.cats)
        };
      })
      .catch(this.handleError);
  }



  /**
   * Get site floorplans
   * @param siteId
   */
  getSiteFloorplans(siteId: number): Promise<SitePlan[]> {
    const url = `/sites/${siteId}/floorplans`;

    return this.http.get<any[]>(this.getUserAPI() + url, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => {
        const body = response.sort(function (a, b) {
          return a.order - b.order;
        });

        return body.filter(s => s.isActive).map(sitePlan => new SitePlan(sitePlan));
      })
      .catch(this.handleError);
  }

  getSiteReview(reviewId: number): Promise<SiteReview> {
    const url = this.getUserAPI() + `/reviews/${reviewId}`;

    return this.http.get<any>(url, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => {
        return new SiteReview(<any>response);
      })
      .catch(this.handleError);
  }

  getSiteReviews(): Promise<GetSiteReviewsInterface[]> {
    const url = this.getUserAPI() + `/reviews`;

    return this.http.get<any[]>(url, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.handleError);
  }

  getSiteReviewAssetTelemetry(reviewId, assetId, startsAt, endsAt): Promise<SiteReviewAssetTelemetryInterface[]> {
    const url = this.getUserAPI() + `/reviews/${reviewId}/assets/${assetId}`;
    const querystring = `startsat=${startsAt}&endsat=${endsAt}`;
    return this.http.get<any[]>(`${url}?${querystring}`, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  postSiteReviewFindings(review: SiteReview): Promise<any> {
    const url = this.getUserAPI() + `/reviews/${review.id}/findings`;

    return this.http.post(url, review.findings, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  postReview(review: Review): Promise<any> {
    let url;
    if (review instanceof SiteReview) {
      url = this.getUserAPI() + `/sites/${review.site.id}/reviews`;
    } else {
      console.error('NOT_SUPPORTED');

      return;
    }

    return this.http.post(url, review, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => {

        return response;
      })
      .catch(this.handleError);
  }

  getCms(): Promise<CMSItem[]> {
    const url = this.apiUrl + `public/cms`;
    return this.http.get<any[]>(url, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => {
        const data = response[0];

        return data.parent.map(cmsData => {
          const cms = new CMSItem(cmsData);
          cms.blocks = data.child
            .filter(childData => childData.cms_id === cms.id).
            map(child => new CmsItemBlock(child));
          return cms;
        });

      })
      .catch(this.handleError);
  }

  apiController(method: string, path: string, data?: object): Promise<any> {
    return new Promise((resolve, reject) => {
      switch (method) {
        case 'get':
          return this.http.get<any[]>(this.apiUrl + `api?p=${path}`, { headers: this.getHttpHeaders() })
            .toPromise()
            .then(response => resolve(response));

        case 'post':
          return this.http.post(this.apiUrl + `api?p=${path}`, data, { headers: this.getHttpHeaders() })
            .toPromise()
            .then(response => resolve(response));
      }
    });
  }

  getArrayOfSiteRAGS3(siteId: number, startYear: number, startMonth: number, months = 1): Promise<S3SiteRag> {
    return new Promise((resolve, reject) => {

      const fn: any[] = [];
      let year = startYear;
      let month = startMonth;
      let to;
      for (let index = 0; index < months; index++) {
        const dt = new Date(year, month - 1);
        if (+dt > +new Date(new Date().getFullYear(), new Date().getMonth())) {
          break;
        }
        // Store date as the last range retrieved
        to = moment(new Date(+dt));
        fn.push(this.getSiteRAGS3(siteId, year, month));

        month++;
        if (month > 12) {
          year++;
          month = 1;
        }
      }

      Promise.all(fn)
        .then(fnResults => {
          const from = moment(new Date(startYear, startMonth - 1));

          const assets: any = {};
          fnResults.filter(result => result !== null)
            .forEach(result => {
              result.assets.forEach(row => {
                let dayCount = 0;
                if (!assets[row.assetId]) {
                  assets[row.assetId] = [];
                }
                const dt = moment(new Date(row.year, row.month - 1));

                row.days.forEach(day => {
                  dt.set('date', day.day);
                  dayCount++;
                  // If a future date and we already have over a month of data ignore
                  if (dt.diff(moment(), 'days') < 0 || dayCount <= 31) {
                    const payload = { date: dt.toDate(), totals: day.totals }
                    assets[row.assetId].push(payload);
                  }
                });
              });
            });

          const payload = {
            from: from.toDate(),
            to: to.endOf('month').toDate(),
            assets
          };

          console.log(payload);

          const s3Site = new S3SiteRag(payload);

          resolve(s3Site);
        })
        .catch(e => alert(e));
    });
  }

  getSiteRAGS3(siteId: number, year: number, month: number): Promise<S3SiteRag> {

    let url = `https://4d-graph-data.s3.eu-west-2.amazonaws.com/sites/${siteId}/${year}/rag-${month}`;

    url += '.json';
    return this.http
      .get<any[]>(url)
      .toPromise()
      .then(data => {
        const s3Rag = new S3SiteRag(data);
        // console.log(s3Rag);
        return s3Rag;
      })
      .catch(() => Promise.resolve(null));
  }

  getChartS3(assetId: number, year: number, month: number, day: number = null): Promise<any[]> {
    return new Promise((resolve, reject) => {

      let url = `https://4d-graph-data.s3.eu-west-2.amazonaws.com/assets/${assetId}/${year}/${month}`;
      if (day) {
        url += `-${day}`;
      }
      url += '.json';
      return this.http
        .get<any[]>(url)
        .toPromise()
        .then(data => {
          data = data
            .filter(item => !!item)
            .map(item => {
              if (day) {
                return { v: item.v, date: new Date(item.d * 1000) };
              } else {
                return {
                  avg: item.a,
                  max: item.x,
                  min: item.m,
                  date: new Date(year + '-' + this.padLeft(month) + '-' + this.padLeft(item.d) + ' ' + this.padLeft(item.h) + ':00:00')
                };
              }
            });
          resolve(data);
        })
        .catch(e => resolve([]));
    });
  }

  async dataPause() {
    return new Promise(async (resolve, reject) => {
      setTimeout(() => {
        resolve(null);
      }, 50);
    });
  }

  async getChartS3LatestTrueParallel(assetId: number, dstart: moment.Moment, dend: moment.Moment): Promise<any[]> {
    let dt = dstart.clone();
    return new Promise(async (resolve, reject) => {
      const fns = [];
      // Allow for the day itself
      const days = dend.diff(dstart, 'days') + 1;
      if (days < 0) {
        alert('-days');
        return;
      }
      for (let idxDay = 0; idxDay < days; idxDay++) {

        fns.push(this
          .getChartS3(assetId, dt.year(), dt.month() + 1, dt.date()));

        await this.dataPause();
        dt.add(1, 'days');
      }

      Promise.all(fns)
        .then(data => {
          let results = [];
          for (let index = 0; index < data.length; index++) {
            results = results.concat(data[index]);
          }
          resolve(results.sort((a, b) => +a.date - +b.date));
        });
    });
  }

  getChartS3LatestTrue(assetId: number, numberOfMonths: number): Promise<any[]> {
    let dt = moment();
    let data = [];
    return new Promise((resolve, reject) => {

      const getData = () => {

        // Get previous day
        if (dt.date() > 1) {
          dt.subtract(1, 'days');
        } else {
          // will roll back a month
          dt.subtract(1, 'days');
          //dt = dt.subtract(1, 'months');
          numberOfMonths--;
        }

        this
          .getChartS3(assetId, dt.year(), dt.month() + 1, dt.date())
          .then(dayData => {
            dayData = dayData
              .filter(item => !!item)
              .map(item => {
                return { v: item.v, date: new Date(item.d * 1000) };
              });

            if (dayData.length) {
              data = [...data, ...dayData];
            }
            if (numberOfMonths) {
              getData();
            } else {
              data.sort((a, b) => +a.date - +b.date);
              resolve(data);
            }
          });
      };

      getData();
    });
  }

  getChartS3LatestHourly(assetId: number, numberOfMonths: number): Promise<any[]> {
    let dt = moment();
    let data = [];
    return new Promise((resolve, reject) => {

      const getData = () => {
        dt = dt.subtract(1, 'months');
        numberOfMonths--;
        this
          .getChartS3(assetId, dt.year(), dt.month() + 1)
          .then(monthData => {

            if (monthData.length) {
              data = [...data, ...monthData];
            }
            if (numberOfMonths) {
              getData();
            } else {
              data.sort((a, b) => +a.date - +b.date);
              resolve(data);
            }
          });
      };

      getData();
    });
  }

  postAsset(assetId, body): Promise<any> {
    const postURL = this.apiUrl + `assets/${assetId}`;
    return this.http
      .post(postURL,
        body, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  getAssetAnnotations(assetId: number, fromId: number, toId: number): Promise<any> {
    const params = new HttpParams()
      .set('vf', String(fromId <= toId ? fromId : toId))
      .set('vt', String(toId >= fromId ? toId : fromId));

    return this.http
      .get<any[]>(this.apiUrl + 'assets/' + assetId + '?annotations=1', {
        headers: this.getHttpHeaders(),
        params
      })
      .toPromise()
      .then(response => response.map(a => {
        return { ...a, createdAt: new Date(a.createdAt) };
      }))
      .catch(this.handleError);
  }

  getComplianceConfigItem(id: number): Promise<ComplianceItem> {
    const params = new HttpParams()
      .set('oid', String(this._user.value.org.id))
      .set('cid', String(id));

    return this.http.get<any>(this.apiUrl + 'compliance', {
      headers: this.getHttpHeaders(),
      params
    })
      .toPromise()
      .then(r => {
        const complianceCollection = new ComplianceItem({ ...r.master, assets: r.assets });

        return complianceCollection;
      });
  };


  getComplianceConfig(): Promise<ComplianceCollection> {
    const params = new HttpParams()
      .set('oid', String(this._user.value.org.id));

    return this.http.get<any>(this.apiUrl + 'compliance', {
      headers: this.getHttpHeaders(),
      params
    })
      .toPromise()
      .then(r => {
        const complianceCollection = new ComplianceCollection(r);

        return complianceCollection;
      });
  };


  postComplianceConfig(complianceItem: ComplianceItem): Promise<any> {
    const params = new HttpParams()
      .set('oid', String(this._user.value.org.id));

    const body = complianceItem.serialise();

    return this.http.post<any>(this.apiUrl + 'compliance', body, {
      headers: this.getHttpHeaders(),
      params
    })
      .toPromise()
      .then(r => r);
  };

  getCompliance(df: Date, dt: Date): Promise<ComplianceCollection> {
    const params = new HttpParams()
      .set('df', String(this.dbDate(df >= dt ? dt : df)))
      .set('dt', String(this.dbDate(df >= dt ? df : dt)))
      .set('oid', String(this._user.value.org.id));

    return this.http.get<any>(this.apiUrl + 'compliance', {
      headers: this.getHttpHeaders(),
      params
    })
      .toPromise()
      .then(r => {
        r.df = df;
        r.dt = dt;
        const complianceCollection = new ComplianceCollection(r);

        return complianceCollection;
      });
  }

  async postQuery(body: IPostQuery): Promise<any> {
    return this.http.post<any>(this.apiUrl + 'query', body, {
      headers: this.getHttpHeaders()
    })
      .toPromise()
      .then(r => r);
  }

  getElogbooks(qs: string): Promise<any> {
    qs = 'oid=' + this.getUserOrg().id + '&' + qs;
    return this.http.get<any>(this.apiUrl + `integrations/elogbooks?${qs}`, {
      headers: this.getHttpHeaders()
    })
      .toPromise()
      .then(r => r);
  }

  postElogbooks(data, qs: string): Promise<any> {
    qs = 'oid=' + this.getUserOrg().id + '&' + qs;
    return this.http.post<any>(this.apiUrl + `integrations/elogbooks?${qs}`, data, {
      headers: this.getHttpHeaders()
    })
      .toPromise()
      .then(r => r);
  }

  /**
   * Get buildings
   */
  getBuildings(siteId?: number, qs?: string): Promise<any[]> {
    let url: string;
    if (siteId) {
      url = `sites/${siteId}/buildings`;
    } else {
      url = `buildings`;
    }
    if (qs) {
      url += '?' + qs;
    }

    return this.http.get<any>(this.apiUrl + url, {
      headers: this.getHttpHeaders()
    })
      .toPromise()
      .then(r => r);
  }

  getSignedUrl(target, id, title, hasPrivacyConcerns, filename, contentType): Promise<string> {
    const url = `${this.apiUrl}uploader`;
    const params = new HttpParams()
      .set('contentType', contentType)
      .set('targetid', id)
      .set('title', title)
      .set('filename', filename)
      .set('target', target);

    return this.http.get(url, { headers: this.getHttpHeaders(), params })
      .toPromise()
      .then(response => {
        console.log(response);
        const apiResponse: any = <any>response;

        return apiResponse.url;
      });
  }

  postUploader(title: string, hasPrivacyConcerns, target: string, id, contentType, filename, file): Promise<any> {
    return new Promise((res, rej) => {
      this.getSignedUrl(target, id, title, hasPrivacyConcerns, filename, contentType)
        .then(url => {
          const xhr = new XMLHttpRequest();
          xhr.open('PUT', url);
          xhr.setRequestHeader('x-amz-acl', 'public-read');
          xhr.onload = function () {
            if (xhr.status === 200) {
              res(xhr.response);
            }
          };
          xhr.send(file);

          return;
        });
    });
  }

  /**
   * Return a MySQL date format (YYYY-MM-DD), pass a Date or string.
   * @param date
   */
  dbDate(date: any) {
    if (date === null || typeof date === 'undefined') {
      return null;
    }
    if (typeof date === 'string') {
      date = new Date(date);
    }

    return date.getFullYear() + '-' +
      ('00' + (date.getMonth() + 1)).slice(-2) + '-' +
      ('00' + date.getDate()).slice(-2);
  }

  dbDateTime(date: Date) {
    if (date === null) {
      return null;
    }

    return date.getFullYear() + '-' +
      ('00' + (date.getMonth() + 1)).slice(-2) + '-' +
      ('00' + date.getDate()).slice(-2) + ' ' +
      ('00' + date.getHours()).slice(-2) + ':' +
      ('00' + date.getMinutes()).slice(-2) + ':' +
      ('00' + date.getSeconds()).slice(-2);
  }

  padLeft(nr, len = 2) {
    return Array(len - String(nr).length + 1).join('0') + nr;
  }

  generateUUID() {
    let d = new Date().getTime(),
      d2 = (performance && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      let r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
  }

  returnBodyFromResponse(response) {
    const body = response;
    if (body && body.errorMessage && body.errorMessage.indexOf('Task timed out')) {

      if (this._user.value.role === 'admin') {
        // this._growl.next({ severity: 'error', summary: 'Task timed out', detail: body.errorMessage });
      }
      console.error({
        severity: 'error',
        summary: 'Task timed out',
        detail: body.errorMessage
      });

      return null;
    }

    return body;
  }

  public handleError(error: any): Promise<any> {
    console.log(`*************> An error occurred ${error}`);
    console.log(error.status);

    return Promise.reject(error);
  }
}

export interface AssetAPICollection {
  asset: Asset;
  apis: any[];
  actions: any[];
}

export interface PartnerInterface {
  siteCount: number;
  org_id: number;
  title: string;
  activity: number; // 0 -> 100%
  engagementCount: number;
  engagementStatus: string;
}

export interface GetGatewaysQueryInterface {
  dataset: string;
}

export interface ActivatedUserInterface {
  errorMessage: string;
  email: string;
  token: string;
}

export interface PostRuleInterface {
  title?: string;
  notifications?: RuleNotification[];
  triggerAfter?: number;
  // Allow for single rule condition update
  condition?: RuleCondition;
  // Allow all conditions update
  conditions?: RuleCondition[];
  instructions?: string;
  ruleTimeRanges?: RuleTimeRange[];
  actions?: RuleAction[];
  severity?: string;
  includeSiteNotifications?: boolean;
  autoResolve?: string;
  bankHoliday?: string;
  gatewayId?: string;
  integration?: any;
  groupings?: Grouping[];
}

export interface GetSitePlanAssetHistory {
  values: any[];
  rags?: any[];
}

export interface GetSiteReviewsInterface {
  id: number;
  createdBy: User;
  createdAt: Date;
  deliveredAt: Date;
  reviewedBy: User;
  org: Org;
  site: Site;
  assetCount: number;
  findingsCount: number;
}

export interface SiteReviewAssetTelemetryInterface {
  i: number;
  v: any;
  d: Date;
}

export interface CMSListInterface {
  parent: any[];
  child: any[];
}

export interface PostAlarmInterface {
  amber_min: boolean;
  amber_max: boolean;
  red_min: boolean;
  red_max: boolean;
}

export interface GetSetpointsForAssetAndRangeInterface {
  setpoints: any[];
  rules: any[];
}

export interface APIAdminInterface {
  usage: any;
  apis: any[];
  assets: any[];
  apikeys: any[];
  ia: any[];
}

export interface GetTelemetryInterface {
  v: string | number;
  d: string;
  i: number;
}

export interface ICompliance {
  groups: IComplianceGroup[];
  values: any[];
  assets: any[];
}

export interface IComplianceGroup {
  checkEvery: 'month' | 'week' | 'day';
  createdAt: string | Date;
  id: number;
  title: string;
  org_id: number;
  values?: any[];
  sites?: any[];
  df?: Date;
  dt?: Date;
  compliance?: {
    noncompliant: number,
    compliant: number
  }
}

export interface IPostQuery {
  action: string;
  t: string;
  id?: any;
  df?: string;
  dt?: string;
  changes?: any;
  oid?: number;
}

export interface IGetOrgTelemetryResponse {
  identifier: string,
  assetId: number,
  assetType: number,
  assetTypeTitle?: string,
  purposeId: number,
  gatewayId: string,
  gatewayOnline: 'online' | 'offline' | string,
  gatewayTitle: string,
  max: number,
  min: number,
  rag: 'red' | 'amber' | 'green' | undefined,
  siteId: number,
  siteTitle: string,
  title: string,
  updatedAt: Date,
  value: number,
  setting?: string;
}

export interface IOrgTelemetry {
  assetId: number,
  assetType: number,
  purposeId: number,
  gatewayId: string,
  gatewayOnline: boolean,
  gatewayTitle: string,
  max: number,
  min: number,
  rag: string,
  siteId: number,
  siteTitle: string,
  title: string,
  updatedAt: Date,
  value: number
};
