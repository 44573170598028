<section *ngIf="theme === 'classic'">
  <header>
    <div class="mb-1">
      <h2>{{ headerTitle }}</h2>
      <small
        >{{ dates.start | amDateFormat: 'DD MMMM YYYY' }}
        to
        {{ dates.end | amDateFormat: 'DD MMMM YYYY' }}</small
      >
    </div>
    <div>
      <!--<img *ngIf="logo" [src]="logo | safeUrl">-->
    </div>
  </header>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th>Address</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="address">
            <p>{{ building.site.address.address1 }}</p>
            <p>{{ building.site.address.address2 }}</p>
            <p>{{ building.site.address.addressTown }}</p>
            <p>{{ building.site.address.addressCounty }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="opening-hours">
    <p class="sub-title">Opening hours</p>
    <table class="table">
      <thead>
        <tr>
          <th>Monday</th>
          <th>Tuesday</th>
          <th>Wednesday</th>
          <th>Thursday</th>
          <th>Friday</th>
          <th>Saturday</th>
          <th>Sunday</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td *ngFor="let day of building.openingHours.hours">
            <span *ngIf="!day.isClosed">{{ day.from }} - {{ day.to }}</span>
            <span *ngIf="day.isClosed">Closed</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>

@if (theme !== 'classic') {
  <section class="default pagebreakafter">
    <main>
      <div class="logo">
        <img [src]="FOURD_LOGO | safeUrl" alt="logo" />
      </div>
      <div>
        <h2>Energy Report, {{ dates.start | amDateFormat: 'DD MMMM YYYY' }} to {{ dates.end | amDateFormat: 'DD MMMM YYYY' }}</h2>
      </div>
      <div>
        <h1>{{ building.title }}</h1>
        <p>{{ building.site.address.addressTown }} {{ building.site.address.addressCounty }} {{ building.site.address.addressPostcode }}</p>
        <p></p>
        <p>&nbsp;</p>
      </div>
      <div>
        <div>
          <div>
            @if (markers) {
              <app-map [center]="center" [zoom]="6" [isStatic]="true" [style]="'mapbox://styles/mapbox/light-v10'" [markers]="markers"></app-map>
            }
          </div>
        </div>
      </div>
    </main>
  </section>
}
