import number from "./number";
export default function (values, valueof) {
  var n = values.length,
    m = 0,
    i = -1,
    mean = 0,
    value,
    delta,
    sum = 0;
  if (valueof == null) {
    while (++i < n) {
      if (!isNaN(value = number(values[i]))) {
        delta = value - mean;
        mean += delta / ++m;
        sum += delta * (value - mean);
      }
    }
  } else {
    while (++i < n) {
      if (!isNaN(value = number(valueof(values[i], i, values)))) {
        delta = value - mean;
        mean += delta / ++m;
        sum += delta * (value - mean);
      }
    }
  }
  if (m > 1) return sum / (m - 1);
}