import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-data-terms',
    templateUrl: './data-terms.component.html',
    styleUrls: ['./data-terms.component.css'],
    standalone: false
})
export class DataTermsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
