import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Building } from 'app/classes/building';
import { CollectionAsset, CollectionForAssets } from 'app/classes/collection-for-assets';
import { InsightFF } from 'app/classes/insight-ff';
import { DialogInsightsWaitComponent } from 'app/dialogs/dialog-insights-wait/dialog-insights-wait.component';
import { APIService } from 'app/shared/api.service';
import { InsightsService, IWaitForBuildingHourlyJobFF } from 'app/shared/insights.service';

import { Subscription } from 'rxjs';
import { InsightFFContainer } from "../../../classes/insight-ff-container";
import moment from 'moment';
@Component({
    selector: 'app-insights-ff-report',
    templateUrl: './insights-ff-report.component.html',
    styleUrls: ['./insights-ff-report.component.css'],
    standalone: false
})
export class InsightsFfReportComponent implements OnInit {

  FOURD_LOGO = '/assets/svg/4d_logo_light.svg';

  @Input()
  clearCache: boolean;

  @Input()
  startDate: Date;

  @Input()
  building: Building;

  @Input()
  collections: CollectionAsset[];

  @Input()
  useOrgLogo = true;

  @Output()
  onReportGenerated: EventEmitter<InsightFF> = new EventEmitter();

  insight = new InsightFF();
  insightPreviousMonth: InsightFF;

  insightContainer: InsightFFContainer;
  isReady = false;
  hasData = false;
  isRunning = false;
  uuid: string;
  logo: string;
  subscriptions: Subscription[] = [];
  headerTitle: string;
  dialogRef: any;
  generatedAt: Date;
  weather: { d: Date, icon: string }[];

  constructor(private apiService: APIService, private insightsService: InsightsService) { }

  ngOnInit(): void {

    this.getFootfallInsights();
    if (this.useOrgLogo) {
      const userOrg = this.apiService.getUserOrg();
      this.setLogo(userOrg.settings.theme_menu_logo);
    } else {
      this.setLogo(this.FOURD_LOGO);
    }
  }

  setLogo(logoKey: string) {
    if (logoKey.indexOf('/') !== -1) {
      // a path was passed.
      this.logo = logoKey;
    } else {
      this.logo = `https://s3.eu-west-2.amazonaws.com/4d-documents/${logoKey}`;
    }
  }

  async getFootfallInsights() {
    const results = await this.insightsService.getFootfallInsights(this.building.id, this.collections.filter(c => c.selected).map(c => c.id), this.startDate, this.building.openingHours);

    console.log(results);

    const { title, floorplans, assets } = results;
    const { start, end } = results.current.dates;
    const { start: startP, end: endP } = results.previous.dates;

    const { ranges, totals, collection, weather } = results.current;
    const { ranges: rangesP, totals: totalsP, collection: collectionP, weather: weatherP } = results.previous;

    this.weather = weather.map(w => { return { d: new Date(w.timestamp), icon: w.icon } });

    this.insight.setConfiguration({ start: moment(start), end: moment(end), assets, ranges, title, totals });

    this.insight.floorplans = floorplans;

    this.insight.generatedAt = this.generatedAt;
    this.insight.collections = this.collections;
    this.headerTitle = this.insight.configuration.title;
    this.insight.setTelemetry(collection, floorplans);

    if (totalsP.telemetryCount > 0) {
      this.insightPreviousMonth = new InsightFF();
      this.insightPreviousMonth.generatedAt = this.generatedAt;
      this.insightPreviousMonth.collections = collectionP;
      this.insightPreviousMonth.setConfiguration({ start: moment(startP), end: moment(endP), assets, ranges: rangesP, title, totals: totalsP });
    } else {
      this.apiService.toastSuccess('No footfall for previous month', '');
    }

    this.insightContainer = new InsightFFContainer(this.insight, this.insightPreviousMonth);

    this.isReady = true;
    this.hasData = true;

    setTimeout(() => {
      const id = 'report';
      const yOffset = -410;
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    }, 10);
    this.onReportGenerated.emit(this.insight);
  }

}
