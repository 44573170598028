import { Component } from '@angular/core';
import { SetpointsService } from 'app/shared/setpoints.service';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogSetpointTemplateCategoryComponent } from "app/dialogs/dialog-setpoint-template-category/dialog-setpoint-template-category.component";
import { SetpointTemplateCategory } from 'app/classes/setpoint-template-category';

@Component({
    selector: 'app-org-setpoints',
    templateUrl: './org-setpoints.component.html',
    styleUrls: ['./org-setpoints.component.css'],
    providers: [DialogService],
    standalone: false
})
export class OrgSetpointsComponent {

  categories: SetpointTemplateCategory[];

  constructor(public setpointsService: SetpointsService, public dialogService: DialogService, public messageService: MessageService) {
    this.get();
  }

  get() {
    this.setpointsService.getTemplateCategories().then(categories => this.categories = categories);
  }

  create() {
    const dialogRef = this.dialogService.open(DialogSetpointTemplateCategoryComponent, {
      header: 'Create Category',
      width: '90%',
      data: { setpointTemplateCategory: new SetpointTemplateCategory() }
    });
    dialogRef.onClose.subscribe((setpointTemplateCategory: SetpointTemplateCategory) => {
      if (setpointTemplateCategory) { }
      this.get();
    });
  }
}
