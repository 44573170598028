import { Component, EventEmitter, OnInit, Output, Signal, computed, effect, input, signal } from '@angular/core';
import { ComplianceItem } from 'app/classes/compliance/compliance-item';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { APIService } from 'app/shared/api.service';
import { ComplianceService } from "app/shared/compliance.service";
import { Asset } from 'app/classes/asset';

@Component({
    selector: 'app-org-compliance-item',
    templateUrl: './org-compliance-item.component.html',
    styleUrls: ['./org-compliance-item.component.css'],
    standalone: false
})
export class OrgComplianceItemComponent implements OnInit {

  complianceItemId = input.required<number>();
  complianceItem = signal<ComplianceItem>(null);
  asset = signal<Asset>(null);

  @Output() onGoBack = new EventEmitter<boolean>();

  constructor(private router: Router, private route: ActivatedRoute, private apiService: APIService, private complianceService: ComplianceService) {
    effect(() => {
      const id = this.complianceItemId();
      if (id && !this.complianceItem()) {
        this.get();
      }
    });
  }

  get() {
    this.apiService.getComplianceConfigItem(this.complianceItemId()).then(this.complianceItem.set);
  }

  ngOnInit(): void {

  }

  async removeAsset() {
    this.apiService.toastSuccess('removing asset..', '');
    await this.complianceService.removeAssets(this.complianceItemId(), [this.asset().id]);
    this.apiService.toastSuccess('Asset removed.', '');
    this.get();
    this.asset.set(null);
  }

  assetClick(asset: Asset) {
    this.asset.set(asset);
  }

  async assetsSelected(event: any) {
    console.log(event);
    const assets = Object.keys(event.assets).map(a => +a);
    console.log(assets);
    if (!assets?.length) {
      console.log('no assets selected');
      return;
    }
    await this.complianceService.updateAssets(this.complianceItem().id, assets);
    this.apiService.getComplianceConfigItem(this.complianceItem().id).then(compliance => this.complianceItem.set(compliance));
  }

  disable() {

  }

  goBack() {
    console.log('back');
    this.onGoBack.emit(true)
  }
}
