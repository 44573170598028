import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'amDateFormat',
    standalone: false
})
export class AmDateFormatPipe implements PipeTransform {

  transform(value: any, format: string): string {
    return moment(value).format(format);
  }

}
