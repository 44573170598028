import { Component, signal } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { AssetService } from 'app/shared/asset.service';
import { sign } from 'crypto';

@Component({
    selector: 'app-energy-home',
    templateUrl: './energy-home.component.html',
    styleUrl: './energy-home.component.css',
    standalone: false
})
export class EnergyHomeComponent {

  asset = signal<Asset>(null);

  constructor(private assetService: AssetService) {
    //  assetService.getAsset(24768).then(a => this.asset.set(a));
  }

}
