import { Component, OnDestroy, signal } from '@angular/core';
import { DataTable, IDataClassConfig } from 'app/classes/data-table/data-table';
import { Org } from 'app/classes/org';
import { User } from 'app/classes/user';
import { APIService } from 'app/shared/api.service';
import { OrgService } from 'app/shared/org.service';

@Component({
  selector: 'app-org-users-activity',
  standalone: false,

  templateUrl: './org-users-activity.component.html',
  styleUrl: './org-users-activity.component.css'
})
export class OrgUsersActivityComponent implements OnDestroy {

  dataTable: DataTable;
  dataTableConfig: IDataClassConfig;
  orgUsers: User[];
  data: any[];
  isloading = signal<boolean>(false);
  timer: NodeJS.Timeout;
  users: any[];
  view = signal<'org' | 'all'>('org');
  orgs: { id: number, stitle: string }[];
  isAdmin = signal<boolean>(false);

  constructor(private orgService: OrgService, private apiService: APIService) {
    this.isAdmin.set(this.apiService.isAdmin());
    this.setDataTableConfig();
    this.get();
  }

  setDataTableConfig() {
    if (!this.isAdmin()) {
      this.view.set('org');
    }
    switch (this.view()) {
      case 'org':
        this.dataTableConfig = {
          "userId": { valueType: "number", title: 'User Id', align: 'right', width: 100 },
          "name": { valueType: "string", title: 'Name', align: 'left', width: 300 },
          "url": { valueType: "string", title: "URL", align: 'left' },
          "createdAt": { valueType: "date", title: 'Date', align: 'left', isHidden: false }
        };
        break;
      case 'all':
        this.dataTableConfig = {
          "orgName": { valueType: "string", title: 'Org', align: 'left', width: 100 },
          "userId": { valueType: "number", title: 'User Id', align: 'right', width: 100 },
          "name": { valueType: "string", title: 'Name', align: 'left', width: 300 },
          "url": { valueType: "string", title: "URL", align: 'left' },
          "createdAt": { valueType: "date", title: 'Date', align: 'left', isHidden: false }
        };
        break;
    }
  }

  get() {
    let data = [];
    this.isloading.set(true);

    this.orgService.getOrgUserActivity(this.view()).then(data => {
      this.orgs = data.orgs;
      this.data = data.urls;
      this.users = data.users;
      if (this.data?.length) {
        // Inject name into data
        this.data.forEach(item => {
          const user = this.users.find(u => u.id === item.userId);
          if (user) {
            item.name = user?.name;
            item.orgName = this.orgs.find(o => o.id === user.orgid)?.stitle;
          }
        });
      }

      this.dataTable = new DataTable({ data: this.data, sortedColumns: null }, this.dataTableConfig);
      this.isloading.set(false);
    });
  }

  onRowClick(ev: any) {
    console.log(ev);
  }

  refresh() {
    if (!this.isloading() && !this.timer) {
      this.get();
      const reference = setInterval(() => {
        this.get();
      }, 60000);
      this.timer = reference;
    }
  }

  toggleView() {
    if (this.view() === 'org') {
      this.view.set('all');
    } else {
      this.view.set('org');
    }

    this.data = null;
    this.setDataTableConfig();



    this.get();
  }

  ngOnDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
      console.log('unsubscribed from timer');
    }
  }
}
