import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SetpointSchedule } from 'app/classes/setpoint-schedule';
import { APIService } from 'app/shared/api.service';
import { AssetService } from 'app/shared/asset.service';
import { SetpointsService } from 'app/shared/setpoints.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-setpoint-schedules',
    templateUrl: './setpoint-schedules.component.html',
    styleUrls: ['./setpoint-schedules.component.css'],
    standalone: false
})
export class SetpointSchedulesComponent implements OnInit, OnDestroy {

  schedules: SetpointSchedule[];
  assetTypes: any = {};
  sub: Subscription;
  isLoading: boolean;

  constructor(private setpointService: SetpointsService, private route: Router, private apiService: APIService, private assetService: AssetService) {

  }

  ngOnInit(): void {
    this.get();
  }

  get() {
    this.isLoading = true;
    this.sub = this.assetService.assetTypes.subscribe(assetTypes => {
      if (assetTypes) {
        this.setpointService.getSchedules().then(schedules => {
          assetTypes.forEach(type => this.assetTypes[type.id] = type);
          this.schedules = schedules;
          this.isLoading = false;
        });
      }
    });
  }

  toolbarClick(event) {
    this.navigateTo('templates');
  }

  navigateTo(location: 'templates') {
    switch (location) {
      case 'templates':
        this.apiService.toastSuccess('Select a template to create a schedule', '');
        this.route.navigate(['/setpoints', 'templates']);
        break;
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
