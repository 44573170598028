import { Component, input, OnInit, output, signal } from '@angular/core';
import { BatteryAsset } from 'app/classes/asset';
import { Building } from 'app/classes/building';
import { AssetService } from 'app/shared/asset.service';
import { IAnnotation, RAGType } from "app/interfaces/annotation-and-rags";

@Component({
    selector: 'app-insights-review-battery',
    templateUrl: './insights-review-battery.component.html',
    styleUrl: './insights-review-battery.component.css',
    standalone: false
})
export class InsightsReviewBatteryComponent implements OnInit {

  dateFrom = input.required<Date>();
  dateTo = input.required<Date>();
  building = input.required<Building>();
  batteryAssets = signal<BatteryAsset[]>(null);
  batteryAsset = signal<BatteryAsset>(null);

  onFocusOutDIV = output<IAnnotation>();
  onRAGChange = output<IAnnotation>();

  annotation: IAnnotation;

  constructor(private assetService: AssetService) {

  }

  ngOnInit() {
    this.assetService.getBatterysForBuilding(this.building().id, this.dateFrom(), this.dateTo()).then(batteries => {

      this.batteryAssets.set(batteries);
      if (batteries?.length === 0) {
        return
      }
      const hasAmber = batteries.filter(battery => battery.battery.rag === 'amber').length;
      const hasRed = batteries.filter(battery => battery.battery.rag === 'red').length;
      const hasNoData = batteries.filter(battery => battery.battery.rag === 'no-data').length;

      let annotation: IAnnotation = { text: '', rag: 'green' };

      if (hasNoData > 0) {
        annotation.text = `${hasNoData} batteries have not reported any data and may have failed.`;
        annotation.rag = 'red';
      }

      if (hasRed > 0) {
        if (annotation.text.length) {
          annotation.text += "\n";
        } else {
          annotation.rag = 'red';
        }
        annotation.text = annotation.text + `${hasRed} batteries are near end of life and may fail.`;
      }

      if (hasAmber > 0) {
        if (annotation.text.length) {
          annotation.text += "\n";
        } else {
          annotation.rag = 'amber';
        }
        annotation.text = annotation.text + `${hasAmber} batteries are operational but may need to be replaced soon.`;
      }

      if (annotation.text === '') {
        annotation.text = 'Batteries in good condition.';
      }
      this.annotation = annotation;
      // Update the parent
      this.focusOutDIV();
    });
  }

  setRAG(rag: RAGType) {
    this.annotation.rag = rag;
    this.onRAGChange.emit(this.annotation);
  }

  clickRAG() {
    let rag = this.annotation.rag;
    switch (rag) {
      case 'green':
        rag = 'amber';
        break;
      case 'amber':
        rag = 'red';
        break;
      default: // handles null to green as well as red
        rag = 'green';
        break;
    }
    this.setRAG(rag);
  }

  focusOutDIV() {
    this.onFocusOutDIV.emit(this.annotation);
  }
}

