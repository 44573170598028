import { Component, Input, OnInit } from '@angular/core';
import { InsightFFContainer } from 'app/classes/insight-ff-container';

@Component({
    selector: 'app-insights-ff-compare',
    templateUrl: './insights-ff-compare.component.html',
    styleUrls: ['./insights-ff-compare.component.css'],
    standalone: false
})
export class InsightsFfCompareComponent implements OnInit {

  @Input()
  insightContainer: InsightFFContainer;

  @Input()
  forCollection: number;

  constructor() { }

  ngOnInit(): void {
  }

}
