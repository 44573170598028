import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Org } from 'app/classes/org';
import { Site } from 'app/classes/site';
import { User } from 'app/classes/user';
import { APIService } from 'app/shared/api.service';
import { timer } from 'rxjs';
import { WeatherService } from "app/shared/weather.service";
import { WeatherItem } from 'app/classes/weather-item';
import { StoreService } from 'app/shared/store.service';
import { Gateway } from 'app/classes/gateway';
import { MapboxService } from "app/shared/mapbox.service";
import moment from 'moment';

declare const google: any;
declare const mapboxgl: any;


@Component({
    selector: 'app-org-map',
    templateUrl: './org-map.component.html',
    styleUrls: ['./org-map.component.css'],
    standalone: false
})
export class OrgMapComponent implements OnInit, OnDestroy {

  _sites: Site[];

  @Input()
  public set sites(v: Site[]) {
    this._sites = v;
    if (v && v.length) {
      //this.initOverlays();
    }
  }
  public get sites(): Site[] {
    return this._sites;
  }

  private timerSubscription: any;
  weatherItems: WeatherItem[] = [];
  overlays: any[] = [];
  options: any;
  user: User;
  org: Org;
  infoWindow: any;
  mapBounds: any;
  orgs: Org[];
  orgSub: any;
  boundsSet: boolean;

  site: Site;
  gateway: Gateway;
  isLoadingWeather: boolean;
  currentZoom: number;

  defaults: any = { zoom: 7, hideSiteDetails: false, center: { lat: 0, lng: 0 }, markers: [] };
  mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    styles: [
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#bdbdbd"
          }
        ]
      },
      {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#c7c7c7"
          }
        ]
      },
      {
        "featureType": "landscape.natural",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#dedede"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dadada"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#c9c9c9"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      }
    ]
  };

  map: any;

  constructor(private apiService: APIService, private weatherService: WeatherService, private storeService: StoreService, private mapboxService: MapboxService) { }

  ngOnInit(): void {
    this.options = {
      center: { lat: 53.7057599, lng: -1.912399 },
      zoom: 5
    };

    this.apiService.user.subscribe((user) => {
      if (user) {
        this.user = user;
        this.org = user.org;
        if (this.org.shortTitle === '4DZA') {
          this.options = {
            center: { lat: -25.795390855062664, lng: 29.460312154541516 },
            zoom: 5
          };
        }
      }
    });

    this.infoWindow = new google.maps.InfoWindow();

    this.orgSub = this.storeService.orgs.subscribe(o => {
      this.orgs = o;
    });

  }


  getTodaysWeather() {
    if (this.isLoadingWeather) {
      return;
    }
    this.isLoadingWeather = true;
    const batch = [...new Set(this.sites.filter(s => s.metobsid).map(s => s.metobsid))];
    if (batch.length === 0) {
      return;
    }
    this.getWeather(batch, 'a=get-metobs-batch');
  }

  getWeather(batch, qs: string) {
    this.weatherService.post({ batch }, qs).then(w => {
      this.weatherItems = w;
      this.buildWeatherOverlay();
    });
  }

  startTimer() {
    if (!this.timerSubscription) {
      const dashboardTick = timer(1000);
      this.timerSubscription = dashboardTick.subscribe(t => {
        console.log('org dashboard tick', t);
        if (this.overlays[0]) {
          this.overlays[0].map.fitBounds(this.mapBounds);
        }
      });
    }
  }

  focusSite(site: Site) {
    site.weather = this.weatherItems.find(w => w.metobsid === site.metobsid);
    this.site = site;
  }


  focusWeather(w: WeatherItem) {
    const overlay: any = this.overlays.find((v) => v.metobsid === w.metobsid);

    if (overlay) {

      const sites = this._sites.filter(s => s.metobsid === w.metobsid).map(s => `<li>${s.title}</li>`).join('');

      const dt = moment(w.pulledAt).format('DD/MM/YYYY  HH:mm');
      const snow = w.snow ? `<div><label>snow</label> ${w.snow}mm</div>` : '';
      const rain = w.rain ? `<div><label>rain</label> ${w.rain}mm</div>` : '';

      const content = `
      <div style="background-color:#f0f0f0;text-align:center;border-radius:6px;"><strong>${w.description}</strong></div>
      <div><label>temperature</label> ${w.temp}c</div>
      <div><label>pressure</label> ${w.pressure}</div>
      <div><label>humidity</label> ${w.humidity}</div>
      <div><label>feels like</label> ${w.feelsLike}</div>
      <div><label>visibility</label> ${w.visibility}</div>
      <div><label>wind</label> ${w.wind.speed}m/s ${w.wind.deg}deg ${w.wind.gust || 'no '}gust</div>
      ${rain}
      ${snow}
      <div><label>Taken at</label> ${dt}</div>
      <div><ul>${sites}</ul></div>
      `;
      this.infoWindow.setContent('' + content + '');
      this.infoWindow.open(overlay.map, overlay);
    }
  }

  handleOverlayClick(event) {
    const isMarker = event.overlay.title != undefined;
    if (isMarker) {
      if (event.overlay.metobsid) {
        this.focusWeather(this.weatherItems.find(w => w.metobsid === event.overlay.metobsid));
      } else {
        const site: Site = this.sites.filter((v) => v.id == event.overlay.title.split(' ')[0].substring(1))[0];
        this.focusSite(site);
      }
    }
  }

  buildWeatherOverlay() {
    const bounds = new google.maps.LatLngBounds();

    const markers = [];

    this.weatherItems
      .forEach(w => {
        const myLatLng = new google.maps.LatLng(Number(w.lat), Number(w.lng));

        const icon = {
          url: w.iconUrl,
          scaledSize: new google.maps.Size(50, 50), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(32, 32), // anchor
          opacity: .9
        };

        const gdata = {
          position: { lat: Number(w.lat), lng: Number(w.lng) },
          title: w.description,
          metobsid: w.metobsid,
          icon,
          clickable: false,
          zIndex: 0
        };

        const marker = new google.maps.Marker(gdata);
        marker.setOpacity(.6);
        markers.push(marker);

        bounds.extend(myLatLng);
      });

    this.overlays = [...markers, ...this.overlays];
  }
  /*
    initOverlays() {
      if (!this.map) {
        console.log('NO_GOOGLE_MAP_INSTANCE');
        return;
      }
      this.currentZoom = this.map.getZoom();
      let waitForOrgs = 0;
      if (this.orgs === null) {
        waitForOrgs = 2000;
      }
  
      setTimeout(() => {
        this.overlays = [];
        let bounds;
        if (!this.boundsSet) {
          bounds = new google.maps.LatLngBounds();
        }
  
        const zoom = this.map.getZoom();
        let scale;
        switch (zoom) {
          case 2:
          case 3:
            scale = 1;
            break;
          case 4:
            scale = 2;
            break;
          case 5:
            scale = 3;
            break;
          case 6:
            scale = 4;
            break;
          default:
            scale = 10;
            break;
        }
  
        this.sites
          .filter(site => site.address.addressLat && site.address.addressLong && site.isActive)
          .forEach(site => {
  
            const org = this.orgs.filter(o => o.id === site.org.id)[0];
  
            if (org) {
              const myLatLng = new google.maps.LatLng(Number(site.address.addressLat), Number(site.address.addressLong));
  
              const gdata = {
                position: { lat: Number(site.address.addressLat), lng: Number(site.address.addressLong) },
                title: '#' + site.id + ' ' + site.title + ' ',
                icon: {
  
                  path: google.maps.SymbolPath.CIRCLE,
                  fillColor: "blue",
                  fillOpacity: 0.6,
                  strokeWeight: 0,
                  rotation: 0,
                  scale,
  
                },
                zIndex: 1
              };
  
              this.overlays.push(
                new google.maps.Marker(gdata)
              );
              if (!this.boundsSet) {
                bounds.extend(myLatLng);
              }
            }
          });
        this.getTodaysWeather();
        setTimeout(() => {
          if (this.sites.length > 1 && this.map && !this.boundsSet) {
            // Onl fit bounds if there is 2 or more sites
            this.map.fitBounds(bounds);
            this.boundsSet = true;
          }
        }, 100);
      }, waitForOrgs);
  
    }

  setMap(event) {
    this.map = event.map;
    console.log('ZOOM', this.map.getZoom());
  }

  zoomChanged() {
    console.log('ZOOM', this.map.getZoom());
    if (this.map.getZoom() !== this.currentZoom) {

      this.initOverlays();
    }
  }
  */
  gatewaySelected(gateway: Gateway) {
    this.gateway = gateway;
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      console.log('unsubscribed from timer');
      this.timerSubscription.unsubscribe();
    }
    this.orgSub.unsubscribe();
  }
}
