import { Component, OnInit } from '@angular/core';
import { StoreService } from "../../shared/store.service";
import { ConfirmationService } from "primeng/api";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Order } from "../../classes/order";
import { APIService } from '../../shared/api.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-order-view',
    templateUrl: './order-view.component.html',
    styleUrls: ['./order-view.component.css'],
    standalone: false
})
export class OrderViewComponent implements OnInit {

  order: Order;
  detail: string = 'loading...';
  inventorys: any[];
  collections: any[];
  orderCollections: any[] = [];
  roles: any[] = [];
  canAuthorise: boolean;
  dialogIcon: string;

  constructor(private confirmationService: ConfirmationService, public apiService: APIService, private router: Router, private route: ActivatedRoute, private storeService: StoreService) {
    route.params.subscribe((params: Params) => {
      let orderId = params['orderid'];

      // Retrieve the inventory list
      this.apiService.getInventory().then(inventorys => {
        if (inventorys) {
          // Retrieve collections of bundled inventory items
          this.apiService.getInventoryCollections().then(collections => {
            this.inventorys = inventorys;
            this.collections = collections || [];
            this.apiService.getOrder(orderId).then(results => {
              // Order retrieved.
              this.order = results.order;
              this.roles = results.roles;
              this.canAuthorise = this.roles.filter(r => r.role === 'auth_order').length === 1;
              this.detail = 'Order for ' + this.order.site.title;
              // Set collections qty to the orders
              this.order.surveyItems.forEach(orderSurveyItem => {
                let filter = this.collections.filter(c => c.id === orderSurveyItem.survey_id);
                if (filter) {
                  // The survey item exists, update it's qty
                  filter[0].qty = orderSurveyItem.qty;
                  this.orderCollections.push(filter[0]);
                }
              });
            });
          });
        }
      });
    });

  }

  ngOnInit() {
    this.storeService.sectionBannerBack
      .pipe(filter(sbb => !!sbb && sbb.state === true && sbb.section === 'orders'))
      .subscribe(state => {
        console.log('back to orders landing');
        // Set to handled
        state.state = false;
        this.storeService.setSectionBannerBack(state);
        // Back to training
        this.router.navigate(['/orders']);
      });
  }

  orderCancel(deleteSite: boolean = false) {
    this.dialogIcon = 'fa fa-trash';

    let msg = 'Cancel this order permanently?';

    if (deleteSite) {
      msg += ' Any site or address created as part of this order will be deleted.';
    }

    this.confirmationService.confirm(
      {
        message: msg,
        accept: () => {
          this.apiService.deleteOrder(this.order.id, deleteSite).then(r => {
            this.apiService.toastSuccess('cancelled', 'Order cancelled');
            setTimeout(() => {
              this.router.navigate(['/orders']);
            });
          })
        }
      });
  }

  modifyOrder() {
    this.apiService.orderStatus(this.order.id, 'editing').then(r => {
      this.apiService.toastSuccess('Status changed.', '');
      setTimeout(() => {
        this.router.navigate(['/orders']);
      });
    });
  }
}
