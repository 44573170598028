import { Component, OnInit, input, output, signal } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { Site } from 'app/classes/site';
import { IDatesFromTo } from 'app/interfaces/dates-from-to';
import { SetpointQueryDateRange } from 'app/setpoints/setpoint-query/setpoint-query.component';
import { APIService } from 'app/shared/api.service';

@Component({
    selector: 'app-dialog-asset-view',
    templateUrl: './dialog-asset-view.component.html',
    styleUrls: ['./dialog-asset-view.component.css'],
    standalone: false
})
export class DialogAssetViewComponent implements OnInit {

  assetId = input.required<number>();
  dates = input.required<IDatesFromTo>();

  asset = signal<Asset>(null);
  site: Site;

  setpointDateRange: SetpointQueryDateRange;

  onClose = output<boolean>();

  constructor(private apiService: APIService) {

  }

  ngOnInit(): void {
    this.setpointDateRange = new SetpointQueryDateRange({ ...this.dates(), type: 'custom' });
    this.get();
  }

  get() {
    if (!this.assetId()) {
      return;
    }
    console.log('getting asset for ', this.assetId());
    this.apiService.getAsset(this.assetId()).then(asset => {
      this.apiService.getSite(asset.site_id).then(site => {
        this.site = site;
        this.asset.set(asset);
      })
    });
  }

  cancel() {
    this.onClose.emit(true);
  }
}
