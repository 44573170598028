import { Component, OnInit, OnDestroy, AfterViewInit, signal } from '@angular/core';
import { APIService } from '../../shared/api.service';
import { StoreService } from '../../shared/store.service';
import { Router, RouterOutlet, RouterLink, RouterLinkWithHref, RouterLinkActive } from '@angular/router';
import { Widget } from '../../classes/widget';
import { ChartFavs } from 'app/classes/charts';
import { ChartService } from 'app/shared/chart.service';
import { Subscription } from 'rxjs';

export const ROUTER_DIRECTIVES = [RouterOutlet, RouterLink, RouterLinkWithHref,
  RouterLinkActive];

declare var window: any;

@Component({
    selector: 'app-sites-dashboard-v2',
    templateUrl: './sites-dashboard-v2.component.html',
    styleUrls: ['./sites-dashboard-v2.component.css'],
    standalone: false
})
export class SitesDashboardV2Component implements OnInit, OnDestroy, AfterViewInit {

  // Check every 30 minutes
  // Disabled, not used.
  static readonly CHECK_EVERY = 1800 * 1000;
  tab: 'reviews' | 'explorer' | 'compliance' | 'changelog' | string = 'reviews';
  wantsToExplore: boolean;
  loading = true;
  timerSubscription: any;
  widgets: any[][] = [];
  gateways: number;
  widget: any = { state: 'available' };
  selectedWidget: any;
  data: any;
  totals: any;
  title: string;
  haveData: boolean;
  timer: any;
  moduleAccess: any;
  user: any;
  docCount: number = null;
  chartFavs: ChartFavs;
  chartSubscription: Subscription;
  isReady = false;
  tabIndex = signal<SitesDashboardTabs>('reviews');

  can = { compliance: true, explorer: true, reviews: true, visualise: false };

  constructor(private router: Router, private chartService: ChartService, private apiService: APIService, private storeService: StoreService) {

    this.user = apiService.user;
    this.moduleAccess = apiService.moduleAccess;
    this.can.compliance = !!this.moduleAccess.compliance;

    this.chartSubscription = chartService.charts.subscribe(chartFavs => {
      this.chartFavs = chartFavs;
    });

    this.tabIndex.set(<SitesDashboardTabs>localStorage.getItem('home:tab:index') || 'reviews');

    if (this.tabIndex() === 'compliance' && !this.can.compliance) {
      // Ensure that the user does not get stuck on a tab they have no access too.
      this.tabIndex.set('reviews');
    }
  }

  ngOnInit() {
    this.widgets[0] = [];
    this.widgets[0][0] = new Widget('orange', 'Gateways', null, 'orange-0');

    this.widgets[0][1] = new Widget('gateways-telemetry-today', 'Telemetry Today', null, 'orange-1');
    this.widgets[0][2] = new Widget('gateways-disconnected', 'Disconnects Today', null, 'orange-2', null, true);
    this.widgets[0][3] = new Widget('gateways-online', 'Gateways Online', null, 'orange-3');
    this.widgets[0][4] = new Widget('assets-inerror', 'Sensors in Error', null, 'orange-4', null, null, true);
    this.widgets[0][5] = new Widget('alarms-delayed', 'Alarms Delayed', null, 'red-1', null, null, true);
    this.widgets[0][6] = new Widget('alarms-not-actioned', 'Alarms New', null, 'red-1', 'New Alarms', null, true);
    this.widgets[0][7] = new Widget('alarms-acknowledged', 'Alarms Known', null, 'red-2', 'Alarms Acknowledged', null, true);
    this.widgets[0][8] = new Widget('alarms-resolved-today', 'Alarms Resolved', null, 'red-3', 'Alarms Resolved');

    if (!this.apiService.isAdmin()) {
      this.widgets[0][5].clickable = false;
      this.widgets[0][6].clickable = false;
      this.widgets[0][7].clickable = false;
    }

    this.storeService.setIsLoading(false);
    this.loading = false;
    setTimeout(() => {
      this.isReady = true;
    }, 10);
    this.can.visualise = this.apiService.isAdmin();
  }

  /**
   * @deprecated
   */
  getDashboard() {
    this.storeService.setIsLoading(false);
    this.apiService.getDashboard().then(
      results => {
        this.loading = false;

        this.setWidget('alarms-delayed', results[8][0].alarmsAtDelayed);
        this.setWidget('alarms-not-actioned', results[3][0].alarmsAtNew);
        this.setWidget('alarms-acknowledged', results[5][0].alarmsAtAcknowledged);
        this.setWidget('alarms-resolved-today', results[2][0].alarmsResolvedToday);
        // this.setWidget('sites-total', results[4][0].siteTotal);
        this.setWidget('gateways-telemetry-today', results[6][0].telemetry);
        this.setWidget('gateways-disconnected', results[6][0].disconnected ? results[6][0].disconnected : '0');
        this.setWidget('assets-inerror', results[7][0].total);
        const perc = (results[1][0].gatewayOnlineTotal * (100 / results[1][0].total)).toFixed(0);
        this.setWidget('gateways-online', results[1][0].gatewayOfflineTotal ? results[1][0].gatewayOfflineTotal : '0', perc);

        if (!this.haveData) {
          this.storeService.setIsLoading(false);
        }

        this.haveData = true;
      }
    );
  }

  selectWidget(widget) {
    if (!widget.clickable) {
      // Can't click this widget
      return;
    }
    widget.setDirty(false);
    console.log(widget);
    this.selectedWidget = widget;
    this.router.navigate(['dashboard/' + widget.tag]);
  }

  getWidget(tag: string) {
    let idx1, idx2;
    switch (tag) {
      case 'alarms-delayed':
        idx1 = 0;
        idx2 = 5;
        break;
      case 'alarms-not-actioned':
        idx1 = 0;
        idx2 = 6;
        break;
      case 'alarms-acknowledged':
        idx1 = 0;
        idx2 = 7;
        break;
      case 'alarms-resolved-today':
        idx1 = 0;
        idx2 = 8;
        break;
      case 'sites-total':
        idx1 = 1;
        idx2 = 1;
        break;
      case 'gateways-disconnected':
        idx1 = 0;
        idx2 = 2;
        break;
      case 'gateways-telemetry-today':
        idx1 = 0;
        idx2 = 1;
        break;
      case 'gateways-online':
        idx1 = 0;
        idx2 = 3;
        break;
      case 'assets-inerror':
        idx1 = 0;
        idx2 = 4;
        break;
      default:
        return;
    }

    return this.widgets[idx1][idx2];
  }

  setWidget(tag, value, perc?) {
    let idx1, idx2;
    switch (tag) {
      case 'alarms-delayed':
        idx1 = 0;
        idx2 = 5;
        break;
      case 'alarms-not-actioned':
        idx1 = 0;
        idx2 = 6;
        break;
      case 'alarms-acknowledged':
        idx1 = 0;
        idx2 = 7;
        break;
      case 'alarms-resolved-today':
        idx1 = 0;
        idx2 = 8;
        break;
      case 'gateways-total':
        idx1 = 0;
        idx2 = 1;
        break;
      case 'sites-total':
        idx1 = 1;
        idx2 = 1;
        break;
      case 'gateways-disconnected':
        idx1 = 0;
        idx2 = 2;
        break;
      case 'gateways-telemetry-today':
        idx1 = 0;
        idx2 = 1;
        break;
      case 'gateways-online':
        idx1 = 0;
        idx2 = 3;
        break;
      case 'assets-inerror':
        idx1 = 0;
        idx2 = 4;
        break;
      default:
        return;
    }
    console.log('setting ' + tag + ' widget to ', value);
    if (this.widgets[idx1][idx2].setData(value, perc)) {
      // Dirty

    }
  }

  toggleFullscreen() {
    const doc: any = window.document;
    const docEl: any = doc.documentElement;

    const requestFullScreen = docEl.requestFullscreen || docEl.webkitRequestFullScreen;
    const cancelFullScreen = doc.exitFullscreen || doc.webkitExitFullscreen;

    if (!doc.fullscreenElement && !doc.webkitFullscreenElement) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  }

  ngOnDestroy() {
    console.log('Stop dashboard subscription.');
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    if (this.chartSubscription) {
      this.chartSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  didLoad(docCount: number) {
    this.docCount = docCount;
  }

  explore() {
    this.wantsToExplore = !this.wantsToExplore;
  }

  handleTabChange(event: any) {
    console.log(event);
    //this.defaultTab = event.index;
    // localStorage.setItem('home:tab:index', String(this.defaultTab));
  }

  tabChange(tab: SitesDashboardTabs) {
    this.tabIndex.set(tab);
    localStorage.setItem('home:tab:index', tab);
  }
}


export type SitesDashboardTabs = 'reviews' | 'explorer' | 'compliance' | 'visualise' | 'changelog';
