/**
 * Occupaction Collection
 * - shared with manage
 * @version 1
 */
import { Org } from "./org";
export class OccCollection {
	title: string;
	id: number;
	isActive: boolean;
	days: OccCollectionDay[] = [];
	bgJobs: { id: number, state: string, collectionId: number, createdAt: Date, createdByName: string }[];
	assets: { id: number, title: string, sTitle: string, gTitle: string, dayCount?: number, value?: number, updatedAt?: Date }[];
	org: Org;
	assetCount: number;

	constructor(data?: any) {
		if (!data) {
			return;
		}
		this.isActive = data.isActive === 1;
		this.title = data.title;
		this.id = data.id;
		this.days = data.days.map(days => new OccCollectionDay(days));
		this.org = new Org({ id: data.org_id, title: data.oTitle, logo: data.logo });
		this.assetCount = data.assetCount || null;
		this.bgJobs = data.bgJobs || [];
		this.assets = data.assets || [];
	}

	getSuggestedNewDayRow() {
		const day = new OccCollectionDay();
		if (!this.days.length) {
			// Default to M-F
			day.days = [1, 1, 1, 1, 1, 0, 0];
		} else if (this.days.length) {
			// Default to M-F
			const days = [0, 0, 0, 0, 0, 0, 0];
			this.days.forEach(dayDays => dayDays.days.forEach((day, index) => {
				if (day === 1) { days[index] = 1 }
			}));
			day.days = days.map(day => day === 1 ? 0 : 1);
		}

		if (day.days.some(day => day === 1)) {
			return day
		} else {
			return null;
		}
	}

	clone(): OccCollection {
		const obj = new OccCollection();
		obj.title = this.title;
		obj.id = this.id;
		obj.isActive = this.isActive;
		obj.days = this.days.map(day => day.clone());
		obj.org = new Org(this.org?.serialise());
		obj.assetCount = this.assetCount;

		return obj;
	}


	serialise() {
		return {
			id: this.id,
			title: this.title,
			isActive: this.isActive,
			days: this.days.map(days => days.serialise())
		};
	}
}


export class OccCollectionDay {
	days: number[] = [0, 0, 0, 0, 0, 0, 0];
	duration: number;
	_startTime: string;
	_endTime: string;

	public set startTime(v: string) {
		this._startTime = v;
		if (v) {
			this.calcMaxDuration();
		}
	}

	public get startTime(): string {
		return this._startTime;
	}

	public set endTime(v: string) {
		this._endTime = v;
		if (v) {
			this.calcMaxDuration();
		}
	}

	public get endTime(): string {
		return this._endTime;
	}

	maxDuration: number;

	title: string;
	isActive: boolean;
	id: number;

	constructor(data?: any) {
		if (!data) {
			return;
		}
		this.id = data.id;
		this.days = data.days;
		this.duration = data.duration;
		this.isActive = data.isActive === 1 || data.isActive === true;
		this.title = data.title;
		this.startTime = data.startTime.substring(0, 5);
		this.endTime = data.endTime.substring(0, 5);

	}

	clone() {
		return new OccCollectionDay(this.serialise());
	}

	calcMaxDuration() {
		if (this.startTime && this.endTime) {

			const dateStart = new Date(`1969-01-01T${this.startTime}:00Z`);
			const dateEnd = new Date(`1969-01-01T${this.endTime}:00Z`);

			const timeDifference = +dateEnd - +dateStart;

			// Calculate the number of minutes in the time difference
			this.maxDuration = Math.abs(Math.floor(timeDifference / (1000 * 60)));
		}
	}

	serialise() {
		return {
			id: this.id,
			duration: this.duration,
			startTime: this.startTime.length === 5 ? this.startTime + ":00" : this.startTime,
			endTime: this.endTime.length === 5 ? this.endTime + ":00" : this.endTime,
			days: this.days,
			isActive: this.isActive,
			title: this.title
		};
	}
}
