import { Component, signal } from '@angular/core';
import { DataTable, IDataClassConfig } from 'app/classes/data-table/data-table';
import { APIService } from 'app/shared/api.service';
import { IGetTrackingIssue, IssueTrackingService } from 'app/shared/issue-tracking.service';

@Component({
  selector: 'app-issue-tracking-list',
  standalone: false,

  templateUrl: './issue-tracking-list.component.html',
  styleUrl: './issue-tracking-list.component.css'
})
export class IssueTrackingListComponent {

  hasLoaded = signal<boolean>(false);
  isEnabled = signal<boolean>(false);
  issues = signal<IGetTrackingIssue[]>(null);
  dataTable: DataTable;
  dataClassConfig: IDataClassConfig;

  selectedIssue = signal<IGetTrackingIssue>(null);

  constructor(private apiServce: APIService, private issueTracking: IssueTrackingService) {
    this.get();
  }

  get() {
    this.issueTracking.getIssues().then(response => {
      console.log('RESPONSE', response);
      const { isEnabled } = response.master;
      this.isEnabled.set(isEnabled);

      if (isEnabled) {
        this.issues.set(response.issues);
        this.filter();
      }

      this.hasLoaded.set(true);
    });
  }

  filter() {
    this.dataClassConfig = {
      "reviewDate": { valueType: "date", title: 'Issue Date', align: 'left', width: 110, dataFormat: 'date' },
      "buildingTitle": { valueType: "string", title: 'Building', align: 'left' },
      "assetTitle": { valueType: "string", title: 'Asset', align: 'left' },
      "annotation": { valueType: "string", title: 'Issue', align: 'left' },
      "status": { valueType: "string", title: 'Status', align: 'left' },
      "proposedAction": { valueType: "string", title: "Proposed Action", align: 'left' },
    };
    if (this.issues().length) {
      this.dataTable = new DataTable({ data: this.issues(), sortedColumns: null }, this.dataClassConfig);
    } else {
      this.dataTable = null;
    }
  }

  rowClick(row: IGetTrackingIssue) {

    this.selectedIssue.set(row);

  }
}
