import { Component, OnInit, AfterViewInit, signal } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { APIService } from '../../shared/api.service';
import { User } from '../../classes/user';
import { Org } from '../../classes/org';
import { Report } from '../../classes/reports/report';
import { ReportBlock } from '../../classes/reports/report-block';
import { ReportFragment } from '../../classes/reports/report-fragment';

@Component({
    selector: 'app-report-list',
    templateUrl: './report-list.component.html',
    styleUrls: ['./report-list.component.css'],
    standalone: false
})
export class ReportListComponent implements OnInit, AfterViewInit {

  view = signal<string>('card');
  reports: Report[];
  isAdmin: boolean;

  constructor(private router: Router, private apiService: APIService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.isAdmin = this.apiService.isAdmin();
    this.apiService.report.subscribe(
      (report) => {
        if (report) {
        }
      }
    );
    console.log('ReportListComponent getReports()...');

    this.apiService.getReports()
      .then((reports) => {
        this.reports = reports;
      });
  }

  triggerReport() {

  }

  selectReport(report: Report) {

  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }
}
