import { Component, OnInit, Input } from '@angular/core';
import { WebsocketIn } from 'app/classes/websocket-in';
import { BuildingsService } from 'app/shared/buildings.service';

@Component({
    selector: 'app-dashboard-table',
    templateUrl: './dashboard-table.component.html',
    styleUrls: ['./dashboard-table.component.css'],
    standalone: false
})
export class DashboardTableComponent implements OnInit {
  // The rep
  filtered: WebsocketIn[];

  @Input()
  rows: WebsocketIn[];

  _mode: string;
  @Input()
  public get mode(): string {
    return this._mode;
  }
  public set mode(v: string) {
    if (!v) {
      return;
    }
    if (v !== this._mode) {
      // Switching mode
      this.modeChange(v);
    }
    this._mode = v;
  }

  constructor(public buildingService: BuildingsService) { }

  ngOnInit() {

  }

  modeChange(mode: string) {
    switch (mode) {
      case 'buildings':
        break;
    }
  }
}
