
import { Component, EventEmitter, Input, Output, input } from '@angular/core';

@Component({
    selector: 'app-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.css'],
    standalone: false
})
export class SearchInputComponent {

  @Output()
  onSearchChanged = new EventEmitter<string>();

  @Input()
  searchText: string;

  @Input()
  tooltipText: string;

  resultCount = input<number>(null);

  constructor() { }

  searchChanged() {
    this.onSearchChanged.emit(this.searchText);
  }
}
