import { Component, computed, effect, input, OnInit, output } from '@angular/core';
import { DataTable, IDataClassConfig } from 'app/classes/data-table/data-table';

@Component({
  selector: 'app-telemetry-table',
  templateUrl: './telemetry-table.component.html',
  styleUrl: './telemetry-table.component.css',
  standalone: false
})
export class TelemetryTableComponent implements OnInit {
  gatewaysBySite = input.required<IAssetGatewayBySite[]>();
  filter = input<any>();
  filteredAssets: IAssetGatewayBySite[] = [];
  sortBy = [];
  dataTable: DataTable;

  gateways = computed(() => {
    const gateways = [].concat(...this.gatewaysBySite());

    return gateways;
  });

  onAssetSelected = output<IAssetGatewayBySite>();

  constructor() {
    effect(() => {
      console.log(`The current count is: ${this.gateways().length}`);
      this.filterTable();
    });
  }

  ngOnInit(): void {
  }

  onRowClick(ev: any) {
    this.clickAsset(ev);
  }

  filterTable() {
    this.filteredAssets = this.gateways();
    this.sortBy.forEach(sortParameter => {
    });

    const classConfig: IDataClassConfig = {
      "gatewayTitle": { valueType: "string", title: 'Gateway', align: 'left' },
      "title": { valueType: "string", title: 'Asset', align: 'left' },
      "assetTypeTitle": { valueType: "string", title: "Sensor", align: 'left' },
      "value": { valueType: "number", title: 'Original', align: 'right', isHidden: true },
      "formattedValue": { valueType: "string", title: 'Value', align: 'right', sortUsingKey: 'value' },
    };

    this.dataTable = new DataTable({ data: this.filteredAssets, sortedColumns: null }, classConfig);
  }

  clickAsset(asset: IAssetGatewayBySite) {
    this.onAssetSelected.emit(asset);
  }
}

export interface IAssetGatewayBySite {
  "assetId": number;
  "assetType": number;
  "display"?: string;
  "gatewayId": string;
  "gatewayOnline": string;
  "gatewayTitle": string;
  "identifier": string;
  "max": number;
  "min": number;
  "purposeId": null,
  "rag": null,
  "siteId": number;
  "siteTitle": string;
  "setting": any,
  "title": string;
  "updatedAt": string;
  "value": string;
}
