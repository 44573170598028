import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { APIService } from 'app/shared/api.service';
import { UserService } from 'app/shared/user.service';

@Component({
    selector: 'app-org-contact-new',
    templateUrl: './org-contact-new.component.html',
    styleUrls: ['./org-contact-new.component.css'],
    standalone: false
})
export class OrgContactNewComponent implements OnInit {

  @Input()
  public set contact(contact: any) {
    if (contact) {
      this.user = contact;
      this.user.job_title = contact.jobTitle;
    } else {
      this.createContact();
    }
  }

  user: { id: number | null, name: string, email: string, notes: string, job_title: string };

  @Output()
  hasSaved: EventEmitter<any> = new EventEmitter();

  @Output()
  hasDeleted: EventEmitter<any> = new EventEmitter();

  constructor(private apiService: APIService, private userService:UserService) {

  }

  ngOnInit(): void {
  }

  cancel() {
    this.hasSaved.emit();
  }

  createContact() {
    this.user = { id: null, email: '', name: '', notes: '', job_title: '' };
  }

  submitContact() {
    if (this.user.email.length < 10 || this.user.name.length < 2) {
      this.apiService.toastWarn('Form issues', 'email or name too short');
      return;
    }

    this.apiService
      .postContactForSite(0, this.user.name, this.user.email, this.user.notes, { job_title: this.user.job_title }, this.user.id)
      .then(results => {
        this.hasSaved.emit(this.user);
      });
  }

  deleteContact() {
    this.userService.deleteUser(this.user.id).then(r => {
      console.log(r);
      this.hasDeleted.emit(this.user);
    })
      
  }
}
