import { Component, OnInit } from '@angular/core';
import { User } from '../../classes/user';

@Component({
    selector: 'app-partner-user-list',
    templateUrl: './partner-user-list.component.html',
    styleUrls: ['./partner-user-list.component.css'],
    standalone: false
})
export class PartnerUserListComponent implements OnInit {

  users: User[] = [];
  constructor() { }

  ngOnInit() {
  }

}
