import { Component, Input, OnInit, signal } from "@angular/core";
import { Grouping } from "app/classes/grouping";
import { RulePackage } from "app/classes/rule-service/rule-package";
import { OrgService } from "app/shared/org.service";

@Component({
    selector: "app-rule-engine-notifications",
    templateUrl: "./rule-engine-notifications.component.html",
    styleUrls: ["./rule-engine-notifications.component.css"],
    standalone: false
})
export class RuleEngineNotificationsComponent implements OnInit {
  @Input()
  rule: RulePackage;

  groupings: Grouping[];
  list: any[];
  dialogRef: any;
  isModifying: null | "user-groups";
  showDialog = signal(false);
  selected: Grouping[];
  groupingActive: Grouping;
  dialogGroupings: Grouping[];
  pleaseWait = signal(false);

  constructor(private orgService: OrgService) { }

  ngOnInit(): void {
    this.get();
  }

  combine() {
    this.list = this.rule.notifications;
  }

  get() {
    this.orgService.getGroupings().then((l) => (this.groupings = l));
  }

  modifyUserGroups() {
    this.pleaseWait.set(true);
    this.orgService.getGroupingsAndUsers().then((groupings) => {
      this.dialogGroupings = groupings;
      this.groupingActive = groupings[0];
      this.rule.groupings.forEach(g => g.selected = this.groupings.some(s => s.id === g.id));
      this.showDialog.set(true);
      this.pleaseWait.set(false);
    });
  }

  selectedGroups(event: any) {
    if (event) {
      this.rule.groupings = this.dialogGroupings;
    }
  }

  cancel() {
    this.showDialog.set(null);
  }

  async submit(event: any) {
    this.showDialog.set(null);
    if (event) {
      this.rule.groupings = this.dialogGroupings.filter(g => g.selected);
    }
  }
}
