import { Component, HostListener, Input, OnInit, signal } from '@angular/core';
import { Asset } from '../../../classes/asset';
import { SettingItem } from '../../../classes/setting-item';
import { Site } from '../../../classes/site';
import { SetpointQueryDateRange } from '../../../setpoints/setpoint-query/setpoint-query.component';
import { APIService } from '../../../shared/api.service';
import { AssetService } from '../../../shared/asset.service';

import moment from 'moment';

@Component({
    selector: 'app-explorer-asset',
    templateUrl: './explorer-asset.component.html',
    styleUrls: ['./explorer-asset.component.css'],
    standalone: false
})
export class ExplorerAssetComponent implements OnInit {

  @Input()
  api: any;

  _tab = signal<'details' | 'custom'>('details');

  _asset = signal<Asset>(new Asset());
  noUpdateFor24Hours: boolean;
  isEditingYourRef = signal<boolean>(false);

  @Input()
  public set asset(v: Asset) {
    this._asset.set(v);
    if (v) {
      this.noUpdateFor24Hours = moment().diff(moment(v.updatedAt), 'days') >= 7;
    }
  }
  public get asset(): Asset {
    return this._asset();
  }

  @Input()
  site: Site;

  @Input()
  alwaysUseCustom: boolean;

  @Input()
  setpointQueryDateRange: SetpointQueryDateRange;


  isSaving: boolean;
  isAddingProperty: boolean;
  settingIndex: number;
  isDirty: boolean;

  // Editable string ref
  dateFormat: string;

  isFav: boolean;
  isAdmin = signal<boolean>(false);

  can = { editAsset: false };

  @HostListener('document:click', ['$event'])
  handleClick(event: MouseEvent) {
    if (this.settingIndex >= 0) {
      this.settingIndex = -1;
    }
  }

  isChangingFavState: boolean;

  constructor(private apiService: APIService, public assetService: AssetService) {
    this.dateFormat = apiService.getDateFormat();
    this.isAdmin.set(apiService.isAdmin());
  }

  ngOnInit(): void {
    this.getIsFav();
    this.can.editAsset = this.apiService.hasOrgRole('amend_asset') || this.apiService.isAdmin();
  }

  getIsFav(force = false) {
    this.assetService.getUserFavourites(force).then(assets => {
      this.isFav = assets.some(asset => asset.id === this.asset.id);
    });
  }

  async clickFav() {
    this.isChangingFavState = true;
    if (this.isFav) {
      await this.assetService.removeUserFavourite(this.asset.id);
    } else {
      await this.assetService.addUserFavourite(this.asset.id, this.asset.labels);
    }
    this.getIsFav(true);
    this.isChangingFavState = false;
  }

  addProperty() {
    this.isAddingProperty = true;
    this.asset.settings.push(new SettingItem({ setting: '', type: 'string', value: '' }));
  }

  saveProperty() {
    this.isSaving = true;
    this.isAddingProperty = false;
    this.apiService
      .patchAssetSettings(this.asset)
      .then(response => {
        if (response) {
          this.rebuild();
          this.apiService.toastSuccess('Updated', '');
        }
        this.isSaving = false;
      });
  }

  removeProperty(i: number) {
    this.asset.settings.splice(i, 1);
    this.settingIndex = -1;
    this.isDirty = true;
  }


  addToFilterClick(event) {
    event.stopPropagation();
    // this.addToFilter.emit(this.asset.settings[this.settingIndex].setting);
    this.settingIndex = -1;
  }

  deletePropertyClick(event) {
    event.stopPropagation();
    this.asset.settings.splice(this.settingIndex, 1);
    this.isDirty = true;
    this.settingIndex = -1;
  }

  settingMenuShown(index: number, event) {
    event.stopPropagation();

    if (this.settingIndex === index) {
      this.settingIndex = -1;
      return;
    }
    this.settingIndex = index;
    console.log(index);
  }

  rebuild() {

  }

  saveRefClick() {
    this.asset.ref = this.asset.ref ? this.asset.ref.trim() : '';
    this.apiService.toastSuccess('Updating your reference...', '');
    this.isEditingYourRef.set(false);
    this.apiService
      .patchAssetRef(this.asset.id, this.site.org.id, this.asset.ref)
      .then(results => {
        console.log(results);
        this.apiService.toastSuccess('Your reference updated.', '');
      });
  }

}
