import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { StoreService } from '../store.service';

@Component({
    selector: 'app-d3-pie',
    templateUrl: './d3-pie.component.html',
    styleUrls: ['./d3-pie.component.css'],
    standalone: false
})
export class D3PieComponent implements OnInit, AfterViewInit {

  @ViewChild('svgContainer', { static: false }) divContainer: ElementRef;

  _data: any;
  @Input()
  public get data(): any {
    return this._data;
  }

  public set data(v: any) {
    this._data = v;
    if (v && this.init) {

      this.createSvg();
      this.build();
    }
  }

  @Input()
  legend: any;

  @Input()
  innerRing: number;

  @Input()
  margin: { left: number, right: number, top: number, bottom: number } = { left: 10, right: 10, top: 10, bottom: 10 };

  @Input()
  height: number = 750;

  @Input()
  width: number = 400;

  @Input()
  value: string;

  @Input()
  fontSize: number = 20;

  @Output()
  onSliceClick = new EventEmitter<string>();

  private svg;

  _width: number;
  _height: number;

  radius: number;
  private init = false;
  selectedIndex = -1;

  constructor(private storeService: StoreService) { }

  ngOnInit() {

    this._width = this.width - this.margin.left - this.margin.right;
    this._height = this.height - this.margin.top - this.margin.bottom;
    this.radius = Math.min(this._width, this._height) / 2 - (this.margin.left + this.margin.right);
  }

  ngAfterViewInit() {
    this.createSvg();
    this.build();
    this.init = true;
  }

  createSvg(): void {
    const ne = this.divContainer.nativeElement;

    const colours = this.storeService.getThemeColours();

    console.log(ne);

    if (ne.clientWidth && ne.clientHeight) {
      this.width = ne.clientWidth - this.margin.left - this.margin.right;
      this.height = ne.clientHeight - this.margin.top - this.margin.bottom;
      this.radius = Math.min(this.width, this.height) / 2 - (this.margin.left + this.margin.right);
    }

    d3.select('div.chart').select('svg').remove();
    this.svg = d3.select('div.chart')
      .append('svg')
      .style('background-color', '')
      .style('fill', colours.foreground_colour)
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + (this.width / 2) + ',' + (this.margin.top + (this.height / 2)) + ')');
  }

  build() {
    const self = this;
    const color = d3.scaleOrdinal()
      .domain(Object.keys(this.data))
      .range(['#98abc5', '#8a89a6', '#7b6888', '#6b486b', '#a05d56']);

    const keys = Object.keys(this.data).filter(k => this.data[k] !== 0);
    const values = keys.map(key => this.data[key]);
    const pie = d3.pie();

    // Creating arc
    const arc = d3.arc()
      .innerRadius(this.innerRing || 0)
      .outerRadius(this.radius);

    // Grouping different arcs
    const arcs = this.svg.selectAll('arc')
      .data(pie(values))
      .enter()
      .append('g');

    if (this.value) {
      this.svg
        .append("g")
        .attr("transform", "translate(5," + (this.fontSize - 4) / 2 + ")")
        .append("text")
        .attr("text-anchor", "middle")
        .attr("font-size", this.fontSize)
        .text(this.value);
    }

    // Appending path
    arcs.
      on('click', function (event, d) {
        console.log(d);
        const e = arcs.nodes();
        const i = e.indexOf(this);
        console.log(d, i, e);
        if (self.selectedIndex === i) {
          self.selectedIndex = -1;
          self.onSliceClick.emit(null);
        } else {
          self.selectedIndex = i;
          console.log(self.legend[keys[i]]);
          self.onSliceClick.emit(keys[i]);
        }

      })
      .on('mouseenter', function (event, d) {
        d3.select(this.children[0])
          .attr('stroke', 'white')
          .transition()
          .duration(500)
          .attr('d', arc)
          .attr('stroke-width', 6);
      })

      .on('mouseleave', function (event, d) {
        d3.select(this.children[0]).transition()
          .attr('d', arc)
          .attr('stroke', 'none');
      })
      .append('path')

      .attr('fill', (data, i) => this.legend[keys[i]].colour)
      .attr('d', arc);

    arcs
      .append('text')
      .attr('transform', (d, i) => {
        const [x, y] = arc.centroid(d);
        const rotation = d.endAngle < Math.PI ? (d.startAngle / 2 + d.endAngle / 2) * 180 / Math.PI : (d.startAngle / 2 + d.endAngle / 2 + Math.PI) * 180 / Math.PI;

        const vert = rotation > 400 ? -90 : 90;

        console.log(i);
        return 'translate(' + [x, y] + ') rotate(-90) rotate(' + rotation + ')';
      })
      .attr('fill', 'white')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text((d, i) => {
        return this.legend[keys[i]].label;
      })
      .append('title').text((d, i) => d.data);
    ;
  }

}
