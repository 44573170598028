import { Component, Output, EventEmitter, input, effect } from '@angular/core';


@Component({
    selector: 'app-telemetry-list-search',
    templateUrl: './telemetry-list-search.component.html',
    styleUrls: ['./telemetry-list-search.component.css'],
    standalone: false
})
export class TelemetryListSearchComponent {

  searchFor = input<string>(null);

  toEmit: string;

  resultCount = input<number>(null);

  @Output()
  onSearchChanged: EventEmitter<string> = new EventEmitter();

  constructor() {
    effect(() => {
      console.log(this.resultCount(), 'changed');
    });
  }

  searchChanged(event: string) {
    console.log(event);
    if (this.toEmit !== event) {
      this.toEmit = event;
      setTimeout(() => {
        if (this.toEmit === event) {
          this.onSearchChanged.emit(event);
        }
      }, 1500);
    }
  }

}
