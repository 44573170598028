import {
  Component,
  OnInit,
  Input,
  Output,
  AfterViewInit,
  EventEmitter,
  OnDestroy
}

  from '@angular/core';

import {
  Router,
  ActivatedRoute,
  Params
}

  from '@angular/router';

import {
  APIService
}

  from '../../../shared/api.service';

import {
  RulePackage
}

  from '../../../classes/rule-service/rule-package';

import {
  StoreService
}

  from '../../../shared/store.service';

import {
  Setpoint
}

  from '../../../classes/setpoint';

import {
  filter,
  take
}

  from 'rxjs/operators';

import {
  SelectedAsset
}

  from 'app/classes/rule-service/selected-asset';

@Component({
    selector: 'app-rule-engine-v2-item',
    templateUrl: './rule-engine-v2-item.component.html',
    styleUrls: ['./rule-engine-v2-item.component.css'],
    standalone: false
}

) export class RuleEngineV2ItemComponent implements OnInit,
  AfterViewInit,
  OnDestroy {

  @Input() rulePackage: RulePackage;

  @Output() changed: EventEmitter<RulePackage> = new EventEmitter<RulePackage>();

  rulePackageId: number;
  subview: string;
  domElementSelected: any;
  dirty: boolean;
  subscription: any;
  setpoints: Setpoint[] = [];
  isShowingPopupSensorData: boolean;
  selectedAsset: any;

  constructor(private storeService: StoreService, private apiService: APIService, private router: Router, private route: ActivatedRoute) {
    this.setpoints = [];

    for (let i = 0; i < 7; i++) {
      this.setpoints.push(new Setpoint(null, i, '', '', false, 0, 0, false));
    }
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      const id = params['ruleid'];

      if (!id) {
        // Get the complete rule package
        return this.getRulePackage(this.rulePackage.id);
      }

      this.rulePackageId = id;
      this.getRulePackage(this.rulePackageId);
    }

    );

    this.subscription = this.storeService.sectionBannerBack.pipe(filter(sbb => ! !sbb && sbb.state === true && sbb.section === 'rules')).subscribe(state => {
      // Set to handled
      state.state = false;
      this.storeService.setSectionBannerBack(state);
      this.router.navigate(['/rules']);
    }

    );
  }

  goBack() {
    this.router.navigate(['/rules']);
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  getRulePackage(id: number) {
    this.apiService.getRulePackage(id).then(rulePackage => {
      if (this.dirty) {
        // rulepackage already exists - emit the rulepackage changed
        this.changed.emit(rulePackage);
        this.dirty = false;
      }

      this.rulePackage = rulePackage;
    }

    );
  }

  getAlarm() { }

  viewHistory(event) {
    if (this.subview === 'history') {
      this.subview = null;
      return;
    }

    this.domElementSelected = event;
    this.subview = 'history';
  }

  enableRule() {
    this.setRuleIsEnabled(true, (err, results) => {
      this.getRulePackage(this.rulePackage.id);
    }

    );
  }

  disableRule() {
    this.setRuleIsEnabled(false, (err, results) => {
      this.getRulePackage(this.rulePackage.id);
    }

    );
  }

  setRuleIsEnabled(state: boolean, callback) {
    this.dirty = true;
    this.apiService.enableRulePackage(this.rulePackage.id, state).then(r => callback(null, r));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  selectAsset(selected: SelectedAsset) {
    this.selectedAsset = selected.asset;
    this.isShowingPopupSensorData = true;
  }

  featureToggle() {
    this.apiService.user.pipe(take(1)).subscribe(u => {
      const c = u.prefs.rules_module_v || null;

      if (c === 3) {
        u.prefs.rules_module_v = null;
      }

      else {
        u.prefs.rules_module_v = 3;
      }

      this.router.navigate(['/rules' + u.prefs.rules_module_v]);
    }

    );
  }
}
