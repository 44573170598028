@defer (when items) {
  <div class="lm-container" [class.lm-icons]="isTextMenu === false">
    @for (item of items; track item) {
      <div class="{{ item.size }} alarms-{{ alarmCount() }}" routerLink="{{ item.route }}" routerLinkActive="active2" (onclick)="scroll(0, 0)" [class.in-alarm]="item.hasAlarmCount && alarmCount()" pTooltip="{{ item.description }}" tooltipPosition="right" appendTo="body" [showDelay]="1000">
        <div><i class="mdi mdi-{{ item.icon }}"></i></div>
        <div class="title">
          <span>{{ item.title }}</span>
        </div>

        @if (item.hasAlarmCount && alarmCount() && isTextMenu) {
          <div pTooltip="{{ alarmCount() }} alarm{{ alarmCount() > 1 ? 's' : '' }}" tooltipPosition="right" appendTo="body">
            <span>{{ alarmCount() }}</span>
          </div>
        }
      </div>
    }
    <div class="lm-expand-shrink" (click)="toggleMenu()" [pTooltip]="isTextMenu ? 'Icons only' : 'Keep open'" appendTo="body">
      <div><i class="mdi" [class.mdi-format-horizontal-align-left]="isTextMenu" [class.mdi-dock-left]="!isTextMenu"></i></div>
    </div>
  </div>
} @placeholder {
  <i class="fa fa-spin fa-refresh"></i>
}
