import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import moment from 'moment';
import { IDatesFromTo } from 'app/interfaces/dates-from-to';
import { APIService } from 'app/shared/api.service';
import { SitePlanComplete } from 'app/shared/floorplan.service';

@Component({
    selector: 'app-site-plan-floorplan-toolbar',
    templateUrl: './site-plan-floorplan-toolbar.component.html',
    styleUrls: ['./site-plan-floorplan-toolbar.component.css'],
    standalone: false
})
export class SitePlanFloorplanToolbarComponent implements OnInit {

  @Output()
  onToolbarChanged = new EventEmitter<IToolbarChanged>();

  items: MenuItem[]

  _from: Date;

  public get from(): Date {
    return this._from;
  }

  public set from(v: Date) {
    this._from = v;
    this.changed();
  }


  fromDaysAgo: string;
  to: Date;

  @Input()
  view: ViewType;

  @Input()
  sitePlanComplete: SitePlanComplete;

  constructor(public apiService: APIService) {

  }

  toggleView() {
    if (this.view === 'realtime') {
      this.clickRealtime('occupancy daily');
    } else {
      this.clickRealtime('realtime');
    }
  }

  buildItems() {
    this.items = [
      {
        label: 'Realtime',
        icon: 'mdi mdi-lightning-bolt',
        command: () => {
          this.clickRealtime('realtime');
        }
      }];

    if (this.sitePlanComplete.sitePlan.hasOccupancy) {
      this.items.push({
        label: 'Occupancy Daily',
        icon: 'mdi mdi-table-chair',
        command: () => {
          this.clickRealtime('occupancy daily');
        }
      });
    }
  }

  ngOnInit() {
    moment.updateLocale('en', {
      week: {
        dow: 1, // Monday is the first day of the week.
      }
    });

    const lastWeek = moment().subtract(1, 'weeks').startOf('week');

    const dates: IDatesFromTo = {
      from: moment().startOf('day').toDate(),
      to: moment().endOf('week').endOf('day').toDate()
    };

    this._from = dates.from;
    this.to = dates.from;
    this.buildItems();
    this.changed();
  }

  clickRealtime(what: ViewType) {
    this.view = what;
    this.changed();
  }

  changed() {
    console.log('changed()');

    this.to = this.from;
    if (moment().diff(this.from, 'hours') >= 24) {
      const days = Math.abs(moment(this.from).diff(moment(), 'days'));
      this.fromDaysAgo = `${days} day${days > 1 ? 's' : ''} ago`;
    } else {
      this.fromDaysAgo = '';
    }
    this.onToolbarChanged.emit({ what: this.view, change: { dates: { from: this.from, to: this.to } } });
  }

  clickedPrevious(metric: 'default' | 'week' = 'default') {
    switch (this.view) {
      case 'occupancy daily':
        const sub = metric === 'default' ? 'day' : 'weeks';
        this._from = moment(this.from).subtract(1, sub).startOf('day').toDate();
        this.to = moment(this.to).subtract(1, sub).endOf('day').toDate();
        break;
      case 'occupancy weekly':
        this._from = moment(this.from).subtract(1, 'weeks').startOf('week').toDate();
        this.to = moment(this.from).endOf('week').endOf('day').toDate();
        break;
    }

    this.changed();
  }

  clickedNext(metric: 'default' | 'week' = 'default') {
    switch (this.view) {
      case 'occupancy daily':
        const sub = metric === 'default' ? 'day' : 'weeks';
        this._from = moment(this.from).add(1, sub).startOf('day').toDate();
        this.to = moment(this.to).add(1, sub).endOf('day').toDate();
        break;
      case 'occupancy weekly':
        this._from = moment(this.from).add(1, 'weeks').startOf('week').toDate();
        this.to = moment(this.from).endOf('week').endOf('day').toDate();
        break;
    }

    this.changed();
  }
}

export interface IToolbarChanged {
  what: ViewType;
  change: any;
}

export type ViewType = 'occupancy daily' | 'occupancy weekly' | 'realtime';
