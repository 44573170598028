import { Component, OnInit, Input } from '@angular/core';
import { Order } from "../../classes/order";
import { APIService } from '../../shared/api.service';

@Component({
    selector: 'app-order-status-table-rows',
    templateUrl: './order-status-table-rows.component.html',
    styleUrls: ['./order-status-table-rows.component.css'],
    standalone: false
})
export class OrderStatusTableRowsComponent implements OnInit {

  @Input()
  order: Order;

  @Input()
  hideTitleRow: boolean;

  constructor(public apiService: APIService) { }

  ngOnInit() {
  }

}
