import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { StoreService } from "../../shared/store.service";
import { Order } from "../../classes/order";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { APIService } from '../../shared/api.service';

@Component({
    selector: 'app-ordering-landing',
    templateUrl: './ordering-landing.component.html',
    styleUrls: ['./ordering-landing.component.css'],
    standalone: false
})
export class OrderingLandingComponent implements OnInit {

  orders: any[] = [{ site: 'Forum house', status: 'auth', created: '90 minutes ago', delivery: '27-09-17' }, { site: 'Columbia', status: 'shipped', created: '3 days ago', delivery: '22-08-17' }];

  items: MenuItem[];
  savedOrders: Order[];
  activeOrders: Order[];

  constructor(public apiService: APIService, private router: Router, private route: ActivatedRoute, private storeService: StoreService) { }

  ngOnInit() {

    this.items = [
      { label: 'Authorise' },
      { label: 'Confirm' },
      { label: 'Order' },
      { label: 'Package' },
      { label: 'Delivery' }
    ];

    this.apiService.getSavedOrders().then(r => {
      this.savedOrders = r.filter(o => o.status === 'editing');
      this.activeOrders = r.filter(o => o.status !== 'editing');
    });
    window.scrollTo(0, 0);
  }
}
