import { AfterViewInit, Component, computed, ElementRef, input, OnDestroy, output, signal, ViewChild } from '@angular/core';
import { SelectListItem } from '../select/select.component';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrl: './dropdown.component.css',
    standalone: false
})
export class DropdownComponent implements OnDestroy {
  list = input.required<SelectListItem[]>();
  placeholder = input.required<string>();
  value = input.required<number | string>();
  onSelected = output<number | string>();
  listVisible = signal<boolean>(false);
  hasBorder = input<boolean>(true);
  autoFit = input<boolean>(false);
  isBlock = input<boolean>(false);
  link = input<string>(null);
  linkIcon = input<string>('mdi mdi-plus');
  selected = signal<number | string>(null);
  delay = 0;
  dimensions = input<{ width?: number, height?: number }>(null);
  width = signal<string>('');
  useHeight = signal<string>(null);

  label = computed(() => {
    if (this.selected()) {
      const item = this.list().find(i => i.id === this.selected());
      return item.title;
    }
    const item = this.list().find(i => i.id === this.value());
    if (item) {
      return item.title;
    }
    return this.placeholder();
  });

  @ViewChild('dropdown') dropdown: ElementRef;

  constructor() {

  }

  ngOnDestroy(): void {
    console.log('destroy');

    this.removeEventListener();
  }

  chevronClick() {
    this.listVisible.set(!this.listVisible());
    if (this.listVisible()) {
      document.addEventListener('click', this.eventListener, false);
      setTimeout(() => {
        console.log('Values on ngAfterViewInit():');
        console.log("dropdown:", this.dropdown.nativeElement);
        if (!this.dimensions()?.height) {
          this.useHeight.set(`calc(100vh - 60px - ${this.dropdown.nativeElement.getBoundingClientRect().top}px)`);
        } else {
          this.useHeight.set(this.dimensions().height + 'px');
        }
        this.width.set(`${this.dropdown.nativeElement.getBoundingClientRect().width}px`)
      }, 10);


    } else {
      this.removeEventListener();
    }
  }

  listItemClick(selectItem: SelectListItem) {
    this.selected.set(selectItem.id);
    console.log('CLICK', selectItem);
    this.onSelected.emit(selectItem.id);
  }

  private eventListener = (event: any) => {
    console.log(this.listVisible(), event, this.delay);

    if (this.listVisible() && this.delay) {
      this.listVisible.set(false);
      this.removeEventListener();
      return;
    }
    this.delay++;
    if (this.delay > 1) {
      this.removeEventListener();
    }
  };

  removeEventListener() {
    console.log('removeEventListener()', this.delay)
    this.delay = 0;
    document.removeEventListener('click', this.eventListener, false);
  }

}
